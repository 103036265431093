import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { getTermProfileClassInfoFail, getTermProfileClassInfoSuccess } from '../actions';
import configActionTypes from '../types';

export function* getTermProfileClassInfo({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.get(`/class-levels/terms/${payload.id}`);
    yield put(getTermProfileClassInfoSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(getTermProfileClassInfoFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTermProfileClassInfo() {
  yield takeLatest(configActionTypes.GET_TERM_PROFILE_CLASS_INFO_START, getTermProfileClassInfo);
}
