import { takeLatest, put } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { updateOnboardingStepsFailure, updateOnboardingStepsSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import userActionTypes from '../types';

export function* updateOnboardingSteps({ payload }) {
  try {
    const response = yield httpClient.put(`/onboarding`, payload);
    yield put(updateOnboardingStepsSuccess(response.data));
    Notify('Onboarding step completed successfully', {
      position: 'top-right',
    });
  } catch (error) {
    yield put(updateOnboardingStepsFailure(error.message));
    Notify('Something went wrong', { status: 'error' });
  }
}

export function* onUpdateOnboardingStepsStart() {
  yield takeLatest(userActionTypes.UPDATE_ONBOARDING_STEPS_START, updateOnboardingSteps);
}
