const studentActionTypes = {
  CREATE_STUDENT_START: 'CREATE_STUDENT_START',
  CREATE_STUDENT_SUCCESS: 'CREATE_STUDENT_SUCCESS',
  CREATE_STUDENT_FAIL: 'CREATE_STUDENT_FAIL',
  EDIT_STUDENT_START: 'EDIT_STUDENT_START',
  EDIT_STUDENT_SUCCESS: 'EDIT_STUDENT_SUCCESS',
  EDIT_STUDENT_FAIL: 'EDIT_STUDENT_FAIL',
  DELETE_STUDENT_START: 'DELETE_STUDENT_START',
  DELETE_STUDENT_SUCCESS: 'DELETE_STUDENT_SUCCESS',
  DELETE_STUDENT_FAIL: 'DELETE_STUDENT_FAIL',
  CREATE_STUDENT_MODAL: 'CREATE_STUDENT_MODAL',
  DELETE_STUDENT_MODAL: 'DELETE_STUDENT_MODAL',
  FETCH_STUDENTS_START: 'FETCH_STUDENTS_START',
  FETCH_STUDENTS_SUCCESS: 'FETCH_STUDENTS_SUCCESS',
  FETCH_STUDENTS_FAIL: 'FETCH_STUDENTS_FAIL',
  HANDLE_STUDENT_PROFILE_DATA: 'HANDLE_STUDENT_PROFILE_DATA',
  SAVE_STUDENT_OTHER_INFO: 'SAVE_STUDENT_OTHER_INFO',
  SAVE_STUDENT_BIO: 'SAVE_STUDENT_BIO',
  FETCH_COUNTRIES_START: 'FETCH_COUNTRIES_START',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE: 'FETCH_COUNTRIES_FAILURE',
  FETCH_STATES_START: 'FETCH_STATES_START',
  FETCH_STATES_SUCCESS: 'FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE: 'FETCH_STATES_FAILURE',
  FETCH_LGA_START: 'FETCH_LGA_START',
  FETCH_LGA_SUCCESS: 'FETCH_LGA_SUCCESS',
  FETCH_LGA_FAILURE: 'FETCH_LGA_FAILURE',
  RESET_EVERYTHING: 'RESET_EVERYTHING',
  RESET_LGA: 'RESET_LGA',
  HANDLE_EDIT_STUDENT: 'HANDLE_EDIT_STUDENT',
  DOWNLOAD_XLSX_TEMPLATE_START: 'DOWNLOAD_XLSX_TEMPLATE_START',
  DOWNLOAD_XLSX_TEMPLATE_SUCCESS: 'DOWNLOAD_XLSX_TEMPLATE_SUCCESS',
  DOWNLOAD_XLSX_TEMPLATE_FAILURE: 'DOWNLOAD_XLSX_TEMPLATE_FAILURE',
  BULK_STUDENT_UPLOAD_START: 'BULK_STUDENT_UPLOAD_START',
  BULK_STUDENT_UPLOAD_SUCCESS: 'BULK_STUDENT_UPLOAD_SUCCESS',
  BULK_STUDENT_UPLOAD_FAILURE: 'BULK_STUDENT_UPLOAD_FAILURE',
  HANDLE_BULK_STUDENT_MODAL: 'HANDLE_BULK_STUDENT_MODAL',
  STUDENT_STATUS_START: 'STUDENT_STATUS_START',
  STUDENT_STATUS_SUCCESS: 'STUDENT_STATUS_SUCCESS',
  STUDENT_STATUS_FAILURE: 'STUDENT_STATUS_FAILURE',
  FETCH_STUDENT_STATISTICS_START: 'FETCH_STUDENT_STATISTICS_START',
  FETCH_STUDENT_STATISTICS_SUCCESS: 'FETCH_STUDENT_STATISTICS_SUCCESS',
  FETCH_STUDENT_STATISTICS_FAILURE: 'FETCH_STUDENT_STATISTICS_FAILURE',
  UPDATE_STUDENT_PHOTO_START: 'UPDATE_STUDENT_PHOTO_START',
  UPDATE_STUDENT_PHOTO_SUCCESS: 'UPDATE_STUDENT_PHOTO_SUCCESS',
  UPDATE_STUDENT_PHOTO_FAIL: 'UPDATE_STUDENT_PHOTO_FAIL',
  HANDLE_UPDATE_PHOTO: 'HANDLE_UPDATE_PHOTO',
  SAVE_AND_RECREATE_STUDENT_START: 'SAVE_AND_RECREATE_STUDENT_START',
  SAVE_AND_RECREATE_STUDENT_SUCCESS: 'SAVE_AND_RECREATE_STUDENT_SUCCESS',
  SAVE_AND_RECREATE_STUDENT_FAIL: 'SAVE_AND_RECREATE_STUDENT_FAIL',
  HANDLE_RECREATE: 'HANDLE_RECREATE',
  FILTER_STUDENTS_START: 'FILTER_STUDENTS_START',
  FILTER_STUDENTS_SUCCESS: ' FILTER_STUDENTS_SUCCESS',
  FILTER_STUDENTS_FAIL: ' FILTER_STUDENTS_FAIL',
  HANDLE_SOFT_DELETE_STUDENT: 'HANDLE_SOFT_DELETE_STUDENT',
  LINK_PARENT_START: 'LINK_PARENT_START',
  LINK_PARENT_SUCCESS: 'LINK_PARENT_SUCCESS',
  LINK_PARENT_FAIL: 'LINK_PARENT_FAIL',
  HANDLE_LINK_CHILD_MODAL: 'HANDLE_LINK_CHILD_MODAL',
  UPLOAD_STUDENT_DOCS_START: 'UPLOAD_STUDENT_DOCS_START',
  UPLOAD_STUDENT_DOCS_SUCCESS: 'UPLOAD_STUDENT_DOCS_SUCCESS',
  UPLOAD_STUDENT_DOCS_FAIL: ' UPLOAD_STUDENT_DOCS_FAIL',
  DELETE_STUDENT_DOCS_START: 'DELETE_STUDENT_DOCS_START',
  DELETE_STUDENT_DOCS_SUCCESS: 'DELETE_STUDENT_DOCS_SUCCESS',
  DELETE_STUDENT_DOCS_FAIL: 'DELETE_STUDENT_DOCS_FAIL',
  FETCH_STUDENT_DOCS_START: 'FETCH_STUDENT_DOCS_START',
  FETCH_STUDENT_DOCS_SUCCESS: 'FETCH_STUDENT_DOCS_SUCCESS',
  FETCH_STUDENT_DOCS_FAIL: 'FETCH_STUDENT_DOCS_FAIL',
  DOWNLOAD_STUDENT_DOCS_START: 'DOWNLOAD_STUDENT_DOCS_START',
  DOWNLOAD_STUDENT_DOCS_SUCCESS: 'DOWNLOAD_STUDENT_DOCS_SUCCESS',
  DOWNLOAD_STUDENT_DOCS_FAIL: 'DOWNLOAD_STUDENT_DOCS_FAIL',
  HANDLE_SET_STUDENT_DOC: 'HANDLE_SET_STUDENT_DOC',
  HANDLE_STUDENT_DOC_MODAL: 'HANDLE_STUDENT_DOC_MODAL',
  HANDLE_DELETE_DOC_MODAL: 'HANDLE_DELETE_DOC_MODAL',
  STOP_UPLOAD_STUDENT_BULK_LOADING: 'STOP_UPLOAD_STUDENT_BULK_LOADING',
  FETCH_STUDENT_START: 'FETCH_STUDENT_START',
  FETCH_STUDENT_SUCCESS: 'FETCH_STUDENT_SUCCESS',
  FETCH_STUDENT_FAIL: 'FETCH_STUDENT_FAIL',
  FETCH_STUDENTS_WITHOUT_CLASS_START: 'FETCH_STUDENTS_WITHOUT_CLASS_START',
  FETCH_STUDENTS_WITHOUT_CLASS_SUCCESS: 'FETCH_STUDENTS_WITHOUT_CLASS_SUCCESS',
  FETCH_STUDENTS_WITHOUT_CLASS_FAIL: 'FETCH_STUDENTS_WITHOUT_CLASS_FAIL',

  REMOVED_ADDED_STUDENTS_FROM_LIST: 'REMOVED_ADDED_STUDENTS_FROM_LIST',
  HANDLE_UNLINK_PARENT_MODAL: 'HANDLE_UNLINK_PARENT_MODAL',

  UNLINK_PARENT_START: 'UNLINK_PARENT_START',
  UNLINK_PARENT_SUCCESS: 'UNLINK_PARENT_SUCCESS',
  UNLINK_PARENT_FAILURE: 'UNLINK_PARENT_FAILURE',

  GET_TOTAL_STUDENTS_START: 'GET_TOTAL_STUDENTS_START',
  GET_TOTAL_STUDENTS_SUCCESS: 'GET_TOTAL_STUDENTS_SUCCESS',
  GET_TOTAL_STUDENTS_FAILURE: 'GET_TOTAL_STUDENTS_FAILURE',

  SET_STUDENT_ACTIVE_TAB: 'SET_STUDENT_ACTIVE_TAB',
};

export default studentActionTypes;
