import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchChildSkillsResultFail, fetchChildSkillsResultSuccess } from '../actions';
import parentActionTypes from '../types';

export function* fetchChildSkillsResult({ payload }) {
  try {
    const response = yield httpClient.get(`/cognitive-skill-assessment/grouped`, {
      params: payload,
    });
    yield put(fetchChildSkillsResultSuccess(response.data));
  } catch (error) {
    yield put(fetchChildSkillsResultFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchingChildSkillsResultStart() {
  yield takeLatest(parentActionTypes.FETCH_CHILD_SKILLS_RESULT_START, fetchChildSkillsResult);
}
