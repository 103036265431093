import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { fetchUserDocsFail, fetchUserDocsSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchUserDoc({ payload }) {
  try {
    const response = yield httpClient.get(`/documents/${payload.link}/${payload.id}`);
    yield put(fetchUserDocsSuccess(response.data));
  } catch (error) {
    yield put(fetchUserDocsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchUserDocument() {
  yield takeLatest(userActionTypes.FETCH_USER_DOCS_START, fetchUserDoc);
}
