import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import {
  adminEditInvoiceItemsSuccess,
  adminEditInvoiceItemsFail,
  fetchStudentInvoicesStart,
} from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* adminEditInvoiceItems({ payload }) {
  const { id, data } = payload;
  const details = {
    'session-id': data.session_id,
    'term-id': data.term_id,
    'student-id': id,
  };
  try {
    const response = yield httpClient.put(`/students-invoice`, {
      ...data,
      enforce_minimum_quantity: false,
    });
    yield put(adminEditInvoiceItemsSuccess(response.data));
    Notify('Changes saved successfully', { position: 'top-right' });
    yield put(fetchStudentInvoicesStart(details));
  } catch (error) {
    yield put(adminEditInvoiceItemsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAdminEditInvoiceItemsStart() {
  yield takeLatest(invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_START, adminEditInvoiceItems);
}
