import { takeLatest, put } from 'redux-saga/effects';
import { deleteStaffFailure, deleteStaffSuccess, fetchStaffsStatsStart } from '../actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';

export function* deleteStaff({ payload }) {
  try {
    const response = yield httpClient.delete(`/staff/${payload.id}/?permanently=false`);
    if (payload.isProfile) {
      payload.callback();
    }
    yield put(deleteStaffSuccess(payload.id));
    yield put(fetchStaffsStatsStart());
    Notify('Staff deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteStaffStart() {
  yield takeLatest(parentActionTypes.DELETE_STAFF_START, deleteStaff);
}
