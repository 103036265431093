/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentTraitApprovalLogDto } from '../models/StudentTraitApprovalLogDto';
import type { StudentTraitApprovalLogHistoryResponse } from '../models/StudentTraitApprovalLogHistoryResponse';
import type { StudentTraitApprovalLogRequest } from '../models/StudentTraitApprovalLogRequest';
import type { StudentTraitApprovalLogStatusResponse } from '../models/StudentTraitApprovalLogStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudentTraitApprovalLogRestControllerService {
  /**
   * approveStudentTraits
   * @returns StudentTraitApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static approveStudentTraitsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: StudentTraitApprovalLogRequest;
  }): CancelablePromise<Array<StudentTraitApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-trait-approvals/approve',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassApprovalHistory
   * @returns StudentTraitApprovalLogHistoryResponse OK
   * @throws ApiError
   */
  public static getClassApprovalHistoryUsingGet1({
    armId,
    classLevelId,
    termId,
    traitGroupId,
  }: {
    /**
     * armId
     */
    armId: string;
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * termId
     */
    termId: string;
    /**
     * traitGroupId
     */
    traitGroupId: string;
  }): CancelablePromise<Array<StudentTraitApprovalLogHistoryResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-trait-approvals/history/term/{termId}/class/{classLevelId}/arm/{armId}',
      path: {
        armId: armId,
        classLevelId: classLevelId,
        termId: termId,
      },
      query: {
        traitGroupId: traitGroupId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recallStudentTraits
   * @returns StudentTraitApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static recallStudentTraitsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: StudentTraitApprovalLogRequest;
  }): CancelablePromise<Array<StudentTraitApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-trait-approvals/recall',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassMembersApprovalStatus
   * @returns StudentTraitApprovalLogStatusResponse OK
   * @throws ApiError
   */
  public static getClassMembersApprovalStatusUsingGet1({
    armId,
    classLevelId,
    termId,
    traitGroupId,
  }: {
    /**
     * armId
     */
    armId: string;
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * termId
     */
    termId: string;
    /**
     * traitGroupId
     */
    traitGroupId: string;
  }): CancelablePromise<Array<StudentTraitApprovalLogStatusResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-trait-approvals/term/{termId}/class/{classLevelId}/arm/{armId}',
      path: {
        armId: armId,
        classLevelId: classLevelId,
        termId: termId,
      },
      query: {
        traitGroupId: traitGroupId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
