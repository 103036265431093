import { takeLatest, put } from 'redux-saga/effects';
import { createClassLevelSuccess, createClassLevelFail, fetchClassLevelStart } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from 'utils/utils';
import { setDisableNext } from 'redux/users/actions';

export function* createClassLevel({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }

    const response = yield httpClient.post(`/class-levels`, payload);
    yield put(createClassLevelSuccess(response.data));
    yield put(fetchClassLevelStart());
    Notify('Class level created succesfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createClassLevelFail(error.fail));
    const err = handleError(error);
  }
}

export function* onCreatingClassStart() {
  yield takeLatest(configActionTypes.CREATE_CLASS_LEVEL_START, createClassLevel);
}
