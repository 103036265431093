import { all, call } from 'redux-saga/effects';
import { onFetchBanksStart } from './fetchListOfBanks';
import { onFetchBankAccountsStart } from './fetchBankAccounts';
import { onCreateBankAccountStart } from './createBankAccount';
import { onEditBankAccountStart } from './editBankAccount';
import { onDeleteBankAccountStart } from './deleteBankAccount';
import { onAssignDefaultAccountStart } from './assignDefaultAccount';
import { onRetrySubAccountStart } from './retrySubAccount';

export default function* BankSaga() {
  yield all([
    call(onFetchBanksStart),
    call(onFetchBankAccountsStart),
    call(onCreateBankAccountStart),
    call(onEditBankAccountStart),
    call(onDeleteBankAccountStart),
    call(onAssignDefaultAccountStart),
    call(onRetrySubAccountStart),
  ]);
}
