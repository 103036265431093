import { all, call } from 'redux-saga/effects';
import { onfetchInvoicesSummariesStart } from './fetchInvoicesSummaries';
import { onMessageDefaultersSsendSMSStart } from './sendSMSMessageDefaulters';
import { onMessageDefaultersSsendEmailStart } from './sendEmailMessageDefaulters';

export function* InvoiceSummariesSagas() {
  yield all([
    call(onfetchInvoicesSummariesStart),
    call(onMessageDefaultersSsendSMSStart),
    call(onMessageDefaultersSsendEmailStart),
  ]);
}
