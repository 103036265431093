import { IAssessmentState } from './reducer';
import assessmentActionTypes from './types';

export const fetchAssessmentsStart = (data?: any) => ({
  type: assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_START,
  payload: data,
});

export const fetchAssessmentsSuccess = (data) => ({
  type: assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_SUCCESS,
  payload: data,
});

export const fetchAssessmentsFailure = (error) => ({
  type: assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_FAILURE,
  payload: error,
});

export const createAssessmentStart = (data) => ({
  type: assessmentActionTypes.CREATE_ASSESSMENT_START,
  payload: data,
});

export const createAssessmentSuccess = (data) => ({
  type: assessmentActionTypes.CREATE_ASSESSMENT_SUCCESS,
  payload: data,
});

export const createAssessmentFailure = (error) => ({
  type: assessmentActionTypes.CREATE_ASSESSMENT_FAILURE,
  payload: error,
});

export const fetchLevelsAssessmentsStart = (payload) => ({
  type: assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_START,
  payload,
});

export const fetchLevelsAssessmentsSuccess = (data) => ({
  type: assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_SUCCESS,
  payload: data,
});

export const fetchLevelsAssessmentsFailure = (error) => ({
  type: assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_FAILURE,
  payload: error,
});
export const setActiveLevelAssessment = (data) => ({
  type: assessmentActionTypes.SET_ACTIVE_LEVEL_ASSESSMENT,
  payload: data,
});

export const handleCreateModalAction = (createModal) => ({
  type: assessmentActionTypes.HANDLE_CREATE_MODAL,
  payload: createModal,
});

export const updateAssessmentState = (payload: IAssessmentState) => ({
  type: assessmentActionTypes.UPDATE_ASSESSMENT_STATE,
  payload,
});
