enum invoiceActionTypes {
  FETCH_INVOICES_START = 'FETCH_INVOICES_START',
  FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE',

  FETCH_INVOICES_CLASS_LEVEL_START = 'FETCH_INVOICES_CLASS_LEVEL_START',
  FETCH_INVOICES_CLASS_LEVEL_SUCCESS = 'FETCH_INVOICES_CLASS_LEVEL_SUCCESS',
  FETCH_INVOICES_CLASS_LEVEL_FAILURE = 'FETCH_INVOICES_CLASS_LEVEL_FAILURE',

  FETCH_INVOICES_SESSIONS_START = 'FETCH_INVOICES_SESSIONS_START',
  FETCH_INVOICES_SESSIONS_SUCCESS = 'FETCH_INVOICES_SESSIONS_SUCCESS',
  FETCH_INVOICES_SESSIONS_FAILURE = 'FETCH_INVOICES_SESSIONS_FAILURE',

  FETCH_INVOICES_TERMS_START = 'FETCH_INVOICES_TERMS_START',
  FETCH_INVOICES_TERMS_SUCCESS = 'FETCH_INVOICES_TERMS_SUCCESS',
  FETCH_INVOICES_TERMS_FAILURE = 'FETCH_INVOICES_TERMS_FAILURE',

  FETCH_INVOICES_ITEMS_START = 'FETCH_INVOICES_ITEMS_START',
  FETCH_INVOICES_ITEMS_SUCCESS = 'FETCH_INVOICES_ITEMS_SUCCESS',
  FETCH_INVOICES_ITEMS_FAILURE = 'FETCH_INVOICES_ITEMS_FAILURE',

  CREATE_INVOICE_START = 'CREATE_INVOICE_START',
  CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_FAILURE = 'CREATE_INVOICE_FAILURE',

  EDIT_INVOICE_START = 'EDIT_INVOICE_START',
  EDIT_INVOICE_SUCCESS = 'EDIT_INVOICE_SUCCESS',
  EDIT_INVOICE_FAILURE = 'EDIT_INVOICE_FAILURE',

  DELETE_INVOICE_START = 'DELETE_INVOICE_START',
  DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE',

  FETCH_ALL_TERMS_START = 'FETCH_ALL_TERMS_START',
  FETCH_ALL_TERMS_SUCCESS = 'FETCH_ALL_TERMS_SUCCESS',
  FETCH_ALL_TERMS_FAILURE = 'FETCH_ALL_TERMS_FAILURE',

  HANDLE_CREATE_INVOICE_MODAL = 'HANDLE_CREATE_INVOICE_MODAL',
  HANDLE_EDIT_INVOICE_MODAL = 'HANDLE_EDIT_INVOICE_MODAL',
  HANDLE_CLOSE_EDIT_INVOICE_MODAL = 'HANDLE_CLOSE_EDIT_INVOICE_MODAL',
  HANDLE_DELETE_MODAL = 'HANDLE_DELETE_MODAL',
  SET_EDIT_DISCOUNT_ITEM = 'SET_EDIT_DISCOUNT_ITEM',

  FETCH_STUDENT_INVOICES_START = 'FETCH_STUDENT_INVOICES_START',
  FETCH_STUDENT_INVOICES_SUCCESS = 'FETCH_STUDENT_INVOICES_SUCCESS',
  FETCH_STUDENT_INVOICES_FAIL = 'FETCH_STUDENT_INVOICES_FAIL',

  HANDLE_ADD_INVOICE_ITEM_MODAL = 'HANDLE_ADD_INVOICE_ITEM_MODAL',
  HANDLE_DELETE_INVOICE_ITEM_MODAL = 'HANDLE_DELETE_INVOICE_ITEM_MODAL',
  HANDLE_INVOICE_DISCOUNT_MODAL = 'HANDLE_INVOICE_DISCOUNT_MODAL',
  HANDLE_EDIT_DISCOUNT_MODAL = 'HANDLE_EDIT_DISCOUNT_MODAL',
  HANDLE_OUTSTANDING_BILL_MODAL = 'HANDLE_OUTSTANDING_BILL_MODAL',

  FETCH_INVOICE_DISCOUNT_START = 'FETCH_INVOICE_DISCOUNT_START',
  FETCH_INVOICE_DISCOUNT_SUCCESS = 'FETCH_INVOICE_DISCOUNT_SUCCESS',
  FETCH_INVOICE_DISCOUNT_FAIL = 'FETCH_INVOICE_DISCOUNT_FAIL',

  ADD_INVOICE_DISCOUNT_START = 'ADD_INVOICE_DISCOUNT_START',
  ADD_INVOICE_DISCOUNT_SUCCESS = 'ADD_INVOICE_DISCOUNT_SUCCESS',
  ADD_INVOICE_DISCOUNT_FAIL = 'ADD_INVOICE_DISCOUNT_FAIL',

  DELETE_INVOICE_DISCOUNT_START = 'DELETE_INVOICE_DISCOUNT_START',
  DELETE_INVOICE_DISCOUNT_SUCCESS = 'DELETE_INVOICE_DISCOUNT_SUCCESS',
  DELETE_INVOICE_DISCOUNT_FAIL = 'DELETE_INVOICE_DISCOUNT_FAIL',

  EDIT_INVOICE_DISCOUNT_START = 'EDIT_INVOICE_DISCOUNT_START',
  EDIT_INVOICE_DISCOUNT_SUCCESS = 'EDIT_INVOICE_DISCOUNT_SUCCESS',
  EDIT_INVOICE_DISCOUNT_FAIL = 'EDIT_INVOICE_DISCOUNT_FAIL',

  ADD_ITEM_TO_INVOICE_START = 'ADD_ITEM_TO_INVOICE_START',
  ADD_ITEM_TO_INVOICE_SUCCESS = 'ADD_ITEM_TO_INVOICE_SUCCESS',
  ADD_ITEM_TO_INVOICE_FAIL = 'ADD_ITEM_TO_INVOICE_FAIL',

  DELETE_ITEM_FROM_INVOICE_START = 'DELETE_ITEM_FROM_INVOICE_START',
  DELETE_ITEM_FROM_INVOICE_SUCCESS = 'DELETE_ITEM_FROM_INVOICE_SUCCESS',
  DELETE_ITEM_FROM_INVOICE_FAIL = 'DELETE_ITEM_FROM_INVOICE_FAIL',

  MAKE_PAYMENT_START = 'MAKE_PAYMENT_START',
  MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS',
  MAKE_PAYMENT_FAIL = 'MAKE_PAYMENT_FAIL',

  HANDLE_PAYMENT_REGISTRATION_MODAL = 'HANDLE_PAYMENT_REGISTRATION_MODAL',
  HANDLE_MAKE_PAYMENT_MODAL = 'HANDLE_MAKE_PAYMENT_MODAL',
  HANDLE_RECEIPT_MODAL = 'HANDLE_RECEIPT_MODAL',

  EDIT_INVOICE_ITEMS_START = 'EDIT_INVOICE_ITEMS_START',
  EDIT_INVOICE_ITEMS_SUCCESS = 'EDIT_INVOICE_ITEMS_SUCCESS',
  EDIT_INVOICE_ITEMS_FAIL = 'EDIT_INVOICE_ITEMS_FAIL',

  RECEIVE_PAYMENT_START = 'RECEIVE_PAYMENT_START',
  RECEIVE_PAYMENT_SUCCESS = 'RECEIVE_PAYMENT_SUCCESS',
  RECEIVE_PAYMENT_FAIL = 'RECEIVE_PAYMENT_FAIL',

  SEND_OUTSTANDING_BILL_EMAIL_START = 'SEND_OUTSTANDING_BILL_EMAIL_START',
  SEND_OUTSTANDING_BILL_EMAIL_SUCCESS = 'SEND_OUTSTANDING_BILL_EMAIL_SUCCESS',
  SEND_OUTSTANDING_BILL_EMAIL_FAIL = 'SEND_OUTSTANDING_BILL_EMAIL_FAIL',

  GET_CHECKOUT_START = 'GET_CHECKOUT_START',
  GET_CHECKOUT_SUCCESS = 'GET_CHECKOUT_SUCCESS',
  GET_CHECKOUT_FAIL = 'GET_CHECKOUT_FAIL',

  FETCH_STUDENT_INVOICE_SUMMARY_START = 'FETCH_STUDENT_INVOICE_SUMMARY_START',
  FETCH_STUDENT_INVOICE_SUMMARY_SUCCESS = 'FETCH_STUDENT_INVOICE_SUMMARY_SUCCESS',
  FETCH_STUDENT_INVOICE_SUMMARY_FAIL = 'FETCH_STUDENT_INVOICE_SUMMARY_FAIL',

  FETCH_PAYMENT_RECEIPT_START = 'FETCH_PAYMENT_RECEIPT_START',
  FETCH_PAYMENT_RECEIPT_SUCCESS = 'FETCH_PAYMENT_RECEIPT_SUCCESS',
  FETCH_PAYMENT_RECEIPT_FAIL = 'FETCH_PAYMENT_RECEIPT_FAIL',

  SET_ACTIVE_RECEIPT = 'SET_ACTIVE_RECEIPT',

  VERIFY_PAYMENT_START = 'VERIFY_PAYMENT_START',
  VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS',
  VERIFY_PAYMENT_FAIL = 'VERIFY_PAYMENT_FAIL',

  GET_TRANSACTION_LOG_START = 'GET_TRANSACTION_LOG_START',
  GET_TRANSACTION_LOG_SUCCESS = 'GET_TRANSACTION_LOG_SUCCESS',
  GET_TRANSACTION_LOG_FAIL = 'GET_TRANSACTION_LOG_FAIL',

  ADMIN_EDIT_INVOICE_ITEMS_START = 'ADMIN_EDIT_INVOICE_ITEMS_START',
  ADMIN_EDIT_INVOICE_ITEMS_SUCCESS = 'ADMIN_EDIT_INVOICE_ITEMS_SUCCESS',
  ADMIN_EDIT_INVOICE_ITEMS_FAIL = 'ADMIN_EDIT_INVOICE_ITEMS_FAIL',

  SET_QUANTITY_EDIT = 'SET_QUANTITY_EDIT',

  FETCH_REVENUE_SUMMARY_START = 'FETCH_REVENUE_SUMMARY_START',
  FETCH_REVENUE_SUMMARY_SUCCESS = 'FETCH_REVENUE_SUMMARY_SUCCESS',
  FETCH_REVENUE_SUMMARY_FAIL = 'FETCH_REVENUE_SUMMARY_FAIL',

  FETCH_REVENUE_STATISTICS_START = 'FETCH_REVENUE_STATISTICS_START',
  FETCH_REVENUE_STATISTICS_SUCCESS = 'FETCH_REVENUE_STATISTICS_SUCCESS',
  FETCH_REVENUE_STATISTICS_FAIL = 'FETCH_REVENUE_STATISTICS_FAIL',

  PARENT_ADD_INVOICE_ITEM_START = 'PARENT_ADD_INVOICE_ITEM_START',
  PARENT_ADD_INVOICE_ITEM_SUCCESS = 'PARENT_ADD_INVOICE_ITEM_SUCCESS',
  PARENT_ADD_INVOICE_ITEM_FAIL = 'PARENT_ADD_INVOICE_ITEM_FAIL',

  GET_SINGLE_PAGE_CHECKOUT_START = 'GET_SINGLE_PAGE_CHECKOUT_START',
  GET_SINGLE_PAGE_CHECKOUT_SUCCESS = 'GET_SINGLE_PAGE_CHECKOUT_SUCCESS',
  GET_SINGLE_PAGE_CHECKOUT_FAIL = 'GET_SINGLE_PAGE_CHECKOUT_FAIL',

  MAKE_DIRECT_PAY_START = 'MAKE_DIRECT_PAY_START',
  MAKE_DIRECT_PAY_SUCCESS = 'MAKE_DIRECT_PAY_SUCCESS',
  MAKE_DIRECT_PAY_FAIL = 'MAKE_DIRECT_PAY_FAIL',

  HANDLE_DIRECT_PAY_MODAL = 'HANDLE_DIRECT_PAY_MODAL',

  GET_BULK_PAYMENT_CHECKOUT_START = 'GET_BULK_PAYMENT_CHECKOUT_START',
  GET_BULK_PAYMENT_CHECKOUT_SUCCESS = 'GET_BULK_PAYMENT_CHECKOUT_SUCCESS',
  GET_BULK_PAYMENT_CHECKOUT_FAIL = 'GET_BULK_PAYMENT_CHECKOUT_FAIL',

  MAKE_BULK_PAYMENT_START = 'MAKE_BULK_PAYMENT_START',
  MAKE_BULK_PAYMENT_SUCCESS = 'MAKE_BULK_PAYMENT_SUCCESS',
  MAKE_BULK_PAYMENT_FAIL = 'MAKE_BULK_PAYMENT_FAIL',

  HANDLE_MAKE_BULK_PAYMENT_MODAL = 'HANDLE_MAKE_BULK_PAYMENT_MODAL',

  UPDATE_INVOICE_STATE = 'UPDATE_INVOICE_STATE',
}

export default invoiceActionTypes;
