/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSectionRequest } from '../models/CreateSectionRequest';
import type { SectionDto } from '../models/SectionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SectionRestControllerService {
  /**
   * getAllSections
   * @returns SectionDto OK
   * @throws ApiError
   */
  public static getAllSectionsUsingGet(): CancelablePromise<Array<SectionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/section',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * create
   * @returns SectionDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createUsingPost2({
    request,
  }: {
    /**
     * request
     */
    request: CreateSectionRequest;
  }): CancelablePromise<SectionDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/section',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * update
   * @returns SectionDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateUsingPut4({
    sectionDto,
  }: {
    /**
     * sectionDto
     */
    sectionDto: SectionDto;
  }): CancelablePromise<SectionDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/section',
      body: sectionDto,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getSection
   * @returns SectionDto OK
   * @throws ApiError
   */
  public static getSectionUsingGet({
    sectionId,
  }: {
    /**
     * section-id
     */
    sectionId: string;
  }): CancelablePromise<SectionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/section/{section-id}',
      path: {
        'section-id': sectionId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
