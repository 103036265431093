import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { findIndexInArray, InsertBeginning } from 'utils/utils';
import parentActionTypes from './types';

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  downloading: false,
  parentList: [],
  parentInfo: {},
  error: null,
  isLoading: false,
  saveButtonLoading: false,
  bulkModal: false,
  deleteModal: false,
  wizard: false,
  edit: false,
  listOfStudents: [],
  parentBio: {},
  otherInfo: {},
  linkedChilds: [],
  editUser: {},
  staffProfileData: {},
  total: 0,
  stats: {},
  profilePictureEdit: false,
  linkModal: false,
  unlinkModal: false,
  activeParent: {},
  parentChildren: [],
  currentStudent: {},
  feedbackModal: false,
};

function parentReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case parentActionTypes.FETCH_PARENTS_START:
      return {
        ...state,
        loading: true,
      };
    case parentActionTypes.FETCH_PARENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        parentList: payload.content,
        total: payload.total_elements,
        activeParent: {},
        parentInfo: {},
      };
    case parentActionTypes.FETCH_PARENTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case parentActionTypes.FETCH_SINGLE_PARENT_START:
      return {
        ...state,
        loading: true,
        activeParent: {},
        parentInfo: {},
      };
    case parentActionTypes.FETCH_SINGLE_PARENT_SUCCESS:
      return {
        ...state,
        loading: false,
        activeParent: payload,
        parentInfo: payload,
      };
    case parentActionTypes.FETCH_SINGLE_PARENT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case parentActionTypes.HANDLE_PARENT_PROFILE:
      return {
        ...state,
        parentInfo: payload,
      };

    case parentActionTypes.FETCH_STAFFS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case parentActionTypes.FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfStaffs: payload.content,
        total: payload.total_elements,
      };
    case parentActionTypes.FETCH_STAFFS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case parentActionTypes.FILTER_STAFFS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case parentActionTypes.FILTER_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfStaffs: payload.content,
        total: payload.total_elements,
      };
    case parentActionTypes.FILTER_STAFFS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case parentActionTypes.FETCH_LIST_OF_STUDENT_START:
      return {
        ...state,
        error: null,
      };
    case parentActionTypes.FETCH_LIST_OF_STUDENT_SUCCESS:
      return {
        ...state,
        listOfStudents: payload.content,
      };
    case parentActionTypes.FETCH_LIST_OF_STUDENT_FAILURE:
      return {
        ...state,
        error: payload,
      };

    case parentActionTypes.CREATE_PARENT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case parentActionTypes.CREATE_PARENT_SUCCESS:
      const modifiedEntities = InsertBeginning(state.parentList, payload);
      return {
        ...state,
        buttonLoading: false,
        wizard: false,
        parentList: modifiedEntities,
        states: [],
        lga: [],
        parentBio: {},
        linkedChilds: [],
        edit: false,
      };
    case parentActionTypes.CREATE_PARENT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case parentActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_START:
      return {
        ...state,
        saveButtonLoading: true,
        error: null,
      };
    case parentActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_SUCCESS:
      const saveModifiedEntities = InsertBeginning(state.parentList, payload);
      return {
        ...state,
        saveButtonLoading: false,
        parentList: saveModifiedEntities,
        states: [],
        lga: [],
        parentBio: {},
        linkedChilds: [],
        edit: false,
      };
    case parentActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_FAILURE:
      return {
        ...state,
        saveButtonLoading: false,
        error: payload,
      };

    case parentActionTypes.EDIT_PARENT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case parentActionTypes.EDIT_PARENT_SUCCESS:
      const indexOfEditedUser = findIndexInArray(state.parentList, payload, 'parent_id');
      state.parentList.splice(indexOfEditedUser, 1, payload);
      return {
        ...state,
        buttonLoading: false,
        wizard: false,
        states: [],
        lga: [],
        staffBio: {},
        otherInfo: {},
        editUser: {},
        edit: false,
      };
    case parentActionTypes.EDIT_PARENT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case parentActionTypes.PARENT_STATUS_START:
      return {
        ...state,
        parentInfo: payload,
        error: null,
      };
    case parentActionTypes.PARENT_STATUS_SUCCESS:
      return {
        ...state,
        parentInfo: payload,
      };
    case parentActionTypes.PARENT_STATUS_FAILURE:
      state.parentInfo.active_status =
        payload.data.active_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
      return {
        ...state,
        parentInfo: state.parentInfo,
        error: payload.error,
      };

    case parentActionTypes.DOWNLOAD_PARENT_XLSX_TEMPLATE_START:
      return {
        ...state,
        downloading: true,
        error: null,
      };
    case parentActionTypes.DOWNLOAD_PARENT_XLSX_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
      };
    case parentActionTypes.DOWNLOAD_PARENT_XLSX_TEMPLATE_FAILURE:
      return {
        ...state,
        downloading: false,
        error: payload,
      };

    case parentActionTypes.BULK_PARENT_UPLOAD_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        feedbackModal: false,
      };
    case parentActionTypes.BULK_PARENT_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bulkModal: false,
        feedbackModal: true,
      };
    case parentActionTypes.BULK_PARENT_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case parentActionTypes.RESET_LGA:
      return {
        ...state,
        lga: payload,
      };
    case parentActionTypes.SAVE_PARENT_BIO:
      return {
        ...state,
        parentBio: payload,
      };
    case parentActionTypes.SAVE_LINKED_CHILD_INFO:
      return {
        ...state,
        linkedChilds: payload,
      };
    case parentActionTypes.OPEN_WIZARD:
      return {
        ...state,
        wizard: payload,
      };
    case parentActionTypes.RESET_EVERYTHING:
      return {
        ...state,
        states: [],
        lga: [],
        parentBio: {},
        linkedChilds: [],
        editUser: {},
        edit: false,
        wizard: false,
      };
    case parentActionTypes.HANDLE_EDIT:
      return {
        ...state,
        parentBio: payload.parentBio,
        linkedChilds: payload.linkedChilds,
        editUser: payload.editUser,
        edit: true,
        wizard: true,
      };
    case parentActionTypes.HANDLE_STAFF_PROFILE_DATA:
      return {
        ...state,
        staffProfileData: payload,
      };

    case parentActionTypes.HANDLE_BULK_MODAL:
      return {
        ...state,
        bulkModal: !payload,
      };

    case parentActionTypes.CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModal: false,
      };
    case parentActionTypes.HANDLE_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackModal: payload,
      };

    case parentActionTypes.HANDLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !payload,
      };

    case parentActionTypes.FETCH_PARENT_STATISTICS_START:
      return {
        ...state,
      };
    case parentActionTypes.FETCH_PARENT_STATISTICS_SUCCESS:
      return {
        ...state,
        stats: payload,
      };
    case parentActionTypes.FETCH_PARENT_STATISTICS_FAILURE:
      return {
        ...state,
      };

    case parentActionTypes.DELETE_PARENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case parentActionTypes.DELETE_PARENT_SUCCESS:
      const indexOfDeleteParent = findIndexInArray(
        state.parentList,
        { parent_id: payload },
        'parent_id',
      );
      state.parentList.splice(indexOfDeleteParent, 1);
      return {
        ...state,
        isLoading: false,
        deleteModal: false,
      };
    case parentActionTypes.DELETE_PARENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case parentActionTypes.UPDATE_PARENT_PICTURE_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case parentActionTypes.UPDATE_PARENT_PICTURE_SUCCESS:
      const currentParent = state.parentList.filter((parent) => {
        return parent.parent_id === payload.id;
      })[0];

      currentParent.profile_pic = payload.link;
      const indexOfUpdatePictureParent = findIndexInArray(
        state.listOfStaffs,
        currentParent,
        'parent_id',
      );
      state.parentList.splice(indexOfUpdatePictureParent, 1, currentParent);
      return {
        ...state,
        parentInfo: currentParent,
        buttonLoading: false,
        profilePictureEdit: false,
      };
    case parentActionTypes.UPDATE_PARENT_PICTURE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case parentActionTypes.LINK_CHILD_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case parentActionTypes.LINK_CHILD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        linkModal: false,
      };
    case parentActionTypes.LINK_CHILD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case parentActionTypes.UNLINK_CHILD_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case parentActionTypes.UNLINK_CHILD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unlinkModal: false,
      };
    case parentActionTypes.UNLINK_CHILD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case parentActionTypes.FETCH_PARENT_CHILDREN_START:
      return {
        ...state,
        isLoading: true,
      };
    case parentActionTypes.FETCH_PARENT_CHILDREN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        parentChildren: payload,
      };
    case parentActionTypes.FETCH_PARENT_CHILDREN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case parentActionTypes.HANDLE_EDIT_PICTURE_ON_PROFILE:
      return {
        ...state,
        profilePictureEdit: payload,
      };
    case parentActionTypes.HANDLE_LINK_MODAL:
      return {
        ...state,
        linkModal: !payload,
      };
    case parentActionTypes.HANDLE_UNLINK_MODAL:
      return {
        ...state,
        unlinkModal: !payload,
      };

    case parentActionTypes.STOP_UPLOAD_PARENT_BULK_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case parentActionTypes.HANDLE_CURRENT_STUDENT:
      return {
        ...state,
        currentStudent: payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'parent',
  storage,
  blacklist: [
    'loading',
    'error',
    'buttonLoading',
    'wizard',
    'edit',
    'bulkModal',
    'deleteModal',
    'parentBio',
    'linkedChilds',
    'isLoading',
    'linkModal',
    'unlinkModal',
    'downloading',
  ],
};

export default persistReducer(persistConfig, parentReducer);
