import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { editInvoiceFailure, editInvoiceSuccess, fetchInvoicesStart } from '../actions';
import invoiceActionTypes from '../types';

export function* editInvoice({ payload }) {
  const { data, apply, callback } = payload;
  try {
    const response = yield httpClient.put(`/invoice-templates`, data);
    yield put(editInvoiceSuccess(response.data));
    yield put(fetchInvoicesStart({ term_id: data.term_id }));
    Notify('Invoice Edited successfully', { position: 'top-right' });
    callback?.();
  } catch (error) {
    yield put(editInvoiceFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditInvoiceStart() {
  yield takeLatest(invoiceActionTypes.EDIT_INVOICE_START, editInvoice);
}
