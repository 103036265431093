/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TermDataImportRequest } from '../models/TermDataImportRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TermDataImportRestControllerService {
  /**
   * stageFourImports
   * @returns any OK
   * @throws ApiError
   */
  public static stageFourImportsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: TermDataImportRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/term-data-import/stage-four',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * stageThreeImports
   * @returns any OK
   * @throws ApiError
   */
  public static stageThreeImportsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: TermDataImportRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/term-data-import/stage-three',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * stageTwoImports
   * @returns any OK
   * @throws ApiError
   */
  public static stageTwoImportsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: TermDataImportRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/term-data-import/stage-two',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
