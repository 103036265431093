import { put, takeLatest } from 'redux-saga/effects';
import ReportTemplateActionTypes from '../types';
import { deleteReportTemplateSuccess, deleteReportTemplateFail } from '../actions';
import httpClient from '../../../utils/http-client';

export function* deleteReportTemplate({ id, callback }) {
  try {
    const response = yield httpClient.delete(`/report-templates/${id}`);
    yield put(deleteReportTemplateSuccess(id));
    callback();
  } catch (error) {
    yield put(deleteReportTemplateFail(error?.message));
  }
}

export function* onDeleteReportTemplateStart() {
  yield takeLatest(ReportTemplateActionTypes.DELETE_REPORT_TEMPLATE_START, deleteReportTemplate);
}
