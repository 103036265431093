import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchAllTraitGroupFail, fetchAllTraitGroupSuccess } from '../actions';
import earlyYearsActionTypes from '../types';

function* fetchAllTraits({ payload }) {
  const callback = payload?.callback;
  delete payload?.callback;
  try {
    const response = yield httpClient.get('/trait-configuration');
    yield put(fetchAllTraitGroupSuccess(response.data));
    callback?.(response.data);
  } catch (error) {
    yield put(fetchAllTraitGroupFail(error.fail));
    const err = handleError(error);
  }
}

export function* onFetchAllTraitsStart() {
  yield takeLatest(earlyYearsActionTypes.FETCH_TRAIT_GROUP_START, fetchAllTraits);
}
