/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignSupervisorRequest } from '../models/AssignSupervisorRequest';
import type { ClassLevelDtoReq } from '../models/ClassLevelDtoReq';
import type { ClassLevelDtoRes } from '../models/ClassLevelDtoRes';
import type { ClassLevelRequest } from '../models/ClassLevelRequest';
import type { Page_ClassLevelDto_ } from '../models/Page_ClassLevelDto_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClassLevelRestControllerService {
  /**
   * getAllClassLevels
   * @returns ClassLevelDtoRes OK
   * @throws ApiError
   */
  public static getAllClassLevelsUsingGet(): CancelablePromise<Array<ClassLevelDtoRes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/class-levels',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createClassLevel
   * @returns ClassLevelDtoRes OK
   * @returns any Created
   * @throws ApiError
   */
  public static createClassLevelUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: ClassLevelRequest;
  }): CancelablePromise<ClassLevelDtoRes | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/class-levels',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateClassLevel
   * @returns ClassLevelDtoRes OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateClassLevelUsingPut({
    dto,
  }: {
    /**
     * dto
     */
    dto: ClassLevelDtoReq;
  }): CancelablePromise<ClassLevelDtoRes | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/class-levels',
      body: dto,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateClassLevels
   * @returns ClassLevelDtoRes OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateClassLevelsUsingPut({
    dtos,
  }: {
    /**
     * dtos
     */
    dtos: Array<ClassLevelDtoReq>;
  }): CancelablePromise<Array<ClassLevelDtoRes> | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/class-levels/batch',
      body: dtos,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * filterClassLevel
   * @returns Page_ClassLevelDto_ OK
   * @throws ApiError
   */
  public static filterClassLevelUsingGet({
    limit,
    offset,
    status,
  }: {
    limit?: number;
    offset?: number;
    /**
     * status
     */
    status?: 'ACTIVE' | 'INACTIVE';
  }): CancelablePromise<Page_ClassLevelDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/class-levels/filter',
      query: {
        limit: limit,
        offset: offset,
        status: status,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createClassLevels
   * @returns ClassLevelDtoRes OK
   * @returns any Created
   * @throws ApiError
   */
  public static createClassLevelsUsingPost({
    classLevelRequestList,
  }: {
    /**
     * classLevelRequestList
     */
    classLevelRequestList: Array<ClassLevelRequest>;
  }): CancelablePromise<Array<ClassLevelDtoRes> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/class-levels/multiple',
      body: classLevelRequestList,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * assignSupervisor
   * @returns any OK
   * @throws ApiError
   */
  public static assignSupervisorUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: AssignSupervisorRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/class-levels/supervisors',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getAllClassLevels
   * @returns ClassLevelDtoRes OK
   * @throws ApiError
   */
  public static getAllClassLevelsUsingGet1({
    termId,
  }: {
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<Array<ClassLevelDtoRes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/class-levels/term',
      query: {
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassesWithStudentCount
   * @returns ClassLevelDtoRes OK
   * @throws ApiError
   */
  public static getClassesWithStudentCountUsingGet({
    termId,
  }: {
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<Array<ClassLevelDtoRes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/class-levels/terms',
      query: {
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassesWithArmInfos
   * @returns ClassLevelDtoRes OK
   * @throws ApiError
   */
  public static getClassesWithArmInfosUsingGet({
    termId,
  }: {
    /**
     * term_id
     */
    termId: string;
  }): CancelablePromise<Array<ClassLevelDtoRes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/class-levels/terms/{term_id}',
      path: {
        term_id: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * uploadClassLevelSignatures
   * @returns any OK
   * @throws ApiError
   */
  public static uploadClassLevelSignaturesUsingPost({
    classLevelIds,
    signature,
    xTenantId,
  }: {
    /**
     * class-level-ids
     */
    classLevelIds: string;
    /**
     * signature
     */
    signature: Blob;
    /**
     * X-TENANT-ID
     */
    xTenantId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/class-levels/uploads/signature',
      headers: {
        'X-TENANT-ID': xTenantId,
      },
      query: {
        'class-level-ids': classLevelIds,
      },
      body: signature,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * uploadClassLevelSignature
   * @returns string OK
   * @returns any Created
   * @throws ApiError
   */
  public static uploadClassLevelSignatureUsingPost({
    classLevelId,
    signature,
    staffId,
    xTenantId,
  }: {
    /**
     * class-level-id
     */
    classLevelId: string;
    /**
     * signature
     */
    signature: Blob;
    /**
     * staff-id
     */
    staffId: string;
    /**
     * X-TENANT-ID
     */
    xTenantId: string;
  }): CancelablePromise<string | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/class-levels/{class-level-id}/uploads/signature/{staff-id}',
      path: {
        'class-level-id': classLevelId,
        'staff-id': staffId,
      },
      headers: {
        'X-TENANT-ID': xTenantId,
      },
      body: signature,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * addClassLevelToSection
   * @returns ClassLevelDtoRes OK
   * @returns any Created
   * @throws ApiError
   */
  public static addClassLevelToSectionUsingPut({
    classLevelId,
    sectionId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * sectionId
     */
    sectionId: string;
  }): CancelablePromise<ClassLevelDtoRes | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/class-levels/{classLevelId}/section/{sectionId}',
      path: {
        classLevelId: classLevelId,
        sectionId: sectionId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassLevel
   * @returns ClassLevelDtoRes OK
   * @throws ApiError
   */
  public static getClassLevelUsingGet({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<ClassLevelDtoRes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/class-levels/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteClassLevel
   * @returns any OK
   * @throws ApiError
   */
  public static deleteClassLevelUsingDelete({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/class-levels/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
