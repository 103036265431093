import { combineReducers } from 'redux';
import assessmentReducer from './assessment/reducer';
import bankReducer from './bank-setting/reducers';
import broadSheetReducer from './broadsheet/reducer';
import classInformationReducer from './class-information/reducer';
import cognitiveSkillsReducer from './cognitve-skills/reducer';
import configReducer from './configuration/reducers';
import earlyYearsReducer from './early-years/reducer';
import globalReducer from './global-store/reducers';
import gradesReducer from './grades/reducer';
import internalRolesReducer from './internal-roles/reducer';
import invoiceReducer from './invoice/reducer';
import parentReducer from './parent/reducers';
import plansReducer from './plans/reducer';
import privilegeReducer from './privileges/reducer';
import reportTemplateReducer from './report-template/reducer';
import scoreEntriesReducer from './score-entry/reducer';
import serviceWorkerReducer from './service-workers/reducer';
import staffReducer from './staff/reducer';
import invoiceSummariesReducer from './student-invoice-summary/reducer';
import studentReducer from './student/reducer';
import subjectsReducer from './subjects/reducer';
import userReducer from './users/reducer';

const rootReducer = combineReducers({
  users: userReducer,
  staff: staffReducer,
  student: studentReducer,
  parent: parentReducer,
  global: globalReducer,
  config: configReducer,
  privileges: privilegeReducer,
  invoice: invoiceReducer,
  invoiceSummaries: invoiceSummariesReducer,
  subjects: subjectsReducer,
  bank: bankReducer,
  grades: gradesReducer,
  classInformation: classInformationReducer,
  earlyYears: earlyYearsReducer,
  assessment: assessmentReducer,
  scoreEntries: scoreEntriesReducer,
  cognitiveSkills: cognitiveSkillsReducer,
  internalRoles: internalRolesReducer,
  broadsheet: broadSheetReducer,
  plans: plansReducer,
  reportTemplate: reportTemplateReducer,
  serviceWorker: serviceWorkerReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
