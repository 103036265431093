import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchInvoicesItemsFailure, fetchInvoicesItemsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchInvoicesItems() {
  try {
    const response = yield httpClient.get('/payable-items/');
    yield put(fetchInvoicesItemsSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesItemsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchInvoicesItemsStart() {
  yield takeLatest(invoiceActionTypes.FETCH_INVOICES_ITEMS_START, fetchInvoicesItems);
}
