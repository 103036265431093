import { SchoolFeesConfigurationRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { school_id } from 'utils/constants';
import { apiWrapper } from 'utils/http-client';
import { fetchSchoolFeesConfigFail, fetchSchoolFeesConfigSuccess } from '../actions';
import configActionTypes from '../types';

// const DEFAULT_SCHOOL_FEE_CONFIG = {
//   enable_part_payment: true,
//   enable_minimum_part_payment: true,
//   minimum_part_payment_percentage: 0,
//   enable_manual_payment: true,
//   school_additional_charge: 0.0,
//   flexisaf_amount: 0.0,
//   paystack_charge_bearer: 'SCHOOL',
//   flexisaf_charge_bearer: 'PAYER',
//   date_modified: '2024-01-31T10:58:49.724454Z',
//   date_created: '2024-01-16T11:04:42.9199Z',
// };

export function* fetchSchoolFeesConfig() {
  try {
    const response = yield apiWrapper(() =>
      SchoolFeesConfigurationRestControllerService.getFeesConfigUsingGet({ xTenantId: school_id }),
    );

    // Force disable part payment when flexisaf has a charge
    if (response?.flexisaf_amount > 0) {
      response['enable_part_payment'] = false;
      response['enable_minimum_part_payment'] = false;
      response['enable_manual_payment'] = false;
    }

    yield put(fetchSchoolFeesConfigSuccess(response));
  } catch (error) {
    yield put(fetchSchoolFeesConfigFail(error.info));
  }
}

export function* onFetchSchoolFeesConfigStart() {
  yield takeLatest(configActionTypes.FETCH_SCHOOL_FEES_CONFIG_START, fetchSchoolFeesConfig);
}
