import { takeLatest, put } from 'redux-saga/effects';
import { deletePayableItemFail, deletePayableItemSuccess } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';

export function* deletePayableItem({ payload }) {
  try {
    const response = yield httpClient.delete(`/payable-items/${payload}`);
    yield put(deletePayableItemSuccess(payload));
    Notify('Item deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deletePayableItemFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeletePayableItemStart() {
  yield takeLatest(configActionTypes.DELETE_PAYABLE_ITEM_START, deletePayableItem);
}
