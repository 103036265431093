import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { FIX_ANY } from 'utils/types';
import gradesActionTypes from './types';

interface IGRADE {
  gradeFormats: FIX_ANY;
  gradeFormatModal: boolean;
  deleteGradeFormatModal: boolean;
  isLoading: boolean;
  buttonLoading: boolean;
}

const INITIAL_STATE: IGRADE = {
  gradeFormats: [],
  gradeFormatModal: false,
  deleteGradeFormatModal: false,
  isLoading: false,
  buttonLoading: false,
};

interface actionParams {
  type: gradesActionTypes;
  payload: any;
}

function gradesReducer(state = INITIAL_STATE, { type, payload }: actionParams) {
  switch (type) {
    case gradesActionTypes.FETCH_GRADE_FORMATS_START:
      return {
        ...state,
        isLoading: true,
      };
    case gradesActionTypes.FETCH_GRADE_FORMATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gradeFormats: payload,
      };
    case gradesActionTypes.FETCH_GRADE_FORMATS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case gradesActionTypes.UPDATE_GRADE_FORMATS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case gradesActionTypes.UPDATE_GRADE_FORMATS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case gradesActionTypes.UPDATE_GRADE_FORMATS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case gradesActionTypes.ADD_GRADE_FORMATS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case gradesActionTypes.ADD_GRADE_FORMATS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        gradeFormats: [...state.gradeFormats, payload],
        gradeFormatModal: false,
      };
    case gradesActionTypes.ADD_GRADE_FORMATS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case gradesActionTypes.DELETE_GRADE_FORMATS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case gradesActionTypes.DELETE_GRADE_FORMATS_SUCCESS:
      const new_arr = state.gradeFormats.filter(({ id }) => id !== payload);
      return {
        ...state,
        buttonLoading: false,
        gradeFormats: new_arr,
        deleteGradeFormatModal: false,
      };
    case gradesActionTypes.DELETE_GRADE_FORMATS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case gradesActionTypes.HANDLE_GRADE_FORMATS_MODAL:
      return {
        ...state,
        gradeFormatModal: !payload,
      };
    case gradesActionTypes.HANDLE_DELETE_GRADE_FORMAT_MODAL:
      return {
        ...state,
        deleteGradeFormatModal: !payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'grades',
  storage,
  blacklist: ['error', 'isLoading', 'buttonLoading', 'gradeFormatModal', 'deleteGradeFormatModal'],
};

export default persistReducer(persistConfig, gradesReducer);
