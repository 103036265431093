import { takeLatest, put } from 'redux-saga/effects';
import {
  setCurrentTermSuccess,
  setCurrentTermFail,
  fetchAllExistingTermsStart,
  getCurrentTermStart,
} from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* setCurrentTerm({ payload }) {
  const id = payload.term_id;
  delete payload.term_id;

  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.put(`/terms/${id}`, payload);
    yield put(setCurrentTermSuccess(response.data));
    yield put(fetchAllExistingTermsStart());
    yield put(getCurrentTermStart());
    callback();
  } catch (error) {
    yield put(setCurrentTermFail(error.fail));
    const err = handleError(error);
  }
}

export function* onSetCurrentTermStart() {
  yield takeLatest(configActionTypes.SET_CURRENT_TERM_START, setCurrentTerm);
}
