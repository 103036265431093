import { StudentInvoiceSummaryRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchStudentIncoiceSummaryFail, fetchStudentIncoiceSummarySuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* getStudentInvoiceSummary({ payload }) {
  try {
    // const response = yield httpClient.get(`/invoice-summaries/${payload}`);
    const response = yield apiWrapper(() =>
      StudentInvoiceSummaryRestControllerService.getStudentInvoiceSummaryUsingGet({
        studentId: payload,
      }),
    );
    yield put(fetchStudentIncoiceSummarySuccess(response));
  } catch (error) {
    yield put(fetchStudentIncoiceSummaryFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetStudentInvoiceSummary() {
  yield takeLatest(
    invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_START,
    getStudentInvoiceSummary,
  );
}
