import { all, call } from 'redux-saga/effects';
import { onfetchAssessmentStart } from './fetchAssessments';
import { onCreateAssessmentStart } from './createAssessment';
import { onFetchLevelsAssessmentstart } from './fetchLevelsAssessments';

export function* AssessmentSagas() {
  yield all([
    call(onfetchAssessmentStart),
    call(onCreateAssessmentStart),
    call(onFetchLevelsAssessmentstart),
  ]);
}
