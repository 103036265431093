const privilegeActionTypes = {
  SMS_SEND_TRUE: 'SMS_SEND_TRUE',
  SMS_R_TRUE: 'SMS_R_TRUE',
  SMS_REPORT_TRUE: 'SMS_REPORT_TRUE',
  EMAIL_SEND_TRUE: 'EMAIL_SEND_TRUE',
  EMAIL_R_TRUE: 'EMAIL_R_TRUE',
  EMAIL_REPORT_TRUE: 'EMAIL_REPORT_TRUE',

  STAFF_C_TRUE: 'STAFF_C_TRUE',
  STAFF_R_TRUE: 'STAFF_R_TRUE',
  STAFF_U_TRUE: 'STAFF_U_TRUE',
  STAFF_D_TRUE: 'STAFF_D_TRUE',
  STAFF_RP_TRUE: 'STAFF_RP_TRUE',
  STAFFP_R_TRUE: 'STAFFP_R_TRUE',
  STAFF_DD_TRUE: 'STAFF_DD_TRUE',
  STAFF_PE_TRUE: 'STAFF_PE_TRUE',

  STUDENT_C_TRUE: 'STUDENT_C_TRUE',
  STUDENT_R_TRUE: 'STUDENT_R_TRUE',
  STUDENT_U_TRUE: 'STUDENT_U_TRUE',
  STUDENT_D_TRUE: 'STUDENT_D_TRUE',
  STUDENTP_R_TRUE: 'STUDENTP_R_TRUE',
  STUDENT_DD_TRUE: 'STUDENT_DD_TRUE',

  STUDENTINV_R_TRUE: 'STUDENTINV_R_TRUE',
  STUDENTINV_U_TRUE: 'STUDENTINV_U_TRUE',
  STUDENTPAY_U_TRUE: 'STUDENTPAY_U_TRUE',
  STUDENTPAY_R_TRUE: 'STUDENTPAY_R_TRUE',
  STUDENTDSC_U_TRUE: 'STUDENTDSC_U_TRUE',
  STUDENTINV_N_TRUE: 'STUDENTINV_N_TRUE',

  CLASS_LEVEL_C_TRUE: 'CLASS_LEVEL_C_TRUE',
  CLASS_LEVEL_R_TRUE: 'CLASS_LEVEL_R_TRUE',
  CLASS_LEVEL_U_TRUE: 'CLASS_LEVEL_U_TRUE',
  CLASS_LEVEL_D_TRUE: 'CLASS_LEVEL_D_TRUE',

  ARM_C_TRUE: 'ARM_C_TRUE',
  ARM_R_TRUE: 'ARM_R_TRUE',
  ARM_U_TRUE: 'ARM_U_TRUE',
  ARM_D_TRUE: 'ARM_D_TRUE',

  PARENT_C_TRUE: 'PARENT_C_TRUE',
  PARENT_R_TRUE: 'PARENT_R_TRUE',
  PARENT_U_TRUE: 'PARENT_U_TRUE',
  PARENT_D_TRUE: 'PARENT_D_TRUE',
  PARENT_RP_TRUE: 'PARENT_RP_TRUE',
  PARENTP_R_TRUE: 'PARENTP_R_TRUE',

  FEES_R_TRUE: 'FEES_R_TRUE',
  FEES_N_TRUE: 'FEES_N_TRUE',
  FEES_TL_TRUE: 'FEES_TL_TRUE',

  CLASSES_R_TRUE: 'CLASSES_R_TRUE',
  CLASSES_FT_TRUE: 'CLASSES_FT_TRUE',
  CLASSES_U_TRUE: 'CLASSES_U_TRUE',

  SUBJECTSP_R_TRUE: 'SUBJECTSP_R_TRUE',
  SUBJECTSP_U_TRUE: 'SUBJECTSP_U_TRUE',
  SUBJECTS_R_TRUE: 'SUBJECTS_R_TRUE',
  SUBJECTS_ST_TRUE: 'SUBJECTS_ST_TRUE',

  RESULTS_AP_TRUE: 'RESULTS_AP_TRUE',
  RESULTS_R_TRUE: 'RESULTS_R_TRUE',

  HEADTEACHER_X_TRUE: 'HEADTEACHER_X_TRUE',

  TRAITS_R_TRUE: 'TRAITS_R_TRUE',

  SKILLS_R_TRUE: 'SKILLS_R_TRUE',

  BROADSHEET_R_TRUE: 'BROADSHEET_R_TRUE',
  BROADSHEET_AP_TRUE: 'BROADSHEET_AP_TRUE',

  SETTINGS_G_TRUE: 'SETTINGS_G_TRUE',
  SETTINGS_F_TRUE: 'SETTINGS_F_TRUE',
  SETTINGS_A_TRUE: 'SETTINGS_A_TRUE',
  SETTINGS_P_TRUE: 'SETTINGS_P_TRUE',

  ADVANCE_PAYMENT_CONFIG_TRUE: 'ADVANCE_PAYMENT_CONFIG_TRUE',

  FEESCIT_R_TRUE: 'FEESCIT_R_TRUE',
  FEESCIT_C_TRUE: 'FEESCIT_C_TRUE',
  FEESCIT_A_TRUE: 'FEESCIT_A_TRUE',
  FEESCIT_U_TRUE: 'FEESCIT_U_TRUE',
  FEESCIT_D_TRUE: 'FEESCIT_D_TRUE',

  STUDENT_PROFILE_R_TRUE: 'STUDENT_PROFILE_R_TRUE',
  BULK_STAFF_C_TRUE: 'BULK_STAFF_C_TRUE',
  BULK_STUDENT_C_TRUE: 'BULK_STUDENT_C_TRUE',
  STAFF_PROFILE_R_TRUE: 'STAFF_PROFILE_R_TRUE',
  PARENT_PROFILE_R_TRUE: 'PARENT_PROFILE_R_TRUE',

  USERS_R_TRUE: 'USERS_R_TRUE',
  USERS_C_TRUE: 'USERS_C_TRUE',
  USERS_U_TRUE: 'USERS_U_TRUE',
  USERS_D_TRUE: 'USERS_D_TRUE',
  ROLES_R_TRUE: 'ROLES_R_TRUE',
  ROLES_C_TRUE: 'ROLES_C_TRUE',
  ROLES_D_TRUE: 'ROLES_D_TRUE',
  ROLES_U_TRUE: 'ROLES_U_TRUE',

  INVOICE_TEMPLATE_R_TRUE: 'INVOICE_TEMPLATE_R_TRUE',
  INVOICE_TEMPLATE_U_TRUE: 'INVOICE_TEMPLATE_U_TRUE',
  INVOICE_TEMPLATE_C_TRUE: 'INVOICE_TEMPLATE_C_TRUE',
  INVOICE_TEMPLATE_ASSIGN_TRUE: 'INVOICE_TEMPLATE_ASSIGN_TRUE',
  INVOICE_TEMPLATE_D_TRUE: 'INVOICE_TEMPLATE_D_TRUE',

  PAYABLE_ITEM_C_TRUE: 'PAYABLE_ITEM_C_TRUE',
  PAYABLE_ITEM_D_TRUE: 'PAYABLE_ITEM_D_TRUE',
  PAYABLE_ITEM_U_TRUE: 'PAYABLE_ITEM_U_TRUE',
  PAYABLE_ITEM_R_TRUE: 'PAYABLE_ITEM_R_TRUE',

  BANK_U_TRUE: 'BANK_U_TRUE',
  BANK_R_TRUE: 'BANK_R_TRUE',
  BANK_C_TRUE: 'BANK_C_TRUE',
  BANK_D_TRUE: 'BANK_D_TRUE',
  ACCOUNT_DASHBOARD_R_TRUE: 'ACCOUNT_DASHBOARD_R_TRUE',
  CLASS_PAYMENT_R_TRUE: 'CLASS_PAYMENT_R_TRUE',
  STUDENT_INVOICE_PROFILE_R_TRUE: 'STUDENT_INVOICE_PROFILE_R_TRUE',
  STUDENT_INVOICE_U_TRUE: 'STUDENT_INVOICE_U_TRUE',
  STUDENT_PAYMENT_RECEIVE_TRUE: 'STUDENT_PAYMENT_RECEIVE_TRUE',
  PAYMENT_HISTORY_R_TRUE: 'PAYMENT_HISTORY_R_TRUE',
  PART_PAYMENT_CONFIGURE_TRUE: 'PART_PAYMENT_CONFIGURE_TRUE',
  SESSIONAL_PAYMENT_CONFIGURE_TRUE: 'SESSIONAL_PAYMENT_CONFIGURE_TRUE',
  STUDENT_ACCOUNT_PROFILE_R_TRUE: 'STUDENT_ACCOUNT_PROFILE_R_TRUE',
  STUDENT_PAYMENT_R_TRUE: 'STUDENT_PAYMENT_R_TRUE',
  STUDENT_DISCOUNT_C_TRUE: 'STUDENT_DISCOUNT_C_TRUE',
  STUDENT_RECEIPT_R_TRUE: 'STUDENT_RECEIPT_R_TRUE',
  MANAGE_PROFILE_EDIT_REQUEST_TRUE: 'MANAGE_PROFILE_EDIT_REQUEST_TRUE',
  ADMIN_PROFILE_R_TRUE: 'ADMIN_PROFILE_R_TRUE',
  CLEAR_ALL_PRIVILEGES: 'CLEAR_ALL_PRIVILEGES',
  SUPER_ADMIN: 'SUPER_ADMIN',
  FEES_D_TRUE: 'FEES_D_TRUE',
  SCHOOL_DIRECTOR: 'SCHOOL_DIRECTOR',
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
};

export default privilegeActionTypes;
