import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchListOfBanksFailure, fetchListOfBanksSuccess } from '../actions';
import bankActionTypes from '../types';

export function* fetchBanks() {
  try {
    const response = yield httpClient.get('/banks');

    yield put(fetchListOfBanksSuccess(response.data));
  } catch (error) {
    yield put(fetchListOfBanksFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchBanksStart() {
  yield takeLatest(bankActionTypes.FETCH_LIST_BANKS_START, fetchBanks);
}
