import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { handleFeedBackAction } from 'redux/parent/actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  bulkStaffUploadFailure,
  bulkStaffUploadSuccess,
  fetchStaffsStart,
  fetchStaffsStatsStart,
  stopStaffBulkLoadingAction,
} from '../actions';
import staffActionTypes from '../types';

export function* bulkStaffUpload({ payload }) {
  const { fileName, data } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post('/staff/uploads/template', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      yield put(stopStaffBulkLoadingAction());

      Notify('Something went wrong, please check the downloaded excel file and try again', {
        status: 'info',
      });
    } else {
      yield put(bulkStaffUploadSuccess());
      const data = {
        offset: 0,
        limit: 10,
      };
      yield put(fetchStaffsStart(data));
      yield put(fetchStaffsStatsStart());
      yield put(handleFeedBackAction(true)); // open Feed back modal
      Notify('Staff Bulk Uploaded successfully', { position: 'top-right' });
    }
  } catch (error) {
    yield put(bulkStaffUploadFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onBulkStaffUploadStart() {
  yield takeLatest(staffActionTypes.BULK_STAFF_UPLOAD_START, bulkStaffUpload);
}
