import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import { changeUsernameSuccess, changeUsernameFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from 'utils/utils';

export function* changeUsername({ data, callback }) {
  try {
    const response = yield httpClient.post('/auth/change-username', data);
    yield put(changeUsernameSuccess(response.data));
    callback();
  } catch (error) {
    yield put(changeUsernameFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onChangeUsername() {
  yield takeLatest(userActionTypes.CHANGE_USERNAME_START, changeUsername);
}
