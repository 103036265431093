/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScoreEntryTimeFrameConfigDto } from '../models/ScoreEntryTimeFrameConfigDto';
import type { ScoreEntryTimeFrameConfigRequest } from '../models/ScoreEntryTimeFrameConfigRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScoreEntryTimeFrameConfigRestControllerService {
  /**
   * update
   * @returns ScoreEntryTimeFrameConfigDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateUsingPut3({
    requests,
  }: {
    /**
     * requests
     */
    requests: ScoreEntryTimeFrameConfigRequest;
  }): CancelablePromise<ScoreEntryTimeFrameConfigDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/score-entry-time-frame-config',
      body: requests,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteById
   * @returns any OK
   * @throws ApiError
   */
  public static deleteByIdUsingDelete({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/score-entry-time-frame-config/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * get
   * @returns ScoreEntryTimeFrameConfigDto OK
   * @throws ApiError
   */
  public static getUsingGet({
    termId,
  }: {
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<ScoreEntryTimeFrameConfigDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/score-entry-time-frame-config/{term-id}',
      path: {
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
