import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { filterEditRequestsFailure, filterEditRequestsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* filterRequests({ payload }) {
  try {
    const response = yield httpClient.get('/staff/profile-edit/filter', { params: payload });
    yield put(filterEditRequestsSuccess(response.data));
  } catch (error) {
    yield put(filterEditRequestsFailure(error?.message));
    handleError(error);
  }
}

export function* onFilterEditRequestsStart() {
  yield takeLatest(staffActionTypes.FILTER_EDIT_REQUEST_START, filterRequests);
}
