import { put, takeLatest } from 'redux-saga/effects';
import invoiceSummariesActionTypes from '../types';
import { messageDefaultersSsendSMSSuccess, messageDefaultersSsendSMSFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* messageDefaultersSsendSMS({ payload }) {
  try {
    const response = yield httpClient.post('/notification/sms', payload);
    yield put(messageDefaultersSsendSMSSuccess(response.data));

    Notify('SMS sent successfully', { position: 'top-right' });
  } catch (error) {
    yield put(messageDefaultersSsendSMSFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onMessageDefaultersSsendSMSStart() {
  yield takeLatest(
    invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_START,
    messageDefaultersSsendSMS,
  );
}
