import { put, takeLatest } from 'redux-saga/effects';
import globalActionTypes from '../types';
import { fetchStatesFailure, fetchStatesSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchStates({ payload }) {
  try {
    const response = yield httpClient.get(`/countries/${payload.value}/states`);

    yield put(fetchStatesSuccess(response.data));
  } catch (error) {
    yield put(fetchStatesFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchStatesStart() {
  yield takeLatest(globalActionTypes.FETCH_STATES_START, fetchStates);
}
