import { takeLatest, put } from 'redux-saga/effects';
import { editRatingSuccess, editRatingFail, fetchRatingStart } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* editRatingName({ payload }) {
  const { id, data } = payload;
  try {
    const response = yield httpClient.put(`/trait-configuration/trait-ratings/${id}`, data);
    yield put(editRatingSuccess(response.data));
    Notify('Rating Name edited succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(editRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onEditRatingNameStart() {
  yield takeLatest(earlyYearsActionTypes.EDIT_RATING_START, editRatingName);
}
