import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchAllStudentFail, fetchAllStudentSuccess } from '../action';
import studentActionTypes from '../types';

export function* getAllStudents({ payload }) {
  try {
    const response = yield httpClient.get(`/students`, { params: payload });
    yield put(fetchAllStudentSuccess(response.data));
  } catch (error) {
    yield put(fetchAllStudentFail(error?.message));
    handleError(error);
  }
}

export function* onFetchAllStudentStart() {
  yield takeLatest(studentActionTypes.FETCH_STUDENTS_START, getAllStudents);
}
