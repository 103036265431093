import { takeLatest, put } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { getCumulativeLevelBroadsheetFail, getCumulativeLevelBroadsheetSuccess } from '../actions';
import broadSheetTypes from '../types';
import { handleError } from 'utils/utils';

export function* fetchCumulativeLevelBroadsheet({ payload }) {
  const params = {
    'class-level-id': payload.classLevelId,
    'term-id': payload.termId,
  };
  try {
    const response = yield httpClient.get(`/student-results/class-level-broad-sheet/cumulative`, {
      params,
    });
    yield put(getCumulativeLevelBroadsheetSuccess(response.data));
  } catch (error) {
    yield put(getCumulativeLevelBroadsheetFail(error?.message));
    const obj = {
      code: 404,
      message: 'No Grading Format defined for class. Please contact your school admin',
    };
    const err = handleError(error, obj);
  }
}

export function* onFetchCumulativeLevelBroadsheetStart() {
  yield takeLatest(
    broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_START,
    fetchCumulativeLevelBroadsheet,
  );
}
