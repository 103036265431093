import { AppState } from 'redux/root-reducer';
import { createSelector } from 'reselect';

const selectConfig = (state: AppState) => state.config;

export const selectButtonLoading = createSelector([selectConfig], (config) => config.buttonLoading);

export const selectSchoolSettings = createSelector(
  [selectConfig],
  (config) => config.schoolSettings,
);

export const selectDeleteModal = createSelector([selectConfig], (config) => config.deleteModal);

export const selectDeactivateModal = createSelector(
  [selectConfig],
  (config) => config.deactivateModal,
);

export const selectCreateModal = createSelector([selectConfig], (config) => config.createModal);

export const selectClassLevelsList = createSelector(
  [selectConfig],
  (config) => config.classLevelsList,
);

export const selectDataLoading = createSelector([selectConfig], (config) => config.dataloading);

export const selectEdit = createSelector([selectConfig], (config) => config.edit);

export const selectClassLevelData = createSelector(
  [selectConfig],
  (config) => config.classLevelData,
);

export const selectEditData = createSelector([selectConfig], (config) => config.editData);

export const selectTotal = createSelector([selectConfig], (config) => config.total);

export const selectDeleteArmModal = createSelector(
  [selectConfig],
  (config) => config.deleteArmModal,
);

export const selectModal = createSelector([selectConfig], (config) => config.modal);

export const selectClassArmList = createSelector([selectConfig], (config) => config.classArmList);

export const selectEditArm = createSelector([selectConfig], (config) => config.editArm);

export const selectTotalArms = createSelector([selectConfig], (config) => config.totalArms);

export const selectClassArmData = createSelector([selectConfig], (config) => config.classArmData);

export const selectArm = createSelector([selectConfig], (config) => config.arm);

export const selectSchoolId = createSelector([selectConfig], (config) => config.schoolId);

export const selectSchoolInfo = createSelector([selectConfig], (config) => config.schoolInfo);

export const selectPayableItemsList = createSelector(
  [selectConfig],
  (config) => config.payableItemsList,
);

export const selectPayableItemId = createSelector([selectConfig], (config) => config.payableItemId);

export const selectPayableItemData = createSelector(
  [selectConfig],
  (config) => config.payableItemData,
);

export const selectSchoolFeesConfig = createSelector(
  [selectConfig],
  (config) => config.schoolFeesConfig,
);

export const selectPayableItemModal = createSelector(
  [selectConfig],
  (config) => config.payableItemModal,
);

export const selectDeletePayableItemModal = createSelector(
  [selectConfig],
  (config) => config.deletePayableItemModal,
);

export const selectEditPayableItem = createSelector(
  [selectConfig],
  (config) => config.editPayableItem,
);

export const selectTermInfo = createSelector([selectConfig], (config) => config.termInfo);

export const selectClassMembers = createSelector([selectConfig], (config) => config.classMembers);

export const selectClassPopulation = createSelector(
  [selectConfig],
  (config) => config.classPopulation,
);

export const selectStudentModal = createSelector([selectConfig], (config) => config.studentModal);

export const selectSessionsInfo = createSelector([selectConfig], (config) => config.sessionsInfo);

export const selectRemoveStudentModal = createSelector(
  [selectConfig],
  (config) => config.removeStudentModal,
);

export const selectClassInfo = createSelector([selectConfig], (config) => config.classInfo);

export const selectCurrentTerm = createSelector([selectConfig], (config) => config.currentTerm);
export const selectCurrentSubscription = createSelector(
  [selectConfig],
  (config) => config.currentSubscription,
);

export const selectCurrentTermDescription = createSelector(
  [selectConfig],
  (config) => config.currentTermDescription,
);

export const selectCurrentTermObject = createSelector(
  [selectConfig],
  (config) => config.currentTermObject,
);

export const selectCurrentTermDTO = createSelector(
  [selectConfig],
  (config) => config.currentTermDTO,
);

export const selectCurrentTermUUID = createSelector(
  [selectConfig],
  (config) => config.currentTermUUID,
);

export const selectCurrentSession = createSelector(
  [selectConfig],
  (config) => config.currentSession,
);

export const selectTermStartDate = createSelector([selectConfig], (config) => config.termStartDate);

export const selectListOfArms = createSelector([selectConfig], (config) => config.listOfArms);

export const selectTermSetupLoading = createSelector(
  [selectConfig],
  (config) => config.termSetupLoading,
);

export const selectListOfActiveArms = createSelector(
  [selectConfig],
  (config) => config.listOfActiveArms,
);

export const selectIsLoading = createSelector([selectConfig], (config) => config.isLoading);

export const selectCurrentClassLevelId = createSelector(
  [selectConfig],
  (config) => config.currentClassLevelId,
);

export const selectClassLevelProfileData = createSelector(
  [selectConfig],
  (config) => config.classLevelProfileData,
);

export const selectTermDefinition = createSelector(
  [selectConfig],
  (config) => config.termDefinitionList,
);

export const selectaddTermModal = createSelector([selectConfig], (config) => config.addTermModal);

export const selectEditTerm = createSelector([selectConfig], (config) => config.editTerm);

export const selectDataToEdit = createSelector([selectConfig], (config) => config.dataToEdit);

export const selectDeleteTermModal = createSelector(
  [selectConfig],
  (config) => config.deleteTermModal,
);

export const selectCurrentSessionObject = createSelector(
  [selectConfig],
  (config) => config.currentSessionObject,
);

export const selectCreateSessionModal = createSelector(
  [selectConfig],
  (config) => config.createSessionModal,
);

export const selectAllExistingTerms = createSelector(
  [selectConfig],
  (config) => config.allExistingTerms,
);

export const selectPath = createSelector([selectConfig], (config) => config.path);

export const selectClassLevelTraits = createSelector(
  [selectConfig],
  (config) => config.classLevelTraits,
);

export const selectClassLevelTraitsRating = createSelector(
  [selectConfig],
  (config) => config.classLevelTraitsRating,
);

export const selectLoadingStudentComment = createSelector(
  [selectConfig],
  (config) => config.loadingStudentComment,
);

export const selectLoadingSkillsResult = createSelector(
  [selectConfig],
  (config) => config.loadingSkillsResult,
);

export const selectLoadingTraitAssessment = createSelector(
  [selectConfig],
  (config) => config.loadingTraitAssessment,
);

export const selectLoadingChildResult = createSelector(
  [selectConfig],
  (config) => config.loadingChildResult,
);

export const selectLoadingChildAttendanceSummary = createSelector(
  [selectConfig],
  (config) => config.loadingChildAttendanceSummary,
);

export const selectGettingAssessment = createSelector(
  [selectConfig],
  (config) => config.gettingAssessment,
);

export const selectCurrentStudentAssessment = createSelector(
  [selectConfig],
  (config) => config.currentStudentAssessment,
);
export const selectTraits = createSelector([selectConfig], (config) => config.traits);
export const selectParentChildTrait = createSelector(
  [selectConfig],
  (config) => config.parentChildTrait,
);
export const selectStudentComments = createSelector(
  [selectConfig],
  (config) => config.studentComments,
);

export const selectApproveResults = createSelector(
  [selectConfig],
  (config) => config.approveResults,
);

export const selectGetApproveResultLoading = createSelector(
  [selectConfig],
  (config) => config.getApproveResultLoading,
);

export const selectListOfClassLevelsWithArms = createSelector(
  [selectConfig],
  (config) => config.listOfClassLevelsWithArms,
);

export const selectLevelDataLoading = createSelector(
  [selectConfig],
  (config) => config.levelDataLoading,
);
export const selectChildResult = createSelector([selectConfig], (config) => config.childResult);
export const selectStudentResults = createSelector(
  [selectConfig],
  (config) => config.studentResults,
);
export const selectStudentSkillsResults = createSelector(
  [selectConfig],
  (config) => config.studentSkillsResults,
);

export const selectStudentTransferModal = createSelector(
  [selectConfig],
  (config) => config.studentTransferModal,
);

export const selectTransferLoading = createSelector(
  [selectConfig],
  (config) => config.transferLoading,
);

export const selectLoadingClassMembers = createSelector(
  [selectConfig],
  (config) => config.loadingClassMembers,
);

export const selectOnboardingInfo = createSelector(
  [selectConfig],
  (config) => config.onboardingInfo,
);

export const selectIsOnboarding = createSelector([selectConfig], (config) => config.isOnboarding);

export const selectRefetchAttendance = createSelector(
  [selectConfig],
  (config) => config.refetchAttendance,
);

export const selectChildAttendanceSummary = createSelector(
  [selectConfig],
  (config) => config.childAttendanceSummary,
);

export const selectBulkModal = createSelector([selectConfig], (config) => config.bulkModal);

export const selectActivityModal = createSelector([selectConfig], (config) => config.activityModal);
export const selectDeleteActivityModal = createSelector(
  [selectConfig],
  (config) => config.deleteActivityModal,
);
export const selectActivityData = createSelector([selectConfig], (config) => config.activityData);

export const selectNoGradingFormat = createSelector(
  [selectConfig],
  (config) => config.noGradingFormat,
);

export const selectEvaluatorActive = createSelector(
  [selectConfig],
  (config) => config.isEvaluatorActive,
);

export const selectCollaboratorActive = createSelector(
  [selectConfig],
  (config) => config.isCollaboratorActive,
);
