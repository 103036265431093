import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  addItemToInvoiceFail,
  addItemToInvoiceSuccess,
  fetchInvoiceDiscountStart,
  fetchStudentIncoiceSummaryStart,
  fetchStudentInvoicesStart,
} from '../actions';
import invoiceActionTypes from '../types';

export function* addItem({ payload }) {
  const { session_id, term_id, student_id, data } = payload;

  const details = {
    'session-id': session_id,
    'term-id': term_id,
    'student-id': student_id,
  };

  const details2 = {
    'session-id': session_id,
    'term-id': term_id,
    student_id,
  };

  try {
    const response = yield httpClient.post(`/students-invoice/${student_id}`, { items: data });
    yield put(addItemToInvoiceSuccess(response.data));
    yield put(fetchStudentInvoicesStart(details));
    yield put(fetchStudentIncoiceSummaryStart(student_id));
    yield put(fetchInvoiceDiscountStart(details2));
    Notify('Item added successfully', { position: 'top-right' });
  } catch (error) {
    yield put(addItemToInvoiceFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAddItemToInvoiceStart() {
  yield takeLatest(invoiceActionTypes.ADD_ITEM_TO_INVOICE_START, addItem);
}
