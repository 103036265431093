import { put, takeLatest } from 'redux-saga/effects';
import { deleteGradeFormatsFail, deleteGradeFormatsSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import gradesActionTypes from '../types';

export function* deleteFormat({ payload }) {
  try {
    const response = yield httpClient.delete(`/grading/group/${payload}`);
    yield put(deleteGradeFormatsSuccess(payload));
    Notify('Grade format was deleted successfully.', { position: 'top-right', status: 'success' });
  } catch (error) {
    yield put(deleteGradeFormatsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteGradeFormatsStart() {
  yield takeLatest(gradesActionTypes.DELETE_GRADE_FORMATS_START, deleteFormat);
}
