import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { getStudentCommentsFail, getStudentCommentsSuccess } from '../actions';
import configActionTypes from '../types';

export function* getStudentTraitComments({ payload }) {
  try {
    const response = yield httpClient.get(`/term-comments/eot-comments`, { params: payload });
    yield put(getStudentCommentsSuccess(response.data));
  } catch (error) {
    yield put(getStudentCommentsFail(error?.message));
    handleError(error);
  }
}

export function* onGetStudentComments() {
  yield takeLatest(configActionTypes.GET_STUDENT_COMMENTS_START, getStudentTraitComments);
}
