import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import { fetchLevelsAndArmsSuccess, fetchLevelsAndArmsFail } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* fetchLevelsWithArm({ payload }) {
  try {
    const params = {
      'term-id': payload,
    };
    const response = yield httpClient.get(`/class-levels/term`, { params });
    yield put(fetchLevelsAndArmsSuccess(response.data));
  } catch (error) {
    yield put(fetchLevelsAndArmsFail(error?.message));
    const obj = {
      code: 400,
      message: 'Current term has not been defined yet, please contact the admin',
    };
    const err = handleError(error, obj);
  }
}

export function* onFetchLevelsWithArmsStart() {
  yield takeLatest(configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_START, fetchLevelsWithArm);
}
