import { takeLatest, put } from 'redux-saga/effects';
import { fetchPaymentReceiptsSuccess, fetchPaymentReceiptsFail } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from 'utils/http-client';
import invoiceActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchPaymentReceipt({ payload }) {
  try {
    const response = yield httpClient.get(`/payments/receipts`, { params: payload });
    yield put(fetchPaymentReceiptsSuccess(response.data));
  } catch (error) {
    yield put(fetchPaymentReceiptsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchPaymentReceipt() {
  yield takeLatest(invoiceActionTypes.FETCH_PAYMENT_RECEIPT_START, fetchPaymentReceipt);
}
