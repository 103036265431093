import { takeLatest, put } from 'redux-saga/effects';
import { Notify } from '@flexisaf/flexibull2';
import studentActionTypes from '../types';
import { studentStatusFail, studentStatusSuccess } from '../action';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* changeStudentStatus({ payload }) {
  try {
    const response = yield httpClient.put(`/students`, payload);
    yield put(studentStatusSuccess(response.data));
    Notify(
      `Student ${payload.active_status === 'ACTIVE' ? 'Activated' : 'Deactivated'} successfully`,
      { position: 'top-right' },
    );
  } catch (error) {
    yield put(studentStatusFail(error?.message, payload));
    const err = handleError(error);
  }
}

export function* onStudentStatusStart() {
  yield takeLatest(studentActionTypes.STUDENT_STATUS_START, changeStudentStatus);
}
