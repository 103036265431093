import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from 'utils/utils';
import httpClient from '../../../utils/http-client';
import {
  createProfileEditFailure,
  createProfileEditSuccess,
  fetchStaffEditRequestsStart,
} from '../actions';
import userActionTypes from '../types';

export function* createRequest({ payload }) {
  try {
    const response = yield httpClient.post('/staff/profile-edit', payload);
    yield put(createProfileEditSuccess(response.data));
    Notify('Your profile changes was submitted successfully.', { position: 'top-right' });
    yield put(fetchStaffEditRequestsStart(payload?.id));
  } catch (error) {
    yield put(createProfileEditFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateEditRequest() {
  yield takeLatest(userActionTypes.CREATE_EDIT_REQUEST_START, createRequest);
}
