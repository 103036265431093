import { put, takeLatest } from 'redux-saga/effects';
import invoiceSummariesActionTypes from '../types';
import { messageDefaultersSsendEmailSuccess, messageDefaultersSsendEmailFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* messageDefaultersSsendEmail({ payload }) {
  try {
    const response = yield httpClient.post('/notification/email', payload);
    yield put(messageDefaultersSsendEmailSuccess(response.data));

    Notify('Email sent successfully', { position: 'top-right' });
  } catch (error) {
    yield put(messageDefaultersSsendEmailFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onMessageDefaultersSsendEmailStart() {
  yield takeLatest(
    invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_START,
    messageDefaultersSsendEmail,
  );
}
