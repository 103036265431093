import { put, takeLatest } from 'redux-saga/effects';
import bankActionTypes from '../types';
import { fetchBankAccountsSuccess, fetchBankAccountsFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchBankAccounts() {
  try {
    const response = yield httpClient.get('/bank-accounts');

    yield put(fetchBankAccountsSuccess(response.data));
  } catch (error) {
    yield put(fetchBankAccountsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchBankAccountsStart() {
  yield takeLatest(bankActionTypes.FETCH_BANK_ACCOUNTS_START, fetchBankAccounts);
}
