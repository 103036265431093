import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { updateStaffPictureFailure, updateStaffPictureSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';
import { editCurrentUserDetails } from 'redux/users/actions';

export function* updateStaffPicture({ payload }) {
  const { data, id, isCurrentUser } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post(`/staff/${id}/uploads/profile-pic`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    if (isCurrentUser) {
      yield put(editCurrentUserDetails({ profile_pic: response.data }));
    } else {
      yield put(updateStaffPictureSuccess(response.data, id));
    }
    Notify('Staff Picture Updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(updateStaffPictureFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onUpdateStaffPictureStart() {
  yield takeLatest(staffActionTypes.UPDATE_STAFF_PICTURE_START, updateStaffPicture);
}
