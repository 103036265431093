import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { getStudentResultsFail, getStudentResultsSuccess, handleNoGradingFormat } from '../actions';
import configActionTypes from '../types';

export function* getStudentResutls({ payload }) {
  try {
    const response = yield httpClient.get(`/student-results`, { params: payload });
    yield put(getStudentResultsSuccess(response.data));
    yield put(handleNoGradingFormat(false));
  } catch (error) {
    yield put(getStudentResultsFail(error?.message));

    const err = handleError(error);

    if (err?.statusCode === 409) {
      yield put(handleNoGradingFormat(true));
    } else {
      yield put(handleNoGradingFormat(false));
    }
  }
}

export function* onGetStudentResults() {
  yield takeLatest(configActionTypes.GET_STUDENT_RESULTS_START, getStudentResutls);
}
