import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { termProfileSetClassInfoSuccess, termProfileSetClassInfoFail } from '../actions';
import { handleError } from 'utils/utils';

export function* termProfileSetClassInfo({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.post('/class-information/term', payload);
    yield put(termProfileSetClassInfoSuccess(response.data));
    callback();
  } catch (error) {
    yield put(termProfileSetClassInfoFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTermProfileSetClassInfo() {
  yield takeLatest(configActionTypes.TERM_PROFILE_SET_CLASS_INFO_START, termProfileSetClassInfo);
}
