/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignStudentToSubCollaboratorRequest } from '../models/AssignStudentToSubCollaboratorRequest';
import type { CollaboratorDto } from '../models/CollaboratorDto';
import type { CreateSubCollaboratorRequest } from '../models/CreateSubCollaboratorRequest';
import type { SubCollaboratorDto } from '../models/SubCollaboratorDto';
import type { UpdateSubCollaboratorRequest } from '../models/UpdateSubCollaboratorRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollaboratorRestControllerService {
  /**
   * getAllCollaborators
   * @returns CollaboratorDto OK
   * @throws ApiError
   */
  public static getAllCollaboratorsUsingGet(): CancelablePromise<Array<CollaboratorDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/collaborators',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createCollaborator
   * @returns CollaboratorDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createCollaboratorUsingPost({
    dto,
  }: {
    /**
     * dto
     */
    dto: CollaboratorDto;
  }): CancelablePromise<CollaboratorDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/collaborators',
      body: dto,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getSubCollaborators
   * @returns SubCollaboratorDto OK
   * @throws ApiError
   */
  public static getSubCollaboratorsUsingGet(): CancelablePromise<Array<SubCollaboratorDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/collaborators/sub-collaborators',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createSubCollaborators
   * @returns SubCollaboratorDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createSubCollaboratorsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: CreateSubCollaboratorRequest;
  }): CancelablePromise<SubCollaboratorDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/collaborators/sub-collaborators',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * assignSubCollaborator
   * @returns any OK
   * @throws ApiError
   */
  public static assignSubCollaboratorUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: AssignStudentToSubCollaboratorRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/collaborators/sub-collaborators/student/assign',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getSubCollaborator
   * @returns SubCollaboratorDto OK
   * @throws ApiError
   */
  public static getSubCollaboratorUsingGet({
    subCollaboratorId,
    termId,
  }: {
    /**
     * sub-collaborator-id
     */
    subCollaboratorId: string;
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<SubCollaboratorDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/collaborators/sub-collaborators/{id}',
      query: {
        'sub-collaborator-id': subCollaboratorId,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateSubCollaborators
   * @returns any OK
   * @throws ApiError
   */
  public static updateSubCollaboratorsUsingPut({
    request,
  }: {
    /**
     * request
     */
    request: UpdateSubCollaboratorRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/collaborators/sub-collaborators/{id}',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteSubCollaborators
   * @returns any OK
   * @throws ApiError
   */
  public static deleteSubCollaboratorsUsingDelete({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/collaborators/sub-collaborators/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * toggleUsesCollaboratorRole
   * @returns any OK
   * @throws ApiError
   */
  public static toggleUsesCollaboratorRoleUsingPut(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/collaborators/toggle',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getCollaborator
   * @returns CollaboratorDto OK
   * @throws ApiError
   */
  public static getCollaboratorUsingGet({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<CollaboratorDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/collaborators/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteCollaborators
   * @returns any OK
   * @throws ApiError
   */
  public static deleteCollaboratorsUsingDelete({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/collaborators/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
