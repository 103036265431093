import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { unlinkChildFailure, unlinkChildSuccess } from '../actions';
import staffActionTypes from '../types';

export function* unlinkChild({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield httpClient.post('/parents/unlink-student', data);
    yield put(unlinkChildSuccess(data));
    Notify('Child unlinked successfully', { position: 'top-right' });
    callback?.();
  } catch (error) {
    const err = handleError(error);
    yield put(unlinkChildFailure(err?.message));
  }
}

export function* onUnlinkChildStart() {
  yield takeLatest(staffActionTypes.UNLINK_CHILD_START, unlinkChild);
}
