import { put, takeLatest } from 'redux-saga/effects';
import subjectsActionTypes from '../types';
import { fetchSubjectsSuccess, fetchSubjectsFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchSubjects({ payload }) {
  try {
    const response = yield httpClient.get('subjects', { params: payload });
    yield put(fetchSubjectsSuccess(response.data));
  } catch (error) {
    yield put(fetchSubjectsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchSubjectsStart() {
  yield takeLatest(subjectsActionTypes.FETCH_SUBJECTS_START, fetchSubjects);
}
