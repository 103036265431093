import { Box, EmptyState, Loader, PageTitle, Spacer, Text } from '@flexisaf/flexibull2';
import BreadCrumbs from 'components/breadcrumb';
import TraitGroup from 'components/traitCollapsible';
import { Theme } from 'config/theme';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useScoreTimeframe from './components/scoreEntry/hooks/useScoreTimeframe';
import ScoreTimeframeItem from './components/scoreEntry/scoreTimeframeItem';

const Collapsible = styled(TraitGroup)`
  .body {
    padding: 0;
    background-color: #fff;
  }
`;
const TimeframeTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  /* width: 100%; */

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  td {
    background-color: #fafafa;
  }
`;

const ScoreEntryTimeframe = (props) => {
  const { id: term_id } = useParams();

  const { structuredAssessmentList, SaveAll, buttonLoading, updateSingleClass, loading } =
    useScoreTimeframe({ term_id });

  const hasItems = structuredAssessmentList?.filter((x) => x.class_levels.length > 0)?.length || 0;

  return (
    <Box pad="30px 36px">
      <PageTitle>
        {' '}
        <Text size={Theme.H6}>Term Profile</Text>{' '}
      </PageTitle>
      <Box>
        <BreadCrumbs
          links={[{ path: `/term-profile/${term_id}`, text: 'Term Profile' }]}
          last="Score entry timeframe"
        />
        <Spacer space={30} />
        <Text bold size="16px">
          Score Entry Timeframe
        </Text>
        <Spacer space={10} />
        <>
          {loading ? (
            <Box pad="150px 0">
              <Loader color={Theme.PrimaryColor} section />
            </Box>
          ) : (
            <>
              {hasItems ? (
                structuredAssessmentList
                  ?.filter((x) => x.class_levels.length > 0)
                  ?.map((assessment) => (
                    <ScoreTimeframeItem
                      save={SaveAll}
                      loading={buttonLoading}
                      assessment={assessment}
                      key={assessment.id}
                      updateSingleClass={updateSingleClass}
                    />
                  ))
              ) : (
                <EmptyState
                  type="documents"
                  title="Currently no class level is assigned to any assessment format."
                />
              )}
            </>
          )}
        </>
      </Box>
    </Box>
  );
};

export default ScoreEntryTimeframe;
