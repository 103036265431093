import { Button, Text } from '@flexisaf/flexibull2';
import { Theme } from 'config/theme';
import { useEffect, useState } from 'react';
import { returnUpdatedList } from 'utils/utils';
import LevelPicker from './levelPicker';

const sort = (a, b) => a.assessment.assessment_order - b.assessment.assessment_order;

const ScoreClassItem = ({ level, format_id, save }) => {
  const [timeframes, setTimeframes] = useState(level.score_entry_timeframes);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeframes(level.score_entry_timeframes);
  }, [level]);

  const updateTimeframe = (data) => {
    const newList = returnUpdatedList(data, timeframes, 'assessment', 'id');
    setTimeframes([...newList]);
  };

  const onSave = () => {
    const loadHandler = {
      start: () => setLoading(true),
      stop: () => setLoading(false),
    };
    level.score_entry_timeframes = timeframes;
    save(level, format_id, loadHandler);
  };

  const disabled = !timeframes.find((x) => x.start_date && x.end_date);

  return (
    <tr>
      <td>
        <Text block style={{ fontWeight: '600' }}>
          {level.short_name}
        </Text>
        <Text style={{ fontWeight: '500' }} size="11px" color={Theme.PrimaryGrey}>
          {level.name}
        </Text>
      </td>
      {timeframes.sort(sort).map((x) => (
        <LevelPicker timeframe={x} updateTimeframe={updateTimeframe} />
      ))}
      <td style={{ textAlign: 'right' }}>
        <Button
          pale
          fontColor={Theme.PrimaryFontColor}
          color={Theme.PrimaryFontColor}
          height={32}
          progress={loading}
          onClick={() => onSave()}
          disabled={disabled}
        >
          <Text size="11px">Apply</Text>
        </Button>
      </td>
    </tr>
  );
};

export default ScoreClassItem;
