import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { uploadSchoolLogoFail, uploadSchoolLogoSuccess } from '../actions';
import configActionTypes from '../types';

export function* uploadSchoolLogo({ payload }) {
  const formData = payload;
  try {
    const response = yield httpClient.post(`/school-info/uploads/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(uploadSchoolLogoSuccess(response.data));
    Notify('School logo uploaded succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(uploadSchoolLogoFail(error.fail));
    const err = handleError(error);
  }
}

export function* onUploadSchoolLogoStart() {
  yield takeLatest(configActionTypes.UPLOAD_SCHOOL_LOGO_START, uploadSchoolLogo);
}
