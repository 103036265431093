import { Notify } from '@flexisaf/flexibull2';
import { AssessmentConfigurationRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { createAssessmentFailure, createAssessmentSuccess } from '../actions';
import assessmentActionTypes from '../types';

export function* createAssessment({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }

    const response = yield apiWrapper(() =>
      AssessmentConfigurationRestControllerService.createAssessmentConfigUsingPost({
        request: payload,
      }),
    );
    yield put(createAssessmentSuccess(response));
    Notify('Assessment created successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createAssessmentFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateAssessmentStart() {
  yield takeLatest(assessmentActionTypes.CREATE_ASSESSMENT_START, createAssessment);
}
