import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { editSkillRatingFailure, editSkillRatingSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* editSkillRating({ payload }) {
  try {
    const response = yield httpClient.put('/cognitive-skill-config/skill-ratings', payload);
    yield put(editSkillRatingSuccess(response.data));

    Notify('Rating edited successfully', { position: 'top-right' });
  } catch (error) {
    yield put(editSkillRatingFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditSkillRatingStart() {
  yield takeLatest(cognitiveSkillsActionTypes.EDIT_SKILL_RATING_START, editSkillRating);
}
