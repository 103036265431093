import { put, takeLatest } from 'redux-saga/effects';
import subjectsActionTypes from '../types';
import { getSubjectResultSuccess, getSubjectResultFailure } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';

export function* getSubjectResult({ payload }) {
  if (!payload.subjectCode) {
    return;
  } else {
    try {
      const params = {
        'term-id': payload.termId,
        'class-level-id': payload.classLevelId,
        'arm-id': payload.armId,
        'subject-code': payload.subjectCode,
      };
      const response = yield httpClient.get('/student-results/subject-results', {
        params,
      });
      yield put(getSubjectResultSuccess(response.data));
    } catch (error) {
      const err = handleError(error);
      yield put(getSubjectResultFailure(err?.message));
    }
  }
}

export function* onGetSubjectResultStart() {
  yield takeLatest(subjectsActionTypes.GET_SUBJECT_RESULT_START, getSubjectResult);
}
