import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { FIX_ANY } from 'utils/types';
import { findIndexInArray } from 'utils/utils';
import cognitiveSkillsActionTypes from './types';

export interface ICognitiveSkillsState {
  isLoading: boolean;
  buttonLoading: boolean;
  addingSkillLoading: boolean;
  addSkillGroup: boolean;
  editSkillGroup: boolean;
  addSkill: boolean;
  editSkill: boolean;
  deleteSkillGroupModal: boolean;
  deletingLoading: boolean;
  skill_configurations: FIX_ANY[];
  skill_rating_configurations: FIX_ANY[];
  createRatingModal: boolean;
  editRatingModal: boolean;
  deleteRatingModal: boolean;
  skillGroups: FIX_ANY[];
  skillRatings: FIX_ANY[];
  studentSkills: FIX_ANY[];
  isFetching: boolean;
}

const INITIAL_STATE = {
  isLoading: false,
  buttonLoading: false,
  addingSkillLoading: false,
  addSkillGroup: false,
  editSkillGroup: false,
  addSkill: false,
  editSkill: false,
  deleteSkillGroupModal: false,
  deletingLoading: false,
  skill_configurations: [],
  skill_rating_configurations: [],
  createRatingModal: false,
  editRatingModal: false,
  deleteRatingModal: false,
  skillGroups: [],
  skillRatings: [],
  studentSkills: [],
  isFetching: false,
};

interface IParam {
  type: cognitiveSkillsActionTypes;
  payload: any;
}

function cognitiveSkillsReducer(
  state: ICognitiveSkillsState = INITIAL_STATE,
  { type, payload }: IParam,
) {
  switch (type) {
    case cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_START:
      return {
        ...state,
        isLoading: true,
        payload,
      };
    case cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_SUCCESS:
      const skill_configurations = payload.skill_configurations.map((item: FIX_ANY) => {
        return {
          id: item.skill_group.id,
          group_name: item.skill_group.group_name,
          skills: item.skills,
        };
      });
      const skill_rating_configurations = payload.skill_rating_configurations.map(
        (item: FIX_ANY) => {
          return {
            id: item.skill_rating.id,
            rating_name: item.skill_rating.rating_name,
            skill_rating_definitions: item.skill_rating_definitions,
          };
        },
      );
      return {
        ...state,
        isLoading: false,
        skill_configurations,
        skill_rating_configurations,
      };
    case cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_SUCCESS:
      const modifiedEntities = [
        ...state.skill_configurations,
        {
          group_name: payload.group_name,
          id: payload.id,
          skills: [],
        },
      ];
      return {
        ...state,
        buttonLoading: false,
        addSkillGroup: false,
        skill_configurations: modifiedEntities,
      };
    case cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_SUCCESS:
      const indexOfUpdatedSkillGroup = findIndexInArray(state.skill_configurations, payload, 'id');

      state.skill_configurations[indexOfUpdatedSkillGroup].group_name = payload.group_name;
      return {
        ...state,
        buttonLoading: false,
        editSkillGroup: false,
      };
    case cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_START:
      return {
        ...state,
        deletingLoading: true,
      };
    case cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_SUCCESS:
      const indexOfDeletedSkillGroup = findIndexInArray(state.skill_configurations, payload, 'id');

      state.skill_configurations.splice(indexOfDeletedSkillGroup, 1);
      return {
        ...state,
        deletingLoading: false,
        deleteSkillGroupModal: false,
      };
    case cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_FAILURE:
      return {
        ...state,
        deletingLoading: false,
        error: null,
      };

    case cognitiveSkillsActionTypes.UPDATE_SKILL_START:
      return {
        ...state,
        addingSkillLoading: true,
      };
    case cognitiveSkillsActionTypes.UPDATE_SKILL_SUCCESS:
      const indexOfUpdatedSkillGroupObj = findIndexInArray(
        state.skill_configurations,
        { id: payload.id },
        'id',
      );

      const currentSkillsList = state.skill_configurations[indexOfUpdatedSkillGroupObj].skills;
      const indexOfUpdatedSkill = findIndexInArray(currentSkillsList, payload.data, 'id');

      currentSkillsList[indexOfUpdatedSkill].skill_name = payload.data.skill_name;

      state.skill_configurations[indexOfUpdatedSkillGroupObj].skills = currentSkillsList;

      return {
        ...state,
        editSkill: false,
        addingSkillLoading: false,
      };
    case cognitiveSkillsActionTypes.UPDATE_SKILL_FAILURE:
      return {
        ...state,
        error: null,
        addingSkillLoading: false,
      };

    case cognitiveSkillsActionTypes.CREATE_SKILL_START:
      return {
        ...state,
        addingSkillLoading: true,
      };
    case cognitiveSkillsActionTypes.CREATE_SKILL_SUCCESS:
      const indexOfSkillConfigurations = findIndexInArray(
        state.skill_configurations,
        { id: payload.id },
        'id',
      );

      const currentSkills = state.skill_configurations[indexOfSkillConfigurations].skills;
      state.skill_configurations[indexOfSkillConfigurations].skills = [
        ...currentSkills,
        payload.data,
      ];

      return {
        ...state,
        addSkill: false,
        addingSkillLoading: false,
      };
    case cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_FAILURE:
      return {
        ...state,
        error: null,
        addingSkillLoading: false,
      };

    case cognitiveSkillsActionTypes.DELETE_SKILL_START:
      return {
        ...state,
        addingSkillLoading: true,
      };
    case cognitiveSkillsActionTypes.DELETE_SKILL_SUCCESS:
      const indexOfDeletedSkillGroupObj = findIndexInArray(
        state.skill_configurations,
        { id: payload.id },
        'id',
      );

      const currentDeletedSkillsList =
        state.skill_configurations[indexOfDeletedSkillGroupObj].skills;
      const indexOfDeletedSkill = findIndexInArray(
        currentDeletedSkillsList,
        { id: payload.skillId },
        'id',
      );

      currentDeletedSkillsList.splice(indexOfDeletedSkill, 1);

      state.skill_configurations[indexOfDeletedSkillGroupObj].skills = currentDeletedSkillsList;

      return {
        ...state,
        addingSkillLoading: false,
      };
    case cognitiveSkillsActionTypes.DELETE_SKILL_FAILURE:
      return {
        ...state,
        error: null,
        addingSkillLoading: false,
      };

    case cognitiveSkillsActionTypes.CREATE_SKILL_RATING_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case cognitiveSkillsActionTypes.CREATE_SKILL_RATING_SUCCESS:
      const ratingData = {
        id: payload.skill_rating.id,
        rating_name: payload.skill_rating.rating_name,
        skill_rating_definitions: payload.skill_rating_definitions,
      };

      const saveModifiedEntities = [...state.skill_rating_configurations, ratingData];

      return {
        ...state,
        buttonLoading: false,
        createRatingModal: false,
        skill_rating_configurations: saveModifiedEntities,
      };
    case cognitiveSkillsActionTypes.CREATE_SKILL_RATING_FAILURE:
      return {
        ...state,
        error: null,
        buttonLoading: false,
      };

    case cognitiveSkillsActionTypes.EDIT_SKILL_RATING_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case cognitiveSkillsActionTypes.EDIT_SKILL_RATING_SUCCESS:
      const modifiedRatingData = {
        id: payload.skill_rating.id,
        rating_name: payload.skill_rating.rating_name,
        skill_rating_definitions: payload.skill_rating_definitions,
      };

      const indexOfEditedRating = findIndexInArray(
        state.skill_rating_configurations,
        modifiedRatingData,
        'id',
      );
      state.skill_rating_configurations.splice(indexOfEditedRating, 1, modifiedRatingData);
      return {
        ...state,
        buttonLoading: false,
        editRatingModal: false,
      };
    case cognitiveSkillsActionTypes.EDIT_SKILL_RATING_FAILURE:
      return {
        ...state,
        error: null,
        buttonLoading: false,
      };

    case cognitiveSkillsActionTypes.DELETE_SKILL_RATING_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case cognitiveSkillsActionTypes.DELETE_SKILL_RATING_SUCCESS:
      const indexOfDeletedSkillRating = findIndexInArray(
        state.skill_rating_configurations,
        payload,
        'id',
      );

      state.skill_rating_configurations.splice(indexOfDeletedSkillRating, 1);
      return {
        ...state,
        buttonLoading: false,
        deleteRatingModal: false,
      };
    case cognitiveSkillsActionTypes.DELETE_SKILL_RATING_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_START:
      return {
        ...state,
        isLoading: true,
      };
    case cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        skillGroups: payload.skill_configurations,
      };
    case cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
        skillGroups: [],
      };

    case cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_START:
      return {
        ...state,
        isLoading: true,
      };
    case cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        skillRatings: payload.skill_rating_definitions,
      };
    case cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
        skillRatings: [],
      };

    case cognitiveSkillsActionTypes.FETCH_STUDENT_COGNITIVE_ASSESSMENT_START:
      return {
        ...state,
        isFetching: true,
      };
    case cognitiveSkillsActionTypes.FETCH_STUDENT_COGNITIVE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        studentSkills: payload,
      };
    case cognitiveSkillsActionTypes.FETCH_STUDENT_COGNITIVE_ASSESSMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: null,
        studentSkills: [],
      };

    case cognitiveSkillsActionTypes.SET_ADD_SKILL_GROUP:
      return {
        ...state,
        addSkillGroup: payload,
      };

    case cognitiveSkillsActionTypes.SET_ADD_SKILL:
      return {
        ...state,
        addSkill: payload,
      };

    case cognitiveSkillsActionTypes.SET_EDIT_SKILL_GROUP:
      return {
        ...state,
        editSkillGroup: payload,
      };

    case cognitiveSkillsActionTypes.SET_EDIT_SKILL:
      return {
        ...state,
        editSkill: payload,
      };

    case cognitiveSkillsActionTypes.HANDLE_DELETE_GROUP_MODAL:
      return {
        ...state,
        deleteSkillGroupModal: payload,
      };

    case cognitiveSkillsActionTypes.HANDLE_CREATE_RATING_MODAL:
      return {
        ...state,
        createRatingModal: payload,
      };

    case cognitiveSkillsActionTypes.HANDLE_EDIT_RATING_MODAL:
      return {
        ...state,
        editRatingModal: payload,
      };

    case cognitiveSkillsActionTypes.HANDLE_DELETE_RATING_MODAL:
      return {
        ...state,
        deleteRatingModal: payload,
      };

    case cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_START:
      return {
        ...state,
        buttonLoading: true,
      };

    case cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };

    case cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'cognitive-skills',
  storage,
  blacklist: [
    'error',
    'isLoading',
    'buttonLoading',
    'addSkillGroup',
    'addSkill',
    'addingSkillLoading',
    'editSkillGroup',
    'editSkill',
    'deleteSkillGroupModal',
    'deletingLoading',
    'createRatingModal',
    'deleteRatingModal',
    'editRatingModal',
    'isFetching',
  ],
};

export default persistReducer(persistConfig, cognitiveSkillsReducer);
