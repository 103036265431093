import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { editPayableItemFail, editPayableItemSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* editPayableItem({ payload }) {
  try {
    const response = yield httpClient.put('/payable-items', payload);
    yield put(editPayableItemSuccess(response.data));
    Notify('Item Edited successfully', { position: 'top-right' });
  } catch (error) {
    yield put(editPayableItemFail(error?.message));
    Notify(`${error?.message}`, { position: 'top-right', status: 'error' });
    const err = handleError(error);
  }
}

export function* onEditPayableItem() {
  yield takeLatest(configActionTypes.EDIT_PAYABLE_ITEM_START, editPayableItem);
}
