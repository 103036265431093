import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  fetchClassArmBroadsheetFail,
  fetchClassArmBroadsheetSuccess,
  setDefaultTemplateStart,
} from '../actions';
import broadSheetTypes from '../types';

export function* fetchClassArmBroadsheet({ payload }) {
  const params = {
    'class-level-id': payload.classLevelId,
    'term-id': payload.termId,
    'arm-id': payload.armId,
  };
  try {
    const response = yield httpClient.get(`/student-results/broad-sheet`, { params });
    yield put(fetchClassArmBroadsheetSuccess(response.data));

    if (response?.data?.report_templates?.length < 1 && response?.data?.student_results?.length) {
      yield put(setDefaultTemplateStart(payload.classLevelId, payload.termId));
    }
  } catch (error) {
    yield put(fetchClassArmBroadsheetFail(error?.message));
    const obj = {
      code: 404,
      message: 'No Grading Format defined for class. Please contact your school admin',
    };
    const err = handleError(error, obj);
  }
}

export function* onFetchClassArmBroadsheetStart() {
  yield takeLatest(broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_START, fetchClassArmBroadsheet);
}
