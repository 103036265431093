import { takeLatest, put } from 'redux-saga/effects';
import { fetchStudentSuccess, fetchStudentFail } from '../action';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchStudent({ payload }) {
  try {
    const response = yield httpClient.get(`/students/${payload}`);
    yield put(fetchStudentSuccess(response.data));
  } catch (error) {
    yield put(fetchStudentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchSingleStudent() {
  yield takeLatest(studentActionTypes.FETCH_STUDENT_START, fetchStudent);
}
