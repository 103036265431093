import { Notify } from '@flexisaf/flexibull2';
import {
  CreateSubjectAssessmentConfigurationRequest,
  SubjectAssessmentConfigurationRestControllerService,
} from 'generated';
import { useCallback, useState } from 'react';
import { apiWrapper } from 'utils/http-client';
import { handleError } from 'utils/utils';

export const useSubjectConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [content, setContent] = useState<any>({});

  const fetchSubjectConfig = useCallback(
    async (payload: { classLevelId: string; subjectId: string; termId: string }) => {
      setLoading(true);
      try {
        const response = await apiWrapper(() =>
          SubjectAssessmentConfigurationRestControllerService.getSubjectAssessmentConfigUsingGet(
            payload,
          ),
        );
        setContent(response);
        setLoading(false);
        return { success: true, response };
      } catch (error) {
        setLoading(false);
        handleError(error);
        return { success: false, response: {} };
      }
    },
    [],
  );

  const postSubjectConfig = useCallback(
    async (payload: CreateSubjectAssessmentConfigurationRequest) => {
      setLoadingSave(true);
      try {
        // const response = await apiWrapper(() => {});
        const response = await apiWrapper(() =>
          SubjectAssessmentConfigurationRestControllerService.createSubjectAssessmentConfigUsingPost(
            { request: payload },
          ),
        );
        Notify('Successfully saved the subject configuration.', { status: 'success' });
        setLoadingSave(false);
        return { success: true, response };
      } catch (error) {
        setLoadingSave(false);
        handleError(error);
        return { success: false, response: {} };
      }
    },
    [],
  );

  const updateSubjectConfig = useCallback(
    async (id: string, payload: CreateSubjectAssessmentConfigurationRequest) => {
      setLoadingSave(true);
      try {
        const response = await apiWrapper(() =>
          SubjectAssessmentConfigurationRestControllerService.updateSubjectAssessmentConfigUsingPut(
            { id, request: payload },
          ),
        );
        Notify('Successfully saved the subject configuration.', { status: 'success' });
        setLoadingSave(false);
        return { success: true, response };
      } catch (error) {
        setLoadingSave(false);
        handleError(error);
        return { success: false, response: {} };
      }
    },
    [],
  );

  return {
    loadingFetch: loading,
    loadingSave: loadingSave,
    fetchSubjectConfig,
    postSubjectConfig,
    updateSubjectConfig,
    subjectAssessmentConfig: content,
  };
};
