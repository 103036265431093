import localforage from 'localforage';
import { put, takeLatest } from 'redux-saga/effects';
import { setSiteConfig } from 'redux/global-store/actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  getUserDetailsFailure,
  getUserDetailsSuccess,
  setAccessToken,
  setLoginResponses,
  setRefreshToken,
} from '../actions';
import authActionTypes from '../types';

export function* getUserDetails({ data }) {
  const callback = data.callback;
  const config = data.config;
  const verifyUserPrivileges = data.verifyUserPrivileges;
  delete data.callBack;
  delete data.verifyUserPrivileges;
  delete data.config;

  try {
    const response = yield httpClient.post(`auth/google/transfer`, data);

    const ref = response.data;
    const ums = ref.ums_login_response;
    const roles = ums.user.roles;

    yield put(setAccessToken(ums.access_token));
    yield put(setRefreshToken(ums.refresh_token));
    yield put(setSiteConfig({ ...config, accessToken: ums.access_token }));

    localforage.setItem('access_token', ums.access_token);
    localforage.setItem('refresh_token', ums.refresh_token);
    localStorage.setItem('access_token', ums.access_token);

    verifyUserPrivileges(roles);
    yield put(setLoginResponses(response));
    yield put(getUserDetailsSuccess(response.data));
  } catch (error) {
    callback();
    yield put(getUserDetailsFailure(err));
    const err = handleError(error);
  }
}

export function* onGetUserDetailsStart() {
  yield takeLatest(authActionTypes.GET_USER_DETAILS_START, getUserDetails);
}
