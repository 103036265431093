import { put, takeLatest } from 'redux-saga/effects';
import bankActionTypes from '../types';
import { editBankAccountSuccess, editBankAccountFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* editBankAccount({ payload }) {
  try {
    const response = yield httpClient.put('/flutterwave/bank-accounts', payload);

    yield put(editBankAccountSuccess(response.data));
    Notify('Bank Account edited successfully', { position: 'top-right' });
  } catch (error) {
    yield put(editBankAccountFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditBankAccountStart() {
  yield takeLatest(bankActionTypes.EDIT_BANK_ACCOUNT_START, editBankAccount);
}
