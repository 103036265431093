/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectionConfigDto } from '../models/ProjectionConfigDto';
import type { ProjectionConfigRequest } from '../models/ProjectionConfigRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectionConfigRestControllerService {
  /**
   * getBySubject
   * @returns ProjectionConfigDto OK
   * @throws ApiError
   */
  public static getBySubjectUsingGet({
    classLevelId,
    subjectId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * subjectId
     */
    subjectId: string;
  }): CancelablePromise<ProjectionConfigDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projection-config',
      query: {
        classLevelId: classLevelId,
        subjectId: subjectId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * create
   * @returns ProjectionConfigDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createUsingPost1({
    request,
  }: {
    /**
     * request
     */
    request: ProjectionConfigRequest;
  }): CancelablePromise<ProjectionConfigDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projection-config',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * update
   * @returns ProjectionConfigDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateUsingPut2({
    request,
  }: {
    /**
     * request
     */
    request: ProjectionConfigDto;
  }): CancelablePromise<ProjectionConfigDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projection-config',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * toggleUsesProjection
   * @returns any OK
   * @throws ApiError
   */
  public static toggleUsesProjectionUsingPut(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projection-config/toggle-uses-projection-flag',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getById
   * @returns ProjectionConfigDto OK
   * @throws ApiError
   */
  public static getByIdUsingGet({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<ProjectionConfigDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projection-config/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * delete
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUsingDelete3({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projection-config/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
