import { IGlobalState } from './reducers';
import globalActionTypes from './types';

export const fetchCountriesStart = () => ({
  type: globalActionTypes.FETCH_COUNTRIES_START,
  payload: {},
});

export const fetchCountriesSuccess = (data) => ({
  type: globalActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: data,
});

export const fetchCountriesFailure = (error) => ({
  type: globalActionTypes.FETCH_COUNTRIES_FAILURE,
  payload: error,
});

export const fetchStatesStart = (data) => ({
  type: globalActionTypes.FETCH_STATES_START,
  payload: data,
});

export const fetchStatesSuccess = (data) => ({
  type: globalActionTypes.FETCH_STATES_SUCCESS,
  payload: data,
});

export const fetchStatesFailure = (error) => ({
  type: globalActionTypes.FETCH_STATES_FAILURE,
  payload: error,
});

export const fetchLgaStart = (data) => ({
  type: globalActionTypes.FETCH_LGA_START,
  payload: data,
});

export const fetchLgaSuccess = (data) => ({
  type: globalActionTypes.FETCH_LGA_SUCCESS,
  payload: data,
});

export const fetchLgaFailure = (error) => ({
  type: globalActionTypes.FETCH_LGA_FAILURE,
  payload: error,
});

export const resetLgaAction = () => ({
  type: globalActionTypes.RESET_LGA,
  payload: [],
});

export const setSiteConfig = (payload) => {
  return {
    type: globalActionTypes.SET_SITE_CONFIG,
    payload,
  };
};

export const updateGlobalState = (payload: IGlobalState) => ({
  type: globalActionTypes.UPDATE_GLOBAL_STATE,
  payload,
});
