import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import {
  documentUploadFailure,
  documentUploadSuccess,
  fetchListStaffDocumentsStart,
} from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchUserDocsStart } from 'redux/users/actions';

export function* documentUpload({ payload }) {
  const formData = payload.data;
  try {
    const response = yield httpClient.post(`/documents/staff/${payload.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(documentUploadSuccess(response.data));
    yield put(fetchListStaffDocumentsStart(payload.id));

    Notify('Staff Document Uploaded successfully', { position: 'top-right' });
  } catch (error) {
    yield put(documentUploadFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDocumentUploadStart() {
  yield takeLatest(staffActionTypes.UPLOAD_STAFF_DOCUMENT_START, documentUpload);
}
