import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { FIX_ANY } from 'utils/types';
import { findIndexInArray, InsertBeginning } from 'utils/utils';
import bankActionTypes from './types';

export interface IBankState {
  loading: boolean;
  buttonLoading: boolean;
  modal: boolean;
  editing: boolean;
  deleteModal: boolean;
  banks: FIX_ANY[];
  banksAccounts: FIX_ANY[];
  defaultAccountModal: boolean;
  bankData: FIX_ANY;
}

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  modal: false,
  editing: false,
  deleteModal: false,
  banks: [],
  banksAccounts: [],
  defaultAccountModal: false,
  bankData: {},
};

interface IParam {
  type: bankActionTypes;
  payload: any;
}

function bankReducer(state: IBankState = INITIAL_STATE, { type, payload }: IParam) {
  switch (type) {
    case bankActionTypes.FETCH_BANK_ACCOUNTS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case bankActionTypes.FETCH_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        banksAccounts: payload,
      };
    case bankActionTypes.FETCH_BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case bankActionTypes.FETCH_LIST_BANKS_START:
      return {
        ...state,
        error: null,
      };
    case bankActionTypes.FETCH_LIST_BANKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        banks: payload,
      };
    case bankActionTypes.FETCH_LIST_BANKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case bankActionTypes.CREATE_BANK_ACCOUNT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case bankActionTypes.CREATE_BANK_ACCOUNT_SUCCESS:
      const modifiedEntities = InsertBeginning(state.banksAccounts, payload);
      return {
        ...state,
        buttonLoading: false,
        banksAccounts: modifiedEntities,
        modal: false,
        editing: false,
        bankData: {},
      };
    case bankActionTypes.CREATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case bankActionTypes.EDIT_BANK_ACCOUNT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case bankActionTypes.EDIT_BANK_ACCOUNT_SUCCESS:
      const indexOfEditedBankAccount = findIndexInArray(state.banksAccounts, payload, 'account_id');
      state.banksAccounts.splice(indexOfEditedBankAccount, 1, payload);
      return {
        ...state,
        buttonLoading: false,
        modal: false,
        editing: false,
      };
    case bankActionTypes.EDIT_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case bankActionTypes.DELETE_BANK_ACCOUNT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case bankActionTypes.DELETE_BANK_ACCOUNT_SUCCESS:
      const indexOfDeleteBankAccount = findIndexInArray(state.banksAccounts, payload, 'account_id');
      state.banksAccounts.splice(indexOfDeleteBankAccount, 1);
      return {
        ...state,
        buttonLoading: false,
        deleteModal: false,
      };
    case bankActionTypes.DELETE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case bankActionTypes.HANDLE_BANK_ACCOUNT_MODAL:
      return {
        ...state,
        modal: payload.modal,
        editing: payload.editing,
      };
    case bankActionTypes.HANDLE_BANK_ACCOUNT_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !payload,
      };
    case bankActionTypes.HANDLE_DEFAULT_ACCOUNT_MODAL:
      return {
        ...state,
        defaultAccountModal: !payload,
      };

    case bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        error: null,
        defaultAccountModal: false,
      };
    case bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case bankActionTypes.RETRY_SUB_ACCOUNT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case bankActionTypes.RETRY_SUB_ACCOUNT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case bankActionTypes.RETRY_SUB_ACCOUNT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case bankActionTypes.SAVE_BANK_DATA:
      return {
        ...state,
        bankData: payload,
      };
    case bankActionTypes.UPDATE_BANK_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'bank',
  storage,
  blacklist: ['loading', 'modal', 'buttonLoading', 'editing', 'deleteModal', 'defaultAccountModal'],
};

export default persistReducer(persistConfig, bankReducer);
