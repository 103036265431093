import { takeLatest, put } from 'redux-saga/effects';
import { fetchAllTermDefSuccess, fetchAllTermDefFail } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* fetchAllTermDefinitions() {
  try {
    const response = yield httpClient.get(`/term-definitions`);
    yield put(fetchAllTermDefSuccess(response.data));
  } catch (error) {
    yield put(fetchAllTermDefFail(error.fail));
    const err = handleError(error);
  }
}

export function* onFetchTermDefinitionStart() {
  yield takeLatest(configActionTypes.FETCH_ALL_TERM_DEF_START, fetchAllTermDefinitions);
}
