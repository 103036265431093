/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassLevelCumulativeApprovalLogRequest } from '../models/ClassLevelCumulativeApprovalLogRequest';
import type { CumulativeApprovalLogDto } from '../models/CumulativeApprovalLogDto';
import type { CumulativeApprovalLogHistoryResponse } from '../models/CumulativeApprovalLogHistoryResponse';
import type { CumulativeApprovalLogRequest } from '../models/CumulativeApprovalLogRequest';
import type { CumulativeApprovalLogStatusResponse } from '../models/CumulativeApprovalLogStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CumulativeApprovalLogRestControllerService {
  /**
   * approveCumulative
   * @returns CumulativeApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static approveCumulativeUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: CumulativeApprovalLogRequest;
  }): CancelablePromise<Array<CumulativeApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cumulative-approvals/approve',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * approveClassLevelCumulative
   * @returns any OK
   * @throws ApiError
   */
  public static approveClassLevelCumulativeUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: ClassLevelCumulativeApprovalLogRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cumulative-approvals/approve-all',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassApprovalHistory
   * @returns CumulativeApprovalLogHistoryResponse OK
   * @throws ApiError
   */
  public static getClassApprovalHistoryUsingGet({
    classLevelId,
    resultConfigId,
    termId,
    armId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * resultConfigId
     */
    resultConfigId: string;
    /**
     * termId
     */
    termId: string;
    /**
     * armId
     */
    armId?: string;
  }): CancelablePromise<Array<CumulativeApprovalLogHistoryResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cumulative-approvals/history/term/{termId}/class/{classLevelId}',
      path: {
        classLevelId: classLevelId,
        termId: termId,
      },
      query: {
        armId: armId,
        resultConfigId: resultConfigId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recallCumulative
   * @returns CumulativeApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static recallCumulativeUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: CumulativeApprovalLogRequest;
  }): CancelablePromise<Array<CumulativeApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cumulative-approvals/recall',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recallClassLevelCumulative
   * @returns any OK
   * @throws ApiError
   */
  public static recallClassLevelCumulativeUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: ClassLevelCumulativeApprovalLogRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cumulative-approvals/recall-all',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassMembersApprovalStatus
   * @returns CumulativeApprovalLogStatusResponse OK
   * @throws ApiError
   */
  public static getClassMembersApprovalStatusUsingGet({
    classLevelId,
    resultConfigId,
    termId,
    armId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * resultConfigId
     */
    resultConfigId: string;
    /**
     * termId
     */
    termId: string;
    /**
     * armId
     */
    armId?: string;
  }): CancelablePromise<Array<CumulativeApprovalLogStatusResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cumulative-approvals/term/{termId}/class/{classLevelId}',
      path: {
        classLevelId: classLevelId,
        termId: termId,
      },
      query: {
        armId: armId,
        resultConfigId: resultConfigId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
