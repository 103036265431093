import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import {
  receivePaymentFail,
  receivePaymentSuccess,
  fetchStudentIncoiceSummaryStart,
} from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* receivePayment({ payload }) {
  const callback = payload.callback;
  delete payload.callback;
  try {
    const response = yield httpClient.post(`/payments/offline`, payload);
    yield put(receivePaymentSuccess(response.data));
    Notify('Payment has been saved successfully.', { position: 'top-right' });
    yield put(fetchStudentIncoiceSummaryStart(payload.student_id));
    callback();
  } catch (error) {
    yield put(receivePaymentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onReceivePaymentStart() {
  yield takeLatest(invoiceActionTypes.RECEIVE_PAYMENT_START, receivePayment);
}
