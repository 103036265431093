const configActionTypes = {
  MANAGE_SCHOOL_INFO_START: 'MANAGE_SCHOOL_INFO_START',
  MANAGE_SCHOOL_INFO_SUCCESS: 'MANAGE_SCHOOL_INFO_SUCCESS',
  MANAGE_SCHOOL_INFO_FAIL: 'MANAGE_SCHOOL_INFO_FAIL',
  CREATE_CLASS_LEVEL_START: 'CREATE_CLASS_LEVEL_START',
  CREATE_CLASS_LEVEL_SUCCESS: 'CREATE_CLASS_LEVEL_SUCCESS',
  CREATE_CLASS_LEVEL_FAIL: 'CREATE_CLASS_LEVEL_FAIL',
  HANDLE_CREATE_CLASS_LEVEL_MODAL: 'HANDLE_CREATE_CLASS_LEVEL_MODAL',
  HANDLE_DELETE_CLASS_LEVEL_MODAL: 'HANDLE_DELETE_CLASS_LEVEL_MODAL',
  HANDLE_DEACTIVATE_CLASS_LEVEL_MODAL: 'HANDLE_DEACTIVATE_CLASS_LEVEL_MODAL',
  FETCH_CLASS_LEVEL_START: 'FETCH_CLASS_LEVEL_START',
  FETCH_CLASS_LEVEL_SUCCESS: 'FETCH_CLASS_LEVEL_SUCCESS',
  FETCH_CLASS_LEVEL_FAIL: 'FETCH_CLASS_LEVEL_FAIL',
  EDIT_CLASS_LEVEL_START: 'EDIT_CLASS_LEVEL_START',
  EDIT_CLASS_LEVEL_SUCCESS: 'EDIT_CLASS_LEVEL_SUCCESS',
  EDIT_CLASS_LEVEL_FAIL: 'EDIT_CLASS_LEVEL_FAIL',
  DELETE_CLASS_LEVEL_START: 'DELETE_CLASS_LEVEL_START',
  DELETE_CLASS_LEVEL_SUCCESS: 'DELETE_CLASS_LEVEL_SUCCESS',
  DELETE_CLASS_LEVEL_FAIL: 'DELETE_CLASS_LEVEL_FAIL',
  HANDLE_CLASS_LEVEL_DATA: 'HANDLE_CLASS_LEVEL_DATA',
  HANDLE_EDIT_CLASS_LEVEL: ' HANDLE_EDIT_CLASS_LEVEL',
  RESET_MODAL: 'RESET_MODAL',
  FETCH_ALL_ARMS_START: 'FETCH_ALL_ARMS_START',
  FETCH_ALL_ARMS_SUCCESS: 'FETCH_ALL_ARMS_SUCCESS',
  FETCH_ALL_ARMS_FAIL: 'FETCH_ALL_ARMS_FAIL',
  CREATE_ARM_START: 'CREATE_ARM_START',
  CREATE_ARM_SUCCESS: 'CREATE_ARM_SUCCESS',
  CREATE_ARM_FAIL: 'CREATE_ARM_FAIL',
  EDIT_ARM_START: 'EDIT_ARM_START',
  EDIT_ARM_SUCCESS: 'EDIT_ARM_SUCCESS',
  EDIT_ARM_FAIL: 'EDIT_ARM_FAIL',
  DELETE_ARM_START: 'DELETE_ARM_START',
  DELETE_ARM_SUCCESS: 'DELETE_ARM_SUCCESS',
  DELETE_ARM_FAIL: 'DELETE_ARM_FAIL',
  HANDLE_ARM_DATA: 'HANDLE_ARM_DATA',
  HANDLE_EDIT_ARM: ' HANDLE_EDIT_ARM',
  HANDLE_CREATE_ARM_MODAL: 'HANDLE_CREATE_ARM_MODAL',
  HANDLE_DELETE_ARM_MODAL: 'HANDLE_DELETE_ARM_MODAL',
  FETCH_SCHOOL_INFO_START: 'FETCH_SCHOOL_INFO_START',
  FETCH_SCHOOL_INFO_SUCCESS: 'FETCH_SCHOOL_INFO_SUCCESS',
  FETCH_SCHOOL_INFO_FAIL: 'FETCH_SCHOOL_INFO_FAIL',
  GET_TERM_ID_START: 'GET_TERM_ID_START',
  GET_TERM_ID_SUCCESS: 'GET_TERM_ID_SUCCESS',
  GET_TERM_ID_FAIL: 'GET_TERM_ID_FAIL',
  GET_SESSION_START: 'GET_SESSION_START',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAIL: 'GET_SESSION_FAIL',
  FETCH_CLASS_INFO_START: 'FETCH_CLASS_INFO_START',
  FETCH_CLASS_INFO_SUCCESS: 'FETCH_CLASS_INFO_SUCCESS',
  FETCH_CLASS_INFO_FAIL: 'FETCH_CLASS_INFO_FAIL',
  CREATE_CLASS_INFO_START: 'CREATE_CLASS_INFO_START',
  CREATE_CLASS_INFO_SUCCESS: 'CREATE_CLASS_INFO_SUCCESS',
  CREATE_CLASS_INFO_FAIL: 'CREATE_CLASS_INFO_FAIL',
  HANDLE_PAYABLE_ITEM: 'HANDLE_PAYABLE_ITEM',
  HANDLE_PAYABLE_ITEM_MODAL: 'HANDLE_PAYABLE_ITEM_MODAL',
  ADD_PAYABLE_ITEM_START: 'ADD_PAYABLE_ITEM_START',
  ADD_PAYABLE_ITEM_SUCCESS: 'ADD_PAYABLE_ITEM_SUCCESS',
  ADD_PAYABLE_ITEM_FAIL: 'ADD_PAYABLE_ITEM_FAIL',
  FETCH_PAYABLE_ITEMS_START: 'FETCH_PAYABLE_ITEMS_START',
  FETCH_PAYABLE_ITEMS_SUCCESS: 'FETCH_PAYABLE_ITEMS_SUCCESS',
  FETCH_PAYABLE_ITEMS_FAIL: 'FETCH_PAYABLE_ITEMS_FAIL',
  DELETE_PAYABLE_ITEM_START: 'DELETE_PAYABLE_ITEM_START',
  DELETE_PAYABLE_ITEM_SUCCESS: 'DELETE_PAYABLE_ITEM_SUCCESS',
  DELETE_PAYABLE_ITEM_FAIL: 'DELETE_PAYABLE_ITEM_FAIL',
  EDIT_PAYABLE_ITEM_START: 'EDIT_PAYABLE_ITEM_START',
  EDIT_PAYABLE_ITEM_SUCCESS: 'EDIT_PAYABLE_ITEM_SUCCESS',
  EDIT_PAYABLE_ITEM_FAIL: 'EDIT_PAYABLE_ITEM_FAIL',
  FILTER_PAYABLE_ITEMS_START: 'FILTER_PAYABLE_ITEMS_START',
  FILTER_PAYABLE_ITEMS_SUCCESS: 'FILTER_PAYABLE_ITEMS_SUCCESS',
  FILTER_PAYABLE_ITEMS_FAIL: 'FILTER_PAYABLE_ITEMS_FAIL',
  HANDLE_DELETE_PAYABLE_ITEM: 'HANDLE_DELETE_PAYABLE_ITEM',
  GET_TERM_START: 'GET_TERM_START',
  GET_TERM_SUCCESS: 'GET_TERM_SUCCESS',
  GET_TERM_FAIL: 'GET_TERM_FAIL',
  GET_CLASS_MEMBERS_START: 'GET_CLASS_MEMBERS_START',
  GET_CLASS_MEMBERS_SUCCESS: 'GET_CLASS_MEMBERS_SUCCESS',
  GET_CLASS_MEMBERS_FAIL: 'GET_CLASS_MEMBERS_FAIL',
  ASSIGN_STUDENTS_TO_CLASS_START: 'ASSIGN_STUDENTS_TO_CLASS_START',
  ASSIGN_STUDENTS_TO_CLASS_SUCCESS: 'ASSIGN_STUDENTS_TO_CLASS_SUCCESS',
  ASSIGN_STUDENTS_TO_CLASS_FAIL: 'ASSIGN_STUDENTS_TO_CLASS_FAIL',
  HANDLE_ADD_STUDENT_MODAL: 'HANDLE_ADD_STUDENT_MODAL',
  REMOVE_STUDENT_FROM_CLASS_START: 'REMOVE_STUDENT_FROM_CLASS_START',
  REMOVE_STUDENT_FROM_CLASS_SUCCESS: 'REMOVE_STUDENT_FROM_CLASS_SUCCESS',
  REMOVE_STUDENT_FROM_CLASS_FAIL: 'REMOVE_STUDENT_FROM_CLASS_FAIL',
  HANDLE_REMOVE_STUDENT_MODAL: 'HANDLE_REMOVE_STUDENT_MODAL',
  UPLOAD_SCHOOL_LOGO_START: 'UPLOAD_SCHOOL_LOGO_START',
  UPLOAD_SCHOOL_LOGO_SUCCESS: 'UPLOAD_SCHOOL_LOGO_SUCCESS',
  UPLOAD_SCHOOL_LOGO_FAIL: 'UPLOAD_SCHOOL_LOGO_FAIL',
  VIEW_CLASS_PROFILE: 'VIEW_CLASS_PROFILE',

  FETCH_HOMEPAGE_START: 'FETCH_HOMEPAGE_START',
  FETCH_HOMEPAGE_SUCCESS: 'FETCH_HOMEPAGE_SUCCESS',
  FETCH_HOMEPAGE_FAIL: 'FETCH_HOMEPAGE_FAIL',

  FETCH_SCHOOL_FEES_CONFIG_START: 'FETCH_SCHOOL_FEES_CONFIG_START',
  FETCH_SCHOOL_FEES_CONFIG_SUCCESS: 'FETCH_SCHOOL_FEES_CONFIG_SUCCESS',
  FETCH_SCHOOL_FEES_CONFIG_FAIL: 'FETCH_SCHOOL_FEES_CONFIG_FAIL',

  GET_CURRENT_TERM_START: 'GET_CURRENT_TERM_START',
  GET_CURRENT_TERM_SUCCESS: 'GET_CURRENT_TERM_',
  GET_CURRENT_TERM_FAIL: 'GET_CURRENT_TERM_FAIL',

  GET_CURRENT_SUBSCRIPTION_START: 'GET_CURRENT_SUBSCRIPTION_START',
  HANDLE_CURRENT_SUBSCRIPTION: 'HANDLE_CURRENT_SUBSCRIPTION',

  FETCH_ACTIVE_ARMS_START: 'FETCH_ACTIVE_ARMS_START',
  FETCH_ACTIVE_ARMS_SUCCESS: 'FETCH_ACTIVE_ARMS_SUCCESS',
  FETCH_ACTIVE_ARMS_FAIL: 'FETCH_ACTIVE_ARMS_FAIL',

  CREATE_CLASS_INFORMATION_START: 'CREATE_CLASS_INFORMATION_START',
  CREATE_CLASS_INFORMATION_SUCCESS: 'CREATE_CLASS_INFORMATION_SUCCESS',
  CREATE_CLASS_INFORMATION_FAIL: 'CREATE_CLASS_INFORMATION_FAIL',

  FETCH_CLASS_INFORMATION_ARM_ID_START: 'FETCH_CLASS_INFORMATION_ARM_ID_START',
  FETCH_CLASS_INFORMATION_ARM_ID_SUCCESS: 'FETCH_CLASS_INFORMATION_ARM_ID_SUCCESS',
  FETCH_CLASS_INFORMATION_ARM_ID_FAIL: 'FETCH_CLASS_INFORMATION_ARM_ID_FAIL',

  HANDLE_CLASS_LEVEL_PROFILE: 'HANDLE_CLASS_LEVEL_PROFILE',

  CREATE_TERM_DEF_START: 'CREATE_TERM_DEF_START',
  CREATE_TERM_DEF_SUCCESS: 'CREATE_TERM_DEF_SUCCESS',
  CREATE_TERM_DEF_FAIL: 'CREATE_TERM_DEF_FAIL',

  FETCH_ALL_TERM_DEF_START: 'FETCH_ALL_TERM_DEF_START',
  FETCH_ALL_TERM_DEF_SUCCESS: 'FETCH_ALL_TERM_DEF_SUCCESS',
  FETCH_ALL_TERM_DEF_FAIL: 'FETCH_ALL_TERM_DEF_FAIL',

  HANDLE_ADD_TERM_MODAL: 'HANDLE_ADD_TERM_MODAL',

  EDIT_TERM_DEF_START: 'EDIT_TERM_DEF_START',
  EDIT_TERM_DEF_SUCCESS: 'EDIT_TERM_DEF_SUCCESS',
  EDIT_TERM_DEF_FAIL: 'EDIT_TERM_DEF_FAIL',

  DELETE_TERM_DEF_START: 'DELETE_TERM_DEF_START',
  DELETE_TERM_DEF_SUCCESS: 'DELETE_TERM_DEF_SUCCESS',
  DELETE_TERM_DEF_FAIL: 'DELETE_TERM_DEF_FAIL',

  HANDLE_EDIT_TERM: 'HANDLE_EDIT_TERM',
  HANDLE_TERM_DATA: 'HANDLE_TERM_DATA',
  HANDLE_DELETE_TERM_MODAL: 'HANDLE_DELETE_TERM_MODAL',

  CREATE_NEW_SESSION_START: 'CREATE_NEW_SESSION_START',
  CREATE_NEW_SESSION_SUCCESS: 'CREATE_NEW_SESSION_SUCCESS',
  CREATE_NEW_SESSION_FAIL: 'CREATE_NEW_SESSION_FAIL',

  FETCH_ALL_EXISTING_TERMS_START: 'FETCH_ALL_EXISTING_TERMS_START',
  FETCH_ALL_EXISTING_TERMS_SUCCESS: 'FETCH_ALL_EXISTING_TERMS_SUCCESS',
  FETCH_ALL_EXISTING_TERMS_FAIL: 'FETCH_ALL_EXISTING_TERMS_FAIL',

  SET_CURRENT_TERM_START: 'SET_CURRENT_TERM_START',
  SET_CURRENT_TERM_SUCCESS: 'SET_CURRENT_TERM_SUCCESS',
  SET_CURRENT_TERM_FAIL: 'SET_CURRENT_TERM_FAIL',

  GET_CLASS_LEVEL_TRAITS_START: 'GET_CLASS_LEVEL_TRAITS_START',
  GET_CLASS_LEVEL_TRAITS_SUCCESS: 'GET_CLASS_LEVEL_TRAITS_SUCCESS',
  GET_CLASS_LEVEL_TRAITS_FAIL: 'GET_CLASS_LEVEL_TRAITS_FAIL',

  GET_STUDENT_TRAIT_ASSESSMENT_START: 'GET_STUDENT_LEVEL_TRAIT_ASSESSMENT_START',
  GET_STUDENT_TRAIT_ASSESSMENT_SUCCESS: 'GET_STUDENT_LEVEL_TRAIT_ASSESSMENT_SUCCESS',
  GET_STUDENT_TRAIT_ASSESSMENT_FAIL: 'GET_STUDENT_LEVEL_TRAIT_ASSESSMENT_FAIL',

  SAVE_STUDENT_TRAIT_ASSESSMENT_START: 'SAVE_STUDENT_LEVEL_TRAIT_ASSESSMENT_START',
  SAVE_STUDENT_TRAIT_ASSESSMENT_SUCCESS: 'SAVE_STUDENT_LEVEL_TRAIT_ASSESSMENT_SUCCESS',
  SAVE_STUDENT_TRAIT_ASSESSMENT_FAIL: 'SAVE_STUDENT_LEVEL_TRAIT_ASSESSMENT_FAIL',

  HANDLE_CREATE_SESSION_MODAL: 'HANDLE_CREATE_SESSION_MODAL',
  HANDLE_EDIT_CLASS_INFO: 'HANDLE_EDIT_CLASS_INFO',

  TERM_PROFILE_SET_DATE_RANGE_START: 'TERM_PROFILE_SET_DATE_RANGE_START',
  TERM_PROFILE_SET_DATE_RANGE_SUCCESS: 'TERM_PROFILE_SET_DATE_RANGE_SUCCESS',
  TERM_PROFILE_SET_DATE_RANGE_FAIL: 'TERM_PROFILE_SET_DATE_RANGE_FAIL',

  TERM_PROFILE_SET_CLASS_INFO_START: 'TERM_PROFILE_SET_CLASS_INFO_START',
  TERM_PROFILE_SET_CLASS_INFO_SUCCESS: 'TERM_PROFILE_SET_CLASS_INFO_SUCCESS',
  TERM_PROFILE_SET_CLASS_INFO_FAIL: 'TERM_PROFILE_SET_CLASS_INFO_FAIL',

  TERM_PROFILE_SET_GRADE_FORMAT_START: 'TERM_PROFILE_SET_GRADE_FORMAT_START',
  TERM_PROFILE_SET_GRADE_FORMAT_SUCCESS: 'TERM_PROFILE_SET_GRADE_FORMAT_SUCCESS',
  TERM_PROFILE_SET_GRADE_FORMAT_FAIL: 'TERM_PROFILE_SET_GRADE_FORMAT_FAIL',

  TERM_PROFILE_SET_ASSESSMENT_FORMAT_START: 'TERM_PROFILE_SET_ASSESSMENT_FORMAT_START',
  TERM_PROFILE_SET_ASSESSMENT_FORMAT_SUCCESS: 'TERM_PROFILE_SET_ASSESSMENT_FORMAT_SUCCESS',
  TERM_PROFILE_SET_ASSESSMENT_FORMAT_FAIL: 'TERM_PROFILE_SET_ASSESSMENT_FORMAT_FAIL',

  TERM_PROFILE_SET_EARLY_YEARS_START: 'TERM_PROFILE_SET_EARLY_YEARS_START',
  TERM_PROFILE_SET_EARLY_YEARS_SUCCESS: 'TERM_PROFILE_SET_EARLY_YEARS_SUCCESS',
  TERM_PROFILE_SET_EARLY_YEARS_FAIL: 'TERM_PROFILE_SET_EARLY_YEARS_FAIL',

  TERM_PROFILE_SET_RATINGS_START: 'TERM_PROFILE_SET_RATINGS_START',
  TERM_PROFILE_SET_RATINGS_SUCCESS: 'TERM_PROFILE_SET_RATINGS_SUCCESS',
  TERM_PROFILE_SET_RATINGS_FAIL: 'TERM_PROFILE_SET_RATINGS_FAIL',

  GET_TERM_PROFILE_DATE_RANGE_START: 'GET_TERM_PROFILE_DATE_RANGE_START',
  GET_TERM_PROFILE_DATE_RANGE_SUCCESS: 'GET_TERM_PROFILE_DATE_RANGE_SUCCESS',
  GET_TERM_PROFILE_DATE_RANGE_FAIL: 'GET_TERM_PROFILE_DATE_RANGE_FAIL',

  GET_TERM_PROFILE_CLASS_INFO_START: 'GET_TERM_PROFILE_CLASS_INFO_START',
  GET_TERM_PROFILE_CLASS_INFO_SUCCESS: 'GET_TERM_PROFILE_CLASS_INFO_SUCCESS',
  GET_TERM_PROFILE_CLASS_INFO_FAIL: 'GET_TERM_PROFILE_CLASS_INFO_FAIL',

  GET_TERM_PROFILE_GRADE_FORMAT_START: 'GET_TERM_PROFILE_GRADE_FORMAT_START',
  GET_TERM_PROFILE_GRADE_FORMAT_SUCCESS: 'GET_TERM_PROFILE_GRADE_FORMAT_SUCCESS',
  GET_TERM_PROFILE_GRADE_FORMAT_FAIL: 'GET_TERM_PROFILE_GRADE_FORMAT_FAIL',

  GET_TERM_PROFILE_SKILL_GROUP_START: 'GET_TERM_PROFILE_SKILL_GROUP_START',
  GET_TERM_PROFILE_SKILL_GROUP_SUCCESS: 'GET_TERM_PROFILE_SKILL_GROUP_SUCCESS',
  GET_TERM_PROFILE_SKILL_GROUP_FAIL: 'GET_TERM_PROFILE_SKILL_GROUP_FAIL',

  GET_TERM_PROFILE_SKILL_RATINGS_START: 'GET_TERM_PROFILE_SKILL_RATINGS_START',
  GET_TERM_PROFILE_SKILL_RATINGS_SUCCESS: 'GET_TERM_PROFILE_SKILL_RATINGS_SUCCESS',
  GET_TERM_PROFILE_SKILL_RATINGS_FAIL: 'GET_TERM_PROFILE_SKILL_RATINGS_FAIL',

  GET_TERM_PROFILE_ASSESSMENT_FORMAT_START: 'GET_TERM_PROFILE_ASSESSMENT_FORMAT_START',
  GET_TERM_PROFILE_ASSESSMENT_FORMAT_SUCCESS: 'GET_TERM_PROFILE_ASSESSMENT_FORMAT_SUCCESS',
  GET_TERM_PROFILE_ASSESSMENT_FORMAT_FAIL: 'GET_TERM_PROFILE_ASSESSMENT_FORMAT_FAIL',

  GET_TERM_PROFILE_EARLY_YEARS_START: 'GET_TERM_PROFILE_EARLY_YEARS_START',
  GET_TERM_PROFILE_EARLY_YEARS_SUCCESS: 'GET_TERM_PROFILE_EARLY_YEARS_SUCCESS',
  GET_TERM_PROFILE_EARLY_YEARS_FAIL: 'GET_TERM_PROFILE_EARLY_YEARS_FAIL',

  GET_TERM_PROFILE_RATINGS_START: 'GET_TERM_PROFILE_RATINGS_START',
  GET_TERM_PROFILE_RATINGS_SUCCESS: 'GET_TERM_PROFILE_RATINGS_SUCCESS',
  GET_TERM_PROFILE_RATINGS_FAIL: 'GET_TERM_PROFILE_RATINGS_FAIL',

  GET_STUDENT_COMMENTS_START: 'GET_STUDENT_COMMENTS_START',
  GET_STUDENT_COMMENTS_SUCCESS: 'GET_STUDENT_COMMENTS_SUCCESS',
  GET_STUDENT_COMMENTS_FAIL: 'GET_STUDENT_COMMENTS_FAIL',

  ADD_STUDENT_COMMENT_START: 'ADD_STUDENT_COMMENT_START',
  ADD_STUDENT_COMMENT_SUCCESS: 'ADD_STUDENT_COMMENT_SUCCESS',
  ADD_STUDENT_COMMENT_FAIL: 'ADD_STUDENT_COMMENT_FAIL',

  FETCH_APPROVE_RESULTS_START: 'FETCH_APPROVE_RESULTS_START',
  FETCH_APPROVE_RESULTS_SUCCESS: 'FETCH_APPROVE_RESULTS_SUCCESS',
  FETCH_APPROVE_RESULTS_FAIL: 'FETCH_APPROVE_RESULTS_FAIL',

  SUBMIT_APPROVAL_RESULTS_START: 'SUBMIT_APPROVAL_RESULTS_START',
  SUBMIT_APPROVAL_RESULTS_SUCCESS: 'SUBMIT_APPROVAL_RESULTS_SUCCESS',
  SUBMIT_APPROVAL_RESULTS_FAIL: 'SUBMIT_APPROVAL_RESULTS_FAIL',

  SETUP_TERM_PROFILE: 'SETUP_TERM_PROFILE',
  SETUP_TERM_PROFILE_COMPLETE: 'SETUP_TERM_PROFILE_COMPLETE',

  FETCH_CLASS_LEVEL_WITH_ARMS_START: 'FETCH_CLASS_LEVEL_WITH_ARMS_START',
  FETCH_CLASS_LEVEL_WITH_ARMS_SUCCESS: 'FETCH_CLASS_LEVEL_WITH_ARMS_SUCCESS',
  FETCH_CLASS_LEVEL_WITH_ARMS_FAIL: 'FETCH_CLASS_LEVEL_WITH_ARMS_FAIL',

  FETCH_CHILD_RESULT_START: 'FETCH_CHILD_RESULT_START',
  FETCH_CHILD_RESULT_SUCCESS: 'FETCH_CHILD_RESULT_SUCCESS',
  FETCH_CHILD_RESULT_FAIL: 'FETCH_CHILD_RESULT_FAIL',

  FETCH_CHILD_SKILLS_RESULT_START: 'FETCH_CHILD_SKILLS_RESULT_START',
  FETCH_CHILD_SKILLS_RESULT_SUCCESS: 'FETCH_CHILD_SKILLS_RESULT_SUCCESS',
  FETCH_CHILD_SKILLS_RESULT_FAIL: 'FETCH_CHILD_SKILLS_RESULT_FAIL',
  GET_STUDENT_RESULTS_START: 'GET_STUDENT_RESULTS_START',
  GET_STUDENT_RESULTS_SUCCESS: 'GET_STUDENT_RESULTS_SUCCESS',
  GET_STUDENT_RESULTS_FAIL: 'GET_STUDENT_RESULTS_FAIL',
  GET_CHILD_ATTENDANCE_SUMMARY_START: 'GET_CHILD_ATTENDANCE_SUMMARY_START',
  GET_CHILD_ATTENDANCE_SUMMARY_SUCCESS: 'GET_CHILD_ATTENDANCE_SUMMARY_SUCCESS',
  GET_CHILD_ATTENDANCE_SUMMARY_FAIL: 'GET_CHILD_ATTENDANCE_SUMMARY_FAIL',

  UPLOAD_SIGNATURE_START: 'UPLOAD_SIGNATURE_START',
  UPLOAD_SIGNATURE_SUCCESS: 'UPLOAD_SIGNATURE_SUCCESS',
  UPLOAD_SIGNATURE_FAIL: 'UPLOAD_SIGNATURE_FAIL',

  OPEN_STUDENT_TRANSFER_MODAL: 'OPEN_STUDENT_TRANSFER_MODAL',

  TRANSFER_STUDENTS_ANOTHER_ARM_START: 'TRANSFER_STUDENTS_ANOTHER_ARM_START',
  TRANSFER_STUDENTS_ANOTHER_ARM_SUCCESS: 'TRANSFER_STUDENTS_ANOTHER_ARM_SUCCESS',
  TRANSFER_STUDENTS_ANOTHER_ARM_FAIL: 'TRANSFER_STUDENTS_ANOTHER_ARM_FAIL',

  SET_ONBOARDING_INF0: 'SET_ONBOARDING_INF0',
  SET_IS_ONBOARDING: 'SET_IS_ONBOARDING',
  SET_REFETCH_ATTENDANCE: 'SET_REFETCH_ATTENDANCE',

  HANDLE_BULK_STUDENT_MODAL: 'HANDLE_BULK_STUDENT_MODAL',

  HANDLE_ACTIVITY_MODAL: 'HANDLE_ACTIVITY_MODAL',
  HANDLE_DELETE_ACTIVITY_MODAL: 'HANDLE_DELETE_ACTIVITY_MODAL',
  HANDLE_ACTIVITY_DATA: 'HANDLE_ACTIVITY_DATA',

  HANDLE_NO_GRADING_FORMAT: 'HANDLE_NO_GRADING_FORMAT',
  UPDATE_APP_CONFIG_STATE: 'UPDATE_APP_CONFIG_STATE',
  RESET_RESULT: 'RESET_RESULT',
  SET_CLASS_LEVEL_TRAITS: 'SET_CLASS_LEVEL_TRAITS',

  SET_EVALUATOR_ACTIVE: 'SET_EVALUATOR_ACTIVE',
  SET_COLLABORATOR_ACTIVE: 'SET_COLLABORATOR_ACTIVE',
};

export default configActionTypes;
