import { createSelector } from 'reselect';

const selectServiceWorker = (state) => state.serviceWorker;

export const selectServiceWorkerRegistration = createSelector(
  [selectServiceWorker],
  (serviceWorker) => serviceWorker?.serviceWorkerRegistration,
);

export const selectIsServiceWorkerInitialized = createSelector(
  [selectServiceWorker],
  (serviceWorker) => serviceWorker.serviceWorkerInitialized,
);

export const selectIsServiceWorkerUpdated = createSelector(
  [selectServiceWorker],
  (serviceWorker) => serviceWorker.serviceWorkerUpdated,
);

export const selectIsServiceWorkerUpdateAvailable = createSelector(
  [selectServiceWorker],
  (serviceWorker) => serviceWorker.serviceWorkerUpdateAvailable,
);

export const selectIsServiceWorkerActive = createSelector(
  [selectServiceWorker],
  (serviceWorker) => serviceWorker.serviceWorkerActive,
);
