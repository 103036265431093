import { takeLatest, put } from 'redux-saga/effects';
import { editSingleTraitSuccess, editSingleTraitFail, fetchAllTraitGroupStart } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* editSingleTrait({ payload }) {
  const id = payload.id;
  const callback = payload.callback;
  delete payload.id;
  delete payload.callback;
  try {
    const response = yield httpClient.put(`/trait-configuration/traits/${id}`, payload);
    yield put(editSingleTraitSuccess(response.data));
    Notify('Trait group edited succesfully', { position: 'top-right' });
    callback();
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(editSingleTraitFail(error.fail));
    const err = handleError(error);
  }
}

export function* onEditSingleTraitStart() {
  yield takeLatest(earlyYearsActionTypes.EDIT_SINGLE_TRAIT_START, editSingleTrait);
}
