import { takeLatest, put } from 'redux-saga/effects';
import { editArmSuccess, editArmFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* editArm({ payload }) {
  try {
    const response = yield httpClient.put('/arms', payload);
    yield put(editArmSuccess(response.data));
    Notify('Arm edited successfully', { position: 'top-right' });
  } catch (error) {
    yield put(editArmFail(error?.message));
    const err = handleError(error);
  }
}

export function* onEditArmStart() {
  yield takeLatest(configActionTypes.EDIT_ARM_START, editArm);
}
