import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchCognitiveSkillsFailure, fetchCognitiveSkillsSuccess } from '../actions';
import cognitiveSkillsActionTypes from '../types';

export function* fetchCognitiveSkills({ payload }) {
  const callback = payload?.callback;
  delete payload?.callback;
  try {
    const response = yield httpClient.get('/cognitive-skill-config', { params: payload });
    yield put(fetchCognitiveSkillsSuccess(response.data));
    callback?.(response.data);
  } catch (error) {
    yield put(fetchCognitiveSkillsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchCognitiveSkillsStart() {
  yield takeLatest(cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_START, fetchCognitiveSkills);
}
