enum plansActionTypes {
  FETCH_APP_MODULES_START = 'FETCH_APP_MODULES_START',
  FETCH_APP_MODULES_SUCCESS = 'FETCH_APP_MODULES_SUCCESS',
  FETCH_APP_MODULES_FAILURE = 'FETCH_APP_MODULES_FAILURE',

  SELECT_APP_MODULES_START = 'SELECT_APP_MODULES_START',
  SELECT_APP_MODULES_SUCCESS = 'SELECT_APP_MODULES_SUCCESS',
  SELECT_APP_MODULES_FAILURE = 'SELECT_APP_MODULES_FAILURE',

  UPDATE_PLAN_STATE = 'UPDATE_PLAN_STATE',
}

export default plansActionTypes;
