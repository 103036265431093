import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from 'utils/utils';
import {
  getTermProfileAssessmentFormatStart,
  getTermProfileClassInfoStart,
  getTermProfileEarlyYearsStart,
  getTermProfileGradeFormatStart,
  getTermProfileRatingsStart,
  getTermProfileSkillGroupStart,
  setupTermProfileComplete,
} from '../actions';
import configActionTypes from '../types';

export function* setTermProfile({ payload }) {
  const dateCallback = payload.dateCallback;
  const classCallback = payload.classCallback;
  const gradeCallback = payload.gradeCallback;
  const assessmentCallback = payload.assessmentCallback;
  const earlyCallback = payload.earlyCallback;
  const ratingsCallback = payload.ratingsCallback;
  const skillGroupCallback = payload.skillGroupCallback;
  const skillRatingsCallback = payload.skillRatingsCallback;
  const id = payload.id;

  try {
    yield put(getTermProfileClassInfoStart({ id, callback: classCallback }));
    yield put(getTermProfileGradeFormatStart({ id, callback: gradeCallback }));
    yield put(getTermProfileAssessmentFormatStart({ id, callback: assessmentCallback }));
    yield put(getTermProfileEarlyYearsStart({ id, callback: earlyCallback }));
    yield put(getTermProfileRatingsStart({ id, callback: ratingsCallback }));
    yield put(
      getTermProfileSkillGroupStart({
        id,
        callback1: skillGroupCallback,
        callback2: skillRatingsCallback,
      }),
    );
    yield put(setupTermProfileComplete());
    if (payload.hasOwnProperty('callback')) {
      payload.callback();
    }
  } catch (error) {
    const err = handleError(error);
    yield put(setupTermProfileComplete());
  }
}

export function* onSetupTermProfile() {
  yield takeLatest(configActionTypes.SETUP_TERM_PROFILE, setTermProfile);
}
