import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchBulkStudentsResultDataFail, fetchBulkStudentsResultDataSuccess } from '../actions';
import broadSheetTypes from '../types';

export function* fetchBulkStudentsResultData({ payload }) {
  const params = {
    'class-level-id': payload.classLevelId,
    'term-id': payload.termId,
    'arm-id': payload.armId,
    'result-config-id': payload.configId,
    'include-unapproved': true,
  };

  if (payload.traitGroupId && payload.traitGroupId !== 'undefined') {
    params['trait-group-id'] = payload.traitGroupId;
  }

  const url = payload.cumulative
    ? '/student-results/cumulative/report/bulk'
    : '/student-results/report/bulk';

  try {
    const response = yield httpClient.get(url, { params: params });
    yield put(fetchBulkStudentsResultDataSuccess(response.data));
  } catch (error) {
    yield put(fetchBulkStudentsResultDataFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchBulkStudentsReportDataStart() {
  yield takeLatest(
    broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_START,
    fetchBulkStudentsResultData,
  );
}
