import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import { sendOTPSuccess, sendOTPFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from 'utils/utils';

export function* sendOTP({ data, callback }) {
  try {
    const response = yield httpClient.post('/auth/verify-otp', data);
    yield put(sendOTPSuccess(response.data));
    callback();
  } catch (error) {
    yield put(sendOTPFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onSendOTP() {
  yield takeLatest(userActionTypes.SEND_OTP_START, sendOTP);
}
