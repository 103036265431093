import cognitiveSkillsActionTypes from './types';

export const fetchCognitiveSkillsStart = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_START,
  payload: data,
});

export const fetchCognitiveSkillsSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_SUCCESS,
  payload: data,
});

export const fetchCognitiveSkillsFailure = (error) => ({
  type: cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_FAILURE,
  payload: error,
});

export const createSkillGroupStart = (data) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_START,
  payload: data,
});

export const createSkillGroupSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_SUCCESS,
  payload: data,
});

export const createSkillGroupFailure = (error) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_FAILURE,
  payload: error,
});

export const updateSkillGroupStart = (data, id) => ({
  type: cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_START,
  payload: { data, id },
});

export const updateSkillGroupSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_SUCCESS,
  payload: data,
});

export const updateSkillGroupFailure = (error) => ({
  type: cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_FAILURE,
  payload: error,
});

export const deleteSkillGroupStart = (id) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_START,
  payload: { id },
});

export const deleteSkillGroupSuccess = (id) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_SUCCESS,
  payload: { id },
});

export const deleteSkillGroupFailure = (error) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_FAILURE,
  payload: error,
});

export const createSkillStart = (data) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_START,
  payload: data,
});

export const createSkillSuccess = (data, id) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_SUCCESS,
  payload: { data, id },
});

export const createSkillFailure = (error) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_FAILURE,
  payload: error,
});

export const updateSkillStart = (data, id) => ({
  type: cognitiveSkillsActionTypes.UPDATE_SKILL_START,
  payload: { data, id },
});

export const updateSkillSuccess = (data, id) => ({
  type: cognitiveSkillsActionTypes.UPDATE_SKILL_SUCCESS,
  payload: { data, id },
});

export const updateSkillFailure = (error) => ({
  type: cognitiveSkillsActionTypes.UPDATE_SKILL_FAILURE,
  payload: error,
});

export const deleteSkillStart = (id, skillId) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_START,
  payload: { id, skillId },
});

export const deleteSkillSuccess = (id, skillId) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_SUCCESS,
  payload: { id, skillId },
});

export const deleteSkillFailure = (error) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_FAILURE,
  payload: error,
});

export const createSkillRatingStart = (data) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_RATING_START,
  payload: data,
});

export const createSkillRatingSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_RATING_SUCCESS,
  payload: data,
});

export const createSkillRatingFailure = (error) => ({
  type: cognitiveSkillsActionTypes.CREATE_SKILL_RATING_FAILURE,
  payload: error,
});

export const editSkillRatingStart = (data) => ({
  type: cognitiveSkillsActionTypes.EDIT_SKILL_RATING_START,
  payload: data,
});

export const editSkillRatingSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.EDIT_SKILL_RATING_SUCCESS,
  payload: data,
});

export const editSkillRatingFailure = (error) => ({
  type: cognitiveSkillsActionTypes.EDIT_SKILL_RATING_FAILURE,
  payload: error,
});

export const deleteSkillRatingStart = (id) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_RATING_START,
  payload: { id },
});

export const deleteSkillRatingSuccess = (id) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_RATING_SUCCESS,
  payload: { id },
});

export const deleteSkillRatingFailure = (error) => ({
  type: cognitiveSkillsActionTypes.DELETE_SKILL_RATING_FAILURE,
  payload: error,
});

export const setAddSkillgroupAction = (addSkillGroup) => ({
  type: cognitiveSkillsActionTypes.SET_ADD_SKILL_GROUP,
  payload: addSkillGroup,
});

export const setAddSkillAction = (addSkill) => ({
  type: cognitiveSkillsActionTypes.SET_ADD_SKILL,
  payload: addSkill,
});

export const setEditSkillGroupAction = (editSkillGroup) => ({
  type: cognitiveSkillsActionTypes.SET_EDIT_SKILL_GROUP,
  payload: editSkillGroup,
});

export const setEditSkillAction = (editSkill) => ({
  type: cognitiveSkillsActionTypes.SET_EDIT_SKILL,
  payload: editSkill,
});

export const handleDeleteModalAction = (deleteSkillGroupModal) => ({
  type: cognitiveSkillsActionTypes.HANDLE_DELETE_GROUP_MODAL,
  payload: deleteSkillGroupModal,
});

export const handleCreateRatingModalAction = (createRatingModal) => ({
  type: cognitiveSkillsActionTypes.HANDLE_CREATE_RATING_MODAL,
  payload: createRatingModal,
});

export const handleEditRatingModalAction = (editRatingModal) => ({
  type: cognitiveSkillsActionTypes.HANDLE_EDIT_RATING_MODAL,
  payload: editRatingModal,
});

export const handleDeleteRatingModalAction = (deleteRatingModal) => ({
  type: cognitiveSkillsActionTypes.HANDLE_DELETE_RATING_MODAL,
  payload: deleteRatingModal,
});

export const fetchSkillsGroupStart = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_START,
  payload: data,
});

export const fetchSkillsGroupSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_SUCCESS,
  payload: data,
});

export const fetchSkillsGroupFailure = (error) => ({
  type: cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_FAILURE,
  payload: error,
});

export const fetchSkillsRatingsStart = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_START,
  payload: data,
});

export const fetchSkillsRatingsSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_SUCCESS,
  payload: data,
});

export const fetchSkillsRatingsFailure = (error) => ({
  type: cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_FAILURE,
  payload: error,
});

export const fetchStudentCognitiveAssessmentStart = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_STUDENT_COGNITIVE_ASSESSMENT_START,
  payload: data,
});

export const fetchStudentCognitiveAssessmentSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.FETCH_STUDENT_COGNITIVE_ASSESSMENT_SUCCESS,
  payload: data,
});

export const fetchStudentCognitiveAssessmentFailure = (error) => ({
  type: cognitiveSkillsActionTypes.FETCH_STUDENT_COGNITIVE_ASSESSMENT_FAILURE,
  payload: error,
});

export const assignSkillRatingStart = (data) => ({
  type: cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_START,
  payload: data,
});

export const assignSkillRatingSuccess = (data) => ({
  type: cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_SUCCESS,
  payload: data,
});

export const assignSkillRatingFailure = (error) => ({
  type: cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_FAILURE,
  payload: error,
});
