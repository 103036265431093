import { takeLatest, put } from 'redux-saga/effects';
import { fetchClassInfoSuccess, fetchClassInfoFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';

export function* fetchClassInfo({ payload }) {
  // const { termId, sessionId} = payload;
  try {
    const response = yield httpClient.get(`/arms`, { params: payload });
    yield put(fetchClassInfoSuccess(response.data));
  } catch (error) {
    yield put(fetchClassInfoFail(error.info));
  }
}

export function* onFetchClassInfoStart() {
  yield takeLatest(configActionTypes.FETCH_CLASS_INFO_START, fetchClassInfo);
}
