import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { handleFeedBackAction } from 'redux/parent/actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  bulkStudentUploadFailure,
  bulkStudentUploadSuccess,
  fetchAllStudentStart,
  fetchStudentsStatsStart,
  stopStudentBulkLoadingAction,
} from '../action';
import studentActionTypes from '../types';

export function* bulkStudentUpload({ payload }) {
  const { filename, data, ...params } = payload;
  const formData = data;

  let response;

  try {
    if (!params.armId) {
      response = yield httpClient.post('students/uploads/template', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      response = yield httpClient.post(
        'students/uploads/template',
        formData,
        { params },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    }

    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();
      yield put(stopStudentBulkLoadingAction());

      Notify('Something went wrong, please check the downloaded excel file and try again', {
        status: 'info',
      });
    } else {
      yield put(bulkStudentUploadSuccess(response.data));
      const data = {
        offset: 0,
        limit: 10,
      };
      yield put(fetchAllStudentStart(data));
      Notify('Bulk Students Uploaded successfully', { position: 'top-right' });
      yield put(fetchStudentsStatsStart());
      yield put(handleFeedBackAction(true)); // Open Feedback modal
    }
  } catch (error) {
    yield put(bulkStudentUploadFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onBulkStudentUploadStart() {
  yield takeLatest(studentActionTypes.BULK_STUDENT_UPLOAD_START, bulkStudentUpload);
}
