import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Navigate, useLocation } from 'react-router-dom';
import { setUserLogout } from 'redux/users/actions';
import { selectIsUserLogout } from 'redux/users/selectors';

/**
 * AuthRedirect component handles redirection to the login page with a return URL.
 * It captures the current location pathname as a query parameter to preserve the original intent
 * of the user before they were redirected to the login page for safe return.
 */
const AuthRedirect = () => {
  const dispatch = useDispatch();
  // State to store the redirection URL
  const [redirectUrl, setReturnUrl] = useState<string | undefined>(undefined);
  const isUserLogout = useSelector(selectIsUserLogout);

  // Get the current location using react-router-dom useLocation hook
  const location = useLocation();

  useEffect(() => {
    /**
     * Function to construct the redirect URL with the return URL query parameter.
     * It uses the current location's pathname.
     */
    const createRedirectURL = () => {
      // Encode the current pathname for safe inclusion in a URL
      const returnQueryParam = encodeURIComponent(location.pathname);
      const searchQuery = encodeURIComponent(location.search.substring(1));
      const isSearch = searchQuery.length > 0;
      const params =
        `${'?returnUrl=' + returnQueryParam}` + `${isSearch ? '&seachQuery=' + searchQuery : ''}`;
      // Construct the redirect URL with the return URL query parameter
      setReturnUrl('/login' + params);
    };

    // Call the function when the pathname changes
    createRedirectURL();

    // Cleanup: Reset the user logout state
    return () => {
      dispatch(setUserLogout(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Render the Navigate component to perform the redirection if redirect url state is not undefined
  return !!redirectUrl ? <Navigate to={isUserLogout ? '/' : redirectUrl} /> : <></>;
};

export default AuthRedirect;
