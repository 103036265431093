const parentActionTypes = {
  FETCH_PARENTS_START: ' FETCH_PARENTS_START',
  FETCH_PARENTS_SUCCESS: ' FETCH_PARENTS_SUCCESS',
  FETCH_PARENTS_FAIL: ' FETCH_PARENTS_FAIL',
  FETCH_SINGLE_PARENT_START: ' FETCH_SINGLE_PARENT_START',
  FETCH_SINGLE_PARENT_SUCCESS: ' FETCH_SINGLE_PARENT_SUCCESS',
  FETCH_SINGLE_PARENT_FAIL: ' FETCH_SINGLE_PARENT_FAIL',
  HANDLE_PARENT_PROFILE: 'HANDLE_PARENT_PROFILE',
  FETCH_COUNTRIES_START: 'FETCH_COUNTRIES_START',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE: 'FETCH_COUNTRIES_FAILURE',
  FETCH_STATES_START: 'FETCH_STATES_START',
  FETCH_STATES_SUCCESS: 'FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE: 'FETCH_STATES_FAILURE',
  FETCH_LGA_START: 'FETCH_LGA_START',
  FETCH_LGA_SUCCESS: 'FETCH_LGA_SUCCESS',
  FETCH_LGA_FAILURE: 'FETCH_LGA_FAILURE',
  CREATE_PARENT_START: 'CREATE_PARENT_START',
  CREATE_PARENT_SUCCESS: 'CREATE_PARENT_SUCCESS',
  CREATE_PARENT_FAILURE: 'CREATE_PARENT_FAILURE',
  SAVE_AND_CREATE_ANOTHER_PARENT_START: 'SAVE_AND_CREATE_ANOTHER_PARENT_START',
  SAVE_AND_CREATE_ANOTHER_PARENT_SUCCESS: 'SAVE_AND_CREATE_ANOTHER_PARENT_SUCCESS',
  SAVE_AND_CREATE_ANOTHER_PARENT_FAILURE: 'SAVE_AND_CREATE_ANOTHER_PARENT_FAILURE',
  EDIT_PARENT_START: 'EDIT_PARENT_START',
  EDIT_PARENT_SUCCESS: 'EDIT_PARENT_SUCCESS',
  EDIT_PARENT_FAILURE: 'EDIT_PARENT_FAILURE',
  FETCH_STAFFS_START: 'FETCH_STAFFS_START',
  FETCH_STAFFS_SUCCESS: 'FETCH_STAFFS_SUCCESS',
  FETCH_STAFFS_FAILURE: 'FETCH_STAFFS_FAILURE',
  FETCH_LIST_OF_STUDENT_START: 'FETCH_LIST_OF_STUDENT_START',
  FETCH_LIST_OF_STUDENT_SUCCESS: 'FETCH_LIST_OF_STUDENT_SUCCESS',
  FETCH_LIST_OF_STUDENT_FAILURE: 'FETCH_LIST_OF_STUDENT_FAILURE',
  FILTER_STAFFS_START: 'FILTER_STAFFS_START',
  FILTER_STAFFS_SUCCESS: 'FILTER_STAFFS_SUCCESS',
  FILTER_STAFFS_FAILURE: 'FILTER_STAFFS_FAILURE',
  DOWNLOAD_PARENT_XLSX_TEMPLATE_START: 'DOWNLOAD_PARENT_XLSX_TEMPLATE_START',
  DOWNLOAD_PARENT_XLSX_TEMPLATE_SUCCESS: 'DOWNLOAD_PARENT_XLSX_TEMPLATE_SUCCESS',
  DOWNLOAD_PARENT_XLSX_TEMPLATE_FAILURE: 'DOWNLOAD_PARENT_XLSX_TEMPLATE_FAILURE',
  BULK_PARENT_UPLOAD_START: 'BULK_PARENT_UPLOAD_START',
  BULK_PARENT_UPLOAD_SUCCESS: 'BULK_PARENT_UPLOAD_SUCCESS',
  BULK_PARENT_UPLOAD_FEEDBACK_CLOSE: 'BULK_PARENT_UPLOAD_FEEDBACK_CLOSE',
  BULK_PARENT_UPLOAD_FAILURE: 'BULK_PARENT_UPLOAD_FAILURE',
  STAFF_STATUS_START: 'STAFF_STATUS_START',
  STAFF_STATUS_SUCCESS: 'STAFF_STATUS_SUCCESS',
  STAFF_STATUS_FAILURE: 'STAFF_STATUS_FAILURE',
  DOWNLOAD_XLSX_TEMPLATE_START: 'DOWNLOAD_XLSX_TEMPLATE_START',
  DOWNLOAD_XLSX_TEMPLATE_SUCCESS: 'DOWNLOAD_XLSX_TEMPLATE_SUCCESS',
  DOWNLOAD_XLSX_TEMPLATE_FAILURE: 'DOWNLOAD_XLSX_TEMPLATE_FAILURE',
  BULK_STAFF_UPLOAD_START: 'BULK_STAFF_UPLOAD_START',
  BULK_STAFF_UPLOAD_SUCCESS: 'BULK_STAFF_UPLOAD_SUCCESS',
  BULK_STAFF_UPLOAD_FAILURE: 'BULK_STAFF_UPLOAD_FAILURE',
  PARENT_STATUS_START: 'PARENT_STATUS_START',
  PARENT_STATUS_SUCCESS: 'PARENT_STATUS_SUCCESS',
  PARENT_STATUS_FAILURE: 'PARENT_STATUS_FAILURE',
  RESET_LGA: 'RESET_LGA',
  SAVE_PARENT_BIO: 'SAVE_PARENT_BIO',
  SAVE_LINKED_CHILD_INFO: 'SAVE_LINKED_CHILD_INFO',
  OPEN_WIZARD: 'OPEN_WIZARD',
  RESET_EVERYTHING: 'RESET_EVERYTHING',
  HANDLE_STAFF_PROFILE_DATA: 'HANDLE_STAFF_PROFILE_DATA',
  HANDLE_EDIT: 'HANDLE_EDIT',
  HANDLE_BULK_MODAL: 'HANDLE_BULK_MODAL',
  HANDLE_FEEDBACK_MODAL: 'HANDLE_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL: 'CLOSE_FEEDBACK_MODAL',
  HANDLE_DELETE_MODAL: 'HANDLE_DELETE_MODAL',
  FETCH_PARENT_STATISTICS_START: 'FETCH_PARENT_STATISTICS_START',
  FETCH_PARENT_STATISTICS_SUCCESS: 'FETCH_PARENT_STATISTICS_SUCCESS',
  FETCH_PARENT_STATISTICS_FAILURE: 'FETCH_PARENT_STATISTICS_FAILURE',
  LINK_CHILD_START: 'LINK_CHILD_START',
  LINK_CHILD_SUCCESS: 'LINK_CHILD_SUCCESS',
  LINK_CHILD_FAILURE: 'LINK_CHILD_FAILURE',
  UNLINK_CHILD_START: 'UNLINK_CHILD_START',
  UNLINK_CHILD_SUCCESS: 'UNLINK_CHILD_SUCCESS',
  UNLINK_CHILD_FAILURE: 'UNLINK_CHILD_FAILURE',
  DELETE_PARENT_START: 'DELETE_PARENT_START',
  DELETE_PARENT_SUCCESS: 'DELETE_PARENT_SUCCESS',
  DELETE_PARENT_FAILURE: 'DELETE_PARENT_FAILURE',
  UPDATE_PARENT_PICTURE_START: 'UPDATE_PARENT_PICTURE_START',
  UPDATE_PARENT_PICTURE_SUCCESS: 'UPDATE_PARENT_PICTURE_SUCCESS',
  UPDATE_PARENT_PICTURE_FAILURE: 'UPDATE_PARENT_PICTURE_FAILURE',
  HANDLE_EDIT_PICTURE_ON_PROFILE: 'HANDLE_EDIT_PICTURE_ON_PROFILE',
  HANDLE_CURRENT_STUDENT: 'HANDLE_CURRENT_STUDENT',
  HANDLE_LINK_MODAL: 'HANDLE_LINK_MODAL',
  HANDLE_UNLINK_MODAL: 'HANDLE_UNLINK_MODAL',
  STOP_UPLOAD_PARENT_BULK_LOADING: 'STOP_UPLOAD_PARENT_BULK_LOADING',

  FETCH_PARENT_CHILDREN_START: 'FETCH_PARENT_CHILDREN_START',
  FETCH_PARENT_CHILDREN_SUCCESS: 'FETCH_PARENT_CHILDREN_SUCCESS',
  FETCH_PARENT_CHILDREN_FAILURE: 'FETCH_PARENT_CHILDREN_FAILURE',

  FETCH_CHILD_RESULT_START: 'FETCH_CHILD_RESULT_START',
  FETCH_CHILD_RESULT_SUCCESS: 'FETCH_CHILD_RESULT_SUCCESS',
  FETCH_CHILD_RESULT_FAIL: 'FETCH_CHILD_RESULT_FAIL',
};

export default parentActionTypes;
