import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { setDisableNext } from 'redux/users/actions';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  createTermDefinitionFail,
  createTermDefinitionSuccess,
  fetchAllExistingTermsStart,
  fetchAllTermDefStart,
} from '../actions';
import configActionTypes from '../types';

export function* createTermDefinition({ payload }) {
  try {
    const response = yield httpClient.post(`/term-definitions`, payload);
    yield put(createTermDefinitionSuccess(response.data));
    yield put(fetchAllTermDefStart());
    yield put(fetchAllExistingTermsStart());
    Notify('Term Created succesfully', { position: 'top-right' });
    yield put(setDisableNext(false));
  } catch (error) {
    yield put(createTermDefinitionFail(error.fail));
    const err = handleError(error);
  }
}

export function* onCreatingTermDefinitionStart() {
  yield takeLatest(configActionTypes.CREATE_TERM_DEF_START, createTermDefinition);
}
