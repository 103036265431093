import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { sendOutstandingBillEmailFail, sendOutstandingBillEmailSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* sendBillMail({ payload }) {
  try {
    const response = yield httpClient.post(`/students-invoice/outstanding/notification`, payload);
    yield put(sendOutstandingBillEmailSuccess(response.data));
    Notify('Email has been sent successfully.', { position: 'top-right' });
  } catch (error) {
    yield put(sendOutstandingBillEmailFail(error?.message));
    const err = handleError(error);
  }
}

export function* onSendBillMailStart() {
  yield takeLatest(invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_START, sendBillMail);
}
