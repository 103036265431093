import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { saveAndCreateAnotherParentFailure, saveAndCreateAnotherParentSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';

export function* saveAndCreateAnotherParent({ payload }) {
  const { data, callback } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post('/parents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    yield put(saveAndCreateAnotherParentSuccess(response.data));
    callback();
    Notify('Parent Saved, Create Another One', { position: 'top-right' });
  } catch (error) {
    yield put(saveAndCreateAnotherParentFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onSaveAndCreateAnotherParentStart() {
  yield takeLatest(
    staffActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_START,
    saveAndCreateAnotherParent,
  );
}
