import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { setDefaultTemplateFail, setDefaultTemplateSuccess } from '../actions';
import broadSheetTypes from '../types';

export function* setDefaultTemplate({ payload }) {
  try {
    const response = yield httpClient.post(
      `/result-generation-configs/class-levels/${payload.classLevelId}`,
      { term_id: payload.termId },
    );
    yield put(setDefaultTemplateSuccess(response.data, true));
  } catch (error) {
    yield put(setDefaultTemplateFail(error?.message));

    const err = handleError(error, obj);
  }
}

export function* onSetDefaultTemplate() {
  yield takeLatest(broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_START, setDefaultTemplate);
}
