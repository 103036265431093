import { takeLatest, put } from 'redux-saga/effects';
import { Notify } from '@flexisaf/flexibull2';
import { fetchSingleParentSuccess, fetchSingleParentFail } from '../actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchSingleParent({ payload }) {
  try {
    const response = yield httpClient.get(`/parents/${payload}`);
    yield put(fetchSingleParentSuccess(response.data));
  } catch (error) {
    yield put(fetchSingleParentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchSingleParentStart() {
  yield takeLatest(parentActionTypes.FETCH_SINGLE_PARENT_START, fetchSingleParent);
}
