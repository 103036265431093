import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import invoiceSummariesActionTypes from './types';

const INITIAL_STATE = {
  isLoading: false,
  listOfInvoicesSummaries: [],
  total: 0,
  activeSummary: {},
  defaultersModal: false,
  buttonLoading: false,
};

function invoiceSummariesReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_START:
      return {
        ...state,
        isLoading: true,
      };
    case invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listOfInvoicesSummaries: payload.content,
        total: payload.total_elements,
      };
    case invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        defaultersModal: false,
      };
    case invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        defaultersModal: false,
      };
    case invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case invoiceSummariesActionTypes.SET_ACTIVE_INVOICE_SUMMARY:
      return {
        ...state,
        activeSummary: payload,
      };

    case invoiceSummariesActionTypes.HANDLE_MESSAGE_DEFAULTS_MODAL:
      return {
        ...state,
        defaultersModal: payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'invoices-summaries',
  storage,
  blacklist: ['error', 'isLoading', 'defaultersModal', 'buttonLoading', 'listOfInvoicesSummaries'],
};

export default persistReducer(persistConfig, invoiceSummariesReducer);
