import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { getTermProfileRatingsSuccess, getTermProfileRatingsFail } from '../actions';
import { handleError } from 'utils/utils';

export function* getTermProfileRatings({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.get(
      `/trait-configuration/trait-ratings/termly/${payload.id}`,
    );
    yield put(getTermProfileRatingsSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(getTermProfileRatingsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTermProfileRatings() {
  yield takeLatest(configActionTypes.GET_TERM_PROFILE_RATINGS_START, getTermProfileRatings);
}
