import { takeLatest, put } from 'redux-saga/effects';
import { fetchListOfStudentSuccess, fetchListOfStudentFailure } from '../actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchListOfStudents({ payload }) {
  try {
    const response = yield httpClient.get(`/students`, { params: payload });
    yield put(fetchListOfStudentSuccess(response.data));
  } catch (error) {
    yield put(fetchListOfStudentFailure(error?.message));
    handleError(error);
  }
}

export function* onfetchListOfStudentsStart() {
  yield takeLatest(parentActionTypes.FETCH_LIST_OF_STUDENT_START, fetchListOfStudents);
}
