import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { linkParentSuccess, linkParentFail, fetchAllStudentStart } from '../action';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

const data = {
  offset: 0,
  limit: 10,
};

export function* linkParent({ payload }) {
  try {
    const response = yield httpClient.post('/parents/link-students', payload);
    yield put(linkParentSuccess(response.data));
    Notify('Child Linked to Parent Successfully', { position: 'top-right' });
    yield put(fetchAllStudentStart(data));
  } catch (error) {
    yield put(linkParentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onlinkParentStart() {
  yield takeLatest(studentActionTypes.LINK_PARENT_START, linkParent);
}
