import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { createInvoiceFailure, createInvoiceSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* createInvoice({ payload }) {
  const { data, apply, callback } = payload;

  try {
    const response = yield httpClient.post(`/invoice-templates`, data);
    yield put(createInvoiceSuccess(response.data));
    Notify('Invoice created successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createInvoiceFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateInvoiceStart() {
  yield takeLatest(invoiceActionTypes.CREATE_INVOICE_START, createInvoice);
}
