import { takeLatest, put } from 'redux-saga/effects';
import { editSingleRatingSuccess, editSingleRatingFail } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* editRateRemark({ payload }) {
  const { id, data } = payload;
  try {
    const response = yield httpClient.put(
      `/trait-configuration/trait-ratings/definitions/${id}`,
      data,
    );
    yield put(editSingleRatingSuccess(response.data));
    Notify('Rating edited succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(editSingleRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onEditRateRemarkStart() {
  yield takeLatest(earlyYearsActionTypes.EDIT_SINGLE_RATE_START, editRateRemark);
}
