import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  fetchClassArmBroadsheetStart,
  studentResultApprovalFail,
  studentResultApprovalSuccess,
} from '../actions';
import broadSheetTypes from '../types';

export function* studentResultApproval({ payload }) {
  try {
    const response = yield httpClient.post(`/term-result-approvals`, payload);
    yield put(studentResultApprovalSuccess(response.data));
    yield put(
      fetchClassArmBroadsheetStart(payload.term_id, payload.class_level_id, payload.arm_id),
    );
    Notify('Result Approved', { position: 'top-right' });
  } catch (error) {
    yield put(studentResultApprovalFail(error?.message));
    const err = handleError(error);
  }
}

export function* onStudentResultApprovalStart() {
  yield takeLatest(broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_START, studentResultApproval);
}
