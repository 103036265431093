/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWeeklyAssessmentRequest } from '../models/CreateWeeklyAssessmentRequest';
import type { WeeklyAssessmentDto } from '../models/WeeklyAssessmentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WeeklyAssessmentRestControllerService {
  /**
   * getBySubject
   * @returns WeeklyAssessmentDto OK
   * @throws ApiError
   */
  public static getBySubjectUsingGet1({
    classLevelId,
    subjectId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * subjectId
     */
    subjectId: string;
  }): CancelablePromise<Array<WeeklyAssessmentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weekly-assessment',
      query: {
        classLevelId: classLevelId,
        subjectId: subjectId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * create
   * @returns WeeklyAssessmentDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createUsingPost5({
    request,
  }: {
    /**
     * request
     */
    request: CreateWeeklyAssessmentRequest;
  }): CancelablePromise<WeeklyAssessmentDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/weekly-assessment',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * update
   * @returns WeeklyAssessmentDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateUsingPut6({
    assessmentId,
    request,
  }: {
    /**
     * assessment-id
     */
    assessmentId: string;
    /**
     * request
     */
    request: CreateWeeklyAssessmentRequest;
  }): CancelablePromise<WeeklyAssessmentDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/weekly-assessment/{assessment-id}',
      path: {
        'assessment-id': assessmentId,
      },
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getById
   * @returns WeeklyAssessmentDto OK
   * @throws ApiError
   */
  public static getByIdUsingGet2({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<WeeklyAssessmentDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weekly-assessment/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * delete
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUsingDelete5({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/weekly-assessment/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
