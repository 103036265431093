import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import assessmentActionTypes from './types';
import { InsertBeginning, findIndexInArray } from 'utils/utils';

const INITIAL_STATE = {
  isLoading: false,
  listOfAssessments: [],
  total: 0,
  createModal: false,
  buttonLoading: false,
  levelAssessments: [],
  activeLevelAssessment: {},
};

export interface IAssessmentState {
  isLoading?: boolean;
  listOfAssessments?: any[];
  total?: number;
  createModal?: boolean;
  buttonLoading?: boolean;
  levelAssessments?: any[];
  activeLevelAssessment?: any;
}

function assessmentReducer(state: IAssessmentState = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listOfAssessments: payload,
        total: payload.length,
      };
    case assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        levelAssessments: payload,
      };
    case assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case assessmentActionTypes.SET_ACTIVE_LEVEL_ASSESSMENT:
      return {
        ...state,
        activeLevelAssessment: payload,
      };

    case assessmentActionTypes.CREATE_ASSESSMENT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case assessmentActionTypes.CREATE_ASSESSMENT_SUCCESS:
      const createdEntities = InsertBeginning(state.listOfAssessments, payload);

      return {
        ...state,
        buttonLoading: false,
        listOfAssessments: createdEntities,
        createModal: false,
        total: (state.total || 0) + 1,
      };
    case assessmentActionTypes.CREATE_ASSESSMENT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case assessmentActionTypes.HANDLE_CREATE_MODAL:
      return {
        ...state,
        createModal: payload,
      };
    case assessmentActionTypes.UPDATE_ASSESSMENT_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'assessment',
  storage,
  blacklist: ['error', 'isLoading', 'createModal', 'buttonLoading'],
};

export default persistReducer(persistConfig, assessmentReducer);
