import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { getLevelSubjectsFailure, getLevelSubjectsSuccess } from '../actions';
import subjectsActionTypes from '../types';

export function* getLevelSubjects({ payload }) {
  try {
    const response = yield httpClient.get('/subjects/class-level', {
      params: { 'class-level-id': payload.class_level_id, 'arm-id': payload.arm_id },
    });
    yield put(getLevelSubjectsSuccess(response.data));
  } catch (error) {
    const err = handleError(error);
    yield put(getLevelSubjectsFailure(err?.message, { status: 'error' }));
  }
}

export function* onGetLevelSubjectsStart() {
  yield takeLatest(subjectsActionTypes.GET_LEVEL_SUBJECTS_START, getLevelSubjects);
}
