const assessmentActionTypes = {
  FETCH_LIST_OF_ASSESSMENTS_START: 'FETCH_LIST_OF_ASSESSMENTS_START',
  FETCH_LIST_OF_ASSESSMENTS_SUCCESS: 'FETCH_LIST_OF_ASSESSMENTS_SUCCESS',
  FETCH_LIST_OF_ASSESSMENTS_FAILURE: 'FETCH_LIST_OF_ASSESSMENTS_FAILURE',

  CREATE_ASSESSMENT_START: 'CREATE_ASSESSMENT_START',
  CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
  CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',

  FETCH_LEVELS_ASSESSMENTS_START: 'FETCH_LEVELS_ASSESSMENTS_START',
  FETCH_LEVELS_ASSESSMENTS_SUCCESS: 'FETCH_LEVELS_ASSESSMENTS_SUCCESS',
  FETCH_LEVELS_ASSESSMENTS_FAILURE: 'FETCH_LEVELS_ASSESSMENTS_FAILURE',

  HANDLE_CREATE_MODAL: 'HANDLE_CREATE_MODAL',
  SET_ACTIVE_LEVEL_ASSESSMENT: 'SET_ACTIVE_LEVEL_ASSESSMENT',
  UPDATE_ASSESSMENT_STATE: 'UPDATE_ASSESSMENT_STATE',
};

export default assessmentActionTypes;
