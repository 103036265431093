import { Box, Text } from '@flexisaf/flexibull2';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { Theme } from 'config/theme';
import moment from 'moment';
import { darken, lighten, transparentize } from 'polished';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const DateSelect = styled.div`
  background-color: #fff;
  display: flex;
  position: relative;
  float: left;
  border-radius: ${Theme.PrimaryRadius};
  border: 1px solid ${Theme.PrimaryBorderColor};

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: none;
    padding: 12px 5px;
    font-size: 12px;
    color: ${Theme.PrimaryFontColor};

    &:hover {
      background-color: ${Theme.PrimaryFade};
    }

    &:nth-of-type(1) {
      width: 50px;
      border-radius: ${Theme.PrimaryRadius} 0 0 ${Theme.PrimaryRadius};
      border-right: 1px solid ${Theme.PrimaryBorderColor};
    }
    &:nth-of-type(3) {
      width: 40px;
      border-radius: 0 ${Theme.PrimaryRadius} ${Theme.PrimaryRadius} 0;
      border-left: 1px solid ${Theme.PrimaryBorderColor};
    }
    &:nth-of-type(2) {
      width: 80px;
    }
    & option {
      font-size: 11px;
      background-color: ${Theme.PrimaryFade};

      &:hover {
        background-color: ${transparentize(0.6, Theme.PrimaryColor)};
      }
    }
  }
`;

const DatePicker = (props) => {
  const [years, setYears] = useState([]);
  const [months] = useState(moment.months());
  const [days, setDays] = useState([]);
  var dt = new Date();
  var currentYear = dt.getFullYear();

  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [minimumDate, setMinimumDate] = useState(null);
  const [maximumDate, setMaximumDate] = useState(null);
  // var i = 1970; i < currentYear + 6; i++
  useEffect(() => {
    const allYears = [];
    for (var i = currentYear + 4; i >= 1900; i--) {
      allYears.push(+i);
    }
    setYears(allYears);
  }, [currentYear]);

  useEffect(() => {
    var daysOfCurrentMonth = new Date(selectedYear, Number(selectedMonth) + 1, 0).getDate();
    const alldays = [];
    for (var i = 1; i < daysOfCurrentMonth + 1; i++) {
      alldays.push(+i);
    }
    setDays(alldays);
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    if (props.value) {
      var valueDate = new Date(props.value);
      setSelectedYear(valueDate.getFullYear());
      setSelectedMonth(valueDate.getMonth());
      setSelectedDay(valueDate.getDate());
    }
  }, [props.value]);

  useEffect(() => {
    if (props.minDate) {
      var min = new Date(props.minDate);
      setMinimumDate({
        year: min.getFullYear(),
        month: min.getMonth(),
        day: min.getDate(),
      });
    }
    if (props.maxDate) {
      var max = new Date(props.maxDate);
      setMaximumDate({
        year: max.getFullYear(),
        month: max.getMonth(),
        day: max.getDate(),
      });
    }
  }, [props.minDate, props.maxDate]);

  const formatDate = (year, month, day) => {
    if (year && month !== '' && day) {
      const newDate = new Date(year, month, day);
      props.onDateChange?.(newDate);
    }
  };

  const handleYear = (e) => {
    setSelectedYear(e);
    formatDate(e, selectedMonth, selectedDay);
    if (props.onYearChange) {
      props.onYearChange(e);
    }
  };

  const handleMonth = (e) => {
    setSelectedMonth(e);
    formatDate(selectedYear, e, selectedDay);
    if (props.onMonthChange) {
      props.onMonthChange(e);
    }
  };

  const handleDay = (e) => {
    setSelectedDay(e);
    formatDate(selectedYear, selectedMonth, e);
    if (props.onDayChange) {
      props.onDayChange(e);
    }
  };

  const disableYearOptions = (year) => {
    if (props.minDate && props.maxDate) {
      if (year < minimumDate.year || year > maximumDate.year) {
        return true;
      }
    } else if (props.minDate) {
      if (year < minimumDate?.year) {
        return true;
      }
    } else {
      if (year < maximumDate?.year) {
        return true;
      }
    }
  };

  const disableMonthOptions = (year, month) => {
    if (minimumDate?.year === Number(year)) {
      if (month >= minimumDate?.month) {
        return false;
      } else {
        return true;
      }
    } else if (maximumDate?.year === Number(year)) {
      if (month <= maximumDate?.month) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const disableDayOptions = (year, month, day) => {
    if (minimumDate?.year === Number(year)) {
      if (minimumDate?.month === Number(month)) {
        if (day >= minimumDate?.day) {
          return false;
        } else {
          return true;
        }
      }
    } else if (maximumDate?.year === Number(year)) {
      if (maximumDate?.month === Number(month)) {
        if (day <= maximumDate?.day) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  return (
    <Wrapper {...props}>
      {props.label ? (
        <Box display="block" margin="-21px 0 9px 0">
          <Text block bold uppercase size="10px">
            {' '}
            {props.label}{' '}
          </Text>
        </Box>
      ) : null}
      <DateSelect>
        <Box pad="0 10px" display="flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
          <i className="saf-calendar-1" />
        </Box>
        <select
          value={selectedYear}
          onChange={(e) => handleYear(e.target.value)}
          disabled={props.disabled}
        >
          <option value="" disabled>
            Year
          </option>
          {years.map((year) => (
            <option value={year} disabled={disableYearOptions(year)} key={year}>
              {year}
            </option>
          ))}
        </select>
        {!props.hideMonth && (
          <select
            value={selectedMonth}
            onChange={(e) => handleMonth(e.target.value)}
            disabled={props.disabled}
          >
            <option value="" disabled>
              Month
            </option>
            {months.map((month, i) => (
              <option disabled={disableMonthOptions(selectedYear, i)} value={i} key={i}>
                {month}
              </option>
            ))}
          </select>
        )}
        {!props.hideDay && (
          <select
            value={selectedDay}
            onChange={(e) => handleDay(e.target.value)}
            {...(props.hideDateDisplay && {
              style: {
                display: 'none',
              },
            })}
          >
            <option value="" disabled>
              Day
            </option>
            {days.map((day) => (
              <option
                disabled={disableDayOptions(selectedYear, selectedMonth, day)}
                value={day}
                key={day}
              >
                {day}
              </option>
            ))}
          </select>
        )}
      </DateSelect>
    </Wrapper>
  );
};

export default DatePicker;

const DateRangePickerWrapper = styled('div').attrs({
  className: 'flexi-datepicker',
})`
  display: block;
  align-items: center;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  min-height: ${(props) => props.height};
  width: ${(props) => (props.width ? props.width : '280px')};
  ${(props) =>
    props.spaceLeft &&
    css`
      margin-left: ${(props) => (props.spaceLeft.length >= 3 ? props.spaceLeft : '10px')};
    `}
  ${(props) =>
    props.spaceRight &&
    css`
      margin-right: ${(props) => (props.spaceRight.length >= 3 ? props.spaceRight : '10px')};
    `}
    ${(props) =>
    props.spaceTop &&
    css`
      margin-top: ${(props) => (props.spaceTop.length >= 3 ? props.spaceTop : '20px')};
    `}
    ${(props) =>
    props.spaceBottom &&
    css`
      margin-bottom: ${(props) => (props.spaceBottom.length >= 3 ? props.spaceBottom : '25px')};
    `}
    ${(props) =>
    props.block &&
    css`
      display: flex;
      width: 100%;
    `}

      & .error-message {
    margin: 5px;
    font-size: 13px;
    color: ${(props) => props.theme.PrimaryRed};
    font-style: italic;
  }

  & .flexi-daterange-label {
    position: absolute;
    display: grid;
    grid-template-columns: 50% 50%;
    top: -12px;
    width: calc(100% - 68px);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.65em;
    cursor: default;
    & span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        &::after {
          ${({ required, theme }) =>
            required &&
            css`
              content: '*';
              color: ${theme.PrimaryRed};
              position: absolute;
              top: -2px;
              margin-left: 2px;
            `};
        }
      }
    }
    &:last-child {
      padding-left: 8px;
    }
  }

  & .react-daterange-picker {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '32px')};
    line-height: 150%;
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.PrimaryBorderColor};
    border-radius: 3px;
    outline: none;
    font-size: 8px;
    font-family: ${(props) => props.theme.PrimaryFont};

    &.react-daterange-picker--open.react-daterange-picker--enabled {
      border: 1px solid ${(props) => props.theme.PrimaryColor};
    }
    & .react-daterange-picker__inputGroup__input {
      min-width: 1rem;
      &::placeholder {
        color: ${({ theme }) => lighten(0.3, theme.PrimaryFontColor)};
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
    & .react-daterange-picker__inputGroup__year {
      min-width: 2rem;
    }
    & .react-date-picker__inputGroup__divider {
      color: ${({ theme }) => lighten(0.3, theme.PrimaryFontColor)};
    }
    & .react-daterange-picker__range-divider {
      margin: 0 5px;
    }
    & .react-daterange-picker__button {
      ${(props) =>
        props.hideBtn &&
        css`
          display: none;
        `}
      & svg {
        stroke: ${(props) =>
          darken(0.2, props.borderColor ? props.borderColor : props.theme.PrimaryBorderColor)};
      }
    }

    &,
    & *,
    & *:before,
    & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    &--disabled {
      background-color: ${({ theme }) => lighten(0.1, theme.PrimaryGreyLight)};
      border: 1px dashed
        ${(props) =>
          transparentize(
            0.1,
            props.borderColor ? props.borderColor : props.theme.PrimaryBorderColor,
          )};

      &::placeholder {
        color: ${({ theme }) => lighten(0.3, theme.PrimaryFontColor)};
      }
    }

    &__wrapper {
      display: flex;
      /* flex-grow: 0;
            flex-shrink: 0; */
      border: none;
    }

    &__inputGroup {
      padding: 0 5px;
      /* width: 70%; */
      text-align: left;

      &__divider {
        padding: 1px 2px;
        white-space: pre;
      }

      &__input {
        -webkit-appearance: none;
        margin: 0 4px;
        outline: none;
        border-radius: none;
        background-color: none;
        font-size: 12px;

        &:focus {
          background-color: none;
        }

        &:invalid {
          background: none;
        }
      }
    }

    &__inputGroup__year {
      width: 25px;
    }

    &__button {
      border: 0;
      background: transparent;
      padding: 4px 10px;
      outline: none;

      &:enabled {
        cursor: pointer;

        &:hover,
        &:focus {
          .react-daterange-picker__button__icon {
            stroke: ${(props) => props.theme.PrimaryColor};
          }
        }
      }

      &:disabled {
        .react-daterange-picker__button__icon {
          stroke: ${(props) =>
            transparentize(
              0.1,
              props.borderColor ? props.borderColor : props.theme.PrimaryBorderColor,
            )};
        }
      }

      svg {
        display: inherit;
        width: 10px;
        height: 10px;
      }
    }

    &__calendar {
      margin-top: 10px;

      &--closed {
        display: none;
      }

      .react-calendar {
        border-width: thin;
        border-radius: 3px;
        padding: 20px;
        border: 1px solid ${(props) => props.theme.PrimaryBorderColor};

        &__navigation {
          &__next2-button,
          &__prev2-button {
            font-size: 14px;
            font-family: ${(props) => props.theme.PrimaryFont};
            border-radius: 100px;
            border: 1px solid ${(props) => props.theme.PrimaryBorderColor};

            &:hover {
              background-color: ${(props) => props.theme.PrimaryBorderColor};
            }
          }
          &__next-button,
          &__prev-button {
            font-size: 14px;
            font-family: ${(props) => props.theme.PrimaryFont};
            font-weight: bold;
            border-radius: 100px;
            color: ${(props) => props.theme.PrimaryColor};
            margin: 0 3px;

            &:hover {
              background-color: ${(props) => lighten(0.3, props.theme.PrimaryColor)};
            }
          }
          &__label {
            font-size: 14px;
            font-family: ${(props) => props.theme.PrimaryFont};
            font-weight: bold;
            color: ${(props) => props.theme.PrimaryColor};
            background: transparent;
            border-radius: 3px;

            &:hover {
              background-color: ${(props) => props.theme.PrimaryBorderColor};
            }
          }
        }

        &__month-view {
          font-size: 12px;
          font-family: ${(props) => props.theme.PrimaryFont};

          &__days {
            &__day {
              font-size: 12px;
              font-family: ${(props) => props.theme.PrimaryFont};
            }
          }
        }

        &__tile {
          &--hasActive {
            background-color: ${(props) => props.theme.PrimaryColor};
            color: #fff;
          }
          &--range:enabled {
            border-radius: 0;
          }
          &--rangeStart:enabled {
            border-radius: 0;
            color: #fff !important;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          &--rangeEnd:enabled {
            border-radius: 0;
            color: #fff !important;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
          &--now:enabled {
            border: 1px solid ${(props) => props.theme.PrimaryColor};
            background-color: transparent;
            color: ${(props) => props.theme.PrimaryColor} !important;
          }
          &--active:enabled {
            background-color: ${(props) => props.theme.PrimaryColor} !important;
            color: #fff !important;
          }
        }
      }
    }
  }
`;

export const CustomDateRangePicker = (props) => {
  const {
    label,
    label2,
    block,
    width,
    spaceLeft,
    spaceRight,
    spaceTop,
    spaceBottom,
    height,
    error,
    icon,
    range,
    ...rest
  } = props;

  return (
    <DateRangePickerWrapper {...props}>
      {label && (
        <div className="flexi-daterange-label">
          <span>{label}</span>
          <span>{label2}</span>
        </div>
      )}
      <DateRangePicker {...rest} disabled={props.disabled} />
      <em className="error-message">{error}</em>
    </DateRangePickerWrapper>
  );
};
