import { takeLatest, put } from 'redux-saga/effects';
import { deleteStudentDocsFail, deleteStudentDocsSuccess } from '../action';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';

export function* deleteStudentDocumet({ payload }) {
  const { data, student_id } = payload;
  try {
    const response = yield httpClient.delete(
      `/documents/student/${student_id}?documentKey=${data.aws_s3_key}`,
    );

    yield put(deleteStudentDocsSuccess(data.aws_s3_key));
    Notify('Document deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteStudentDocsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteStudentDocumentStart() {
  yield takeLatest(studentActionTypes.DELETE_STUDENT_DOCS_START, deleteStudentDocumet);
}
