import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from 'utils/utils';
import httpClient from '../../../utils/http-client';
import { googleAuthLoginFailure, googleAuthLoginSuccess } from '../actions';
import userActionTypes from '../types';

export function* googleAuthLogin({ callback_url }) {
  try {
    const response = yield httpClient.get(`/auth/google/login?callback-url=${callback_url}`);
    yield put(googleAuthLoginSuccess(response.data.redirect_url));
    window.location.href = response.data.redirect_url;
  } catch (error) {
    yield put(googleAuthLoginFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onGoogleAuthLogin() {
  yield takeLatest(userActionTypes.GOOGLE_AUTH_LOGIN_START, googleAuthLogin);
}
