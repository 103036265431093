/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubCollaboratorStudentSkillCommentRequest } from '../models/SubCollaboratorStudentSkillCommentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubCollaboratorStudentSkillCommentRestControllerService {
  /**
   * getSubCollaboratorStudentSkillComments
   * @returns any OK
   * @throws ApiError
   */
  public static getSubCollaboratorStudentSkillCommentsUsingGet({
    subCollaboratorId,
    termId,
  }: {
    /**
     * sub-collaborator-id
     */
    subCollaboratorId: string;
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sub-collaborator-comments',
      query: {
        'sub-collaborator-id': subCollaboratorId,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createSubCollaboratorStudentSkillComment
   * @returns any OK
   * @throws ApiError
   */
  public static createSubCollaboratorStudentSkillCommentUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: SubCollaboratorStudentSkillCommentRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sub-collaborator-comments',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateSubCollaboratorStudentSkillComment
   * @returns any OK
   * @throws ApiError
   */
  public static updateSubCollaboratorStudentSkillCommentUsingPut({
    request,
  }: {
    /**
     * request
     */
    request: SubCollaboratorStudentSkillCommentRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sub-collaborator-comments',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getSubCollaboratorStudentSkillComment
   * @returns any OK
   * @throws ApiError
   */
  public static getSubCollaboratorStudentSkillCommentUsingGet({
    id,
    subCollaboratorId,
    termId,
  }: {
    /**
     * id
     */
    id: string;
    /**
     * sub-collaborator-id
     */
    subCollaboratorId: string;
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sub-collaborator-comments/student/{id}',
      path: {
        id: id,
      },
      query: {
        'sub-collaborator-id': subCollaboratorId,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
