import { IClassInformationState } from './reducer';
import classInformationTypes from './types';

export const fetchListOfClassInformationStart = (data) => ({
  type: classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_START,
  payload: data,
});

export const fetchListOfClassInformationSuccess = (data) => ({
  type: classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_SUCCESS,
  payload: data,
});

export const fetchListOfClassInformationFailure = (error) => ({
  type: classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_FAILURE,
  payload: error,
});

export const handleAssignFormTeacherModal = (formTeacherModal) => ({
  type: classInformationTypes.HANDLE_ASSIGN_FORM_TEACHER_MODAL,
  payload: formTeacherModal,
});

export const fetchAllTeachersStart = () => ({
  type: classInformationTypes.FETCH_ALL_TEACHERS_START,
});

export const fetchAllTeachersSuccess = (data) => ({
  type: classInformationTypes.FETCH_ALL_TEACHERS_SUCCESS,
  payload: data,
});

export const fetchAllTeachersFail = (error) => ({
  type: classInformationTypes.FETCH_ALL_TEACHERS_FAIL,
  payload: error,
});

export const assignFormTeachersStart = (data) => ({
  type: classInformationTypes.ASSIGN_FORM_TEACHERS_START,
  payload: data,
});

export const assignFormTeachersSuccess = (data) => ({
  type: classInformationTypes.ASSIGN_FORM_TEACHERS_SUCCCESS,
  payload: data,
});

export const assignFormTeachersFail = (error) => ({
  type: classInformationTypes.ASSIGN_FORM_TEACHERS_FAIL,
  payload: error,
});
export const saveFilteredClasses = (data) => ({
  type: classInformationTypes.SAVE_FILTERED_CLASSES,
  payload: data,
});

export const updateClassInformationState = (payload: IClassInformationState) => ({
  type: classInformationTypes.UPDATE_CLASS_INFORMATION_STATE,
  payload,
});
