import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import plansActionTypes from './types';
import { FIX_ANY } from 'utils/types';

const INITIAL_STATE = {
  isLoading: false,
  buttonLoading: false,
  modules: [],
};

export interface IPlanState {
  isLoading: boolean;
  buttonLoading: boolean;
  modules: FIX_ANY[];
}

interface IParam {
  type: plansActionTypes;
  payload: any;
}

function plansReducer(state: IPlanState = INITIAL_STATE, { type, payload }: IParam) {
  switch (type) {
    case plansActionTypes.FETCH_APP_MODULES_START:
      return {
        ...state,
        isLoading: true,
      };

    case plansActionTypes.FETCH_APP_MODULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modules: payload,
      };
    case plansActionTypes.FETCH_APP_MODULES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case plansActionTypes.SELECT_APP_MODULES_START:
      return {
        ...state,
        buttonLoading: true,
      };

    case plansActionTypes.SELECT_APP_MODULES_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case plansActionTypes.SELECT_APP_MODULES_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };
    case plansActionTypes.UPDATE_PLAN_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'plans',
  storage,
  blacklist: ['error', 'isLoading', 'buttonLoading'],
};

export default persistReducer(persistConfig, plansReducer);
