import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import { getSessionFail, getSessionSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';

export function* getSessions() {
  try {
    const response = yield httpClient.get('/sessions');
    yield put(getSessionSuccess(response.data));
  } catch (error) {
    yield put(getSessionFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetSessionsStart() {
  yield takeLatest(configActionTypes.GET_SESSION_START, getSessions);
}
