import { takeLatest, put } from 'redux-saga/effects';
import {
  deleteSingleTraitSuccess,
  deleteSingleTraitFail,
  fetchAllTraitGroupStart,
} from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* deleteSingleTrait({ payload }) {
  try {
    const response = yield httpClient.delete(`/trait-configuration/traits/${payload.id}`);
    yield put(deleteSingleTraitSuccess());
    Notify('Trait group deleted succesfully', { position: 'top-right' });
    payload.callback();
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(deleteSingleTraitFail(error.fail));
    const obj = {
      code: 400,
      message: 'Trait cannot be deleted. This might be as a result of children subtraits.',
    };
    const err = handleError(error, obj);
  }
}

export function* onDeleteSingleTraitStart() {
  yield takeLatest(earlyYearsActionTypes.DELETE_SINGLE_TRAIT_START, deleteSingleTrait);
}
