import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  Loader,
  RadioButton,
  Select,
  Spacer,
  Text,
} from '@flexisaf/flexibull2';
import EmptyState from 'assets/academics.svg';
import { CustomEmptyState } from 'components/EmptyState';
import BreadCrumbs from 'components/breadcrumb';
import { Theme } from 'config/theme';
import useGroupTermsBySessions from 'general-hooks/term-hooks/useGroupTermsBySessions';
import useSubjectsByClassLevelsFetch from 'general-hooks/useSubjectsByClassLevelFetch';
import { AssessmentOverrideData, CreateSubjectAssessmentConfigurationRequest } from 'generated';
import { useAssessmentBreakdownGet } from 'pages/my-assessment-format/hooks/useAssessmentBreakdown';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUrlParam } from 'utils/utils';
import { useSubjectConfiguration } from '../hooks/useSubjectConfiguration';

const SubjectConfiguration = (props) => {
  // const currentTerm = useSelector(selectCurrentTermDTO);
  const levelID = getUrlParam('level') as string;
  const urlLevelName = getUrlParam('level_name');
  const urlSubjectId = getUrlParam('sub_id');
  const urlSubjectName = getUrlParam('sub_name');

  const [showGrade, setShowGrade] = useState('SCORE');
  const [assBreakdownId, setAssBreakdownId] = useState<string[]>([]);
  const [assOverrideList, setAssOverrideList] = useState<AssessmentOverrideData[]>([]);
  const [subjectAssConfigId, setSubjectAssConfigId] = useState<string | null>(null);

  // HANDLE SUBJECT LIST
  const [selectedSubject, setSelectedSubject] = useState({
    subject_name: urlSubjectName,
    id: urlSubjectId,
  });
  const { loadingSubjects, subjects } = useSubjectsByClassLevelsFetch({
    classLevelId: levelID,
  });

  // Handle the Next and Previous toggle of subjects
  const toggleSubject = (direction: string) => {
    const listCount = subjects?.length;
    const currentPositon = subjects?.findIndex((item) => item?.id === selectedSubject?.id);

    if (currentPositon > -1) {
      if (direction === 'NEXT') {
        const nextPosition = listCount > currentPositon + 1 ? currentPositon + 1 : 0;
        setSelectedSubject({
          id: subjects[nextPosition]?.id,
          subject_name: subjects[nextPosition]?.subject_name,
        });
      } else {
        const prevPosition = currentPositon === 0 ? listCount - 1 : currentPositon - 1;
        setSelectedSubject({
          id: subjects[prevPosition]?.id,
          subject_name: subjects[prevPosition]?.subject_name,
        });
      }
    }
  };

  const totalSubjects = useMemo(() => subjects?.length, [subjects]);

  const [overideMode, setOverideMode] = useState(false);

  const { loadingAssessmentBreakdown, assessmentBreakDownContent, fetchAssessmentBreakdown } =
    useAssessmentBreakdownGet();

  const {
    loadingFetch,
    loadingSave,
    fetchSubjectConfig,
    subjectAssessmentConfig,
    postSubjectConfig,
    updateSubjectConfig,
  } = useSubjectConfiguration();

  const {
    groupedTermsBySessions,
    allTerms,
    selectTermOptions,
    sessionValue,
    setSessionValue,
    setSelectTermOptions,
    selectedTerm,
    currentTermDTO,
    setSelectedTerm,
  } = useGroupTermsBySessions({});

  const sessionnOptions = useMemo(() => {
    const list = Object.entries(groupedTermsBySessions).map(([k, v]) => ({
      value: k,
      label: k,
      extra: v,
    }));
    return list;
  }, [groupedTermsBySessions]);

  const handleSubjectAssConfigFetch = useCallback(
    async (payload) => {
      const { response, success } = await fetchSubjectConfig(payload);

      if (success) {
        if (response?.id) {
          setSubjectAssConfigId(response?.id);
          const list = response?.assessment_breakdowns.map((item) => item.id);
          const overideList = response?.assessment_overrides?.map((item) => ({
            assessment_id: item?.assessment?.id,
            assessment_total: item?.assessment_total,
          }));
          setAssBreakdownId(list);
          setAssOverrideList(overideList);
          setShowGrade(response?.show_grade_alphabet ? 'ALPHABET' : 'SCORE');
          if (overideList?.length > 0) {
            setOverideMode(true);
          }
        } else {
          setSubjectAssConfigId(null);
          setAssBreakdownId([]);
        }
      } else {
      }
    },
    [fetchSubjectConfig],
  );

  const handleOverrideUpdate = (e, assessmentId) => {
    e?.stopPropagation();
    const value = Number(e?.target?.value);
    const newList = [...assOverrideList];
    const existIndex = newList?.findIndex((item) => item?.assessment_id === assessmentId);

    if (existIndex > -1) {
      if (value > 0) {
        newList[existIndex].assessment_total = value;
      } else {
        newList.splice(existIndex, 1);
      }
    } else {
      if (value > 0) {
        newList.push({
          assessment_id: assessmentId,
          assessment_total: value,
        });
      }
    }

    setAssOverrideList(newList);
  };

  useEffect(() => {
    const payload = {
      classLevelId: levelID as string,
      subjectId: selectedSubject?.id as string,
      termId: selectedTerm?.value?.term_id as string,
    };
    if (levelID && selectedSubject?.id && selectedTerm?.value?.term_id) {
      fetchAssessmentBreakdown(payload);
      handleSubjectAssConfigFetch(payload);
    }
  }, [
    selectedTerm?.value?.term_id,
    fetchAssessmentBreakdown,
    handleSubjectAssConfigFetch,
    levelID,
    selectedSubject?.id,
  ]);

  const handleAssBreakdownToggle = (id: string) => {
    const newList = [...assBreakdownId];
    const index = newList.findIndex((item) => item === id);

    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList.push(id);
    }
    setAssBreakdownId(newList);
  };

  const onSubmit = async () => {
    const payload = {
      assessment_breakdown_id: assBreakdownId,
      class_level_id: levelID,
      show_grade_alphabet: showGrade === 'ALPHABET',
      subject_id: selectedSubject?.id,
      term_id: selectedTerm?.value?.term_id,
    } as CreateSubjectAssessmentConfigurationRequest;

    if (assOverrideList?.length > 0) {
      payload['assessment_overrides'] = assOverrideList;
    }

    if (subjectAssConfigId) {
      updateSubjectConfig(subjectAssConfigId, payload);
    } else {
      const { success, response } = await postSubjectConfig(payload);
      if (success) {
        setSubjectAssConfigId(response?.id);
      }
    }
  };

  return (
    <Box pad="40px">
      <BreadCrumbs
        links={[{ path: `/class-level/${levelID}`, text: 'Class Level' }]}
        last={`${selectedSubject?.subject_name} Assessment Configuration`}
      />

      <Grid default="max-content max-content max-content auto" alignItems="center">
        {totalSubjects > 1 && (
          <span>
            <i
              className="saf-arrow-circle-left"
              onClick={() => toggleSubject('PREV')}
              style={{
                fontSize: '25px',
                color: `${Theme.PrimaryGrey}`,
                cursor: 'pointer',
              }}
            />
          </span>
        )}
        <Box align="center" display="flex" alignItems="center">
          <span
            style={{
              padding: '5px 8px',
              margin: '0 10px 0 0',
              border: `0.5px solid ${Theme.PrimaryBorderColor}`,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="saf-book-1" style={{ fontSize: '24px' }} />
          </span>
          <Box pad="10px" margin="0 10px" align="left">
            <Text block bold size="18px">
              {selectedSubject?.subject_name}
            </Text>
            <Text block>{urlLevelName}</Text>
          </Box>
        </Box>
        {totalSubjects > 1 && (
          <span>
            <i
              className="saf-arrow-circle-right"
              onClick={() => toggleSubject('NEXT')}
              style={{
                fontSize: '25px',
                color: `${Theme.PrimaryGrey}`,
                cursor: 'pointer',
              }}
            />
          </span>
        )}
      </Grid>
      <Spacer />
      <Text>
        Assessment breakdown:{' '}
        <b>{assessmentBreakDownContent?.assessment_format?.assessment_format_name}</b>
      </Text>
      <Box pad="40px 0 20px 0">
        <Grid
          default="max-content max-content auto"
          md="max-content max-content auto"
          sm="repeat(2, 1fr)"
        >
          <Select
            label="session"
            placeholder="Select Session"
            options={sessionnOptions}
            value={sessionValue}
            onChange={(value) => {
              setSessionValue(value);
              setSelectTermOptions(value?.extra);
            }}
          />
          <Select
            label="term"
            placeholder="Select Term"
            options={selectTermOptions?.map((item) => ({
              label: item?.school_term_definition?.name,
              value: item,
              extra: item,
            }))}
            value={selectedTerm}
            onChange={(value) => setSelectedTerm(value)}
          />
        </Grid>
      </Box>
      <hr style={{ border: 'none', borderBottom: `0.5px solid ${Theme.PrimaryBorderColor}` }} />

      {loadingAssessmentBreakdown ? (
        <Box pad="150px 0">
          <Loader color={Theme.PrimaryColor} section />
        </Box>
      ) : assessmentBreakDownContent?.assessments_and_breakdowns?.length ? (
        <Box>
          <Box pad="20px 0">
            <Grid default="1.5fr 1fr" tablet="1.5fr 1fr" mobile="repeat(1, 1fr)">
              <Box>
                <Text bold>Assessment List</Text>
                <Spacer />
                <Text>
                  Select the assessment you want to be compiled in the end of term report.
                </Text>
              </Box>
              <Box align="right">
                {overideMode ? (
                  <a href="javascript:void(0)" onClick={() => setOverideMode(false)}>
                    {' '}
                    <i className="saf-backward-item" />
                    Hide Assessment Override{' '}
                  </a>
                ) : (
                  <a href="javascript:void(0)" onClick={() => setOverideMode(true)}>
                    {' '}
                    <i className="saf-backward-item" /> Show Assessment Override{' '}
                  </a>
                )}
              </Box>
            </Grid>

            {/* <Box
          margin="10px 0"
          style={{
            border: `0.5px solid ${Theme.PrimaryBorderColor}`,
            borderRadius: Theme.PrimaryRadius,
          }}
          > */}
            {assessmentBreakDownContent?.assessments_and_breakdowns
              ?.sort((a, b) => {
                if (
                  (a?.assessment?.assessment_order || 1) > (b?.assessment?.assessment_order || 0)
                ) {
                  return 1;
                } else {
                  return -1;
                }
              })
              ?.map((item) => {
                const assessmentId = item?.assessment?.id;
                const overrideValue = assOverrideList?.find(
                  (item) => item?.assessment_id === assessmentId,
                )?.assessment_total;
                return (
                  <Box
                    margin="10px 0"
                    style={{
                      border: `0.5px solid ${Theme.PrimaryBorderColor}`,
                      borderRadius: Theme.PrimaryRadius,
                    }}
                  >
                    <Box pad="10px 8px">
                      <Grid
                        default="1.5fr 1fr"
                        md="1.5fr 1fr"
                        sm="1.5fr 1fr"
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <Text uppercase block style={{}}>
                          <b>{item?.assessment?.assessment_name}</b>
                        </Text>

                        <Box display="flex">
                          {overideMode ? (
                            <Text size="10px" style={{ margin: 'auto  8px auto auto' }}>
                              Enter maximum score:{' '}
                              <Input
                                width="60px"
                                height="30px"
                                type="number"
                                value={overrideValue}
                                onChange={(e) => handleOverrideUpdate(e, assessmentId)}
                              />
                            </Text>
                          ) : null}
                        </Box>
                      </Grid>
                    </Box>
                    {item?.breakdowns?.map((breakdown, index) => {
                      const breakdownId = breakdown?.id ?? '';
                      const isActive = assBreakdownId?.includes(breakdownId);
                      return (
                        <Box
                          index={`${index}-${breakdown?.id}`}
                          pad="10px 8px"
                          style={{ borderBottom: `0.5px solid ${Theme.PrimaryBorderColor}` }}
                        >
                          <Checkbox
                            label={
                              <Text>
                                <b>{breakdown?.assessment_breakdown_name}</b>
                                <br />
                                {isActive ? null : (
                                  <span>This assessment will not be compiled into the result.</span>
                                )}
                              </Text>
                            }
                            checked={assBreakdownId?.includes(breakdownId)}
                            onChange={() => handleAssBreakdownToggle(breakdownId)}
                            size="15px"
                          />
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            {/* </Box> */}
          </Box>
          <hr style={{ border: 'none', borderBottom: `0.5px solid ${Theme.PrimaryBorderColor}` }} />

          <Box pad="20px 0">
            <Text bold>Grade Format</Text>
            <Spacer />
            <Text>This handles how the subject grade would be displayed on the result.</Text>

            <Box
              margin="10px 0"
              style={{
                border: `0.5px solid ${Theme.PrimaryBorderColor}`,
                borderRadius: Theme.PrimaryRadius,
              }}
            >
              <Box
                pad="15px 10px"
                style={{ borderBottom: `0.5px solid ${Theme.PrimaryBorderColor}` }}
              >
                <RadioButton
                  onClick={() => setShowGrade('SCORE')}
                  checked={showGrade === 'SCORE' ? true : false}
                  name="showGrade"
                  thickness="1px"
                  size="20px"
                  label={
                    <Text>
                      <b>Computable Subject</b>
                      <br />

                      <span>
                        This subject will be used in calculating final averages and position.
                      </span>
                    </Text>
                  }
                />
              </Box>
              <Box
                pad="15px 10px"
                style={{ borderBottom: `0.5px solid ${Theme.PrimaryBorderColor}` }}
              >
                <RadioButton
                  onClick={() => setShowGrade('ALPHABET')}
                  checked={showGrade === 'ALPHABET' ? true : false}
                  name="showGrade"
                  thickness="1px"
                  size="20px"
                  label={
                    <Text>
                      <b>Non-Computable Subject</b>
                      <br />

                      <span>
                        This subject will NOT be used in calculating final averages and position.
                      </span>
                    </Text>
                  }
                />
              </Box>
            </Box>
          </Box>
          <hr style={{ border: 'none', borderBottom: `0.5px solid ${Theme.PrimaryBorderColor}` }} />
          <Box pad="20px 0" align="right">
            <Button
              progress={loadingSave || !assessmentBreakDownContent?.assessment_format?.id}
              disabled={loadingSave || !assessmentBreakDownContent?.assessment_format?.id}
              onClick={() => onSubmit()}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      ) : (
        <CustomEmptyState image={EmptyState} title="There are currently no records." />
      )}
    </Box>
  );
};

export default SubjectConfiguration;
