import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import subjectsActionTypes from './types';
import { InsertBeginning, findIndexInArray } from 'utils/utils';

const INITIAL_STATE = {
  isLoading: false,
  listOfSubjects: [],
  total: 0,
  buttonLoading: false,
  modal: false,
  editing: false,
  deleteModal: false,
  total: 0,
  levelSubjects: [],
  subjectTeacherModal: false,
  subjectResult: [],
  resultLoading: false,
  studentSubjectModal: false,
};

function subjectsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case subjectsActionTypes.CREATE_SUBJECT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case subjectsActionTypes.CREATE_SUBJECT_SUCCESS:
      const createdEntities = InsertBeginning(state.listOfSubjects, payload);
      return {
        ...state,
        buttonLoading: false,
        listOfSubjects: createdEntities.sort((a, b) => a.subject_order - b.subject_order),
        modal: false,
      };
    case subjectsActionTypes.CREATE_SUBJECT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case subjectsActionTypes.EDIT_SUBJECT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case subjectsActionTypes.EDIT_SUBJECT_SUCCESS:
      const indexOfEditedSubject = findIndexInArray(state.listOfSubjects, payload, 'id');
      state.listOfSubjects.splice(indexOfEditedSubject, 1, payload);
      return {
        ...state,
        buttonLoading: false,
        modal: false,
        editing: false,
      };
    case subjectsActionTypes.EDIT_SUBJECT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case subjectsActionTypes.DELETE_SUBJECT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case subjectsActionTypes.DELETE_SUBJECT_SUCCESS:
      const indexOfDeleteSubject = findIndexInArray(state.listOfSubjects, { id: payload }, 'id');
      state.listOfSubjects.splice(indexOfDeleteSubject, 1);

      return {
        ...state,
        buttonLoading: false,
        deleteModal: false,
        total: --state.total,
      };
    case subjectsActionTypes.DELETE_SUBJECT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case subjectsActionTypes.FETCH_SUBJECTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case subjectsActionTypes.FETCH_SUBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listOfSubjects: payload,
      };
    case subjectsActionTypes.FETCH_SUBJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case subjectsActionTypes.HANDLE_MODAL:
      return {
        ...state,
        modal: payload.modal,
        editing: payload.editing,
      };

    case subjectsActionTypes.HANDLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: payload,
      };

    case subjectsActionTypes.GET_LEVEL_SUBJECTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case subjectsActionTypes.GET_LEVEL_SUBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        levelSubjects: payload,
      };
    case subjectsActionTypes.GET_LEVEL_SUBJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case subjectsActionTypes.HANDLE_ASSIGN_SUBJECT_TEACHER_MODAL:
      return {
        ...state,
        subjectTeacherModal: payload.subjectTeacherModal,
        editing: payload.editing,
      };

    case subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_SUCCCESS:
      const indexOfAssignedSubjectTeacher = findIndexInArray(
        state.levelSubjects,
        { id: payload.id },
        'id',
      );
      state.levelSubjects[indexOfAssignedSubjectTeacher].subject_teachers = payload.data;
      return {
        ...state,
        buttonLoading: false,
        subjectTeacherModal: false,
        levelSubjects: state.levelSubjects,
      };
    case subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case subjectsActionTypes.EDIT_SUBJECT_TEACHERS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case subjectsActionTypes.EDIT_SUBJECT_TEACHERS_SUCCESS:
      const indexOfEditedSubjectTeacher = findIndexInArray(
        state.levelSubjects,
        { id: payload.id },
        'id',
      );
      state.levelSubjects[indexOfEditedSubjectTeacher].subject_teachers = payload.data;
      return {
        ...state,
        buttonLoading: false,
        subjectTeacherModal: false,
        editing: false,
        levelSubjects: state.levelSubjects,
      };
    case subjectsActionTypes.EDIT_SUBJECT_TEACHERS_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };
    case subjectsActionTypes.HANDLE_SET_STUDENT_SUBJECT_MODAL:
      return {
        ...state,
        studentSubjectModal: payload,
      };
    case subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_START:
      return {
        ...state,
        isLoading: true,
      };
    case subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentSubjectModal: false,
      };
    case subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case subjectsActionTypes.GET_SUBJECT_RESULT_START:
      return {
        ...state,
        resultLoading: true,
      };
    case subjectsActionTypes.GET_SUBJECT_RESULT_SUCCESS:
      return {
        ...state,
        subjectResult: payload,
        resultLoading: false,
      };
    case subjectsActionTypes.GET_SUBJECT_RESULT_FAIL:
      return {
        ...state,
        resultLoading: false,
        error: payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'subjects',
  storage,
  blacklist: [
    'error',
    'isLoading',
    'buttonLoading',
    'modal',
    'editing',
    'deleteModal',
    'subjectTeacherModal',
    'resultLoading',
    'studentSubjectModal',
  ],
};

export default persistReducer(persistConfig, subjectsReducer);
