import { takeLatest, put } from 'redux-saga/effects';
import { fetchAllArmsSuccess, fetchAllArmsFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';

export function* fetchArms() {
  try {
    const response = yield httpClient.get(`/arms`);
    yield put(fetchAllArmsSuccess(response.data));
  } catch (error) {
    yield put(fetchAllArmsFail(error?.message));
  }
}

export function* onFetchArmsStart() {
  yield takeLatest(configActionTypes.FETCH_ALL_ARMS_START, fetchArms);
}
