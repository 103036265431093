import { put, takeLatest } from 'redux-saga/effects';
import { fetchSkillsGroupStart, fetchSkillsRatingsStart } from 'redux/cognitve-skills/actions';
import { getLevelSubjectsStart } from 'redux/subjects/actions';
import httpClient from 'utils/http-client';
import {
  fetchClassInformationByArmIDFail,
  fetchClassInformationByArmIDSuccess,
  getClassLevelTraitsStart,
  getClassMemberStart,
} from '../actions';
import configActionTypes from '../types';

export function* fetchClassInfoArmId({ payload }) {
  try {
    const response = yield httpClient.get(`/class-information`, { params: payload.data });
    const { class_level, arm, term } = response.data[0];

    yield put(getClassMemberStart(class_level.id, arm.id, term.session.id, term.term_id));
    yield put(fetchClassInformationByArmIDSuccess(response.data));
    yield put(fetchSkillsGroupStart({ class_level_id: class_level.id }));
    yield put(fetchSkillsRatingsStart({ classLevelId: class_level.id, termId: term.term_id }));

    if (class_level.early_years) {
      yield put(getClassLevelTraitsStart({ termId: term.term_id, classLevelId: class_level.id }));
    } else {
      yield put(getLevelSubjectsStart({ class_level_id: class_level?.id, arm_id: arm?.id }));
    }
  } catch (error) {
    yield put(fetchClassInformationByArmIDFail(error));
  }
}

export function* onFetchClassInfoArmIDStart() {
  yield takeLatest(configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_START, fetchClassInfoArmId);
}
