import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { assignSubjectTeachersFail, assignSubjectTeachersSuccess } from '../actions';
import subjectsActionTypes from '../types';

export function* assignSubjectTeachers({ payload }) {
  try {
    const response = yield httpClient.post(`/subjects/teacher`, payload);
    yield put(assignSubjectTeachersSuccess(response.data, payload.subject_id));
    Notify('Subject teacher(s) assigned successfully', {
      position: 'top-right',
    });
  } catch (error) {
    yield put(assignSubjectTeachersFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAssignSubjectTeachersStart() {
  yield takeLatest(subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_START, assignSubjectTeachers);
}
