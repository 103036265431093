import internalRolesActionTypes from './types';

export const fetchInternalRolesStart = (data) => ({
  type: internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_START,
  payload: data,
});

export const fetchInternalRolesSuccess = (data) => ({
  type: internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_SUCCESS,
  payload: data,
});

export const fetchInternalRolesFailure = (error) => ({
  type: internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_FAILURE,
  payload: error,
});

export const fetchUserRolesStart = (data) => ({
  type: internalRolesActionTypes.FETCH_USER_ROLES_START,
  payload: data,
});

export const fetchUserRolesSuccess = (data) => ({
  type: internalRolesActionTypes.FETCH_USER_ROLES_SUCCESS,
  payload: data,
});

export const fetchUserRolesFailure = (error) => ({
  type: internalRolesActionTypes.FETCH_USER_ROLES_FAILURE,
  payload: error,
});

export const assignRolesStart = (data) => ({
  type: internalRolesActionTypes.ASSING_ROLES_START,
  payload: data,
});

export const assignRolesSuccess = (data) => ({
  type: internalRolesActionTypes.ASSING_ROLES_SUCCESS,
  payload: data,
});

export const assignRolesFailure = (error) => ({
  type: internalRolesActionTypes.ASSING_ROLES_FAILURE,
  payload: error,
});

export const assignRolesModalAction = (assignRolesModal) => ({
  type: internalRolesActionTypes.HANDLE_ASSIGN_ROLES_MODAL,
  payload: assignRolesModal,
});
