import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { saveStudentTraitAssessmentFail, saveStudentTraitAssessmentSuccess } from '../actions';
import configActionTypes from '../types';

export function* saveStudentTraitAssessment({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield httpClient.post(`/student-trait-assessments`, data);
    yield put(saveStudentTraitAssessmentSuccess(response.data));
    callback();
  } catch (error) {
    yield put(saveStudentTraitAssessmentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onSaveStudentTraitAssessment() {
  yield takeLatest(
    configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_START,
    saveStudentTraitAssessment,
  );
}
