import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { downloadXLSXTempleteFailure, downloadXLSXTempleteSuccess } from '../actions';
import staffActionTypes from '../types';

export function* downloadXLSXTemplete() {
  try {
    const response = yield httpClient.get('/staff/downloads/template', { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'staff-upload-template.xlsx');
    document.body.appendChild(link);
    link.click();

    yield put(downloadXLSXTempleteSuccess(response.data));
  } catch (error) {
    yield put(downloadXLSXTempleteFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDownloadXLSXTempleteStart() {
  yield takeLatest(staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_START, downloadXLSXTemplete);
}
