enum gradesActionTypes {
  FETCH_GRADE_FORMATS_START = 'FETCH_GRADE_FORMATS_START',
  FETCH_GRADE_FORMATS_SUCCESS = 'FETCH_GRADE_FORMATS_SUCCESS',
  FETCH_GRADE_FORMATS_FAIL = 'FETCH_GRADE_FORMATS_FAIL',

  UPDATE_GRADE_FORMATS_START = 'UPDATE_GRADE_FORMATS_START',
  UPDATE_GRADE_FORMATS_SUCCESS = 'UPDATE_GRADE_FORMATS_SUCCESS',
  UPDATE_GRADE_FORMATS_FAIL = 'UPDATE_GRADE_FORMATS_FAIL',

  ADD_GRADE_FORMATS_START = 'ADD_GRADE_FORMATS_START',
  ADD_GRADE_FORMATS_SUCCESS = 'ADD_GRADE_FORMATS_SUCCESS',
  ADD_GRADE_FORMATS_FAIL = 'ADD_GRADE_FORMATS_FAIL',

  DELETE_GRADE_FORMATS_START = 'DELETE_GRADE_FORMATS_START',
  DELETE_GRADE_FORMATS_SUCCESS = 'DELETE_GRADE_FORMATS_SUCCESS',
  DELETE_GRADE_FORMATS_FAIL = 'DELETE_GRADE_FORMATS_FAIL',

  HANDLE_GRADE_FORMATS_MODAL = 'HANDLE_GRADE_FORMATS_MODAL',
  HANDLE_DELETE_GRADE_FORMAT_MODAL = 'HANDLE_DELETE_GRADE_FORMAT_MODAL',
}

export default gradesActionTypes;
