enum globalActionTypes {
  FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START',
  FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE',
  FETCH_STATES_START = 'FETCH_STATES_START',
  FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE = 'FETCH_STATES_FAILURE',
  FETCH_LGA_START = 'FETCH_LGA_START',
  FETCH_LGA_SUCCESS = 'FETCH_LGA_SUCCESS',
  FETCH_LGA_FAILURE = 'FETCH_LGA_FAILURE',
  RESET_LGA = 'RESET_LGA',
  SET_SITE_CONFIG = 'SET_SITE_CONFIG',
  UPDATE_GLOBAL_STATE = 'UPDATE_GLOBAL_STATE',
}

export default globalActionTypes;
