import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  getTermProfileAssessmentFormatFail,
  getTermProfileAssessmentFormatSuccess,
} from '../actions';
import configActionTypes from '../types';

export function* getTermProfileAssessmentFormat({ payload }) {
  const callback = payload.callback;
  delete payload.callback;
  try {
    const response = yield httpClient.get(`/assessment-config/termly/${payload.id}`);
    yield put(getTermProfileAssessmentFormatSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(getTermProfileAssessmentFormatFail(error?.message));
    callback([]);
    const err = handleError(error);
  }
}

export function* onGetTermProfileAssessmentFormat() {
  yield takeLatest(
    configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_START,
    getTermProfileAssessmentFormat,
  );
}
