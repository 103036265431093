import { takeLatest, put } from 'redux-saga/effects';
import { submitApprovalResultsSuccess, submitApprovalResultsFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* submitApprovalResults({ payload }) {
  try {
    const response = yield httpClient.put(`/assessment-approval`, payload);

    yield put(submitApprovalResultsSuccess(payload));
    Notify('Results saved succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(submitApprovalResultsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onSubmitApprovalResults() {
  yield takeLatest(configActionTypes.SUBMIT_APPROVAL_RESULTS_START, submitApprovalResults);
}
