import { IStaffState } from './reducer';
import staffActionTypes from './types';

export const fetchCountriesStart = () => ({
  type: staffActionTypes.FETCH_COUNTRIES_START,
  payload: {},
});

export const fetchCountriesSuccess = (data) => ({
  type: staffActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: data,
});

export const fetchCountriesFailure = (error) => ({
  type: staffActionTypes.FETCH_COUNTRIES_FAILURE,
  payload: error,
});

export const fetchStatesStart = (data) => ({
  type: staffActionTypes.FETCH_STATES_START,
  payload: data,
});

export const fetchStatesSuccess = (data) => ({
  type: staffActionTypes.FETCH_STATES_SUCCESS,
  payload: data,
});

export const fetchStatesFailure = (error) => ({
  type: staffActionTypes.FETCH_STATES_FAILURE,
  payload: error,
});

export const fetchLgaStart = (data) => ({
  type: staffActionTypes.FETCH_LGA_START,
  payload: data,
});

export const fetchLgaSuccess = (data) => ({
  type: staffActionTypes.FETCH_LGA_SUCCESS,
  payload: data,
});

export const fetchLgaFailure = (error) => ({
  type: staffActionTypes.FETCH_LGA_FAILURE,
  payload: error,
});

export const createStaffStart = (data) => ({
  type: staffActionTypes.CREATE_STAFF_START,
  payload: data,
});

export const createStaffSuccess = (data) => ({
  type: staffActionTypes.CREATE_STAFF_SUCCESS,
  payload: data,
});

export const createStaffFailure = (error) => ({
  type: staffActionTypes.CREATE_STAFF_FAILURE,
  payload: error,
});

export const saveAndCreateAnotherStaffStart = (data, callback) => ({
  type: staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_START,
  payload: { data, callback },
});

export const saveAndCreateAnotherStaffSuccess = (data) => ({
  type: staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_SUCCESS,
  payload: data,
});

export const saveAndCreateAnotherStaffFailure = (error) => ({
  type: staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_FAILURE,
  payload: error,
});

export const editStaffStart = (data) => ({
  type: staffActionTypes.EDIT_STAFF_START,
  payload: data,
});

export const editStaffSuccess = (data) => ({
  type: staffActionTypes.EDIT_STAFF_SUCCESS,
  payload: data,
});

export const editStaffFailure = (error) => ({
  type: staffActionTypes.EDIT_STAFF_FAILURE,
  payload: error,
});

export const fetchStaffsStart = (data) => ({
  type: staffActionTypes.FETCH_STAFFS_START,
  payload: data,
});

export const fetchStaffsSuccess = (data) => ({
  type: staffActionTypes.FETCH_STAFFS_SUCCESS,
  payload: data,
});

export const fetchStaffsFailure = (error) => ({
  type: staffActionTypes.FETCH_STAFFS_FAILURE,
  payload: error,
});

export const fetchEditRequestsStart = (data) => ({
  type: staffActionTypes.FETCH_EDIT_REQUESTS_START,
  payload: data,
});

export const fetchEditRequestsSuccess = (data) => ({
  type: staffActionTypes.FETCH_EDIT_REQUESTS_SUCCESS,
  payload: data,
});

export const fetchEditRequestsFailure = (data) => ({
  type: staffActionTypes.FETCH_EDIT_REQUESTS_FAILURE,
  payload: data,
});

export const fetchStaffsStatsStart = () => ({
  type: staffActionTypes.FETCH_STAFFS_STATS_START,
});

export const fetchStaffsStatsSuccess = (data) => ({
  type: staffActionTypes.FETCH_STAFFS_STATS_SUCCESS,
  payload: data,
});

export const fetchStaffsStatsFailure = (error) => ({
  type: staffActionTypes.FETCH_STAFFS_STATS_FAILURE,
  payload: error,
});

export const filterStaffsStart = (data) => ({
  type: staffActionTypes.FILTER_STAFFS_START,
  payload: data,
});

export const filterStaffsSuccess = (data) => ({
  type: staffActionTypes.FILTER_STAFFS_SUCCESS,
  payload: data,
});

export const filterStaffsFailure = (error) => ({
  type: staffActionTypes.FILTER_STAFFS_FAILURE,
  payload: error,
});

export const filterEditRequestsStart = (data) => ({
  type: staffActionTypes.FILTER_EDIT_REQUEST_START,
  payload: data,
});

export const filterEditRequestsSuccess = (data) => ({
  type: staffActionTypes.FILTER_EDIT_REQUEST_SUCCESS,
  payload: data,
});

export const filterEditRequestsFailure = (error) => ({
  type: staffActionTypes.FILTER_EDIT_REQUEST_FAILURE,
  payload: error,
});

export const downloadXLSXTempleteStart = () => ({
  type: staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_START,
  payload: {},
});

export const downloadXLSXTempleteSuccess = (data) => ({
  type: staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_SUCCESS,
  payload: data,
});

export const downloadXLSXTempleteFailure = (error) => ({
  type: staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_FAILURE,
  payload: error,
});

export const bulkStaffUploadStart = (fileName, data) => ({
  type: staffActionTypes.BULK_STAFF_UPLOAD_START,
  payload: { fileName, data },
});

export const staffStatusStart = (data) => ({
  type: staffActionTypes.STAFF_STATUS_START,
  payload: data,
});

export const bulkStaffUploadSuccess = (data) => ({
  type: staffActionTypes.BULK_STAFF_UPLOAD_SUCCESS,
  payload: data,
});

export const bulkStaffUploadFailure = (error) => ({
  type: staffActionTypes.BULK_STAFF_UPLOAD_FAILURE,
  payload: error,
});

export const staffStatusSuccess = (data) => ({
  type: staffActionTypes.STAFF_STATUS_SUCCESS,
  payload: data,
});

export const staffStatusFailure = (error, data) => ({
  type: staffActionTypes.STAFF_STATUS_FAILURE,
  payload: { error, data },
});

export const resetLgaAction = () => ({
  type: staffActionTypes.RESET_LGA,
  payload: [],
});

export const saveStaffBioAction = (data) => ({
  type: staffActionTypes.SAVE_STAFF_BIO,
  payload: data,
});

export const saveOtherInfoAction = (data) => ({
  type: staffActionTypes.SAVE_OTHER_INFO,
  payload: data,
});

export const openWizardAction = (wizard) => ({
  type: staffActionTypes.OPEN_WIZARD,
  payload: wizard,
});

export const resetEverythingAction = () => ({
  type: staffActionTypes.RESET_EVERYTHING,
  payload: {},
});

export const handleStaffProfileAction = (staffProfileData) => ({
  type: staffActionTypes.HANDLE_STAFF_PROFILE_DATA,
  payload: staffProfileData,
});

export const handleEditRequestAction = (data) => ({
  type: staffActionTypes.HANDLE_EDIT_REQUEST_ACTION,
  payload: data,
});

export const handleEditAction = (editUser, staffBio, otherInfo) => ({
  type: staffActionTypes.HANDLE_EDIT,
  payload: { editUser, staffBio, otherInfo },
});

export const handleStaffRequestEditAction = (editUser, staffBio) => ({
  type: staffActionTypes.CREATE_STAFF_PROFILE_EDIT_REQUEST,
  payload: { editUser, staffBio },
});

export const acceptProfileEditRequest = (id) => ({
  type: staffActionTypes.ACCEPT_STAFF_EDIT_REQUEST,
  payload: id,
});

export const rejectProfileEditRequest = (data) => ({
  type: staffActionTypes.REJECT_STAFF_EDIT_REQUEST,
  payload: data,
});

export const handleBulkModalAction = (bulkModal) => ({
  type: staffActionTypes.HANDLE_BULK_MODAL,
  payload: bulkModal,
});

export const stopStaffBulkLoadingAction = () => ({
  type: staffActionTypes.STOP_UPLOAD_STAFF_BULK_LOADING,
  payload: {},
});

export const handleDeclineModalAction = (declineModal) => ({
  type: staffActionTypes.HANDLE_DECLINE_MODAL,
  payload: declineModal,
});

export const handleEditPictureOnProfileAction = (status) => ({
  type: staffActionTypes.HANDLE_EDIT_PICTURE_ON_PROFILE,
  payload: status,
});

export const updateStaffPictureStart = (payload) => ({
  type: staffActionTypes.UPDATE_STAFF_PICTURE_START,
  payload,
});

export const updateStaffPictureSuccess = (link, id) => ({
  type: staffActionTypes.UPDATE_STAFF_PICTURE_SUCCESS,
  payload: { link, id },
});

export const updateStaffPictureFailure = (error) => ({
  type: staffActionTypes.UPDATE_STAFF_PICTURE_FAILURE,
  payload: error,
});

export const updateStaffSignatureStart = (payload) => ({
  type: staffActionTypes.UPDATE_STAFF_SIGNATURE_START,
  payload,
});

export const updateStaffSignatureSuccess = (link, id) => ({
  type: staffActionTypes.UPDATE_STAFF_SIGNATURE_SUCCESS,
  payload: { link, id },
});

export const updateStaffSignatureFailure = (error) => ({
  type: staffActionTypes.UPDATE_STAFF_SIGNATURE_FAILURE,
  payload: error,
});

export const deleteStaffStart = (id, isProfile, callback) => ({
  type: staffActionTypes.DELETE_STAFF_START,
  payload: { id, isProfile, callback },
});

export const deleteStaffSuccess = (id) => ({
  type: staffActionTypes.DELETE_STAFF_SUCCESS,
  payload: id,
});

export const deleteStaffFailure = (error) => ({
  type: staffActionTypes.DELETE_STAFF_FAILURE,
  payload: error,
});

export const handleDeleteModalAction = (deleteModal) => ({
  type: staffActionTypes.HANDLE_DELETE_MODAL,
  payload: deleteModal,
});

export const handleDocumentModalAction = (documentModal) => ({
  type: staffActionTypes.HANDLE_DOCUMENT_UPLOAD_MODAL,
  payload: documentModal,
});

export const documentUploadStart = (staff_id, id, data) => ({
  type: staffActionTypes.UPLOAD_STAFF_DOCUMENT_START,
  payload: { staff_id, id, data },
});

export const documentUploadSuccess = (data) => ({
  type: staffActionTypes.UPLOAD_STAFF_DOCUMENT_SUCCESS,
  payload: data,
});

export const documentUploadFailure = (error) => ({
  type: staffActionTypes.UPLOAD_STAFF_DOCUMENT_FAILURE,
  payload: error,
});

export const fetchListStaffDocumentsStart = (id) => ({
  type: staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_START,
  payload: id,
});

export const fetchListStaffDocumentsSuccess = (data) => ({
  type: staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_SUCCESS,
  payload: data,
});

export const fetchListStaffDocumentsFailure = (error) => ({
  type: staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_FAILURE,
  payload: error,
});

export const downloadStaffDocumentsStart = (document_name, data) => ({
  type: staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_START,
  payload: { document_name, data },
});

export const downloadStaffDocumentsSuccess = (data) => ({
  type: staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_SUCCESS,
  payload: data,
});

export const downloadStaffDocumentsFailure = (error) => ({
  type: staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_FAILURE,
  payload: error,
});

export const handleDeleteModalDocumentAction = (deleteDocumentModal) => ({
  type: staffActionTypes.HANDLE_DELETE_DOCUMENT_MODAL,
  payload: deleteDocumentModal,
});

export const deleteStaffDocumentsStart = (staff_id, data, fromUser) => ({
  type: staffActionTypes.DELETE_STAFF_DOCUMENTS_START,
  payload: { staff_id, data, fromUser },
});

export const deleteStaffDocumentsSuccess = (data) => ({
  type: staffActionTypes.DELETE_STAFF_DOCUMENTS_SUCCESS,
  payload: data,
});

export const deleteStaffDocumentsFailure = (error) => ({
  type: staffActionTypes.DELETE_STAFF_DOCUMENTS_FAILURE,
  payload: error,
});

export const handleResetModalAction = (resetModal) => ({
  type: staffActionTypes.HANDLE_RESET_MODAL,
  payload: resetModal,
});

export const adminResetPasswordStart = (data) => ({
  type: staffActionTypes.ADMIN_RESET_PASSWORD_START,
  data,
});

export const adminResetPasswordSuccess = (data) => ({
  type: staffActionTypes.ADMIN_RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const adminResetPasswordFailure = (error) => ({
  type: staffActionTypes.ADMIN_RESET_PASSWORD_FAILURE,
  payload: error,
});

export const sendPasswordResetLinkStart = (data) => ({
  type: staffActionTypes.SEND_PASSWORD_RESET_LINK_START,
  data,
});

export const sendPasswordResetLinkSuccess = (data) => ({
  type: staffActionTypes.SEND_PASSWORD_RESET_LINK_SUCCESS,
  payload: data,
});

export const sendPasswordResetLinkFailure = (error) => ({
  type: staffActionTypes.SEND_PASSWORD_RESET_LINK_FAILURE,
  payload: error,
});

export const updateAppStaffState = (payload: IStaffState) => ({
  type: staffActionTypes.UPDATE_STAFF_STATE,
  payload,
});
