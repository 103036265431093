import { put, takeLatest } from 'redux-saga/effects';
import bankActionTypes from '../types';
import {
  createBankAccountSuccess,
  createBankAccountFailure,
  fetchBankAccountsStart,
} from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';
import { setDisableNext } from 'redux/users/actions';

export function* createBankAccounts({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.post('/bank-accounts', payload);

    yield put(createBankAccountSuccess(response.data));
    yield put(fetchBankAccountsStart());
    Notify('Bank Account created successfully', { position: 'top-right' });
    if (payload.default_account === true) {
      callback();
    }
  } catch (error) {
    yield put(createBankAccountFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateBankAccountStart() {
  yield takeLatest(bankActionTypes.CREATE_BANK_ACCOUNT_START, createBankAccounts);
}
