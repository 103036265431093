import { takeLatest, put } from 'redux-saga/effects';
import {
  editSingleDefinitionSuccess,
  editSingleDefinitionFail,
  fetchAllTraitGroupStart,
} from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* editSingleDefinition({ payload }) {
  const id = payload.id;
  const callback = payload.callback;
  delete payload.id;
  delete payload.callback;
  try {
    const response = yield httpClient.put(`/trait-configuration/trait-definitions/${id}`, payload);
    yield put(editSingleDefinitionSuccess(response.data));
    Notify('Trait definition edited succesfully', { position: 'top-right' });
    callback();
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(editSingleDefinitionFail(error.fail));
    const err = handleError(error);
  }
}

export function* onEditSingleDefinitionStart() {
  yield takeLatest(earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_START, editSingleDefinition);
}
