import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { createSkillRatingSuccess, createSkillRatingFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';
import { setDisableNext } from 'redux/users/actions';

export function* createSkillRating({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.post('/cognitive-skill-config/skill-ratings', payload);
    yield put(createSkillRatingSuccess(response.data));

    Notify('Rating created successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createSkillRatingFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateSkillRatingStart() {
  yield takeLatest(cognitiveSkillsActionTypes.CREATE_SKILL_RATING_START, createSkillRating);
}
