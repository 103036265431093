import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import { filterPayableItemsFail, filterPayableItemsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* filterPayableItems({ payload }) {
  try {
    const response = yield httpClient.get('/payable-items/filter', { params: payload });
    yield put(filterPayableItemsSuccess(response.data));
  } catch (error) {
    yield put(filterPayableItemsFail(error?.message));
    handleError(error);
  }
}

export function* onFilterPayableItemsStart() {
  yield takeLatest(configActionTypes.FILTER_PAYABLE_ITEMS_START, filterPayableItems);
}
