import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { fetchChildResultFail, fetchChildResultSuccess } from '../actions';
import parentActionTypes from '../types';

export function* fetchChildResult({ payload }) {
  const params = {
    'student-id': payload.studentId,
    'term-id': payload.termId,
    'result-config-id': payload.configId,
  };
  try {
    const response = yield httpClient.get(`/student-results`, { params });
    yield put(fetchChildResultSuccess(response.data));
  } catch (error) {
    yield put(fetchChildResultFail(error?.message));
  }
}

export function* onFetchingChildResultStart() {
  yield takeLatest(parentActionTypes.FETCH_CHILD_RESULT_START, fetchChildResult);
}
