import React from 'react';
import { Button, Box, Text, Spacer } from '@flexisaf/flexibull2';
import notFound from 'assets/404.svg';
import ghost from 'assets/ghost-strokes.svg';
import singleBg from 'assets/singleBg.svg';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const history = useNavigate();
  return (
    <Box relative pad="30px" style={{ overflow: 'hidden' }} maxHeight="100vh" height="100vh">
      <Box margin="10% auto" width="20vw" align="center">
        <img src={ghost} />
        <Spacer space="20px" />
        <img src={notFound} />
        <Spacer space="20px" />
        <Text block size="16px">
          Oops!! Seems you have wandered into an unknown path, nothing exists here
        </Text>
        <Spacer space="30px" />
        <Button iconRight={<i className="flexibull-angle-right" />} onClick={() => History(-1)}>
          Go Back
        </Button>
      </Box>
      <img src={singleBg} style={{ position: 'absolute', bottom: '-80px', right: '-100px' }} />
    </Box>
  );
};

export default NotFound;
