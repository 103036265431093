import { takeLatest, put } from 'redux-saga/effects';
import { fetchTotalStudentsSuccess, fetchTotalStudentsFail } from '../action';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* getAllStudents() {
  try {
    const response = yield httpClient.get(`/students/all-students`);
    yield put(fetchTotalStudentsSuccess(response.data));
  } catch (error) {
    yield put(fetchTotalStudentsFail(error?.message));
    handleError(error);
  }
}

export function* onGetTotalStudentsStart() {
  yield takeLatest(studentActionTypes.GET_TOTAL_STUDENTS_START, getAllStudents);
}
