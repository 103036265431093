enum cognitiveSkillsActionTypes {
  FETCH_COGNITIVE_SKILLS_START = 'FETCH_COGNITIVE_SKILLS_START',
  FETCH_COGNITIVE_SKILLS_SUCCESS = 'FETCH_COGNITIVE_SKILLS_SUCCESS',
  FETCH_COGNITIVE_SKILLS_FAILURE = 'FETCH_COGNITIVE_SKILLS_FAILURE',

  CREATE_SKILL_GROUP_START = 'CREATE_SKILL_GROUP_START',
  CREATE_SKILL_GROUP_SUCCESS = 'CREATE_SKILL_GROUP_SUCCESS',
  CREATE_SKILL_GROUP_FAILURE = 'CREATE_SKILL_GROUP_FAILURE',

  UPDATE_SKILL_GROUP_START = 'UPDATE_SKILL_GROUP_START',
  UPDATE_SKILL_GROUP_SUCCESS = 'UPDATE_SKILL_GROUP_SUCCESS',
  UPDATE_SKILL_GROUP_FAILURE = 'UPDATE_SKILL_GROUP_FAILURE',

  DELETE_SKILL_GROUP_START = 'DELETE_SKILL_GROUP_START',
  DELETE_SKILL_GROUP_SUCCESS = 'DELETE_SKILL_GROUP_SUCCESS',
  DELETE_SKILL_GROUP_FAILURE = 'DELETE_SKILL_GROUP_FAILURE',

  UPDATE_SKILL_START = 'UPDATE_SKILL_START',
  UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS',
  UPDATE_SKILL_FAILURE = 'UPDATE_SKILL_FAILURE',

  CREATE_SKILL_START = 'CREATE_SKILL_START',
  CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS',
  CREATE_SKILL_FAILURE = 'CREATE_SKILL_FAILURE',

  DELETE_SKILL_START = 'DELETE_SKILL_START',
  DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS',
  DELETE_SKILL_FAILURE = 'DELETE_SKILL_FAILURE',

  CREATE_SKILL_RATING_START = 'CREATE_SKILL_RATING_START',
  CREATE_SKILL_RATING_SUCCESS = 'CREATE_SKILL_RATING_SUCCESS',
  CREATE_SKILL_RATING_FAILURE = 'CREATE_SKILL_RATING_FAILURE',

  EDIT_SKILL_RATING_START = 'EDIT_SKILL_RATING_START',
  EDIT_SKILL_RATING_SUCCESS = 'EDIT_SKILL_RATING_SUCCESS',
  EDIT_SKILL_RATING_FAILURE = 'EDIT_SKILL_RATING_FAILURE',

  DELETE_SKILL_RATING_START = 'DELETE_SKILL_RATING_START',
  DELETE_SKILL_RATING_SUCCESS = 'DELETE_SKILL_RATING_SUCCESS',
  DELETE_SKILL_RATING_FAILURE = 'DELETE_SKILL_RATING_FAILURE',

  MESSAGE_DEFAULTERS_SEND_EMAIL_START = 'MESSAGE_DEFAULTERS_SEND_EMAIL_START',
  MESSAGE_DEFAULTERS_SEND_EMAIL_SUCCESS = 'MESSAGE_DEFAULTERS_SEND_EMAIL_SUCCESS',
  MESSAGE_DEFAULTERS_SEND_EMAIL_FAILURE = 'MESSAGE_DEFAULTERS_SEND_EMAIL_FAILURE',

  FETCH_SKILLS_GROUP_START = 'FETCH_SKILLS_GROUP_START',
  FETCH_SKILLS_GROUP_SUCCESS = 'FETCH_SKILLS_GROUP_SUCCESS',
  FETCH_SKILLS_GROUP_FAILURE = 'FETCH_SKILLS_GROUP_FAILURE',

  FETCH_STUDENT_COGNITIVE_ASSESSMENT_START = 'FETCH_STUDENT_COGNITIVE_ASSESSMENT_START',
  FETCH_STUDENT_COGNITIVE_ASSESSMENT_SUCCESS = 'FETCH_STUDENT_COGNITIVE_ASSESSMENT_SUCCESS',
  FETCH_STUDENT_COGNITIVE_ASSESSMENT_FAILURE = 'FETCH_STUDENT_COGNITIVE_ASSESSMENT_FAILURE',

  FETCH_SKILLS_RATINGS_START = 'FETCH_SKILLS_RATINGS_START',
  FETCH_SKILLS_RATINGS_SUCCESS = 'FETCH_SKILLS_RATINGS_SUCCESS',
  FETCH_SKILLS_RATINGS_FAILURE = 'FETCH_SKILLS_RATINGS_FAILURE',

  SET_ADD_SKILL_GROUP = 'SET_ADD_SKILL_GROUP',
  SET_EDIT_SKILL_GROUP = 'SET_EDIT_SKILL_GROUP',

  SET_ADD_SKILL = 'SET_ADD_SKILL',
  SET_EDIT_SKILL = 'SET_EDIT_SKILL',

  HANDLE_DELETE_GROUP_MODAL = 'HANDLE_DELETE_GROUP_MODAL',

  HANDLE_CREATE_RATING_MODAL = 'HANDLE_CREATE_RATING_MODAL',
  HANDLE_EDIT_RATING_MODAL = 'HANDLE_EDIT_RATING_MODAL',
  HANDLE_DELETE_RATING_MODAL = 'HANDLE_DELETE_RATING_MODAL',

  ASSIGN_SKILL_RATING_START = 'ASSIGN_SKILL_RATING_START',
  ASSIGN_SKILL_RATING_SUCCESS = 'ASSIGN_SKILL_RATING_SUCCESS',
  ASSIGN_SKILL_RATING_FAILURE = 'ASSIGN_SKILL_RATING_FAILURE',
}

export default cognitiveSkillsActionTypes;
