import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { getCheckoutFail, getCheckoutSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* getCheckout({ payload }) {
  try {
    const response = yield httpClient.post(`/checkout`, payload);
    yield put(getCheckoutSuccess(response.data));
  } catch (error) {
    yield put(getCheckoutFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetCheckoutStart() {
  yield takeLatest(invoiceActionTypes.GET_CHECKOUT_START, getCheckout);
}
