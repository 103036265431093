import { all, call } from 'redux-saga/effects';
import { onfetchInternalRolesStart } from './fetchInternalRoles';
import { onfetchUserRolesStart } from './fetchUserRoles';
import { onAssignRolesStart } from './assignRoles';

export function* InternalRolesSagas() {
  yield all([
    call(onfetchInternalRolesStart),
    call(onfetchUserRolesStart),
    call(onAssignRolesStart),
  ]);
}
