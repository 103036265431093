import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { makeDirectPayFail, makeDirectPaySuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* makeDirectPay({ payload }) {
  try {
    const response = yield httpClient.post(`/direct-pay/pay`, payload);
    yield put(makeDirectPaySuccess(response.data));
    window.location.href = response.data.data.link;
  } catch (error) {
    yield put(makeDirectPayFail(error?.message));
    const err = handleError(error);
  }
}

export function* onMakeDirectPayStart() {
  yield takeLatest(invoiceActionTypes.MAKE_DIRECT_PAY_START, makeDirectPay);
}
