import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { createSkillSuccess, createSkillFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* createSkill({ payload }) {
  try {
    const response = yield httpClient.post('/cognitive-skill-config/skill-groups/skills', payload);
    yield put(createSkillSuccess(response.data, payload.skill_group_id));

    Notify('Skill added successfully', { position: 'top-right' });
  } catch (error) {
    yield put(createSkillFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateSkillStart() {
  yield takeLatest(cognitiveSkillsActionTypes.CREATE_SKILL_START, createSkill);
}
