import { Notify } from '@flexisaf/flexibull2';
import { SchoolFeesPaymentsRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient, { apiWrapper } from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  deleteInvoiceItemFail,
  deleteInvoiceItemSuccess,
  fetchStudentIncoiceSummaryStart,
} from '../actions';
import invoiceActionTypes from '../types';

export function* deleteItem({ payload }) {
  const { student_id, term_id, item_id, session_id } = payload;

  const params = {
    'student-id': student_id,
    'term-id': term_id,
    'payable-item-id': item_id,
  };

  try {
    const receipts = yield apiWrapper(() =>
      SchoolFeesPaymentsRestControllerService.getPaymentReceiptUsingGet1({
        studentId: student_id,
        sessionId: session_id,
      }),
    );

    if (receipts) {
      const exists = !!receipts.find((item) =>
        item.student_bill_data?.student_bill?.find((data) => data.payable_item?.id === item_id),
      );

      if (!exists) {
        const response = yield httpClient.delete(`/students-invoice/bill-item`, { params });
        yield put(deleteInvoiceItemSuccess(item_id));
        yield put(fetchStudentIncoiceSummaryStart(student_id));
        Notify('Item deleted successfully', { position: 'top-right' });
      } else {
        yield put(deleteInvoiceItemFail('Payment has already been made for this item.'));
        Notify('Payment has already been made for this item.', { status: 'error' });
      }
    }
  } catch (error) {
    yield put(deleteInvoiceItemFail(error?.message));
    handleError(error);
  }
}

export function* onDeleteItemFromInvoice() {
  yield takeLatest(invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_START, deleteItem);
}
