import { put, takeLatest } from 'redux-saga/effects';
import bankActionTypes from '../types';
import {
  deleteBankAccountSuccess,
  deleteBankAccountFailure,
  fetchBankAccountsStart,
} from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* deleteBankAccounts({ payload }) {
  try {
    const response = yield httpClient.delete(`/bank-accounts/${payload}`);

    yield put(deleteBankAccountSuccess(payload));
    yield put(fetchBankAccountsStart());
    Notify('Bank Account deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteBankAccountFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteBankAccountStart() {
  yield takeLatest(bankActionTypes.DELETE_BANK_ACCOUNT_START, deleteBankAccounts);
}
