import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { createSkillGroupSuccess, createSkillGroupFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';
import { setDisableNext } from 'redux/users/actions';

export function* createSkillGroup({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.post('/cognitive-skill-config/skill-groups', payload);
    yield put(createSkillGroupSuccess(response.data));

    Notify('Skill Group created successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createSkillGroupFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateSkillGroupStart() {
  yield takeLatest(cognitiveSkillsActionTypes.CREATE_SKILL_GROUP_START, createSkillGroup);
}
