import { takeLatest, put } from 'redux-saga/effects';
import { filterStudentsSuccess, filterStudentsFailure } from '../action';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* filterStudents({ payload }) {
  try {
    const response = yield httpClient.get('/students/filter', { params: payload });
    yield put(filterStudentsSuccess(response.data));
  } catch (error) {
    yield put(filterStudentsFailure(error?.message));
    handleError(error);
  }
}

export function* onfilterStudentsStart() {
  yield takeLatest(studentActionTypes.FILTER_STUDENTS_START, filterStudents);
}
