import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchRatingFail, fetchRatingSuccess } from '../actions';
import earlyYearsActionTypes from '../types';

function* fetchAllRatings({ payload }) {
  try {
    const response = yield httpClient.get('/trait-configuration/trait-ratings');
    yield put(fetchRatingSuccess(response.data));
    payload?.callback?.(response.data);
  } catch (error) {
    yield put(fetchRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onFetchAllRatingsStart() {
  yield takeLatest(earlyYearsActionTypes.FETCH_RATING_START, fetchAllRatings);
}
