import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { getClassMemberStart } from 'redux/configuration/actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { setStudentSubjectProfileFailure, setStudentSubjectProfileSuccess } from '../actions';
import subjectsActionTypes from '../types';

export function* setStudentSubjectProfile({ payload }) {
  try {
    const response = yield httpClient.put(`/class-members/assign-subjects`, payload.data);
    yield put(setStudentSubjectProfileSuccess(payload.data));
    const { term_id, class_level_id, arm_id } = payload.data;
    yield put(getClassMemberStart(class_level_id, arm_id, '', term_id));
    Notify('Your changes have been saved successfully.', { position: 'top-right' });
    payload.callback();
  } catch (error) {
    yield put(setStudentSubjectProfileFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onSetStudentSubjectProfileStart() {
  yield takeLatest(subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_START, setStudentSubjectProfile);
}
