import { Box, Button, Grid, Modal, ModalBody } from '@flexisaf/flexibull2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateServiceWorker } from './redux/service-workers/actions';
import {
  selectIsServiceWorkerInitialized,
  selectIsServiceWorkerUpdateAvailable,
  selectServiceWorkerRegistration,
} from './redux/service-workers/selectors';

const AppServiceWorker = () => {
  const dispatch = useDispatch();
  const [updatingSW, setUpdatingSW] = useState(false);

  const serviceWorkerRegistration = useSelector(selectServiceWorkerRegistration);
  const isServiceWorkerInitialized = useSelector(selectIsServiceWorkerInitialized);
  const isServiceWorkerUpdateAvailable = useSelector(selectIsServiceWorkerUpdateAvailable);

  const closeUpdate = () => {
    if (updatingSW) return;
    dispatch(updateServiceWorker(false));
  };

  const updateServiceApp = () => {
    const registrationWaiting = serviceWorkerRegistration?.waiting;
    if (registrationWaiting) {
      setUpdatingSW(true);
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          closeUpdate();
          window.location.reload(true);
        }
      });
      window.location.reload(true);
    } else {
      closeUpdate();
    }
  };

  useEffect(() => {
    if (isServiceWorkerInitialized) {
      updateServiceApp();
    }
    // eslint-disable-next-line
  }, [isServiceWorkerInitialized]);

  // if (!isServiceWorkerUpdateAvailable) {
  //   return <BrowserCheckModal />;
  // }

  return (
    <Modal onClose={closeUpdate} open={isServiceWorkerUpdateAvailable}>
      <ModalBody
        bgColor="#fff"
        width="350px"
        style={{
          boxShadow: '#4e399212 -4px 0px 20px 10px',
        }}
      >
        <Box pad="20px 30px 0 30px">
          <h2>New Update Available</h2>
        </Box>
        <Box pad="0 30px 30px 30px">
          <Box margin="20px 0 30px" align="center">
            <p>Hello there,</p>
            <p>You can install SAFSIMS on your device for easier access.</p>
          </Box>

          <Grid default="1fr 1fr" margin="0 0 0 auto">
            <Button height={50} pale color="transparent" fontColor="#cc" onClick={closeUpdate}>
              Dismiss
            </Button>
            <Button progress={updatingSW} height={45} onClick={updateServiceApp}>
              Update app
            </Button>
          </Grid>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default AppServiceWorker;
