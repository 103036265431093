import { put, takeLatest } from 'redux-saga/effects';
import ReportTemplateActionTypes from '../types';
import { fetchReportTemplateSuccess, fetchReportTemplateFail } from '../actions';
import httpClient from '../../../utils/http-client';

export function* fetchReportTemplates() {
  try {
    const response = yield httpClient.get('/report-templates');
    yield put(fetchReportTemplateSuccess(response?.data));
  } catch (error) {
    yield put(fetchReportTemplateFail(error?.message));
  }
}

export function* onFetchReportTemplatesStart() {
  yield takeLatest(ReportTemplateActionTypes.FETCH_REPORT_TEMPLATE_START, fetchReportTemplates);
}
