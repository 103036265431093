import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { editStaffFailure, editStaffSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* rejectProfileEdit({ payload }) {
  try {
    const response = yield httpClient.put(`/staff/profile-edit/${payload.request_id}`, {
      declineReason: payload.message,
      status: 'DECLINED',
    });
    yield put(editStaffSuccess(response.data));
    Notify("Staff's profile edit rejected.", { position: 'top-right' });
    payload.history(-1);
  } catch (error) {
    yield put(editStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onRejectProfileEdit() {
  yield takeLatest(staffActionTypes.REJECT_STAFF_EDIT_REQUEST, rejectProfileEdit);
}
