import { IBankState } from './reducers';
import bankActionTypes from './types';

export const fetchBankAccountsStart = () => ({
  type: bankActionTypes.FETCH_BANK_ACCOUNTS_START,
  payload: {},
});

export const fetchBankAccountsSuccess = (data) => ({
  type: bankActionTypes.FETCH_BANK_ACCOUNTS_SUCCESS,
  payload: data,
});

export const fetchBankAccountsFailure = (error) => ({
  type: bankActionTypes.FETCH_BANK_ACCOUNTS_FAILURE,
  payload: error,
});

export const fetchListOfBanksStart = () => ({
  type: bankActionTypes.FETCH_LIST_BANKS_START,
  payload: {},
});

export const fetchListOfBanksSuccess = (data) => ({
  type: bankActionTypes.FETCH_LIST_BANKS_SUCCESS,
  payload: data,
});

export const fetchListOfBanksFailure = (error) => ({
  type: bankActionTypes.FETCH_LIST_BANKS_FAILURE,
  payload: error,
});

export const createBankAccountStart = (data) => ({
  type: bankActionTypes.CREATE_BANK_ACCOUNT_START,
  payload: data,
});

export const createBankAccountSuccess = (data) => ({
  type: bankActionTypes.CREATE_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const createBankAccountFailure = (error) => ({
  type: bankActionTypes.CREATE_BANK_ACCOUNT_FAILURE,
  payload: error,
});

export const editBankAccountStart = (data) => ({
  type: bankActionTypes.EDIT_BANK_ACCOUNT_START,
  payload: data,
});

export const editBankAccountSuccess = (data) => ({
  type: bankActionTypes.EDIT_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const editBankAccountFailure = (error) => ({
  type: bankActionTypes.EDIT_BANK_ACCOUNT_FAILURE,
  payload: error,
});

export const deleteBankAccountStart = (id) => ({
  type: bankActionTypes.DELETE_BANK_ACCOUNT_START,
  payload: id,
});

export const deleteBankAccountSuccess = (data) => ({
  type: bankActionTypes.DELETE_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const deleteBankAccountFailure = (error) => ({
  type: bankActionTypes.DELETE_BANK_ACCOUNT_FAILURE,
  payload: error,
});

export const handleModalAction = (modal, editing) => ({
  type: bankActionTypes.HANDLE_BANK_ACCOUNT_MODAL,
  payload: { modal, editing },
});

export const handleDeleteModalAction = (deleteModal) => ({
  type: bankActionTypes.HANDLE_BANK_ACCOUNT_DELETE_MODAL,
  payload: deleteModal,
});

export const handleDefaultAccountModal = (data) => ({
  type: bankActionTypes.HANDLE_DEFAULT_ACCOUNT_MODAL,
  payload: data,
});

export const assignDefaultAccountStart = (id) => ({
  type: bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_START,
  payload: id,
});

export const assignDefaultAccountSuccess = (data) => ({
  type: bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_SUCCESS,
  payload: data,
});

export const assignDefaultAccountFailure = (error) => ({
  type: bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_FAILURE,
  payload: error,
});

export const retrySubAccountStart = (data) => ({
  type: bankActionTypes.RETRY_SUB_ACCOUNT_START,
  payload: data,
});

export const retrySubAccountSuccess = (data) => ({
  type: bankActionTypes.RETRY_SUB_ACCOUNT_SUCCESS,
  payload: data,
});

export const retrySubAccountFailure = (error) => ({
  type: bankActionTypes.RETRY_SUB_ACCOUNT_FAILURE,
  payload: error,
});

export const saveBankData = (data) => ({
  type: bankActionTypes.SAVE_BANK_DATA,
  payload: data,
});

export const updateBankState = (payload: IBankState) => ({
  type: bankActionTypes.UPDATE_BANK_STATE,
});
