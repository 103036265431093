import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { termProfileSetGradeFormatSuccess, termProfileSetGradeFormatFail } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* termProfileSetGradeFormat({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.post('/grade-format/termly', payload);
    yield put(termProfileSetGradeFormatSuccess(response.data));
    callback();
  } catch (error) {
    yield put(termProfileSetGradeFormatFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTermProfileSetGradeFormat() {
  yield takeLatest(
    configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_START,
    termProfileSetGradeFormat,
  );
}
