import { Notify } from '@flexisaf/flexibull2';
import { StaffRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { editCurrentUserDetails } from 'redux/users/actions';
import { apiWrapper } from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { editStaffFailure, editStaffSuccess } from '../actions';
import staffActionTypes from '../types';

export function* editStaff({ payload }) {
  const isCurrentUser = payload.isCurrentUser;
  delete payload.isCurrentUser;

  try {
    const response = yield apiWrapper(() =>
      StaffRestControllerService.updateStaffUsingPut({ dto: payload }),
    );
    yield put(editStaffSuccess(response));
    Notify('Staff Details Edited successfully', { position: 'top-right' });
    if (isCurrentUser) {
      yield put(editCurrentUserDetails(payload));
    }
  } catch (error) {
    yield put(editStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditStaffStart() {
  yield takeLatest(staffActionTypes.EDIT_STAFF_START, editStaff);
}
