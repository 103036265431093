import invoiceSummariesActionTypes from './types';

export const fetchInvoicesSummariesStart = (data) => ({
  type: invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_START,
  payload: data,
});

export const fetchInvoicesSummariesSuccess = (data) => ({
  type: invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_SUCCESS,
  payload: data,
});

export const fetchInvoicesSummariesFailure = (error) => ({
  type: invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_FAILURE,
  payload: error,
});

export const messageDefaultersSendSMSStart = (data) => ({
  type: invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_START,
  payload: data,
});

export const messageDefaultersSsendSMSSuccess = (data) => ({
  type: invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_SUCCESS,
  payload: data,
});

export const messageDefaultersSsendSMSFailure = (error) => ({
  type: invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_SMS_FAILURE,
  payload: error,
});

export const messageDefaultersSendEmailStart = (data) => ({
  type: invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_START,
  payload: data,
});

export const messageDefaultersSsendEmailSuccess = (data) => ({
  type: invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_SUCCESS,
  payload: data,
});

export const messageDefaultersSsendEmailFailure = (error) => ({
  type: invoiceSummariesActionTypes.MESSAGE_DEFAULTERS_SEND_EMAIL_FAILURE,
  payload: error,
});

export const setActiveSummary = (summary) => ({
  type: invoiceSummariesActionTypes.SET_ACTIVE_INVOICE_SUMMARY,
  payload: summary,
});

export const setDefaultersModalAction = (defaultersModal) => ({
  type: invoiceSummariesActionTypes.HANDLE_MESSAGE_DEFAULTS_MODAL,
  payload: defaultersModal,
});
