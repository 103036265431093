import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { termProfileSetDateRangeSuccess, termProfileSetDateRangeFail } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* termProfileSetDateRange({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.post('/date-range/termly', payload);
    yield put(termProfileSetDateRangeSuccess(response.data));
    callback();
  } catch (error) {
    yield put(termProfileSetDateRangeFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTermProfileSetDateRange() {
  yield takeLatest(configActionTypes.TERM_PROFILE_SET_DATE_RANGE_START, termProfileSetDateRange);
}
