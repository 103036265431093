import { Theme } from 'config/theme';
import { lighten, transparentize } from 'polished';
import styled from 'styled-components';

export const Orbs = styled.div`
  height: ${(props) => (props.size ? props.size : '5px')};
  width: ${(props) => (props.size ? props.size : '5px')};
  background-color: ${(props) => (props.orbColor ? props.orbColor : Theme.PrimaryGrey)};
  border-radius: 50%;
  display: inline-block;
`;

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  border-radius: 0 0 ${Theme.SecondaryRadius} ${Theme.SecondaryRadius};
  border-top: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${transparentize(0.8, Theme.PrimaryFontColor)};
    border-radius: 20px;
    display: block;
    border: 2px solid ${Theme.PrimaryFade};
  }
`;

export const SubjectContainer = styled.div`
  border-radius: ${Theme.SecondaryRadius};
  border: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};
  width: 100%;
  background: #fff;

  & .school-info {
    display: none;
  }
  & .print_table {
    display: none;
  }

  @media print {
    width: 98%;

    @page {
      margin: 0.5cm;
    }

    & .print_table {
      display: block;
    }

    & table {
      font-size: 10px;

      & thead {
        & th {
          padding: 3px;
        }
      }

      & tbody {
        & tr {
          & td {
            padding: 3px;
          }
        }
      }
    }

    margin: 20px auto;
    & .school-info {
      display: grid;
      grid-template-columns: auto max-content;
      align-items: flex-end;
      padding: 20px;
      & .school-info-left img {
        max-height: 80px;
        display: block;
        margin-bottom: 10px;
      }
      & .school-info-right {
        text-align: right;
      }
    }
    @page {
      size: landscape;
    }
  }
`;

export const Table = styled.table`
  white-space: nowrap;
  margin: 0;
  border: none;
  /* border-collapse: collapse; */
  border-spacing: 0;
  table-layout: fixed;
  background-color: #fff;
  position: relative;
  /* width: 100%; */

  @media print {
    display: none;
  }

  & thead {
    border-bottom: 1px solid ${(props) => lighten(0.1, props.theme.PrimaryGreyLight)};
    border-radius: ${Theme.SecondaryRadius} ${Theme.SecondaryRadius} 0 0;

    & tr {
      & th {
        text-align: center;
        font-weight: bold;
        padding: 20px 15px;
        position: sticky;
        top: 0;
        z-index: 1;
        background: #fff;
        border-left: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};
        @media print {
          padding: 20px 10px;
        }
      }
      &:first-of-type {
        & th {
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
            border-left: none;
            border-right: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};
            text-align: left;
            @media print {
              display: none;
            }
          }
        }
      }

      &:last-of-type {
        border-top: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};
      }
    }
  }

  & tbody {
    & tr {
      border-bottom: none;
      &:hover {
        background-color: ${transparentize(0.9, Theme.PrimaryColor)};
      }

      &:nth-child(odd) {
        background-color: ${transparentize(0.9, Theme.PrimaryGrey)};

        & td {
          &:first-child {
            background-color: ${lighten(0.3, Theme.PrimaryGrey)};
          }
        }

        &:hover {
          background-color: ${transparentize(0.9, Theme.PrimaryColor)};

          & td {
            &:first-child {
              background-color: ${lighten(0.45, Theme.PrimaryColor)};
            }
          }
        }
      }

      &:nth-child(even) {
        & td {
          &:first-child {
            background-color: #fff;
          }
        }

        &:hover {
          background-color: ${transparentize(0.9, Theme.PrimaryColor)};

          & td {
            &:first-child {
              background-color: ${lighten(0.45, Theme.PrimaryColor)};
            }
          }
        }
      }

      & td {
        padding: 15px;
        border-left: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};
        text-align: center;

        &:first-child {
          font-weight: 500;
          text-align: left;
          position: relative;
          padding: 15px;
          position: sticky;
          left: 0;
          border-left: none;
          border-right: 1px solid ${lighten(0.2, Theme.PrimaryGrey)};
          z-index: 2;
          @media print {
            display: none;
            padding: 8px;
          }
          /* background: white; */

          /* &:nth-child(odd) {
            background-color: ${lighten(0.3, Theme.PrimaryGrey)};
          } */
        }
      }
    }
  }
`;

export const NakedTable = styled.table`
  /* white-space: nowrap; */
  margin: 0;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  /* table-layout: fixed; */
  background-color: transparent;
  /* width: 100%; */

  & thead {
    border-bottom: 1px solid #d8d8e3;

    & th {
      font-weight: bold;
      padding: 8px 15px;
      background: transparent;
      border-right: 1px solid #d8d8e3;
      text-align: left;

      &:last-child {
        border-right: none;
      }
    }
  }

  & tbody {
    & tr {
      border-bottom: 1px solid #d8d8e3;

      &:last-child {
        border-bottom: none;
      }

      & td {
        padding: 15px;
        border-right: 1px solid #d8d8e3;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
`;

export const TileTable = styled.table`
  width: 100%;
  background-color: #fafafa;
  border-collapse: collapse;

  & thead {
    border-bottom: 1px solid ${Theme.PrimaryBorderColor};

    & th {
      padding: 15px;
      border-right: 1px solid ${Theme.PrimaryBorderColor};
      text-align: center;
      color: ${Theme.PrimaryGrey};
      font-weight: 400;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        border-right: none;
        text-align: left;
      }
    }
  }

  & tbody {
    width: 100%;
    border-bottom: 1px solid ${Theme.PrimaryBorderColor};

    & tr {
      border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};
      background-color: #fafafa;

      &.group-header {
        /* background-color: ${lighten(0.25, Theme.PrimaryGrey)}; */
      }

      &.group-listing {
        background-color: ${Theme.PrimaryWhite};

        &:nth-child(even) {
          background-color: ${Theme.PrimaryWhite};
        }
      }

      &:nth-child(even) {
        background-color: ${lighten(0.303, Theme.PrimaryGrey)};
      }

      & td {
        padding: 10px;
        vertical-align: middle;

        &:first-child {
          font-weight: 900;
          padding: 10px 15px;
        }
      }
    }
  }
`;

export const ApprovalTable = styled.table`
  /* border-top: 1px solid ${(props) => props.theme.PrimaryBorderColor}; */
  width: 100%;
  /* border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor}; */
  border-collapse: collapse;

  & thead {
    & tr {
      &:first-of-type {
        & th {
          border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          text-align: left;
          padding: 20px 10px;
          border-top: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          &:first-child {
            border-top: none;
            border-right: none;
            border-bottom: none;
          }
          &:nth-child(2) {
            border-left: 1px solid ${(props) => props.theme.PrimaryBorderColor};
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
            border-top-right-radius: 10px;
          }
        }
      }

      &:nth-of-type(2) {
        th {
          border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          text-align: left;
          padding: 10px;
          border-top: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          &:first-child {
            border-top: none;
            border-left: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          }
          &:last-child {
            border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          }
        }
      }
    }
  }
  & tbody {
    & tr {
      background: ${(props) => transparentize(0.5, props.theme.PrimaryFade)};
      & td {
        padding: 10px;
        border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
        border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};
        vertical-align: middle;
        white-space: nowrap;

        &:first-child {
          border: none;
          border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          border-bottom: none;
        }
      }
      &:last-child {
        border-bottom: none;
        border-right: 1px solid ${(props) => props.theme.PrimaryBorderColor};
      }
      &:nth-child(even) {
        background: ${(props) => transparentize(0.08, props.theme.PrimaryFade)};
      }
    }
  }
  & li {
    padding: 10px;
    border-bottom: 1px dotted ${(props) => props.theme.PrimaryBorderColor};
    list-style: none;
    &:last-child {
      border-bottom: none;
    }
  }
  .pd-0 {
    padding: 0;
  }
  .ct {
    text-align: center;
  }
`;

export const ResultTable = styled.table`
  min-width: 1000px !important;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  & thead {
    background: #fafafa;
    /* border: 1px solid #D8D8E3; */
    margin-bottom: 16px;
    width: 100%;
    display: table-header-group;
    vertical-align: middle;

    & th {
      border: 1px solid #d8d8e3;
      padding: 30px;
      font-size: 12px;
      font-weight: bold;
      border-right: none;
      color: ${Theme.PrimaryGrey};

      &:first-child {
        border-right: none;
        border-radius: 5px 0 0 5px;
        text-align: left;
      }

      &:last-child {
        border-left: 1px solid #d8d8e3;
        border-right: 1px solid #d8d8e3;
        border-radius: 0 5px 5px 0;
        text-align: left;
      }
    }
  }

  & tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    & tr {
      border: none;
      margin-bottom: 10px;
      background: rgba(255, 255, 255, 0.58);
      cursor: pointer;
      &:nth-child(odd) {
        background: #fff;
      }
      & td {
        text-align: center;
        padding: 20px 30px;

        &:first-child {
          text-align: left;
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          text-align: left;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
`;

export const MobileResult = styled.div`
  background-color: #fff;
  border: 1px solid #d0d5d9;
  border-radius: ${Theme.PrimaryRadius};
  margin-bottom: 10px;

  & .subjectHeader {
    padding: 15px 10px;
    border-bottom: 1px solid #d0d5d9;
  }
  & .subjectResult {
    text-align: center;
    border-right: 1px solid #d0d5d9;
    width: 100%;
    & .assessmentName {
      padding: 10px;
      border-bottom: 1px solid #d0d5d9;
    }
    & .assessmentScore {
      padding: 10px;
    }
  }
  & .subjectComment {
    border-top: 1px solid #d0d5d9;
    width: 100%;
    padding: 5px 10px;
  }
`;

const StyledTable = (props) => {
  return (
    <Wrapper>
      <Table>{props.children}</Table>
    </Wrapper>
  );
};

export default StyledTable;
