import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { verifyPaymentFail, verifyPaymentSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* verifyPayment({ payload }) {
  try {
    const response = yield httpClient.post(`/flutterwave/verify-payment/${payload}`);
    yield put(verifyPaymentSuccess(response.data));
  } catch (error) {
    yield put(verifyPaymentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onVerifyPaymentStart() {
  yield takeLatest(invoiceActionTypes.VERIFY_PAYMENT_START, verifyPayment);
}
