import { put, takeLatest } from 'redux-saga/effects';
import subjectsActionTypes from '../types';
import { deleteSubjectSuccess, deleteSubjectFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* deleteSubject({ payload }) {
  try {
    const response = yield httpClient.delete(`subjects/${payload}`);
    yield put(deleteSubjectSuccess(payload));
    Notify('Subject deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteSubjectFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteSubjectStart() {
  yield takeLatest(subjectsActionTypes.DELETE_SUBJECT_START, deleteSubject);
}
