import { put, takeLatest } from 'redux-saga/effects';
import bankActionTypes from '../types';
import {
  assignDefaultAccountSuccess,
  assignDefaultAccountFailure,
  fetchBankAccountsStart,
} from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* assignAccount({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.post(`/bank-accounts/default-account/${payload.id}`);

    yield put(assignDefaultAccountSuccess(response.data));
    yield put(fetchBankAccountsStart());
    Notify('Default Account set successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(assignDefaultAccountFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onAssignDefaultAccountStart() {
  yield takeLatest(bankActionTypes.ASSIGN_DEFAULT_ACCOUNT_START, assignAccount);
}
