import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import httpClient from '../../../utils/http-client';
import { sendPasswordResetLinkSuccess, sendPasswordResetLinkFailure } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* sendPasswordResetLink({ data }) {
  try {
    const response = yield httpClient.post(`auth/password/reset-request`, data);

    yield put(sendPasswordResetLinkSuccess(response.data));
    Notify('Password Reset Link Sent successfully', { position: 'top-right' });
  } catch (error) {
    yield put(sendPasswordResetLinkFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onSendPasswordResetLinkStart() {
  yield takeLatest(userActionTypes.SEND_PASSWORD_RESET_LINK_START, sendPasswordResetLink);
}
