import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchInvoicesSuccess, fetchInvoicesFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchInvoices({ payload }) {
  if (!payload.term_id) {
    yield put(fetchInvoicesFailure());
  } else {
    try {
      const response = yield httpClient.get(`/invoice-templates/${payload.term_id}`);
      yield put(fetchInvoicesSuccess(response.data));
    } catch (error) {
      yield put(fetchInvoicesFailure(error?.message));
      const err = handleError(error);
    }
  }
}

export function* onfetchInvoicesStart() {
  yield takeLatest(invoiceActionTypes.FETCH_INVOICES_START, fetchInvoices);
}
