import subjectsActionTypes from './types';

export const createSubjectStart = (data) => ({
  type: subjectsActionTypes.CREATE_SUBJECT_START,
  payload: data,
});

export const createSubjectSuccess = (data) => ({
  type: subjectsActionTypes.CREATE_SUBJECT_SUCCESS,
  payload: data,
});

export const createSubjectFailure = (error) => ({
  type: subjectsActionTypes.CREATE_SUBJECT_FAILURE,
  payload: error,
});

export const fetchSubjectsStart = (data) => ({
  type: subjectsActionTypes.FETCH_SUBJECTS_START,
  payload: data,
});

export const fetchSubjectsSuccess = (data) => ({
  type: subjectsActionTypes.FETCH_SUBJECTS_SUCCESS,
  payload: data,
});

export const fetchSubjectsFailure = (error) => ({
  type: subjectsActionTypes.FETCH_SUBJECTS_FAILURE,
  payload: error,
});

export const editSubjectStart = (data) => ({
  type: subjectsActionTypes.EDIT_SUBJECT_START,
  payload: data,
});

export const editSubjectSuccess = (data) => ({
  type: subjectsActionTypes.EDIT_SUBJECT_SUCCESS,
  payload: data,
});

export const editSubjectFailure = (error) => ({
  type: subjectsActionTypes.EDIT_SUBJECT_FAILURE,
  payload: error,
});

export const deleteSubjectStart = (id) => ({
  type: subjectsActionTypes.DELETE_SUBJECT_START,
  payload: id,
});

export const deleteSubjectSuccess = (data) => ({
  type: subjectsActionTypes.DELETE_SUBJECT_SUCCESS,
  payload: data,
});

export const deleteSubjectFailure = (error) => ({
  type: subjectsActionTypes.DELETE_SUBJECT_FAILURE,
  payload: error,
});

export const handleModalAction = (modal, editing) => ({
  type: subjectsActionTypes.HANDLE_MODAL,
  payload: { modal, editing },
});

export const handleDeleteModalAction = (deleteModal) => ({
  type: subjectsActionTypes.HANDLE_DELETE_MODAL,
  payload: deleteModal,
});

export const getLevelSubjectsStart = (data) => ({
  type: subjectsActionTypes.GET_LEVEL_SUBJECTS_START,
  payload: data,
});

export const getLevelSubjectsSuccess = (data) => ({
  type: subjectsActionTypes.GET_LEVEL_SUBJECTS_SUCCESS,
  payload: data,
});

export const getLevelSubjectsFailure = (error) => ({
  type: subjectsActionTypes.GET_LEVEL_SUBJECTS_FAILURE,
  payload: error,
});

export const handleAssignSubjectTeacherModal = (subjectTeacherModal, editing) => ({
  type: subjectsActionTypes.HANDLE_ASSIGN_SUBJECT_TEACHER_MODAL,
  payload: { subjectTeacherModal, editing },
});

export const assignSubjectTeachersStart = (data) => ({
  type: subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_START,
  payload: data,
});

export const assignSubjectTeachersSuccess = (data, id) => ({
  type: subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_SUCCCESS,
  payload: { data, id },
});

export const assignSubjectTeachersFail = (error) => ({
  type: subjectsActionTypes.ASSIGN_SUBJECT_TEACHERS_FAIL,
  payload: error,
});

export const editSubjectTeachersStart = (data) => ({
  type: subjectsActionTypes.EDIT_SUBJECT_TEACHERS_START,
  payload: data,
});

export const editSubjectTeachersSuccess = (data, id) => ({
  type: subjectsActionTypes.EDIT_SUBJECT_TEACHERS_SUCCESS,
  payload: { data, id },
});

export const editSubjectTeachersFailure = (error) => ({
  type: subjectsActionTypes.EDIT_SUBJECT_TEACHERS_FAILURE,
  payload: error,
});

export const setStudentSubjectProfileStart = (data) => ({
  type: subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_START,
  payload: data,
});

export const setStudentSubjectProfileSuccess = (data) => ({
  type: subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_SUCCESS,
  payload: data,
});

export const setStudentSubjectProfileFailure = (error) => ({
  type: subjectsActionTypes.SET_STUDENT_SUBJECT_PROFILE_FAILURE,
  payload: error,
});

export const getSubjectResultStart = (termId, classLevelId, armId, subjectCode) => ({
  type: subjectsActionTypes.GET_SUBJECT_RESULT_START,
  payload: { termId, classLevelId, armId, subjectCode },
});

export const getSubjectResultSuccess = (data) => ({
  type: subjectsActionTypes.GET_SUBJECT_RESULT_SUCCESS,
  payload: data,
});

export const getSubjectResultFailure = (error) => ({
  type: subjectsActionTypes.GET_SUBJECT_RESULT_FAIL,
  payload: error,
});

export const handleSetStudentSubjectModal = (studentSubjectModal) => ({
  type: subjectsActionTypes.HANDLE_SET_STUDENT_SUBJECT_MODAL,
  payload: studentSubjectModal,
});
