import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  bulkParentUploadFailure,
  bulkParentUploadSuccess,
  fetchParentsStart,
  stopParentBulkLoadingAction,
} from '../actions';
import parentActionTypes from '../types';

export function* bulkParentUpload({ payload }) {
  const { filename, data } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post('/parents/uploads/template', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();
      yield put(stopParentBulkLoadingAction());

      Notify('Something went wrong, please check the downloaded excel file and try again', {
        status: 'info',
      });
    } else {
      yield put(bulkParentUploadSuccess(response.data));
      const data = {
        offset: 0,
        limit: 10,
      };
      yield put(fetchParentsStart(data));

      Notify('Parent Bulk Uploaded successfully', { position: 'top-right' });
    }
  } catch (error) {
    yield put(bulkParentUploadFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onBulkParentUploadStart() {
  yield takeLatest(parentActionTypes.BULK_PARENT_UPLOAD_START, bulkParentUpload);
}
