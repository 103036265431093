/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubjectTraitApprovalLogDto } from '../models/SubjectTraitApprovalLogDto';
import type { SubjectTraitApprovalLogHistoryResponse } from '../models/SubjectTraitApprovalLogHistoryResponse';
import type { SubjectTraitApprovalLogRequest } from '../models/SubjectTraitApprovalLogRequest';
import type { SubjectTraitApprovalLogStatusResponse } from '../models/SubjectTraitApprovalLogStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubjectTraitApprovalLogRestControllerService {
  /**
   * approveStudentTraits
   * @returns SubjectTraitApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static approveStudentTraitsUsingPost1({
    request,
  }: {
    /**
     * request
     */
    request: SubjectTraitApprovalLogRequest;
  }): CancelablePromise<Array<SubjectTraitApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-trait-approvals/approve',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassApprovalHistory
   * @returns SubjectTraitApprovalLogHistoryResponse OK
   * @throws ApiError
   */
  public static getClassApprovalHistoryUsingGet3({
    armId,
    classLevelId,
    termId,
  }: {
    /**
     * armId
     */
    armId: string;
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * termId
     */
    termId: string;
  }): CancelablePromise<Array<SubjectTraitApprovalLogHistoryResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subject-trait-approvals/history/term/{termId}/class/{classLevelId}/arm/{armId}',
      path: {
        armId: armId,
        classLevelId: classLevelId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recallStudentTraits
   * @returns SubjectTraitApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static recallStudentTraitsUsingPost1({
    request,
  }: {
    /**
     * request
     */
    request: SubjectTraitApprovalLogRequest;
  }): CancelablePromise<Array<SubjectTraitApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-trait-approvals/recall',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassMembersApprovalStatus
   * @returns SubjectTraitApprovalLogStatusResponse OK
   * @throws ApiError
   */
  public static getClassMembersApprovalStatusUsingGet3({
    armId,
    classLevelId,
    termId,
  }: {
    /**
     * armId
     */
    armId: string;
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * termId
     */
    termId: string;
  }): CancelablePromise<Array<SubjectTraitApprovalLogStatusResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subject-trait-approvals/term/{termId}/class/{classLevelId}/arm/{armId}',
      path: {
        armId: armId,
        classLevelId: classLevelId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
