/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassLevelSubjectScoreApprovalLogRequest } from '../models/ClassLevelSubjectScoreApprovalLogRequest';
import type { SubjectScoreApprovalLogDto } from '../models/SubjectScoreApprovalLogDto';
import type { SubjectScoreApprovalLogHistoryResponse } from '../models/SubjectScoreApprovalLogHistoryResponse';
import type { SubjectScoreApprovalLogRequest } from '../models/SubjectScoreApprovalLogRequest';
import type { SubjectScoreApprovalLogStatusResponse } from '../models/SubjectScoreApprovalLogStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubjectScoreApprovalLogRestControllerService {
  /**
   * approveSubjectScores
   * @returns SubjectScoreApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static approveSubjectScoresUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: SubjectScoreApprovalLogRequest;
  }): CancelablePromise<Array<SubjectScoreApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-score-approvals/approve',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * approveClassLevelSubjectScores
   * @returns any OK
   * @throws ApiError
   */
  public static approveClassLevelSubjectScoresUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: ClassLevelSubjectScoreApprovalLogRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-score-approvals/approve-all',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassApprovalHistory
   * @returns SubjectScoreApprovalLogHistoryResponse OK
   * @throws ApiError
   */
  public static getClassApprovalHistoryUsingGet2({
    classLevelId,
    resultConfigId,
    termId,
    armId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * resultConfigId
     */
    resultConfigId: string;
    /**
     * termId
     */
    termId: string;
    /**
     * armId
     */
    armId?: string;
  }): CancelablePromise<Array<SubjectScoreApprovalLogHistoryResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subject-score-approvals/history/term/{termId}/class/{classLevelId}',
      path: {
        classLevelId: classLevelId,
        termId: termId,
      },
      query: {
        armId: armId,
        resultConfigId: resultConfigId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recallSubjectScores
   * @returns SubjectScoreApprovalLogDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static recallSubjectScoresUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: SubjectScoreApprovalLogRequest;
  }): CancelablePromise<Array<SubjectScoreApprovalLogDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-score-approvals/recall',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recallClassLevelSubjectScores
   * @returns any OK
   * @throws ApiError
   */
  public static recallClassLevelSubjectScoresUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: ClassLevelSubjectScoreApprovalLogRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-score-approvals/recall-all',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassMembersApprovalStatus
   * @returns SubjectScoreApprovalLogStatusResponse OK
   * @throws ApiError
   */
  public static getClassMembersApprovalStatusUsingGet2({
    classLevelId,
    resultConfigId,
    termId,
    armId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * resultConfigId
     */
    resultConfigId: string;
    /**
     * termId
     */
    termId: string;
    /**
     * armId
     */
    armId?: string;
  }): CancelablePromise<Array<SubjectScoreApprovalLogStatusResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subject-score-approvals/term/{termId}/class/{classLevelId}',
      path: {
        classLevelId: classLevelId,
        termId: termId,
      },
      query: {
        armId: armId,
        resultConfigId: resultConfigId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
