import { all, call } from 'redux-saga/effects';
import { onFetchClassArmBroadsheetStart } from './class-arm-saga';
import { onFetchClassLevelBroadsheetStart } from './class-level-sheet-saga';
import { onFetchCumulativeArmBroadsheetStart } from './cummulative-class-arm-sheet';
import { onFetchCumulativeLevelBroadsheetStart } from './cumulative-level-broadsheet-saga';
import { onFetchBulkStudentsReportDataStart } from './fetchBulkStudentsResultData';
import { onFetchStudentReportDataStart } from './fetchStudentResultData';
import { onSetDefaultTemplate } from './set-default-template-saga';
import { onStudentResultApprovalStart } from './single-student-result-approval';

export default function* broadSheetSagas() {
  yield all([
    call(onFetchClassArmBroadsheetStart),
    call(onFetchClassLevelBroadsheetStart),
    call(onStudentResultApprovalStart),
    call(onFetchCumulativeArmBroadsheetStart),
    call(onFetchCumulativeLevelBroadsheetStart),
    call(onFetchStudentReportDataStart),
    call(onFetchBulkStudentsReportDataStart),
    call(onSetDefaultTemplate),
  ]);
}
