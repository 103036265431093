import { takeLatest, put } from 'redux-saga/effects';
import {
  addSingleDefinitionSuccess,
  addSingleDefinitionFail,
  fetchAllTraitGroupStart,
} from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* addSingleDefinition({ payload }) {
  const callback = payload.callback;
  delete payload.callback;
  try {
    const response = yield httpClient.post('/trait-configuration/trait-definitions', payload);
    yield put(addSingleDefinitionSuccess(response.data));
    Notify('Trait definition added succesfully', { position: 'top-right' });
    callback(response.data.id);
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(addSingleDefinitionFail(error.fail));
    const err = handleError(error);
  }
}

export function* onAddSingleDefinitionStart() {
  yield takeLatest(earlyYearsActionTypes.ADD_SINGLE_DEFINITION_START, addSingleDefinition);
}
