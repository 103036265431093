import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { deleteSkillSuccess, deleteSkillFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* deleteSkill({ payload }) {
  try {
    const response = yield httpClient.delete(
      `/cognitive-skill-config/skill-groups/skills/${payload.skillId}`,
      payload.data,
    );
    yield put(deleteSkillSuccess(payload.id, payload.skillId));

    Notify('Skill Deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteSkillFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteSkillStart() {
  yield takeLatest(cognitiveSkillsActionTypes.DELETE_SKILL_START, deleteSkill);
}
