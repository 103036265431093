import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAccessToken, selectIsLoggedIn } from 'redux/users/selectors';

const PublicRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const accessToken = useSelector(selectAccessToken);
  const auth = !!(isLoggedIn && accessToken);
  return auth ? <Navigate to="/user-profile" /> : children;
};

export default PublicRoute;
