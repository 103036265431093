/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TermCommentDto } from '../models/TermCommentDto';
import type { TermCommentRequest } from '../models/TermCommentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TermCommentRestControllerService {
  /**
   * createOrUpdateTermComment
   * @returns TermCommentDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createOrUpdateTermCommentUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: TermCommentRequest;
  }): CancelablePromise<TermCommentDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/term-comments',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getStudentEOTComments
   * @returns TermCommentDto OK
   * @throws ApiError
   */
  public static getStudentEotCommentsUsingGet({
    studentId,
    termId,
    traitGroupId,
  }: {
    studentId: string;
    termId: string;
    traitGroupId?: string;
  }): CancelablePromise<Array<TermCommentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/term-comments/eot-comments',
      query: {
        studentId: studentId,
        termId: termId,
        traitGroupId: traitGroupId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getStudentMidtermComments
   * @returns TermCommentDto OK
   * @throws ApiError
   */
  public static getStudentMidtermCommentsUsingGet({
    studentId,
    termId,
    traitGroupId,
  }: {
    studentId: string;
    termId: string;
    traitGroupId?: string;
  }): CancelablePromise<Array<TermCommentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/term-comments/midterm-comments',
      query: {
        studentId: studentId,
        termId: termId,
        traitGroupId: traitGroupId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteTermComment
   * @returns any OK
   * @throws ApiError
   */
  public static deleteTermCommentUsingDelete({
    termCommentId,
  }: {
    /**
     * termCommentId
     */
    termCommentId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/term-comments/{termCommentId}',
      path: {
        termCommentId: termCommentId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
