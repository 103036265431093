import PropTypes from 'prop-types';
import styled, { ThemeConsumer } from 'styled-components';

const EmptyWrapper = styled('div').attrs({
  className: 'flexi-emptystate-container',
})`
  display: block;
  text-align: center;
  margin: calc(10% - 5px) auto 0;
  max-width: ${(props) => (props.width ? props.width : '400px')};
  padding: 20px 0;

  & img {
    margin: calc(10% - 5px) auto 0;
    width: 150px;
  }
`;

const Heading = styled.p`
  font-size: 18px;
  font-weight: 600;
  font-family: ${(props) => props.PrimaryFont};
  margin: 10px 0;
`;

const P = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  margin: 20px 0;
  font-family: ${(props) => props.SecondaryFont};
`;

export const CustomEmptyState = (props) => {
  return (
    <ThemeConsumer>
      {(theme) => (
        <EmptyWrapper {...props}>
          {props.image && <img src={props.image} height={props.imgHeight} />}
          {props.title && <Heading>{props.title}</Heading>}
          {props.info && <P>{props.info}</P>}
          {props.action && <div>{props.action}</div>}
        </EmptyWrapper>
      )}
    </ThemeConsumer>
  );
};

CustomEmptyState.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  action: PropTypes.any,
  image: PropTypes.any,
  imgHeight: PropTypes.string,
  width: PropTypes.string,
};
