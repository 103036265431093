import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { termProfileSetRatingsSuccess, termProfileSetRatingsFail } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* termProfileSetRatings({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.post('/assessment-config/termly', payload);
    yield put(termProfileSetRatingsSuccess(response.data));
    callback();
  } catch (error) {
    yield put(termProfileSetRatingsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTermProfileSetRatings() {
  yield takeLatest(configActionTypes.TERM_PROFILE_SET_RATINGS_START, termProfileSetRatings);
}
