import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { school_id } from 'utils/constants';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { createStaffFailure, createStaffSuccess, fetchStaffsStatsStart } from '../actions';
import staffActionTypes from '../types';

export function* createStaff({ payload }) {
  const formData = payload;
  try {
    const response = yield httpClient.post('/staff', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    yield put(createStaffSuccess(response.data));
    yield put(fetchStaffsStatsStart());
    Notify('Staff created successfully', { position: 'top-right' });
  } catch (error) {
    yield put(createStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateStaffStart() {
  yield takeLatest(staffActionTypes.CREATE_STAFF_START, createStaff);
}
