import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { fetchSkillsRatingsFailure, fetchSkillsRatingsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchSkillsRatings({ payload }) {
  try {
    const response = yield httpClient.get(`/cognitive-skill-config/skill-rating`, {
      params: payload,
    });
    yield put(fetchSkillsRatingsSuccess(response.data));
  } catch (error) {
    yield put(fetchSkillsRatingsFailure(error?.message));
  }
}

export function* onfetchSkillRatingsStart() {
  yield takeLatest(cognitiveSkillsActionTypes.FETCH_SKILLS_RATINGS_START, fetchSkillsRatings);
}
