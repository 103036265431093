/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentProjectionAssessmentProgressResponse } from '../models/StudentProjectionAssessmentProgressResponse';
import type { StudentProjectionAssessmentReportResponse } from '../models/StudentProjectionAssessmentReportResponse';
import type { StudentProjectionAssessmentRequest } from '../models/StudentProjectionAssessmentRequest';
import type { StudentProjectionAssessmentResponse } from '../models/StudentProjectionAssessmentResponse';
import type { StudentProjectionDailySummaryResponse } from '../models/StudentProjectionDailySummaryResponse';
import type { StudentProjectionWhatsappMessageRequest } from '../models/StudentProjectionWhatsappMessageRequest';
import type { StudentProjectionWhatsappPreviewResponse } from '../models/StudentProjectionWhatsappPreviewResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudentProjectionAssessmentRestControllerService {
  /**
   * getStudentAssessment
   * @returns StudentProjectionAssessmentResponse OK
   * @throws ApiError
   */
  public static getStudentAssessmentUsingGet({
    classLevelId,
    date,
    studentId,
    subjectId,
    termId,
  }: {
    classLevelId: string;
    date: string;
    studentId: string;
    subjectId: string;
    termId: string;
  }): CancelablePromise<Array<StudentProjectionAssessmentResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-projection-assessments',
      query: {
        classLevelId: classLevelId,
        date: date,
        studentId: studentId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * recordStudentAssessment
   * @returns any OK
   * @throws ApiError
   */
  public static recordStudentAssessmentUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: StudentProjectionAssessmentRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-projection-assessments',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getPagesReadByDate
   * @returns StudentProjectionDailySummaryResponse OK
   * @throws ApiError
   */
  public static getPagesReadByDateUsingGet({
    classLevelId,
    endDate,
    startDate,
    studentId,
    subjectId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * endDate
     */
    endDate: string;
    /**
     * startDate
     */
    startDate: string;
    /**
     * studentId
     */
    studentId: string;
    /**
     * subjectId
     */
    subjectId: string;
  }): CancelablePromise<Array<StudentProjectionDailySummaryResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-projection-assessments/calender',
      query: {
        classLevelId: classLevelId,
        endDate: endDate,
        startDate: startDate,
        studentId: studentId,
        subjectId: subjectId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * sendWhatsappMessagesToParents
   * @returns any OK
   * @throws ApiError
   */
  public static sendWhatsappMessagesToParentsUsingPost({
    requests,
  }: {
    /**
     * requests
     */
    requests: Array<StudentProjectionWhatsappMessageRequest>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-projection-assessments/message-parents',
      body: requests,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * sendWhatsappMessagesToParentsViaTemplate
   * @returns any OK
   * @throws ApiError
   */
  public static sendWhatsappMessagesToParentsViaTemplateUsingPost({
    armId,
    classLevelId,
    date,
    subjectId,
    termId,
    sectionIndex,
    studentId,
  }: {
    armId: string;
    classLevelId: string;
    date: string;
    subjectId: string;
    termId: string;
    sectionIndex?: number;
    studentId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-projection-assessments/message-parents-via-template',
      query: {
        armId: armId,
        classLevelId: classLevelId,
        date: date,
        sectionIndex: sectionIndex,
        studentId: studentId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getStudentWhatsappMessagePreviews
   * @returns StudentProjectionWhatsappPreviewResponse OK
   * @throws ApiError
   */
  public static getStudentWhatsappMessagePreviewsUsingGet({
    armId,
    classLevelId,
    date,
    subjectId,
    termId,
    sectionIndex,
    studentId,
  }: {
    armId: string;
    classLevelId: string;
    date: string;
    subjectId: string;
    termId: string;
    sectionIndex?: number;
    studentId?: string;
  }): CancelablePromise<Array<StudentProjectionWhatsappPreviewResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-projection-assessments/previews',
      query: {
        armId: armId,
        classLevelId: classLevelId,
        date: date,
        sectionIndex: sectionIndex,
        studentId: studentId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getClassProgress
   * @returns StudentProjectionAssessmentProgressResponse OK
   * @throws ApiError
   */
  public static getClassProgressUsingGet({
    armId,
    classLevelId,
    subjectId,
    termId,
  }: {
    /**
     * armId
     */
    armId: string;
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * subjectId
     */
    subjectId: string;
    /**
     * termId
     */
    termId: string;
  }): CancelablePromise<Array<StudentProjectionAssessmentProgressResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-projection-assessments/progress',
      query: {
        armId: armId,
        classLevelId: classLevelId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getReportTable
   * @returns StudentProjectionAssessmentReportResponse OK
   * @throws ApiError
   */
  public static getReportTableUsingGet({
    classLevelId,
    endDate,
    startDate,
    studentId,
    subjectId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * endDate
     */
    endDate: string;
    /**
     * startDate
     */
    startDate: string;
    /**
     * studentId
     */
    studentId: string;
    /**
     * subjectId
     */
    subjectId: string;
  }): CancelablePromise<StudentProjectionAssessmentReportResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-projection-assessments/report-table',
      query: {
        classLevelId: classLevelId,
        endDate: endDate,
        startDate: startDate,
        studentId: studentId,
        subjectId: subjectId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
