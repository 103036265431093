import { takeLatest, put } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { assignSkillRatingFailure, assignSkillRatingSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import cognitiveSkillsActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* assignSkillRating({ payload }) {
  try {
    const response = yield httpClient.put(`/cognitive-skill-assessment`, payload);
    yield put(assignSkillRatingSuccess(response.data));
    Notify('Skill rating assigned successfully', {
      position: 'top-right',
    });
  } catch (error) {
    yield put(assignSkillRatingFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onAssignSkillRatingStart() {
  yield takeLatest(cognitiveSkillsActionTypes.ASSIGN_SKILL_RATING_START, assignSkillRating);
}
