import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchInvoicesSessionsFailure, fetchInvoicesSessionsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchInvoicesSessions() {
  try {
    const response = yield httpClient.get('/sessions/');
    yield put(fetchInvoicesSessionsSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesSessionsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchInvoicesSessionsStart() {
  yield takeLatest(invoiceActionTypes.FETCH_INVOICES_SESSIONS_START, fetchInvoicesSessions);
}
