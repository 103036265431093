import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Theme } from 'config/theme';

const BreadCrumbWrapper = styled.div`
  margin-bottom: 20px;
`;
const BreadCrumbs = ({ links, last }) => (
  <BreadCrumbWrapper>
    {links.map((link) => {
      return (
        <span style={{ color: Theme.PrimaryColor }} key={link.text}>
          <i className="flexibull-angle-left" />
          {link.hasOwnProperty('onClick') ? (
            <>
              <span
                onClick={link?.onClick}
                style={{ color: Theme.PrimaryColor, cursor: 'pointer' }}
              >
                {link.text}
              </span>
            </>
          ) : (
            <Link to={link.path} style={{ color: Theme.PrimaryColor }}>
              {link.text}
            </Link>
          )}
        </span>
      );
    })}
    <span style={{ color: Theme.PrimaryGrey }}>{` / ${last}`}</span>
  </BreadCrumbWrapper>
);

export default BreadCrumbs;
