import studentActionTypes from './types';

export const handleCreateModal = (modal) => ({
  type: studentActionTypes.CREATE_STUDENT_MODAL,
  payload: modal,
});

export const createStudentStart = (data) => ({
  type: studentActionTypes.CREATE_STUDENT_START,
  payload: data,
});

export const createStudentSuccess = (data) => ({
  type: studentActionTypes.CREATE_STUDENT_SUCCESS,
  payload: data,
});

export const createStudentFail = (error) => ({
  type: studentActionTypes.CREATE_STUDENT_FAIL,
  payload: error,
});

export const editStudentStart = (data) => ({
  type: studentActionTypes.EDIT_STUDENT_START,
  payload: data,
});

export const editStudentSuccess = (data) => ({
  type: studentActionTypes.EDIT_STUDENT_SUCCESS,
  payload: data,
});

export const editStudentFail = (error) => ({
  type: studentActionTypes.EDIT_STUDENT_FAIL,
  payload: error,
});

export const deleteStudentStart = (id) => ({
  type: studentActionTypes.DELETE_STUDENT_START,
  payload: id,
});

export const deleteStudentSuccess = (data) => ({
  type: studentActionTypes.DELETE_STUDENT_SUCCESS,
  payload: data,
});

export const deleteStudentFail = (error) => ({
  type: studentActionTypes.DELETE_STUDENT_FAIL,
  payload: error,
});

export const fetchAllStudentStart = (data) => ({
  type: studentActionTypes.FETCH_STUDENTS_START,
  payload: data,
});

export const fetchAllStudentSuccess = (data) => ({
  type: studentActionTypes.FETCH_STUDENTS_SUCCESS,
  payload: data,
});

export const fetchAllStudentFail = (error) => ({
  type: studentActionTypes.FETCH_STUDENTS_FAIL,
  payload: error,
});

export const handleStudentProfileAction = (studentProfileData) => ({
  type: studentActionTypes.HANDLE_STUDENT_PROFILE_DATA,
  payload: studentProfileData,
});

export const saveStudentOtherInfoAction = (data) => ({
  type: studentActionTypes.SAVE_STUDENT_OTHER_INFO,
  payload: data,
});

export const saveStudentBioAction = (data) => ({
  type: studentActionTypes.SAVE_STUDENT_BIO,
  payload: data,
});

export const fetchCountriesStart = () => ({
  type: studentActionTypes.FETCH_COUNTRIES_START,
  payload: {},
});

export const fetchCountriesSuccess = (data) => ({
  type: studentActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: data,
});

export const fetchCountriesFailure = (error) => ({
  type: studentActionTypes.FETCH_COUNTRIES_FAILURE,
  payload: error,
});

export const fetchStatesStart = (data) => ({
  type: studentActionTypes.FETCH_STATES_START,
  payload: data,
});

export const fetchStatesSuccess = (data) => ({
  type: studentActionTypes.FETCH_STATES_SUCCESS,
  payload: data,
});

export const fetchStatesFailure = (error) => ({
  type: studentActionTypes.FETCH_STATES_FAILURE,
  payload: error,
});

export const fetchLgaStart = (data) => ({
  type: studentActionTypes.FETCH_LGA_START,
  payload: data,
});

export const fetchLgaSuccess = (data) => ({
  type: studentActionTypes.FETCH_LGA_SUCCESS,
  payload: data,
});

export const fetchLgaFailure = (error) => ({
  type: studentActionTypes.FETCH_LGA_FAILURE,
  payload: error,
});

export const resetLgaAction = () => ({
  type: studentActionTypes.RESET_LGA,
  payload: [],
});

export const resetEverythingAction = () => ({
  type: studentActionTypes.RESET_EVERYTHING,
  payload: {},
});

export const handleEditAction = (studentProfileData, studentBio, studentOtherInfo) => ({
  type: studentActionTypes.HANDLE_EDIT_STUDENT,
  payload: { studentProfileData, studentBio, studentOtherInfo },
});

export const downloadXLSXTempleteStart = () => ({
  type: studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_START,
  payload: {},
});

export const downloadXLSXTempleteSuccess = (data) => ({
  type: studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS,
  payload: data,
});

export const downloadXLSXTempleteFailure = (error) => ({
  type: studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILURE,
  payload: error,
});

export const stopStudentBulkLoadingAction = () => ({
  type: studentActionTypes.STOP_UPLOAD_STUDENT_BULK_LOADING,
  payload: {},
});

export const bulkStudentUploadStart = (filename, data, classLevelId, armId, termId) => ({
  type: studentActionTypes.BULK_STUDENT_UPLOAD_START,
  payload: { filename, data, classLevelId, armId, termId },
});

export const bulkStudentUploadSuccess = (data) => ({
  type: studentActionTypes.BULK_STUDENT_UPLOAD_SUCCESS,
  payload: data,
});

export const bulkStudentUploadFailure = (error) => ({
  type: studentActionTypes.BULK_STUDENT_UPLOAD_FAILURE,
  payload: error,
});

export const handleBulkModalAction = (bulkModal) => ({
  type: studentActionTypes.HANDLE_BULK_STUDENT_MODAL,
  payload: bulkModal,
});
export const studentStatusStart = (data) => ({
  type: studentActionTypes.STUDENT_STATUS_START,
  payload: data,
});

export const studentStatusSuccess = (data) => ({
  type: studentActionTypes.STUDENT_STATUS_SUCCESS,
  payload: data,
});

export const studentStatusFail = (error, data) => ({
  type: studentActionTypes.STUDENT_STATUS_FAILURE,
  payload: { error, data },
});

export const fetchStudentsStatsStart = (data) => ({
  type: studentActionTypes.FETCH_STUDENT_STATISTICS_START,
  payload: data,
});

export const fetchStudentsStatsSuccess = (data) => ({
  type: studentActionTypes.FETCH_STUDENT_STATISTICS_SUCCESS,
  payload: data,
});

export const fetchStudentsStatsFailure = (error) => ({
  type: studentActionTypes.FETCH_STUDENT_STATISTICS_FAILURE,
  payload: error,
});

export const updateStudentPhotoStart = (data, id) => ({
  type: studentActionTypes.UPDATE_STUDENT_PHOTO_START,
  payload: { data, id },
});

export const updateStudentPhotoSuccess = (link, id) => ({
  type: studentActionTypes.UPDATE_STUDENT_PHOTO_SUCCESS,
  payload: { link, id },
});

export const updateStudentPhotoFail = (error) => ({
  type: studentActionTypes.UPDATE_STUDENT_PHOTO_FAIL,
  payload: error,
});

export const handlePhotoUpdateAction = (status) => ({
  type: studentActionTypes.HANDLE_UPDATE_PHOTO,
  payload: status,
});

export const saveAndCreateAnotherStart = (data, callback) => ({
  type: studentActionTypes.SAVE_AND_RECREATE_STUDENT_START,
  payload: { data, callback },
});

export const saveAndCreateAnotherSuccess = (data) => ({
  type: studentActionTypes.SAVE_AND_RECREATE_STUDENT_SUCCESS,
  payload: data,
});

export const saveAndCreateAnotherFail = (error) => ({
  type: studentActionTypes.SAVE_AND_RECREATE_STUDENT_FAIL,
  payload: error,
});

export const handleRecreateAction = () => ({
  type: studentActionTypes.HANDLE_RECREATE,
  payload: {},
});

export const filterStudentsStart = (data) => ({
  type: studentActionTypes.FILTER_STUDENTS_START,
  payload: data,
});

export const filterStudentsSuccess = (data) => ({
  type: studentActionTypes.FILTER_STUDENTS_SUCCESS,
  payload: data,
});

export const filterStudentsFailure = (error) => ({
  type: studentActionTypes.FILTER_STUDENTS_FAILURE,
  payload: error,
});
export const handleSoftDeleteAction = (deleteModal) => ({
  type: studentActionTypes.HANDLE_SOFT_DELETE_STUDENT,
  payload: deleteModal,
});

export const handleLinkParentModalAction = (linkModal) => ({
  type: studentActionTypes.HANDLE_LINK_CHILD_MODAL,
  payload: linkModal,
});

export const linkParentStart = (data) => ({
  type: studentActionTypes.LINK_PARENT_START,
  payload: data,
});

export const linkParentSuccess = (data) => ({
  type: studentActionTypes.LINK_PARENT_SUCCESS,
  payload: data,
});

export const linkParentFail = (error) => ({
  type: studentActionTypes.LINK_PARENT_FAIL,
  payload: error,
});

export const uploadStudentDocsStart = (student_id, id, data) => ({
  type: studentActionTypes.UPLOAD_STUDENT_DOCS_START,
  payload: { student_id, id, data },
});

export const uploadStudentDocsSuccess = (data) => ({
  type: studentActionTypes.UPLOAD_STUDENT_DOCS_SUCCESS,
  payload: data,
});

export const uploadStudentDocsFail = (data) => ({
  type: studentActionTypes.UPLOAD_STUDENT_DOCS_FAIL,
  payload: data,
});

export const handleSetStudentDoc = (data) => ({
  type: studentActionTypes.HANDLE_SET_STUDENT_DOC,
  payload: data,
});

export const fetchStudentDocsStart = (id) => ({
  type: studentActionTypes.FETCH_STUDENT_DOCS_START,
  payload: id,
});

export const fetchStudentDocsSuccess = (data) => ({
  type: studentActionTypes.FETCH_STUDENT_DOCS_SUCCESS,
  payload: data,
});

export const fetchStudentDocsFail = (data) => ({
  type: studentActionTypes.FETCH_STUDENT_DOCS_FAIL,
  payload: data,
});

export const deleteStudentDocsStart = (student_id, data) => ({
  type: studentActionTypes.DELETE_STUDENT_DOCS_START,
  payload: { student_id, data },
});

export const deleteStudentDocsSuccess = (data) => ({
  type: studentActionTypes.DELETE_STUDENT_DOCS_SUCCESS,
  payload: data,
});

export const deleteStudentDocsFail = (error) => ({
  type: studentActionTypes.DELETE_STUDENT_DOCS_FAIL,
  payload: error,
});

export const handleStudentDocModal = (data) => ({
  type: studentActionTypes.HANDLE_STUDENT_DOC_MODAL,
  payload: data,
});

export const handleDeleteDocModal = (deleteDocModal) => ({
  type: studentActionTypes.HANDLE_DELETE_DOC_MODAL,
  payload: deleteDocModal,
});

export const downloadStudentDocStart = (document_name, data) => ({
  type: studentActionTypes.DOWNLOAD_STUDENT_DOCS_START,
  payload: { document_name, data },
});

export const downloadStudentDocSuccess = (data) => ({
  type: studentActionTypes.DOWNLOAD_STUDENT_DOCS_SUCCESS,
  payload: data,
});

export const downloadStudentDocFail = (error) => ({
  type: studentActionTypes.DOWNLOAD_STUDENT_DOCS_FAIL,
  payload: error,
});

export const fetchStudentsWithoutClassStart = (data) => ({
  type: studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_START,
  payload: data,
});

export const fetchStudentsWithoutClassSuccess = (data) => ({
  type: studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_SUCCESS,
  payload: data,
});

export const fetchStudentsWithoutClassFail = (error) => ({
  type: studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_FAIL,
  payload: error,
});

export const fetchStudentStart = (id) => ({
  type: studentActionTypes.FETCH_STUDENT_START,
  payload: id,
});

export const fetchStudentSuccess = (data) => ({
  type: studentActionTypes.FETCH_STUDENT_SUCCESS,
  payload: data,
});

export const fetchStudentFail = (data) => ({
  type: studentActionTypes.FETCH_STUDENT_FAIL,
  payload: data,
});

export const removedAddedStudentsFromListAction = (data) => ({
  type: studentActionTypes.REMOVED_ADDED_STUDENTS_FROM_LIST,
  payload: data,
});

export const handleUnlinkParentModalAction = (parentUnlinkModal) => ({
  type: studentActionTypes.HANDLE_UNLINK_PARENT_MODAL,
  payload: parentUnlinkModal,
});

export const unlinkParentStart = (data) => ({
  type: studentActionTypes.UNLINK_PARENT_START,
  payload: data,
});

export const unlinkParentSuccess = (data) => ({
  type: studentActionTypes.UNLINK_PARENT_SUCCESS,
  payload: data,
});

export const unlinkParentFail = (error) => ({
  type: studentActionTypes.UNLINK_PARENT_FAILURE,
  payload: error,
});

export const fetchTotalStudentsStart = () => ({
  type: studentActionTypes.GET_TOTAL_STUDENTS_START,
});

export const fetchTotalStudentsSuccess = (data) => ({
  type: studentActionTypes.GET_TOTAL_STUDENTS_SUCCESS,
  payload: data,
});

export const fetchTotalStudentsFail = (error) => ({
  type: studentActionTypes.GET_TOTAL_STUDENTS_FAILURE,
  payload: error,
});

export const setStudentActiveTab = (data) => ({
  type: studentActionTypes.SET_STUDENT_ACTIVE_TAB,
  payload: data,
});
