import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { getClassMemberFail, getClassMemberSuccess } from '../actions';
import configActionTypes from '../types';

export function* getClassMember({ payload }) {
  const search = payload.search;
  const limit = payload.limit;
  const offset = payload.offset;
  delete payload.search;
  delete payload.limit;
  delete payload.offset;
  try {
    const response = yield httpClient.get('/class-members', { params: { ...payload } });
    yield put(getClassMemberSuccess(response.data));
  } catch (error) {
    yield put(getClassMemberFail(error?.message));
  }
}

export function* onGetClassMemberStart() {
  yield takeLatest(configActionTypes.GET_CLASS_MEMBERS_START, getClassMember);
}
