import { IPlanState } from './reducer';
import plansActionTypes from './types';

export const fetchAppModulesStart = () => ({
  type: plansActionTypes.FETCH_APP_MODULES_START,
});

export const fetchAppModulesSuccess = (data) => ({
  type: plansActionTypes.FETCH_APP_MODULES_SUCCESS,
  payload: data,
});

export const fetchAppModulesFailure = (error) => ({
  type: plansActionTypes.FETCH_APP_MODULES_FAILURE,
  payload: error,
});

export const selectAppModulesStart = (data) => ({
  type: plansActionTypes.SELECT_APP_MODULES_START,
  payload: data,
});

export const selectAppModulesSuccess = (data) => ({
  type: plansActionTypes.SELECT_APP_MODULES_SUCCESS,
  payload: data,
});

export const selectAppModulesFailure = (error) => ({
  type: plansActionTypes.SELECT_APP_MODULES_FAILURE,
  payload: error,
});

export const updatePlanState = (payload: IPlanState) => ({
  type: plansActionTypes.UPDATE_PLAN_STATE,
  payload,
});
