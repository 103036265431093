import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { fetchStaffsStatsFailure, fetchStaffsStatsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchStaffsStats() {
  try {
    const response = yield httpClient.get('/staff/statistics');
    yield put(fetchStaffsStatsSuccess(response.data));
  } catch (error) {
    yield put(fetchStaffsStatsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchStaffsStatsStart() {
  yield takeLatest(staffActionTypes.FETCH_STAFFS_STATS_START, fetchStaffsStats);
}
