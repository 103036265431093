import { takeLatest, put } from 'redux-saga/effects';
import { createClassInformationSuccess, createClassInformationFail } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* createClassInfo({ payload }) {
  try {
    const response = yield httpClient.post(`/class-information`, payload);
    yield put(createClassInformationSuccess(response.data));
    Notify('Arms assigned to class level succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(createClassInformationFail(error.fail));
    const err = handleError(error);
  }
}

export function* onCreateInfoStart() {
  yield takeLatest(configActionTypes.CREATE_CLASS_INFORMATION_START, createClassInfo);
}
