import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { editStudentFail, editStudentSuccess } from '../action';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* editStudent({ payload }) {
  try {
    const response = yield httpClient.put('/students', payload);
    yield put(editStudentSuccess(response.data));
    Notify('Student Edited successfully', { position: 'top-right' });
  } catch (error) {
    yield put(editStudentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onEditStudentStart() {
  yield takeLatest(studentActionTypes.EDIT_STUDENT_START, editStudent);
}
