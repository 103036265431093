import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { getTermProfileEarlyYearsSuccess, getTermProfileEarlyYearsFail } from '../actions';
import { handleError } from 'utils/utils';

export function* getTermProfileEarlyYears({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.get(`/trait-configuration/traits/termly/${payload.id}`);
    yield put(getTermProfileEarlyYearsSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(getTermProfileEarlyYearsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTermProfileEarlyYears() {
  yield takeLatest(configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_START, getTermProfileEarlyYears);
}
