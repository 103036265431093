import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { saveAndCreateAnotherStaffSuccess, saveAndCreateAnotherStaffFailure } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';

export function* saveAndCreateAnotherStaff({ payload }) {
  const { data, callback } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post('/staff', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': school_id,
      },
    });
    yield put(saveAndCreateAnotherStaffSuccess(response.data));
    callback();
    Notify('Staff Saved, Create Another One', { position: 'top-right' });
  } catch (error) {
    yield put(saveAndCreateAnotherStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onSaveAndCreateAnotherStaff() {
  yield takeLatest(staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_START, saveAndCreateAnotherStaff);
}
