import { all, call } from 'redux-saga/effects';
import { onBulkParentUploadStart } from './bulkParentUpload';
import { onCreateParentStart } from './createParent';
import { onDeleteParentStart } from './deleteParent';
import { onDownloadXLSXTempleteStart } from './downloadXLSXTemplete';
import { onEditParentStart } from './editParent';
import { onfetchListOfStudentsStart } from './fetchListOfStudents';
import { onFetchParentChildrenStart } from './fetchParentChildren';
import { onFetchParentsStart } from './fetchParents';
import { onFetchParentsStatsStart } from './fetchParentStats';
import { onFetchSingleParentStart } from './fetchSingleParent';
import { onLinkChildStart } from './linkChild';
import { onParentStatusStart } from './parentStatus';
import { onSaveAndCreateAnotherParentStart } from './saveAndCreateAnotherParent';
import { onUnlinkChildStart } from './unlinkChild';
import { onUpdateParentPictureStart } from './updateParentPicture';

export default function* ParentSaga() {
  yield all([
    call(onFetchParentsStart),
    call(onCreateParentStart),
    call(onEditParentStart),
    call(onDownloadXLSXTempleteStart),
    call(onBulkParentUploadStart),
    call(onParentStatusStart),
    call(onSaveAndCreateAnotherParentStart),
    call(onfetchListOfStudentsStart),
    call(onFetchParentsStatsStart),
    call(onUpdateParentPictureStart),
    call(onLinkChildStart),
    call(onUnlinkChildStart),
    call(onDeleteParentStart),
    call(onFetchSingleParentStart),
    call(onFetchParentChildrenStart),
  ]);
}
