import serviceWorkerActionTypes from './types';

const INITIAL_STATE = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  serviceWorkerUpdateAvailable: false,
  serviceWorkerActive: false,
};

function serviceWorkerReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case serviceWorkerActionTypes.INITIALIZE_SERVICE_WORKER:
      return {
        ...state,
        serviceWorkerInitialized: true,
        serviceWorkerRegistration: payload.registration,
        serviceWorkerActive: payload.serviceWorkerActive,
      };
    case serviceWorkerActionTypes.UPDATE_SERVICE_WORKER:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: payload,
        serviceWorkerUpdateAvailable: payload?.serviceWorkerUpdateAvailable ?? false,
      };
    default:
      return state;
  }
}

export default serviceWorkerReducer;
