import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { handleEditClassInfo } from 'redux/configuration/actions';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  assignFormTeachersFail,
  assignFormTeachersSuccess,
  fetchListOfClassInformationStart,
} from '../actions';
import classInformationTypes from '../types';

export function* assignFormTeachers({ payload }) {
  const frontendId = payload.frontendId;
  const origin = payload.origin;
  delete payload.frontendId;
  delete payload.origin;
  try {
    const response = yield httpClient.post(`/class-information/form-teachers`, payload);
    yield put(assignFormTeachersSuccess({ ...response.data, frontendId }));
    yield put(fetchListOfClassInformationStart({ termId: payload.term_id }));
    Notify('Form Teacher Assigned Succesfully', { position: 'top-right' });
    const { main_form_teacher, assistant_form_teacher } = response.data;

    if (origin === 'classInfo') {
      yield put(handleEditClassInfo({ main_form_teacher, assistant_form_teacher }));
    }
  } catch (error) {
    yield put(assignFormTeachersFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAssignFormTeachersStart() {
  yield takeLatest(classInformationTypes.ASSIGN_FORM_TEACHERS_START, assignFormTeachers);
}
