import { takeLatest, put } from 'redux-saga/effects';
import { getApproveResultsSuccess, getApproveResultsFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* getApproveResults({ payload }) {
  try {
    const response = yield httpClient.get(`/assessment-approval`, { params: payload });
    if (payload.callback) payload.callback();

    yield put(getApproveResultsSuccess(response.data));
  } catch (error) {
    yield put(getApproveResultsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetApproveResults() {
  yield takeLatest(configActionTypes.FETCH_APPROVE_RESULTS_START, getApproveResults);
}
