import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { updateStudentPhotoFail, updateStudentPhotoSuccess } from '../action';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';

export function* updateStudentPhoto({ payload }) {
  const { data, id } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post(
      `/students/${encodeURIComponent(id)}/uploads/profile-pic`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-TENANT-ID': `${school_id}`,
        },
      },
    );
    yield put(updateStudentPhotoSuccess(response.data, id));
    Notify('Student Picture Updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(updateStudentPhotoFail(error?.message));
    const err = handleError(error);
  }
}

export function* onUpdateStudentPhotoStart() {
  yield takeLatest(studentActionTypes.UPDATE_STUDENT_PHOTO_START, updateStudentPhoto);
}
