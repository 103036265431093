import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { downloadStaffDocumentsFailure } from '../actions';
import staffActionTypes from '../types';

export function* downloadStaffDocument({ payload }) {
  const { data, document_name } = payload;
  try {
    const response = yield httpClient.post('/documents/download', data, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${document_name}`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(downloadStaffDocumentsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDownloadStaffDocumentStart() {
  yield takeLatest(staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_START, downloadStaffDocument);
}
