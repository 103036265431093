import { put, takeLatest } from 'redux-saga/effects';
import internalRolesActionTypes from '../types';
import { assignRolesSuccess, assignRolesFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* assignRoles({ payload }) {
  try {
    const response = yield httpClient.put('/user-roles/assign', payload);
    yield put(assignRolesSuccess(response.data));
    Notify('Roles assigned successfully', { position: 'top-right' });
  } catch (error) {
    yield put(assignRolesFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onAssignRolesStart() {
  yield takeLatest(internalRolesActionTypes.ASSING_ROLES_START, assignRoles);
}
