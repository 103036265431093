import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { addPayableItemFail, addPayableItemSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { setDisableNext } from 'redux/users/actions';
import { handleError } from 'utils/utils';

export function* addPayableItem({ payload }) {
  try {
    const response = yield httpClient.post('/payable-items', payload);
    yield put(addPayableItemSuccess(response.data));
    Notify('Item added successfully.', { position: 'top-right' });
  } catch (error) {
    yield put(addPayableItemFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAddPayableItem() {
  yield takeLatest(configActionTypes.ADD_PAYABLE_ITEM_START, addPayableItem);
}
