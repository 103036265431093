import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { deleteSkillGroupSuccess, deleteSkillGroupFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* deleteSkillGroup({ payload }) {
  try {
    const response = yield httpClient.delete(
      `/cognitive-skill-config/skill-groups/${payload.id}?force-delete=true`,
    );
    yield put(deleteSkillGroupSuccess(payload.id));

    Notify('Skill Group deleted  successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteSkillGroupFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteSkillGroupStart() {
  yield takeLatest(cognitiveSkillsActionTypes.DELETE_SKILL_GROUP_START, deleteSkillGroup);
}
