import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { staffStatusFailure, staffStatusSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* staffStatus({ payload }) {
  try {
    const response = yield httpClient.put('/staff', payload);
    yield put(staffStatusSuccess(response.data));
    Notify(`Staff ${payload.active_status === 'ACTIVE' ? 'Actived' : 'Deactivated'} successfully`, {
      position: 'top-right',
    });
  } catch (error) {
    yield put(staffStatusFailure(error?.message, payload));
    const err = handleError(error);
  }
}

export function* onStaffStatusStart() {
  yield takeLatest(staffActionTypes.STAFF_STATUS_START, staffStatus);
}
