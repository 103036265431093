/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWeeklyProjectionRequest } from '../models/CreateWeeklyProjectionRequest';
import type { UpdateWeeklyProjectionRequest } from '../models/UpdateWeeklyProjectionRequest';
import type { WeeklyProjectionDto } from '../models/WeeklyProjectionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WeeklyProjectionRestControllerService {
  /**
   * getBySubject
   * @returns WeeklyProjectionDto OK
   * @throws ApiError
   */
  public static getBySubjectUsingGet2({
    classLevelId,
    subjectId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * subjectId
     */
    subjectId: string;
  }): CancelablePromise<Array<WeeklyProjectionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weekly-projection',
      query: {
        classLevelId: classLevelId,
        subjectId: subjectId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * create
   * @returns WeeklyProjectionDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createUsingPost6({
    request,
  }: {
    /**
     * request
     */
    request: Array<CreateWeeklyProjectionRequest>;
  }): CancelablePromise<Array<WeeklyProjectionDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/weekly-projection',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * update
   * @returns WeeklyProjectionDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateUsingPut7({
    request,
  }: {
    /**
     * request
     */
    request: UpdateWeeklyProjectionRequest;
  }): CancelablePromise<Array<WeeklyProjectionDto> | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/weekly-projection',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getById
   * @returns WeeklyProjectionDto OK
   * @throws ApiError
   */
  public static getByIdUsingGet3({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<WeeklyProjectionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weekly-projection/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * delete
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUsingDelete6({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/weekly-projection/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
