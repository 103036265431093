enum bankActionTypes {
  FETCH_LIST_BANKS_START = 'FETCH_LIST_BANKS_START',
  FETCH_LIST_BANKS_SUCCESS = 'FETCH_LIST_BANKS_SUCCESS',
  FETCH_LIST_BANKS_FAILURE = 'FETCH_LIST_BANKS_FAILURE',

  FETCH_BANK_ACCOUNTS_START = 'FETCH_BANK_ACCOUNTS_START',
  FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS',
  FETCH_BANK_ACCOUNTS_FAILURE = 'FETCH_BANK_ACCOUNTS_FAILURE',

  CREATE_BANK_ACCOUNT_START = 'CREATE_BANK_ACCOUNT_START',
  CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS',
  CREATE_BANK_ACCOUNT_FAILURE = 'CREATE_BANK_ACCOUNT_FAILURE',

  EDIT_BANK_ACCOUNT_START = 'EDIT_BANK_ACCOUNT_START',
  EDIT_BANK_ACCOUNT_SUCCESS = 'EDIT_BANK_ACCOUNT_SUCCESS',
  EDIT_BANK_ACCOUNT_FAILURE = 'EDIT_BANK_ACCOUNT_FAILURE',

  DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START',
  DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS',
  DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE',

  HANDLE_BANK_ACCOUNT_MODAL = 'HANDLE_BANK_ACCOUNT_MODAL',
  HANDLE_BANK_ACCOUNT_DELETE_MODAL = 'HANDLE_BANK_ACCOUNT_DELETE_MODAL',

  HANDLE_DEFAULT_ACCOUNT_MODAL = 'HANDLE_DEFAULT_ACCOUNT_MODAL',

  ASSIGN_DEFAULT_ACCOUNT_START = 'ASSIGN_DEFAULT_ACCOUNT_START',
  ASSIGN_DEFAULT_ACCOUNT_SUCCESS = 'ASSIGN_DEFAULT_ACCOUNT_SUCCESS',
  ASSIGN_DEFAULT_ACCOUNT_FAILURE = 'ASSIGN_DEFAULT_ACCOUNT_FAILURE',

  RETRY_SUB_ACCOUNT_START = 'RETRY_SUB_ACCOUNT_START',
  RETRY_SUB_ACCOUNT_SUCCESS = 'RETRY_SUB_ACCOUNT_SUCCESS',
  RETRY_SUB_ACCOUNT_FAILURE = 'RETRY_SUB_ACCOUNT_FAILURE',

  UPDATE_BANK_STATE = 'UPDATE_BANK_STATE',

  SAVE_BANK_DATA = 'SAVE_BANK_DATA',
}

export default bankActionTypes;
