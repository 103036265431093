import { takeLatest, put } from 'redux-saga/effects';
import { fetchSchoolInfoSuccess, fetchSchoolInfoFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { school_id } from 'utils/constants';

export function* fetchSchoolConfig() {
  try {
    const response = yield httpClient.get(`/school-info/${school_id}`);
    yield put(fetchSchoolInfoSuccess(response.data));
  } catch (error) {
    yield put(fetchSchoolInfoFail(error.info));
  }
}

export function* onFetchSchoolConfigStart() {
  yield takeLatest(configActionTypes.FETCH_SCHOOL_INFO_START, fetchSchoolConfig);
}
