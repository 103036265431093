import { takeLatest, put } from 'redux-saga/effects';
import { manageSchoolInfoSuccess, manageSchoolInfoFail, fetchSchoolInfoStart } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';

export function* schoolConfig({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.put(`/school-info`, payload);
    yield put(manageSchoolInfoSuccess(response.data));
    Notify('School configuration edited succesfully', { position: 'top-right' });
    yield put(fetchSchoolInfoStart(payload.school_id));
    callback();
  } catch (error) {
    yield put(manageSchoolInfoFail(error.fail));
  }
}

export function* onConfigureSchoolStart() {
  yield takeLatest(configActionTypes.MANAGE_SCHOOL_INFO_START, schoolConfig);
}
