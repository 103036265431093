import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { editParentFailure, editParentSuccess, fetchParentsStart } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* editParent({ payload }) {
  try {
    const response = yield httpClient.put('/parents', payload);
    yield put(editParentSuccess(response.data));
    const data = {
      offset: 0,
      limit: 10,
    };
    yield put(fetchParentsStart(data));
    Notify('Parent Edited successfully', { position: 'top-right' });
  } catch (error) {
    yield put(editParentFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditParentStart() {
  yield takeLatest(staffActionTypes.EDIT_PARENT_START, editParent);
}
