import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { saveAndCreateAnotherSuccess, saveAndCreateAnotherFail } from '../action';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';

export function* saveAndCreateAnotherStudent({ payload }) {
  const { data, callback } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post('/students', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    yield put(saveAndCreateAnotherSuccess(response.data));
    callback();
    Notify('Student created successfully.', { position: 'top-right' });
  } catch (error) {
    yield put(saveAndCreateAnotherFail(error?.message));
    const err = handleError(error);
  }
}

export function* onSaveAndCreateAnotherStudent() {
  yield takeLatest(studentActionTypes.SAVE_AND_RECREATE_STUDENT_START, saveAndCreateAnotherStudent);
}
