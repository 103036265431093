import { put, takeLatest } from 'redux-saga/effects';
import { addGradeFormatsFail, addGradeFormatsSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import gradesActionTypes from '../types';
import { setDisableNext } from 'redux/users/actions';

export function* addFormat({ payload, callBack }) {
  try {
    let onboardingCallback = () => {};
    if (payload.hasOwnProperty('onboardingCallback')) {
      onboardingCallback = payload.onboardingCallback;
      delete payload.onboardingCallback;
    }
    const response = yield httpClient.post(`/grading/group`, payload);
    yield put(addGradeFormatsSuccess(payload));
    Notify('Grade format was saved successfully', { position: 'top-right', status: 'success' });
    callBack();
    onboardingCallback();
  } catch (error) {
    yield put(addGradeFormatsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAddGradeFormatsStart() {
  yield takeLatest(gradesActionTypes.ADD_GRADE_FORMATS_START, addFormat);
}
