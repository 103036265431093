import { takeLatest, put } from 'redux-saga/effects';
import { fetchParentsSuccess, fetchParentsFail } from '../actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchParents({ payload }) {
  let endpoint;

  if (payload.hasOwnProperty('status')) {
    endpoint = '/parents/status';
  } else {
    endpoint = '/parents';
  }
  try {
    const response = yield httpClient.get(endpoint, { params: payload });
    yield put(fetchParentsSuccess(response.data));
  } catch (error) {
    const err = handleError(error);
    yield put(fetchParentsFail(err?.message));
  }
}

export function* onFetchParentsStart() {
  yield takeLatest(parentActionTypes.FETCH_PARENTS_START, fetchParents);
}
