import { takeLatest, put } from 'redux-saga/effects';
import { deleteParentFailure, deleteParentSuccess } from '../actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';

export function* deleteParent({ payload }) {
  try {
    const response = yield httpClient.delete(`parents/${payload.id}`);
    if (payload.isProfile) {
      payload.callback();
    }
    yield put(deleteParentSuccess(payload.id));
    Notify('Parent deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteParentFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteParentStart() {
  yield takeLatest(parentActionTypes.DELETE_PARENT_START, deleteParent);
}
