import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import { verifyNewEmailSuccess, verifyNewEmailFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from 'utils/utils';

export function* verifyNewEmail({ data, callback, callback2 }) {
  try {
    const response = yield httpClient.post('/auth/verify-email-link', data);
    yield put(verifyNewEmailSuccess(response.data));
    callback(true);
    callback2();
  } catch (error) {
    yield put(verifyNewEmailFailure(error?.message));
    const err = handleError(error);
    callback(false);
    callback2(false);
  }
}

export function* onVerifyNewEmail() {
  yield takeLatest(userActionTypes.VERIFY_NEW_EMAIL_START, verifyNewEmail);
}
