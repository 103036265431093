import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchInvoicesTermsFailure, fetchInvoicesTermsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchInvoicesTerms({ payload }) {
  try {
    const response = yield httpClient.get(`/terms/sessions/?session-id=${payload}`);
    yield put(fetchInvoicesTermsSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesTermsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchInvoicesTermsStart() {
  yield takeLatest(invoiceActionTypes.FETCH_INVOICES_TERMS_START, fetchInvoicesTerms);
}
