import { IInvoiceState } from './reducer';
import invoiceActionTypes from './types';

export const fetchInvoicesStart = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_START,
  payload: data,
});

export const fetchInvoicesSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_SUCCESS,
  payload: data,
});

export const fetchInvoicesFailure = (error) => ({
  type: invoiceActionTypes.FETCH_INVOICES_FAILURE,
  payload: error,
});

export const fetchInvoicesClassLevelStart = () => ({
  type: invoiceActionTypes.FETCH_INVOICES_CLASS_LEVEL_START,
});

export const fetchInvoicesClassLevelSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_CLASS_LEVEL_SUCCESS,
  payload: data,
});

export const fetchInvoicesClassLevelFailure = (error) => ({
  type: invoiceActionTypes.FETCH_INVOICES_CLASS_LEVEL_FAILURE,
  payload: error,
});

export const fetchInvoicesSessionsStart = () => ({
  type: invoiceActionTypes.FETCH_INVOICES_SESSIONS_START,
});

export const fetchInvoicesSessionsSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_SESSIONS_SUCCESS,
  payload: data,
});

export const fetchInvoicesSessionsFailure = (error) => ({
  type: invoiceActionTypes.FETCH_INVOICES_SESSIONS_FAILURE,
  payload: error,
});

export const fetchInvoicesTermsStart = (sessionId) => ({
  type: invoiceActionTypes.FETCH_INVOICES_TERMS_START,
  payload: sessionId,
});

export const fetchInvoicesTermsSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_TERMS_SUCCESS,
  payload: data,
});

export const fetchInvoicesTermsFailure = (error) => ({
  type: invoiceActionTypes.FETCH_INVOICES_TERMS_FAILURE,
  payload: error,
});

export const fetchInvoicesItemsStart = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_ITEMS_START,
  payload: data,
});

export const fetchInvoicesItemsSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICES_ITEMS_SUCCESS,
  payload: data,
});

export const fetchInvoicesItemsFailure = (error) => ({
  type: invoiceActionTypes.FETCH_INVOICES_ITEMS_FAILURE,
  payload: error,
});

export const createInvoiceStart = (data, apply, callback) => ({
  type: invoiceActionTypes.CREATE_INVOICE_START,
  payload: { data, apply, callback },
});

export const createInvoiceSuccess = (data) => ({
  type: invoiceActionTypes.CREATE_INVOICE_SUCCESS,
  payload: data,
});

export const createInvoiceFailure = (error) => ({
  type: invoiceActionTypes.CREATE_INVOICE_FAILURE,
  payload: error,
});

export const editInvoiceStart = (data, apply, callback = () => {}) => ({
  type: invoiceActionTypes.EDIT_INVOICE_START,
  payload: { data, apply, callback },
});

export const editInvoiceSuccess = (data) => ({
  type: invoiceActionTypes.EDIT_INVOICE_SUCCESS,
  payload: data,
});

export const editInvoiceFailure = (error) => ({
  type: invoiceActionTypes.EDIT_INVOICE_FAILURE,
  payload: error,
});

export const deleteInvoiceStart = (id) => ({
  type: invoiceActionTypes.DELETE_INVOICE_START,
  payload: id,
});

export const deleteInvoiceSuccess = (id) => ({
  type: invoiceActionTypes.DELETE_INVOICE_SUCCESS,
  payload: id,
});

export const deleteInvoiceFailure = (error) => ({
  type: invoiceActionTypes.DELETE_INVOICE_FAILURE,
  payload: error,
});

export const fetchAllTermsStart = () => ({
  type: invoiceActionTypes.FETCH_ALL_TERMS_START,
  payload: {},
});

export const fetchAllTermsSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_ALL_TERMS_SUCCESS,
  payload: data,
});

export const fetchAllTermsFailure = (error) => ({
  type: invoiceActionTypes.FETCH_ALL_TERMS_FAILURE,
  payload: error,
});

export const handleCreateInvoiceModalAction = (invoiceModal) => ({
  type: invoiceActionTypes.HANDLE_CREATE_INVOICE_MODAL,
  payload: invoiceModal,
});

export const handleEditInvoiceModalAction = (editModal, data) => ({
  type: invoiceActionTypes.HANDLE_EDIT_INVOICE_MODAL,
  payload: { editModal, data },
});

export const handleCloseEditInvoiceModalAction = (editModal) => ({
  type: invoiceActionTypes.HANDLE_CLOSE_EDIT_INVOICE_MODAL,
  payload: { editModal },
});

export const handleDeleteModalAction = (deleteModal) => ({
  type: invoiceActionTypes.HANDLE_DELETE_MODAL,
  payload: deleteModal,
});

export const fetchStudentInvoicesStart = (data) => ({
  type: invoiceActionTypes.FETCH_STUDENT_INVOICES_START,
  payload: data,
});

export const fetchStudentInvoicesSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_STUDENT_INVOICES_SUCCESS,
  payload: data,
});

export const fetchStudentInvoicesFail = (data) => ({
  type: invoiceActionTypes.FETCH_STUDENT_INVOICES_FAIL,
  payload: data,
});

export const handleAddInvoiceItemModal = (data) => ({
  type: invoiceActionTypes.HANDLE_ADD_INVOICE_ITEM_MODAL,
  payload: data,
});

export const handleInvoiceDiscountModal = (data) => ({
  type: invoiceActionTypes.HANDLE_INVOICE_DISCOUNT_MODAL,
  payload: data,
});

export const fetchInvoiceDiscountStart = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICE_DISCOUNT_START,
  payload: data,
});

export const fetchInvoiceDiscountSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_INVOICE_DISCOUNT_SUCCESS,
  payload: data,
});

export const fetchInvoiceDiscountFail = (error) => ({
  type: invoiceActionTypes.FETCH_INVOICE_DISCOUNT_FAIL,
  payload: error,
});

export const addItemToInvoiceStart = (payload) => ({
  type: invoiceActionTypes.ADD_ITEM_TO_INVOICE_START,
  payload,
});

export const addItemToInvoiceSuccess = (payload) => ({
  type: invoiceActionTypes.ADD_ITEM_TO_INVOICE_SUCCESS,
  payload,
});

export const addItemToInvoiceFail = (error) => ({
  type: invoiceActionTypes.ADD_ITEM_TO_INVOICE_FAIL,
  payload: error,
});

export const addInvoiceDiscountStart = (data) => ({
  type: invoiceActionTypes.ADD_INVOICE_DISCOUNT_START,
  payload: data,
});

export const addInvoiceDiscountSuccess = (data) => ({
  type: invoiceActionTypes.ADD_INVOICE_DISCOUNT_SUCCESS,
  payload: data,
});

export const addInvoiceDiscountFail = (error) => ({
  type: invoiceActionTypes.ADD_INVOICE_DISCOUNT_FAIL,
  payload: error,
});

export const deleteInvoiceDiscountStart = (data) => ({
  type: invoiceActionTypes.DELETE_INVOICE_DISCOUNT_START,
  payload: data,
});

export const deleteInvoiceDiscountSuccess = (data) => ({
  type: invoiceActionTypes.DELETE_INVOICE_DISCOUNT_SUCCESS,
  payload: data,
});

export const deleteInvoiceDiscountFail = (error) => ({
  type: invoiceActionTypes.DELETE_INVOICE_DISCOUNT_FAIL,
  payload: error,
});

export const setEditDiscountItem = (item) => ({
  type: invoiceActionTypes.SET_EDIT_DISCOUNT_ITEM,
  payload: item,
});

export const handleEditDiscountModal = (data) => ({
  type: invoiceActionTypes.HANDLE_EDIT_DISCOUNT_MODAL,
  payload: data,
});

export const handleDeleteItemModal = (data) => ({
  type: invoiceActionTypes.HANDLE_DELETE_INVOICE_ITEM_MODAL,
  payload: data,
});

export const deleteInvoiceItemStart = (data) => ({
  type: invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_START,
  payload: data,
});

export const deleteInvoiceItemSuccess = (data) => ({
  type: invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_SUCCESS,
  payload: data,
});

export const deleteInvoiceItemFail = (error) => ({
  type: invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_FAIL,
  payload: error,
});

export const fetchStudentIncoiceSummaryStart = (id) => ({
  type: invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_START,
  payload: id,
});

export const makePaymentStart = (data) => ({
  type: invoiceActionTypes.MAKE_PAYMENT_START,
  payload: data,
});

export const makePaymentSuccess = (data) => ({
  type: invoiceActionTypes.MAKE_PAYMENT_SUCCESS,
  payload: data,
});

export const makePaymentFail = (error) => ({
  type: invoiceActionTypes.MAKE_PAYMENT_FAIL,
  payload: error,
});

export const handleMakePaymentModal = (data) => ({
  type: invoiceActionTypes.HANDLE_MAKE_PAYMENT_MODAL,
  payload: data,
});

export const handlePaymentRegistrationModal = (data) => ({
  type: invoiceActionTypes.HANDLE_PAYMENT_REGISTRATION_MODAL,
  payload: data,
});

export const handleReceiptModal = (data) => ({
  type: invoiceActionTypes.HANDLE_RECEIPT_MODAL,
  payload: data,
});

export const editInvoiceItemsStart = (data) => ({
  type: invoiceActionTypes.EDIT_INVOICE_ITEMS_START,
  payload: data,
});

export const editInvoiceItemsSuccess = (data) => ({
  type: invoiceActionTypes.EDIT_INVOICE_ITEMS_SUCCESS,
  payload: data,
});

export const editInvoiceItemsFail = (error) => ({
  type: invoiceActionTypes.EDIT_INVOICE_ITEMS_FAIL,
  payload: error,
});

export const receivePaymentStart = (data) => ({
  type: invoiceActionTypes.RECEIVE_PAYMENT_START,
  payload: data,
});

export const receivePaymentSuccess = (data) => ({
  type: invoiceActionTypes.RECEIVE_PAYMENT_SUCCESS,
  payload: data,
});

export const receivePaymentFail = (error) => ({
  type: invoiceActionTypes.RECEIVE_PAYMENT_FAIL,
  payload: error,
});

export const handleOutstandingBillModal = (data) => ({
  type: invoiceActionTypes.HANDLE_OUTSTANDING_BILL_MODAL,
  payload: data,
});

export const sendOutstandingBillEmailStart = (data) => ({
  type: invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_START,
  payload: data,
});

export const sendOutstandingBillEmailSuccess = (data) => ({
  type: invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_SUCCESS,
  payload: data,
});

export const sendOutstandingBillEmailFail = (error) => ({
  type: invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_FAIL,
  payload: error,
});

export const getCheckoutStart = (data) => ({
  type: invoiceActionTypes.GET_CHECKOUT_START,
  payload: data,
});

export const getCheckoutSuccess = (data) => ({
  type: invoiceActionTypes.GET_CHECKOUT_SUCCESS,
  payload: data,
});

export const getCheckoutFail = (error) => ({
  type: invoiceActionTypes.GET_CHECKOUT_FAIL,
  payload: error,
});

export const fetchStudentIncoiceSummarySuccess = (data) => ({
  type: invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_SUCCESS,
  payload: data,
});

export const fetchStudentIncoiceSummaryFail = (error) => ({
  type: invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_FAIL,
  payload: error,
});

export const fetchPaymentReceiptsStart = (data) => ({
  type: invoiceActionTypes.FETCH_PAYMENT_RECEIPT_START,
  payload: data,
});

export const fetchPaymentReceiptsSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_PAYMENT_RECEIPT_SUCCESS,
  payload: data,
});

export const fetchPaymentReceiptsFail = (error) => ({
  type: invoiceActionTypes.FETCH_PAYMENT_RECEIPT_FAIL,
  payload: error,
});

export const setActiveReceipt = (data) => ({
  type: invoiceActionTypes.SET_ACTIVE_RECEIPT,
  payload: data,
});

export const verifyPaymentStart = (data) => ({
  type: invoiceActionTypes.VERIFY_PAYMENT_START,
  payload: data,
});

export const verifyPaymentSuccess = (data) => ({
  type: invoiceActionTypes.VERIFY_PAYMENT_SUCCESS,
  payload: data,
});

export const verifyPaymentFail = (error) => ({
  type: invoiceActionTypes.VERIFY_PAYMENT_FAIL,
  payload: error,
});

export const getTransactionLogStart = (data) => ({
  type: invoiceActionTypes.GET_TRANSACTION_LOG_START,
  payload: data,
});

export const getTransactionLogSuccess = (data) => ({
  type: invoiceActionTypes.GET_TRANSACTION_LOG_SUCCESS,
  payload: data,
});

export const getTransactionLogFail = (error) => ({
  type: invoiceActionTypes.GET_TRANSACTION_LOG_FAIL,
  payload: error,
});

export const adminEditInvoiceItemsStart = (data) => ({
  type: invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_START,
  payload: data,
});

export const adminEditInvoiceItemsSuccess = (data) => ({
  type: invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_SUCCESS,
  payload: data,
});

export const adminEditInvoiceItemsFail = (error) => ({
  type: invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_FAIL,
  payload: error,
});

export const setQuantityEdit = (payload) => ({
  type: invoiceActionTypes.SET_QUANTITY_EDIT,
  payload,
});

export const fetchRevenueSummaryStart = () => ({
  type: invoiceActionTypes.FETCH_REVENUE_SUMMARY_START,
});

export const fetchRevenueSummarySuccess = (data) => ({
  type: invoiceActionTypes.FETCH_REVENUE_SUMMARY_SUCCESS,
  payload: data,
});

export const fetchRevenueSummaryFail = (error) => ({
  type: invoiceActionTypes.FETCH_REVENUE_SUMMARY_FAIL,
  payload: error,
});

export const fetchRevenueStatisticsStart = (data) => ({
  type: invoiceActionTypes.FETCH_REVENUE_STATISTICS_START,
  payload: data,
});

export const fetchRevenueStatisticsSuccess = (data) => ({
  type: invoiceActionTypes.FETCH_REVENUE_STATISTICS_SUCCESS,
  payload: data,
});

export const fetchRevenueStatisticsFail = (error) => ({
  type: invoiceActionTypes.FETCH_REVENUE_STATISTICS_FAIL,
  payload: error,
});

export const parentAddInvoiceItemStart = (data) => ({
  type: invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_START,
  payload: data,
});

export const parentAddInvoiceItemSuccess = (data) => ({
  type: invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_SUCCESS,
  payload: data,
});

export const parentAddInvoiceItemFail = (error) => ({
  type: invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_FAIL,
  payload: error,
});

export const getSinglePageCheckoutStart = (data) => ({
  type: invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_START,
  payload: data,
});

export const getSinglePageCheckoutSuccess = (data) => ({
  type: invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_SUCCESS,
  payload: data,
});

export const getSinglePageCheckoutFail = (error) => ({
  type: invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_FAIL,
  payload: error,
});

export const makeDirectPayStart = (data) => ({
  type: invoiceActionTypes.MAKE_DIRECT_PAY_START,
  payload: data,
});

export const makeDirectPaySuccess = (data) => ({
  type: invoiceActionTypes.MAKE_DIRECT_PAY_SUCCESS,
  payload: data,
});

export const makeDirectPayFail = (error) => ({
  type: invoiceActionTypes.MAKE_DIRECT_PAY_FAIL,
  payload: error,
});

export const handleDirectPayModal = (data) => ({
  type: invoiceActionTypes.HANDLE_DIRECT_PAY_MODAL,
  payload: data,
});

export const getBulkCheckoutInfoStart = (data) => ({
  type: invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_START,
  payload: data,
});

export const getBulkCheckoutInfoSuccess = (data) => ({
  type: invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_SUCCESS,
  payload: data,
});

export const getBulkCheckoutInfoFail = (data) => ({
  type: invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_FAIL,
  payload: data,
});

export const makeBulkPaymentStart = (data) => ({
  type: invoiceActionTypes.MAKE_BULK_PAYMENT_START,
  payload: data,
});

export const makeBulkPaymentSuccess = (data) => ({
  type: invoiceActionTypes.MAKE_BULK_PAYMENT_SUCCESS,
  payload: data,
});

export const makeBulkPaymentFail = (error) => ({
  type: invoiceActionTypes.MAKE_BULK_PAYMENT_FAIL,
  payload: error,
});

export const handleMakeBulkPaymentModal = (data) => ({
  type: invoiceActionTypes.HANDLE_MAKE_BULK_PAYMENT_MODAL,
  payload: data,
});

export const updateInvoiceState = (payload: IInvoiceState) => ({
  type: invoiceActionTypes.UPDATE_INVOICE_STATE,
  payload,
});
