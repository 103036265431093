import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchAppModulesFailure, fetchAppModulesSuccess } from '../actions';
import plansActionTypes from '../types';

export function* fetchAppModules() {
  try {
    const response = yield httpClient.get('/app-module');
    yield put(fetchAppModulesSuccess(response.data));
  } catch (error) {
    yield put(fetchAppModulesFailure(error.message));
    const err = handleError(error);
  }
}

export function* onFetchAppModulesStart() {
  yield takeLatest(plansActionTypes.FETCH_APP_MODULES_START, fetchAppModules);
}
