import { SchoolFeesPaymentsRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { makeBulkPaymentFail, makeBulkPaymentSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* makeBulkPayment({ payload }) {
  try {
    const { callback } = payload;
    delete payload.callback;

    const response = yield apiWrapper(() =>
      SchoolFeesPaymentsRestControllerService.initOnlineBulkPaymentUsingPost({ request: payload }),
    );

    yield put(makeBulkPaymentSuccess(response));

    if (payload?.payment_method === 'PAYSTACK' || payload?.payment_method === 'STRIPE') {
      callback({
        reference: response?.safsims_transaction_ref,
        subaccounts: response?.fees_payments?.map((item) => ({
          subaccount: item.subaccount_ref,
          share: parseInt(item.amount),
        })),
        connectId: response?.connect_id,
        clientSecret: response?.client_secret,
      });
    } else {
      window.location.href = response?.link;
    }
  } catch (error) {
    yield put(makeBulkPaymentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onMakeBulkPaymentStart() {
  yield takeLatest(invoiceActionTypes.MAKE_BULK_PAYMENT_START, makeBulkPayment);
}
