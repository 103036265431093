import { put, takeLatest } from 'redux-saga/effects';
import authActionTypes from '../types';
import httpClient from '../../../utils/http-client';
import { resetPasswordFailure, resetPasswordSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';

export function* resetPassword({ data }) {
  try {
    delete httpClient.defaults.headers.common['Authorization'];
    const response = yield httpClient.post(`/auth/password/reset`, data);
    yield put(resetPasswordSuccess(response.data));
    Notify('Password Created Successfully, Please login now.', { position: 'top-right' });
  } catch (error) {
    yield put(resetPasswordFailure(error));
    const err = handleError(error);
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(authActionTypes.RESET_SET_PASSWORD_START, resetPassword);
}
