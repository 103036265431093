import { takeLatest, put } from 'redux-saga/effects';
import { createStudentSuccess, createStudentFail, resetEverythingAction } from '../action';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import studentActionTypes from '../types';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';

export function* createStudent({ payload }) {
  const formData = payload;
  try {
    const response = yield httpClient.post(`/students`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    yield put(createStudentSuccess(response.data));
    Notify('Student created successfully', { position: 'top-right' });
    yield put(resetEverythingAction());
  } catch (error) {
    yield put(createStudentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateStudentStart() {
  yield takeLatest(studentActionTypes.CREATE_STUDENT_START, createStudent);
}
