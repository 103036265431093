import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { editCurrentUserDetails } from 'redux/users/actions';
import { school_id } from 'utils/constants';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { updateStaffSignatureFailure, updateStaffSignatureSuccess } from '../actions';
import staffActionTypes from '../types';

export function* updateStaffSignature({ payload }) {
  const { data, id, isCurrentUser } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post(`/staff/${id}/uploads/signature`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    if (isCurrentUser) {
      yield put(editCurrentUserDetails({ signature: response.data }));
    } else {
      yield put(updateStaffSignatureSuccess(response.data, id));
    }
    Notify('Staff Signature Updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(updateStaffSignatureFailure(error?.message));
    handleError(error);
  }
}

export function* onUpdateStaffSignatureStart() {
  yield takeLatest(staffActionTypes.UPDATE_STAFF_SIGNATURE_START, updateStaffSignature);
}
