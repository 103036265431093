import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { filterStaffsFailure, filterStaffsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* filterStaffs({ payload }) {
  try {
    const response = yield httpClient.get('/staff/filter', { params: payload });
    yield put(filterStaffsSuccess(response.data));
  } catch (error) {
    yield put(filterStaffsFailure(error?.message));
    handleError(error);
  }
}

export function* onFilterStaffsStart() {
  yield takeLatest(staffActionTypes.FILTER_STAFFS_START, filterStaffs);
}
