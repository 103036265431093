import { takeLatest, put } from 'redux-saga/effects';
import { fetchParentStatsFailure, fetchParentStatsSuccess } from '../actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchParentsStats() {
  try {
    const response = yield httpClient.get(`/parents/statistics`);
    yield put(fetchParentStatsSuccess(response.data));
  } catch (error) {
    yield put(fetchParentStatsFailure(error?.message));
    handleError(error);
  }
}

export function* onFetchParentsStatsStart() {
  yield takeLatest(parentActionTypes.FETCH_PARENT_STATISTICS_START, fetchParentsStats);
}
