import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { fetchStudentDocsFail, fetchStudentDocsSuccess } from '../action';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchStudentDoc({ payload }) {
  try {
    const response = yield httpClient.get(`/documents/student/${encodeURIComponent(payload)}`);
    yield put(fetchStudentDocsSuccess(response.data));
  } catch (error) {
    const err = handleError(error);
    yield put(fetchStudentDocsFail(error?.message));
  }
}

export function* onFetchStudentDocument() {
  yield takeLatest(studentActionTypes.FETCH_STUDENT_DOCS_START, fetchStudentDoc);
}
