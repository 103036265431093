/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenAiRestControllerService {
  /**
   * generateBulkSubjectTeacherComment
   * @returns any OK
   * @throws ApiError
   */
  public static generateBulkSubjectTeacherCommentUsingGet({
    armId,
    classLevelId,
    subjectId,
    termId,
  }: {
    /**
     * armId
     */
    armId: string;
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * subjectId
     */
    subjectId: string;
    /**
     * termId
     */
    termId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/openai/generate-all-subject-comment',
      query: {
        armId: armId,
        classLevelId: classLevelId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * generateComment
   * @returns string OK
   * @throws ApiError
   */
  public static generateCommentUsingGet({
    studentId,
    termId,
  }: {
    /**
     * student-id
     */
    studentId: string;
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/openai/generate-comment',
      query: {
        'student-id': studentId,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * generateSubjectTeacherComment
   * @returns string OK
   * @throws ApiError
   */
  public static generateSubjectTeacherCommentUsingGet({
    classLevelId,
    studentId,
    subjectId,
    termId,
  }: {
    /**
     * classLevelId
     */
    classLevelId: string;
    /**
     * studentId
     */
    studentId: string;
    /**
     * subjectId
     */
    subjectId: string;
    /**
     * termId
     */
    termId: string;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/openai/generate-subject-comment',
      query: {
        classLevelId: classLevelId,
        studentId: studentId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
