import { put, takeLatest } from 'redux-saga/effects';
import assessmentActionTypes from '../types';
import { fetchLevelsAssessmentsSuccess, fetchLevelsAssessmentsFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchAssessment({ payload }) {
  const params = payload?.term_id ? { params: { 'term-id': payload.term_id } } : {};
  try {
    const response = yield httpClient.get('/class-levels/term', params);
    yield put(fetchLevelsAssessmentsSuccess(response.data));
  } catch (error) {
    yield put(fetchLevelsAssessmentsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchLevelsAssessmentstart() {
  yield takeLatest(assessmentActionTypes.FETCH_LEVELS_ASSESSMENTS_START, fetchAssessment);
}
