import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { findIndexInArray, InsertBeginning } from 'utils/utils';
import studentActionTypes from './types';

const INITIAL_STATE = {
  loading: false,
  downloading: false,
  modal: false,
  studentList: [],
  studentProfileData: {},
  states: [],
  lga: [],
  edit: false,
  studentBio: {},
  studentOtherInfo: {},
  countries: [],
  buttonProgress: false,
  error: null,
  bulkModal: false,
  totalStudents: 0,
  totalStudentsWithoutClass: 0,
  stats: {},
  profilePictureEdit: false,
  saveButtonLoading: false,
  deleteModal: false,
  deletedStudentId: null,
  linkModal: false,
  documentModal: false,
  documentLoading: false,
  studentDocuments: [],
  studentsWithoutClass: [],
  deleteDocModal: false,
  isLoading: false,
  isFetching: false,
  currentStudent: {},
  parentUnlinkModal: false,
  allStudents: [],
  studentActiveTab: '',
  File: null,
};

function studentReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case studentActionTypes.CREATE_STUDENT_MODAL:
      return {
        ...state,
        modal: payload,
      };

    case studentActionTypes.FETCH_STUDENTS_START:
      return {
        ...state,
        loading: true,
      };
    case studentActionTypes.FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentList: payload.content,
        totalStudents: payload.total_elements,
        studentProfileData: {},
        currentStudent: {},
      };
    case studentActionTypes.FETCH_STUDENTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_START:
      return {
        ...state,
        isFetching: true,
      };
    case studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        studentsWithoutClass: payload.content,
        totalStudentsWithoutClass: payload.total_elements,
      };
    case studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case studentActionTypes.REMOVED_ADDED_STUDENTS_FROM_LIST:
      payload.forEach((element) => {
        const indexOfDeleteStudent = findIndexInArray(
          state.studentsWithoutClass,
          { id: element },
          'id',
        );
        state.studentsWithoutClass.splice(indexOfDeleteStudent, 1);
      });
      return {
        ...state,
        totalStudentsWithoutClass: state.totalStudentsWithoutClass - payload.length,
      };

    case studentActionTypes.HANDLE_STUDENT_PROFILE_DATA:
      return {
        ...state,
        studentProfileData: payload,
        profilePictureEdit: false,
      };
    case studentActionTypes.SAVE_STUDENT_BIO:
      return {
        ...state,
        studentBio: payload,
      };
    case studentActionTypes.CREATE_STUDENT_START:
      return {
        ...state,
        modal: true,
        buttonProgress: true,
        error: null,
      };
    case studentActionTypes.CREATE_STUDENT_SUCCESS:
      const modifiedEntities = InsertBeginning(state.studentList, payload);
      return {
        ...state,
        modal: false,
        studentList: modifiedEntities,
        states: [],
        lga: [],
        edit: false,
        studentBio: {},
        studentOtherInfo: {},
        buttonProgress: false,
        totalStudents: state.totalStudents + 1,
      };
    case studentActionTypes.CREATE_STUDENT_FAIL:
      return {
        ...state,
        modal: true,
        buttonProgress: false,
        error: payload,
      };
    case studentActionTypes.FETCH_COUNTRIES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case studentActionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: payload,
      };
    case studentActionTypes.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case studentActionTypes.FETCH_STATES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case studentActionTypes.FETCH_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        states: payload,
      };
    case studentActionTypes.FETCH_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case studentActionTypes.FETCH_LGA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case studentActionTypes.FETCH_LGA_SUCCESS:
      return {
        ...state,
        loading: false,
        lga: payload,
      };
    case studentActionTypes.FETCH_LGA_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case studentActionTypes.RESET_LGA:
      return {
        ...state,
        lga: payload,
      };
    case studentActionTypes.RESET_EVERYTHING:
      return {
        ...state,
        states: [],
        lga: [],
        studentBio: {},
        edit: false,
        studentOtherInfo: {},
        modal: false,
      };
    case studentActionTypes.EDIT_STUDENT_START:
      return {
        ...state,
        buttonProgress: false,
        error: null,
      };
    case studentActionTypes.EDIT_STUDENT_SUCCESS:
      const { studentList } = state;
      const indexOfEditedUser = findIndexInArray(studentList, payload, 'student_id');
      studentList.splice(indexOfEditedUser, 1, payload);
      return {
        ...state,
        studentList,
        modal: false,
        states: [],
        lga: [],
        edit: false,
        studentProfileData: payload,
        studentBio: {},
        studentOtherInfo: {},
        buttonProgress: false,
      };
    case studentActionTypes.EDIT_STUDENT_FAIL:
      return {
        ...state,
        modal: true,
        buttonProgress: false,
        error: payload,
      };
    case studentActionTypes.HANDLE_EDIT_STUDENT:
      return {
        ...state,
        modal: true,
        studentBio: payload.studentBio,
        studentOtherInfo: payload.studentOtherInfo,
        studentProfileData: payload.studentProfileData,
        edit: true,
      };
    case studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_START:
      return {
        ...state,
        downloading: true,
        error: payload,
      };
    case studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
      };

    case studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILURE:
      return {
        ...state,
        downloading: false,
        error: payload,
      };

    case studentActionTypes.BULK_STUDENT_UPLOAD_START:
      return {
        ...state,
        buttonProgress: true,
        error: null,
      };

    case studentActionTypes.BULK_STUDENT_UPLOAD_SUCCESS:
      return {
        ...state,
        buttonProgress: false,
        bulkModal: false,
      };

    case studentActionTypes.BULK_STUDENT_UPLOAD_FAILURE:
      return {
        ...state,
        buttonProgress: false,
        error: payload,
        bulkModal: true,
      };

    case studentActionTypes.HANDLE_SET_STUDENT_DOC:
      return {
        ...state,
        File: payload,
      };

    case studentActionTypes.UPLOAD_STUDENT_DOCS_START:
      return {
        ...state,
        documentLoading: true,
        error: null,
      };
    case studentActionTypes.UPLOAD_STUDENT_DOCS_SUCCESS:
      return {
        ...state,
        documentLoading: false,
        error: null,
        documentModal: false,
        studentActiveTab: 'documents',
        File: null,
      };
    case studentActionTypes.UPLOAD_STUDENT_DOCS_FAIL:
      return {
        ...state,
        documentLoading: false,
        documentModal: false,
        error: payload,
      };
    case studentActionTypes.FETCH_STUDENT_DOCS_START:
      return {
        ...state,
        error: null,
      };
    case studentActionTypes.FETCH_STUDENT_DOCS_SUCCESS:
      return {
        ...state,
        error: null,
        studentDocuments: payload,
      };
    case studentActionTypes.FETCH_STUDENT_DOCS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case studentActionTypes.HANDLE_STUDENT_DOC_MODAL:
      return {
        ...state,
        documentModal: !payload,
        File: null,
      };
    case studentActionTypes.HANDLE_BULK_STUDENT_MODAL:
      return {
        ...state,
        bulkModal: !payload,
        buttonProgress: false,
      };
    case studentActionTypes.STUDENT_STATUS_START:
      return {
        ...state,
        studentProfileData: payload,
        error: null,
      };
    case studentActionTypes.STUDENT_STATUS_SUCCESS:
      return {
        ...state,
        studentProfileData: payload,
      };
    case studentActionTypes.STUDENT_STATUS_FAILURE:
      state.studentProfileData.active_status =
        payload.data.active_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
      return {
        ...state,
        studentProfileData: state.studentProfileData,
        error: payload.error,
      };
    case studentActionTypes.FETCH_STUDENT_STATISTICS_START:
      return {
        ...state,
      };
    case studentActionTypes.FETCH_STUDENT_STATISTICS_SUCCESS:
      return {
        ...state,
        stats: payload,
      };
    case studentActionTypes.FETCH_STUDENT_STATISTICS_FAILURE:
      return {
        ...state,
      };
    case studentActionTypes.UPDATE_STUDENT_PHOTO_START:
      return {
        ...state,
        buttonProgress: true,
        error: null,
      };
    case studentActionTypes.UPDATE_STUDENT_PHOTO_SUCCESS:
      const current_student = state.studentList.filter((student) => {
        return student.id === payload.id;
      })[0];
      current_student.profile_pic = payload.link;
      const indexOfCurrentStudent = findIndexInArray(state.studentList, current_student, 'id');
      state.studentList.splice(indexOfCurrentStudent, 1, current_student);
      return {
        ...state,
        studentProfileData: current_student,
        buttonProgress: false,
        profilePictureEdit: false,
        studentBio: current_student,
      };
    case studentActionTypes.UPDATE_STUDENT_PHOTO_FAIL:
      return {
        ...state,
        buttonProgress: false,
        error: payload,
      };
    case studentActionTypes.HANDLE_UPDATE_PHOTO:
      return {
        ...state,
        profilePictureEdit: payload,
      };
    case studentActionTypes.SAVE_AND_RECREATE_STUDENT_START:
      return {
        ...state,
        saveButtonLoading: true,
        error: null,
      };
    case studentActionTypes.SAVE_AND_RECREATE_STUDENT_SUCCESS:
      const saveModifiedEntities = InsertBeginning(state.studentList, payload);
      return {
        ...state,
        saveButtonLoading: false,
        studentList: saveModifiedEntities,
        states: [],
        lga: [],
        edit: false,
        studentBio: {},
        studentOtherInfo: {},
        buttonProgress: false,
        totalStudents: state.totalStudents + 1,
      };
    case studentActionTypes.SAVE_AND_RECREATE_STUDENT_FAIL:
      return {
        ...state,
        saveButtonLoading: false,
        error: payload,
      };
    case studentActionTypes.HANDLE_RECREATE:
      return {
        ...state,
        states: [],
        lga: [],
        studentBio: {},
        edit: false,
        studentOtherInfo: {},
        studentProfileData: {},
      };
    case studentActionTypes.FILTER_STUDENTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case studentActionTypes.FILTER_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentList: payload.content,
        totalStudents: payload.total_elements,
      };
    case studentActionTypes.FILTER_STUDENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case studentActionTypes.HANDLE_SOFT_DELETE_STUDENT:
      return {
        ...state,
        deleteModal: payload,
      };
    case studentActionTypes.DELETE_STUDENT_START:
      return {
        ...state,
        deletedStudentId: payload,
        deleteModal: true,
        buttonProgress: true,
        error: null,
      };
    case studentActionTypes.DELETE_STUDENT_SUCCESS:
      const newStudentList = state.studentList.filter(({ student_id }) => student_id !== payload);
      const newTotalStudentList = state.allStudents.filter(
        ({ student_id }) => student_id !== payload,
      );
      return {
        ...state,
        buttonProgress: false,
        studentProfileData: {},
        deleteModal: false,
        deletedStudentId: null,
        studentList: newStudentList,
        allStudents: newTotalStudentList,
      };
    case studentActionTypes.DELETE_STUDENT_FAIL:
      return {
        ...state,
        buttonProgress: false,
        deleteModal: true,
        error: payload,
      };
    case studentActionTypes.HANDLE_LINK_CHILD_MODAL:
      return {
        ...state,
        linkModal: payload,
      };
    case studentActionTypes.LINK_PARENT_START:
      return {
        ...state,
        buttonProgress: true,
      };
    case studentActionTypes.LINK_PARENT_SUCCESS:
      return {
        ...state,
        buttonProgress: false,
        linkModal: false,
      };
    case studentActionTypes.LINK_PARENT_FAIL:
      return {
        ...state,
        buttonProgress: false,
      };

    case studentActionTypes.STOP_UPLOAD_STUDENT_BULK_LOADING:
      return {
        ...state,
        loading: false,
      };

    case studentActionTypes.DOWNLOAD_STUDENT_DOCS_START:
      return {
        ...state,
        error: null,
      };
    case studentActionTypes.DOWNLOAD_STUDENT_DOCS_SUCCESS:
      return {
        ...state,
      };
    case studentActionTypes.DOWNLOAD_STUDENT_DOCS_FAIL:
      return {
        ...state,
        error: payload,
      };

    case studentActionTypes.HANDLE_DELETE_DOC_MODAL:
      return {
        ...state,
        deleteDocModal: !payload,
      };

    case studentActionTypes.DELETE_STUDENT_DOCS_START:
      return {
        ...state,
        isLoading: true,
      };
    case studentActionTypes.DELETE_STUDENT_DOCS_SUCCESS:
      const indexOfDeleteStudentDocument = findIndexInArray(
        state.studentDocuments,
        { payload },
        'aws_s3_key',
      );
      state.studentDocuments.splice(indexOfDeleteStudentDocument, 1);
      return {
        ...state,
        isLoading: false,
        deleteDocModal: false,
      };
    case studentActionTypes.DELETE_STUDENT_DOCS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case studentActionTypes.FETCH_STUDENT_START:
      return {
        ...state,
        loading: true,
      };
    case studentActionTypes.FETCH_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudent: payload,
      };
    case studentActionTypes.FETCH_STUDENT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case studentActionTypes.HANDLE_UNLINK_PARENT_MODAL:
      return {
        ...state,
        parentUnlinkModal: payload,
      };
    case studentActionTypes.UNLINK_PARENT_START:
      return {
        ...state,
        buttonProgress: true,
      };
    case studentActionTypes.UNLINK_PARENT_SUCCESS:
      return {
        ...state,
        buttonProgress: false,
        parentUnlinkModal: false,
        linkModal: false,
      };
    case studentActionTypes.UNLINK_PARENT_FAILURE:
      return {
        ...state,
        buttonProgress: false,
        parentUnlinkModal: true,
      };
    case studentActionTypes.GET_TOTAL_STUDENTS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case studentActionTypes.GET_TOTAL_STUDENTS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        allStudents: payload,
      };
    case studentActionTypes.GET_TOTAL_STUDENTS_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case studentActionTypes.SET_STUDENT_ACTIVE_TAB:
      return {
        ...state,
        studentActiveTab: payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'student',
  storage,
  blacklist: [
    'loading',
    'modal',
    'buttonProgress',
    'bulkModal',
    'deleteModal',
    'linkModal',
    'documentModal',
    'documentLoading',
    'deleteDocModal',
    'isLoading',
    'currentStudent',
    'isFetching',
    'parentUnlinkModal',
    'studentDocuments',
    'downloading',
  ],
};

export default persistReducer(persistConfig, studentReducer);
