import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { removedAddedStudentsFromListAction } from '../../student/action';
import { assignStudentsFail, assignStudentsSuccess, getClassMemberStart } from '../actions';
import configActionTypes from '../types';

export function* assignStudents({ payload }) {
  const callback = payload.callback;
  delete payload.callback;
  const { class_information_dto } = payload;
  const { arm, class_level, term } = class_information_dto;
  try {
    const response = yield httpClient.post(`/class-members`, payload);
    yield put(assignStudentsSuccess(response.data));
    yield put(removedAddedStudentsFromListAction(payload.student_ids));
    yield put(getClassMemberStart(class_level.id, arm.id, term?.session?.id, term.term_id));
    callback?.();
    Notify('Student Assigned succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(assignStudentsFail(error));
    const err = handleError(error);
  }
}

export function* onAssignStudentStart() {
  yield takeLatest(configActionTypes.ASSIGN_STUDENTS_TO_CLASS_START, assignStudents);
}
