enum broadSheetTypes {
  FETCH_CLASS_ARM_BROADSHEET_START = 'FETCH_CLASS_ARM_BROADSHEET_START',
  FETCH_CLASS_ARM_BROADSHEET_SUCCESS = 'FETCH_CLASS_ARM_BROADSHEET_SUCCESS',
  FETCH_CLASS_ARM_BROADSHEET_FAIL = 'FETCH_CLASS_ARM_BROADSHEET_FAIL',

  SET_DEFAULT_TEMPLATE_CLASS_START = 'SET_DEFAULT_TEMPLATE_CLASS_START',
  SET_DEFAULT_TEMPLATE_CLASS_SUCCESS = 'SET_DEFAULT_TEMPLATE_CLASS_SUCCESS',
  SET_DEFAULT_TEMPLATE_CLASS_FAIL = 'SET_DEFAULT_TEMPLATE_CLASS_FAIL',

  FETCH_CLASS_LEVEL_BROADSHEET_START = 'FETCH_CLASS_LEVEL_BROADSHEET_START',
  FETCH_CLASS_LEVEL_BROADSHEET_SUCCESS = 'FETCH_CLASS_LEVEL_BROADSHEET_SUCCESS',
  FETCH_CLASS_LEVEL_BROADSHEET_FAIL = 'FETCH_CLASS_LEVEL_BROADSHEET_FAIL',

  SINGLE_STUDENT_RESULT_APPROVAL_START = 'SINGLE_STUDENT_RESULT_APPROVAL_START',
  SINGLE_STUDENT_RESULT_APPROVAL_SUCCESS = 'SINGLE_STUDENT_RESULT_APPROVAL_SUCCESS',
  SINGLE_STUDENT_RESULT_APPROVAL_FAIL = 'SINGLE_STUDENT_RESULT_APPROVAL_FAIL',

  FETCH_CUMMULATIVE_ARM_BROADSHEET_START = 'FETCH_CUMMULATIVE_ARM_BROADSHEET_START',
  FETCH_CUMMULATIVE_ARM_BROADSHEET_SUCCESS = 'FETCH_CUMMULATIVE_ARM_BROADSHEET_SUCCESS',
  FETCH_CUMMULATIVE_ARM_BROADSHEET_FAIL = 'FETCH_CUMMULATIVE_ARM_BROADSHEET_FAIL',

  FETCH_CUMULATIVE_LEVEL_BROADSHEET_START = 'FETCH_CUMULATIVE_LEVEL_BROADSHEET_START',
  FETCH_CUMULATIVE_LEVEL_BROADSHEET_SUCCESS = 'FETCH_CUMULATIVE_LEVEL_BROADSHEET_SUCCESS',
  FETCH_CUMULATIVE_LEVEL_BROADSHEET_FAIL = 'FETCH_CUMULATIVE_LEVEL_BROADSHEET_FAIL',

  FETCH_STUDENT_RESULT_DATA_START = 'FETCH_STUDENT_RESULT_DATA_START',
  FETCH_STUDENT_RESULT_DATA_SUCCESS = 'FETCH_STUDENT_RESULT_DATA_SUCCESS',
  FETCH_STUDENT_RESULT_DATA_FAIL = ' FETCH_STUDENT_RESULT_DATA_FAIL',

  FETCH_BULK_STUDENTS_RESULT_DATA_START = 'FETCH_BULK_STUDENTS_RESULT_DATA_START',
  FETCH_BULK_STUDENTS_RESULT_DATA_SUCCESS = 'FETCH_BULK_STUDENTS_RESULT_DATA_SUCCESS',
  FETCH_BULK_STUDENTS_RESULT_DATA_FAIL = ' FETCH_BULK_STUDENTS_RESULT_DATA_FAIL',
}

export default broadSheetTypes;
