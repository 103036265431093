import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { uploadSignatureFail, uploadSignatureSuccess } from '../actions';
import configActionTypes from '../types';

export function* uploadSignature({ payload }) {
  const formData = payload;
  try {
    const response = yield httpClient.post(`/school-info/uploads/principal-signature`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(uploadSignatureSuccess(response.data));
    Notify("Principal's signature uploaded succesfully", { position: 'top-right' });
  } catch (error) {
    yield put(uploadSignatureFail(error.fail));
    const err = handleError(error);
  }
}

export function* onUploadSignature() {
  yield takeLatest(configActionTypes.UPLOAD_SIGNATURE_START, uploadSignature);
}
