import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { fetchPayableItemsFail, fetchPayableItemsSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchPayableItem({ payload }) {
  try {
    const response = yield httpClient.get(`/payable-items`, { params: payload });
    yield put(fetchPayableItemsSuccess(response.data));
  } catch (error) {
    yield put(fetchPayableItemsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchPayableItems() {
  yield takeLatest(configActionTypes.FETCH_PAYABLE_ITEMS_START, fetchPayableItem);
}
