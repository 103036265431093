import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { termProfileSetEarlyYearsSuccess, termProfileSetEarlyYearsFail } from '../actions';
import { handleError } from 'utils/utils';

export function* termProfileSetEarlyYears({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.post('/early-years/termly', payload);
    yield put(termProfileSetEarlyYearsSuccess(response.data));
    callback();
  } catch (error) {
    yield put(termProfileSetEarlyYearsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTermProfileSetEarlyYears() {
  yield takeLatest(configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_START, termProfileSetEarlyYears);
}
