import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { apiWrapper } from 'utils/http-client';
import { returnUpdatedList } from 'utils/utils';

type ServiceFunction<A, B> = (payload: A) => Promise<B>;

interface UseGetRequestOptions<A, B> {
  service: ServiceFunction<A, B>;
  payload: A;
  page?: number;
  limit?: number;
  searchText?: string;
  enabled?: boolean;
  onSuccess?: (val?: any) => void;
  onError?: (val?: any) => void;
  tag: string;
}

interface UseGetRequestResult<B> {
  loading: boolean;
  isLoading: boolean;
  data?: B;
  onUpdateCallback?: (val: any, key?: string) => void;
  onAddCallback?: (val: B) => void;
  error?: any;
  refetch?: () => void;
  isRefetching?: boolean;
}

export function useGetRequest<A, B>({
  service,
  payload,
  onError,
  tag,
  enabled = true,
}: UseGetRequestOptions<A, B>): UseGetRequestResult<B> {
  const methodName = service.name;
  const queryClient = useQueryClient();
  const queryKey = [tag, { methodName, payload }];

  const info = useQuery({
    queryKey,
    queryFn: () => apiWrapper(() => service(payload)),
    enabled,
  });

  useEffect(() => {
    if (info.isError) {
      // handleError?.(info.error);
      onError?.(info.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.isError, info.error]);

  const onAddCallback = (data) => {
    const list: any = queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, [...list, data]);
  };

  const onUpdateCallback = (data, key = 'id') => {
    const list: any = queryClient.getQueryData(queryKey);
    const newList = returnUpdatedList(data, list, key);
    queryClient.setQueryData(queryKey, [...newList]);
  };

  return {
    loading: info.isLoading,
    isLoading: info.isLoading,
    data: info.data,
    onAddCallback,
    onUpdateCallback,
    error: info.error,
    refetch: info.refetch,
    isRefetching: info.isRefetching || info.isFetching,
  };
}
