/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page_StudentInvoiceSummaryAndItemsDto_ } from '../models/Page_StudentInvoiceSummaryAndItemsDto_';
import type { Page_StudentInvoiceSummaryDto_ } from '../models/Page_StudentInvoiceSummaryDto_';
import type { SendInvoiceNotificationRequest } from '../models/SendInvoiceNotificationRequest';
import type { StudentInvoiceSummaryAndItemsDto } from '../models/StudentInvoiceSummaryAndItemsDto';
import type { StudentTermInvoiceSummaryDto } from '../models/StudentTermInvoiceSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudentInvoiceSummaryRestControllerService {
  /**
   * getAllByTerm
   * @returns Page_StudentInvoiceSummaryAndItemsDto_ OK
   * @throws ApiError
   */
  public static getAllByTermUsingGet1({
    armId,
    classLevelId,
    limit,
    month,
    offset,
    paymentType,
    search,
    sectionId,
    termId,
    year,
  }: {
    /**
     * arm-id
     */
    armId?: string;
    /**
     * class-level-id
     */
    classLevelId?: string;
    limit?: number;
    /**
     * month
     */
    month?:
      | 'APRIL'
      | 'AUGUST'
      | 'DECEMBER'
      | 'FEBRUARY'
      | 'JANUARY'
      | 'JULY'
      | 'JUNE'
      | 'MARCH'
      | 'MAY'
      | 'NOVEMBER'
      | 'OCTOBER'
      | 'SEPTEMBER';
    offset?: number;
    /**
     * payment-type
     */
    paymentType?: 'ALL' | 'FULL' | 'OVER_PAID' | 'PART' | 'UNPAID';
    /**
     * search
     */
    search?: string;
    /**
     * section-id
     */
    sectionId?: string;
    /**
     * term-id
     */
    termId?: string;
    /**
     * year
     */
    year?: number;
  }): CancelablePromise<Page_StudentInvoiceSummaryAndItemsDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-summaries',
      query: {
        'arm-id': armId,
        'class-level-id': classLevelId,
        limit: limit,
        month: month,
        offset: offset,
        'payment-type': paymentType,
        search: search,
        'section-id': sectionId,
        'term-id': termId,
        year: year,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getByClassArm
   * @returns Page_StudentInvoiceSummaryDto_ OK
   * @throws ApiError
   */
  public static getByClassArmUsingGet({
    armId,
    classLevelId,
    termId,
    limit,
    offset,
  }: {
    /**
     * arm-id
     */
    armId: string;
    /**
     * class-level-id
     */
    classLevelId: string;
    /**
     * term-id
     */
    termId: string;
    limit?: number;
    offset?: number;
  }): CancelablePromise<Page_StudentInvoiceSummaryDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-summaries/class-arms',
      query: {
        'arm-id': armId,
        'class-level-id': classLevelId,
        limit: limit,
        offset: offset,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getByClassLevel
   * @returns Page_StudentInvoiceSummaryDto_ OK
   * @throws ApiError
   */
  public static getByClassLevelUsingGet1({
    classLevelId,
    termId,
    limit,
    offset,
  }: {
    /**
     * class-level-id
     */
    classLevelId: string;
    /**
     * term-id
     */
    termId: string;
    limit?: number;
    offset?: number;
  }): CancelablePromise<Page_StudentInvoiceSummaryDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-summaries/class-levels',
      query: {
        'class-level-id': classLevelId,
        limit: limit,
        offset: offset,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * sendInvoiceNotification
   * @returns any OK
   * @throws ApiError
   */
  public static sendInvoiceNotificationUsingPost({
    request,
    xTenantId,
  }: {
    /**
     * request
     */
    request: SendInvoiceNotificationRequest;
    /**
     * X-TENANT-ID
     */
    xTenantId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/invoice-summaries/notification',
      headers: {
        'X-TENANT-ID': xTenantId,
      },
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getAllByTerm
   * @returns StudentInvoiceSummaryAndItemsDto OK
   * @throws ApiError
   */
  public static getAllByTermUsingGet({
    armId,
    classLevelId,
    month,
    paymentType,
    search,
    sectionId,
    termId,
    year,
  }: {
    /**
     * arm-id
     */
    armId?: string;
    /**
     * class-level-id
     */
    classLevelId?: string;
    /**
     * month
     */
    month?:
      | 'APRIL'
      | 'AUGUST'
      | 'DECEMBER'
      | 'FEBRUARY'
      | 'JANUARY'
      | 'JULY'
      | 'JUNE'
      | 'MARCH'
      | 'MAY'
      | 'NOVEMBER'
      | 'OCTOBER'
      | 'SEPTEMBER';
    /**
     * payment-type
     */
    paymentType?: 'ALL' | 'FULL' | 'OVER_PAID' | 'PART' | 'UNPAID';
    /**
     * search
     */
    search?: string;
    /**
     * section-id
     */
    sectionId?: string;
    /**
     * term-id
     */
    termId?: string;
    /**
     * year
     */
    year?: number;
  }): CancelablePromise<Array<StudentInvoiceSummaryAndItemsDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-summaries/unpaged',
      query: {
        'arm-id': armId,
        'class-level-id': classLevelId,
        month: month,
        'payment-type': paymentType,
        search: search,
        'section-id': sectionId,
        'term-id': termId,
        year: year,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getStudentInvoiceSummary
   * @returns StudentTermInvoiceSummaryDto OK
   * @throws ApiError
   */
  public static getStudentInvoiceSummaryUsingGet({
    studentId,
  }: {
    /**
     * student-id
     */
    studentId: string;
  }): CancelablePromise<Array<StudentTermInvoiceSummaryDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-summaries/{student-id}',
      path: {
        'student-id': studentId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
