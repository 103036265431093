import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchStudentsWithoutClassStart } from 'redux/student/action';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { removeStudentFromClassFail, removeStudentFromClassSuccess } from '../actions';
import configActionTypes from '../types';

export function* removeStudent({ payload }) {
  const { id, classLevelId, armId, sessionId, termId, refetchClassMembers } = payload;
  try {
    const response = yield httpClient.delete(`/class-members/${id}`, {
      params: { classLevelId, armId, sessionId, termId },
    });
    yield put(removeStudentFromClassSuccess(response));
    Notify('Student Removed succesfully', { position: 'top-right' });
    refetchClassMembers();
    yield put(
      fetchStudentsWithoutClassStart({
        offset: 0,
        limit: 10,
        'term-id': termId,
        'session-id': sessionId,
      }),
    );
  } catch (error) {
    yield put(removeStudentFromClassFail(error?.message));
    const err = handleError(error);
  }
}

export function* onRemoveStudentStart() {
  yield takeLatest(configActionTypes.REMOVE_STUDENT_FROM_CLASS_START, removeStudent);
}
