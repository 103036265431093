import { event, initialize, pageview } from 'react-ga';

export const GOOGLE_TRACKING_ID = 'G-23ZQXYSL26';

export const initializeGoogleAnalytics = () => {
  window.process = { ...window.process };
  if (process.env.NODE_ENV === 'production') {
    initialize(GOOGLE_TRACKING_ID);
  }
};

export const googleAnalyticsMonitorPages = () => {
  window.process = { ...window.process };
  if (process.env.NODE_ENV === 'production') {
    pageview(window.location.pathname + window.location.search);
  }
};

interface IProps {
  category: string;
}

export const useAnalyticsEventTracker = ({ category }: IProps) => {
  const eventTracker = (action: string, label: string) => {
    event({ category, action, label });
  };
  return eventTracker;
};
