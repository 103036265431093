import { takeLatest, put } from 'redux-saga/effects';
import { createArmSuccess, createArmFail } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from '../../../utils/utils';
import { setDisableNext } from 'redux/users/actions';

export function* createClassArm({ payload }) {
  try {
    const response = yield httpClient.post(`/arms`, payload);
    yield put(createArmSuccess(response.data));
    Notify('Arm created successfully', { position: 'top-right' });
  } catch (error) {
    yield put(createArmFail(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateArmStart() {
  yield takeLatest(configActionTypes.CREATE_ARM_START, createClassArm);
}
