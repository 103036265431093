import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import {
  deleteStudentSuccess,
  deleteStudentFail,
  fetchStudentsStatsStart,
  fetchAllStudentStart,
} from '../action';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';

export function* deleteStudent({ payload }) {
  try {
    const response = yield httpClient.delete(
      `students/${encodeURIComponent(payload)}/hard-delete/false`,
    );
    yield put(deleteStudentSuccess(payload));
    Notify('Student deleted successfully', { position: 'top-right' });
    yield put(fetchStudentsStatsStart());
    yield put(fetchAllStudentStart({ offset: 0, limit: 10 }));
  } catch (error) {
    yield put(deleteStudentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteStudentStart() {
  yield takeLatest(studentActionTypes.DELETE_STUDENT_START, deleteStudent);
}
