import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { fetchAllTeachersFail, fetchAllTeachersSuccess } from '../actions';
import classInformationTypes from '../types';

export function* fetchTeachers() {
  try {
    const response = yield httpClient.get(`/staff/academic-staff`);
    yield put(fetchAllTeachersSuccess(response.data));
  } catch (error) {
    yield put(fetchAllTeachersFail(error?.message));
  }
}

export function* onFetchTeachersStart() {
  yield takeLatest(classInformationTypes.FETCH_ALL_TEACHERS_START, fetchTeachers);
}
