import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchRevenueSummaryFail, fetchRevenueSummarySuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* fetchRevenueSummary({ payload }) {
  try {
    const response = yield httpClient.get(`/revenue-statistics/revenue-summary`);
    yield put(fetchRevenueSummarySuccess(response.data));
  } catch (error) {
    yield put(fetchRevenueSummaryFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchRevenueSummaryStart() {
  yield takeLatest(invoiceActionTypes.FETCH_REVENUE_SUMMARY_START, fetchRevenueSummary);
}
