import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { fetchSkillsGroupSuccess, fetchSkillsGroupFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchSkillsGroup({ payload }) {
  try {
    const response = yield httpClient.get(
      `/cognitive-skill-config/skill-group-config/${payload.class_level_id}`,
    );
    yield put(fetchSkillsGroupSuccess(response.data));
  } catch (error) {
    yield put(fetchSkillsGroupFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchSkillGroupStart() {
  yield takeLatest(cognitiveSkillsActionTypes.FETCH_SKILLS_GROUP_START, fetchSkillsGroup);
}
