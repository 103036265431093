import { ISkillRatingsSetting } from 'pages/safsims-configuration/session-terms/session-initialization/modal/components/interfaces';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { getTermProfileSkillGroupFail, getTermProfileSkillGroupSuccess } from '../actions';
import configActionTypes from '../types';

export function* getTermProfileSkillGroup({ payload }) {
  try {
    const callback1 = payload.callback1;
    const callback2 = payload.callback2;
    delete payload.callback1;
    delete payload.callback2;

    const { data } = yield httpClient.get(
      `/cognitive-skill-config/skill-group-config/${payload.id}`,
    );
    yield put(getTermProfileSkillGroupSuccess(data));
    const dbconfig = data.skill_rating_configurations;
    const ratings: ISkillRatingsSetting[] = dbconfig.map((item) => ({
      id: item.skill_rating.id,
      rating_name: item.skill_rating.rating_name,
      skill_rating_definitions: item.skill_rating_definitions,
      class_levels: item.class_levels,
    }));

    callback2(ratings);
    callback1(
      data.skill_configurations.map((x) => ({
        ...x,
        id: x.skill_group.id,
        group_name: x.skill_group.group_name,
      })),
    );
  } catch (error) {
    yield put(getTermProfileSkillGroupFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTermProfileSkillGroup() {
  yield takeLatest<any>(
    configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_START,
    getTermProfileSkillGroup,
  );
}
