import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { linkChildFailure, linkChildSuccess } from '../actions';
import staffActionTypes from '../types';

export function* linkChild({ payload }) {
  const { data, listOfSelectedStudents, callback } = payload;
  try {
    const response = yield httpClient.post('/parents/link-students', data);
    yield put(linkChildSuccess(response.data, listOfSelectedStudents));
    Notify('Children Linked to a Parent successfully', { position: 'top-right' });
    callback?.();
  } catch (error) {
    yield put(linkChildFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onLinkChildStart() {
  yield takeLatest(staffActionTypes.LINK_CHILD_START, linkChild);
}
