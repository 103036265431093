import { all, call } from 'redux-saga/effects';
import { onCreateSubjectStart } from './createSubject';
import { onEditSubjectStart } from './editSubject';
import { onFetchSubjectsStart } from './fetchSubjects';
import { onDeleteSubjectStart } from './deleteSubject';
import { onGetLevelSubjectsStart } from './getLevelSubjects';
import { onAssignSubjectTeachersStart } from './assignSubjectTeachers';
import { onEditSubjectTeachersStart } from './editSubjectTeachers';
import { onSetStudentSubjectProfileStart } from './setStudentSubjectProfile';
import { onGetSubjectResultStart } from './getSubjectResult';

export function* SubjectsSagas() {
  yield all([
    call(onCreateSubjectStart),
    call(onFetchSubjectsStart),
    call(onEditSubjectStart),
    call(onDeleteSubjectStart),
    call(onGetLevelSubjectsStart),
    call(onAssignSubjectTeachersStart),
    call(onEditSubjectTeachersStart),
    call(onSetStudentSubjectProfileStart),
    call(onGetSubjectResultStart),
  ]);
}
