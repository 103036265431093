import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchUserRolesFailure, fetchUserRolesSuccess } from '../actions';
import internalRolesActionTypes from '../types';

export function* fetchUserRoles({ payload }) {
  if (!payload.user_id) {
    Notify(
      'This staff has not been assigned any role. Resend activation link to activate this staff.',
      { status: 'info' },
    );
  } else {
    try {
      const response = yield httpClient.get(`/user-roles?user-id=${payload.user_id}`);
      yield put(fetchUserRolesSuccess(response.data));
    } catch (error) {
      yield put(fetchUserRolesFailure(error?.message));
      const err = handleError(error);
    }
  }
}

export function* onfetchUserRolesStart() {
  yield takeLatest(internalRolesActionTypes.FETCH_USER_ROLES_START, fetchUserRoles);
}
