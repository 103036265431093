import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { downloadXLSXTempleteFailure, downloadXLSXTempleteSuccess } from '../action';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* downloadXLSXTemplete() {
  try {
    const response = yield httpClient.get('/students/downloads/template', { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'student-upload-template.xlsx');
    document.body.appendChild(link);
    link.click();

    yield put(downloadXLSXTempleteSuccess(response.data));
  } catch (error) {
    yield put(downloadXLSXTempleteFailure(error?.message));
    handleError(error);
  }
}

export function* onDownloadXLSXTempleteStart() {
  yield takeLatest(studentActionTypes.DOWNLOAD_XLSX_TEMPLATE_START, downloadXLSXTemplete);
}
