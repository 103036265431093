import { takeLatest, put } from 'redux-saga/effects';
import { deleteArmSuccess, deleteArmFail, fetchClassInfoStart } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* deleteArm({ payload }) {
  try {
    const response = yield httpClient.delete(`/arms/${payload}`);
    yield put(deleteArmSuccess(payload));
    Notify('Arm deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteArmFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteArmStart() {
  yield takeLatest(configActionTypes.DELETE_ARM_START, deleteArm);
}
