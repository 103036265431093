/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttendanceConfigDto } from '../models/AttendanceConfigDto';
import type { UpdateAttendanceConfigRequest } from '../models/UpdateAttendanceConfigRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AttendanceConfigRestControllerService {
  /**
   * getAttendanceConfigForClassLevel
   * @returns AttendanceConfigDto OK
   * @throws ApiError
   */
  public static getAttendanceConfigForClassLevelUsingGet({
    classLevelId,
    termId,
  }: {
    /**
     * class-level-id
     */
    classLevelId: string;
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<AttendanceConfigDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/attendance-configs',
      query: {
        'class-level-id': classLevelId,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateAttendanceConfig
   * @returns AttendanceConfigDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateAttendanceConfigUsingPut({
    request,
  }: {
    /**
     * request
     */
    request: UpdateAttendanceConfigRequest;
  }): CancelablePromise<AttendanceConfigDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/attendance-configs',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
