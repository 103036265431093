import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { editStaffFailure, editStaffSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* acceptProfileEdit({ payload }) {
  try {
    const response = yield httpClient.put(`/staff/profile-edit/${payload.id}`, {
      status: 'APPROVED',
    });
    yield put(editStaffSuccess(response.data));
    Notify("Staff's changes saved successfully", { position: 'top-right' });
    payload.history(-1);
  } catch (error) {
    yield put(editStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onAcceptProfileEdit() {
  yield takeLatest(staffActionTypes.ACCEPT_STAFF_EDIT_REQUEST, acceptProfileEdit);
}
