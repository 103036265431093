import { put, takeLatest } from 'redux-saga/effects';
import globalActionTypes from '../types';
import { fetchCountriesSuccess, fetchCountriesFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchCountries() {
  try {
    const response = yield httpClient.get('/countries');
    yield put(fetchCountriesSuccess(response.data));
  } catch (error) {
    yield put(fetchCountriesFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchCountriesStart() {
  yield takeLatest(globalActionTypes.FETCH_COUNTRIES_START, fetchCountries);
}
