import { put, takeLatest } from 'redux-saga/effects';
import subjectsActionTypes from '../types';
import { editSubjectSuccess, editSubjectFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* editSubject({ payload }) {
  const callback = payload.callback;
  delete payload.callback;

  try {
    const response = yield httpClient.put('subjects', payload);
    yield put(editSubjectSuccess(response.data));
    Notify('Subject edited successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(editSubjectFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditSubjectStart() {
  yield takeLatest(subjectsActionTypes.EDIT_SUBJECT_START, editSubject);
}
