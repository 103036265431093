import { put, takeLatest } from 'redux-saga/effects';
import classInformationTypes from '../types';
import { fetchListOfClassInformationSuccess, fetchListOfClassInformationFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchClassInformation({ payload }) {
  try {
    const response = yield httpClient.get('class-information', { params: payload });
    yield put(fetchListOfClassInformationSuccess(response.data));
  } catch (error) {
    yield put(fetchListOfClassInformationFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchClassInformationStart() {
  yield takeLatest(
    classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_START,
    fetchClassInformation,
  );
}
