import { takeLatest, put } from 'redux-saga/effects';
import { transferStudentsAnotherArmSuccess, transferStudentsAnotherArmFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* transferStudentsAnotherArm({ payload, callback }) {
  try {
    const response = yield httpClient.put('/class-members/transfer', payload);
    yield put(transferStudentsAnotherArmSuccess(payload.student_ids));
    Notify('Student(s) Transfered succesfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(transferStudentsAnotherArmFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTransferStudentsAnotherArm() {
  yield takeLatest(
    configActionTypes.TRANSFER_STUDENTS_ANOTHER_ARM_START,
    transferStudentsAnotherArm,
  );
}
