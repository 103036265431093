import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { getTermProfileDateRangeSuccess, getTermProfileDateRangeFail } from '../actions';
import { handleError } from 'utils/utils';

export function* getTermProfileDateRange({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.get(`/date-range/termly/${payload.id}`);
    yield put(getTermProfileDateRangeSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(getTermProfileDateRangeFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTermProfileDateRange() {
  yield takeLatest(configActionTypes.GET_TERM_PROFILE_DATE_RANGE_START, getTermProfileDateRange);
}
