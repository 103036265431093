import { put, takeLatest } from 'redux-saga/effects';
import { handleCurrentSubscription } from '../actions';
import configActionTypes from '../types';

const dummy_subscription = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  subscription_end_date: '2023-06-22T14:49:54.546Z',
  subscription_plan: {
    amount: 0,
    features: ['ATTENDANCE_REPORT_AND_ANALYSIS'],
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    max_student_count: 0,
    name: 'Free Trail',
  },
  subscription_start_date: '2023-06-22T14:49:54.546Z',
  term: {
    current_term: true,
    end_date: '2023-06-22T14:49:54.546Z',
    initialized: true,
    next_term_start_date: '2023-06-22T14:49:54.546Z',
    school_term_definition: {
      definition_order: 0,
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'string',
      term_definition: {
        definition_order: 0,
        description: 'string',
        id: 'string',
      },
    },
    session: {
      id: 'string',
      name: 'string',
    },
    start_date: '2023-06-22T14:49:54.546Z',
    term_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  },
  trial: true,
  trial_expiry_date: '2023-06-22T14:49:54.546Z',
};

export function* getCurrentSubscription(termId) {
  try {
    const response_2 = yield httpClient.get(`/subscriptions/term/${termId}`);
    // const currentSubscriptionPlan = yield call(apiWrapper(() =>
    //   SubscriptionRestControllerService.getSubscriptionByTermUsingGet({ termId: termId }),
    // ))
    const responseData = response_2?.data;
    yield put(handleCurrentSubscription(responseData));
  } catch (error) {
    // yield put(getCurrentTermFail(error?.message));
  }
}

export function* onGetCurrentSubscription() {
  yield takeLatest(configActionTypes.GET_CURRENT_SUBSCRIPTION_START, getCurrentSubscription);
}
