import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { fetchStudentDocsStart, uploadStudentDocsFail, uploadStudentDocsSuccess } from '../action';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* uploadStudentDoc({ payload }) {
  const formData = payload.data;
  try {
    const response = yield httpClient.post(`/documents/student/${payload.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(uploadStudentDocsSuccess(response.data));
    yield put(fetchStudentDocsStart(payload.id));
    Notify("Student's document uploaded successfully", { position: 'top-right' });
  } catch (error) {
    yield put(uploadStudentDocsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onUploadStudentDocument() {
  yield takeLatest(studentActionTypes.UPLOAD_STUDENT_DOCS_START, uploadStudentDoc);
}
