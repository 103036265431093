import reportTemplateActionTypes from './types';

export const fetchReportTemplateStart = () => ({
  type: reportTemplateActionTypes.FETCH_REPORT_TEMPLATE_START,
});

export const fetchReportTemplateSuccess = (data) => ({
  type: reportTemplateActionTypes.FETCH_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});

export const fetchReportTemplateFail = (error) => ({
  type: reportTemplateActionTypes.FETCH_REPORT_TEMPLATE_FAIL,
  payload: error,
});

export const deleteReportTemplateStart = (id, callback) => ({
  type: reportTemplateActionTypes.DELETE_REPORT_TEMPLATE_START,
  id,
  callback,
});

export const deleteReportTemplateSuccess = (data) => ({
  type: reportTemplateActionTypes.DELETE_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});

export const deleteReportTemplateFail = (error) => ({
  type: reportTemplateActionTypes.DELETE_REPORT_TEMPLATE_FAIL,
  payload: error,
});

export const createReportTemplateStart = (data, callback) => ({
  type: reportTemplateActionTypes.CREATE_REPORT_TEMPLATE_START,
  data,
  callback,
});

export const createReportTemplateSuccess = (data) => ({
  type: reportTemplateActionTypes.CREATE_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});

export const createReportTemplateFail = (error) => ({
  type: reportTemplateActionTypes.CREATE_REPORT_TEMPLATE_FAIL,
  payload: error,
});
