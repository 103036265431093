import httpClient from 'utils/http-client';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchBankAccountsStart, retrySubAccountFailure, retrySubAccountSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import bankActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* retrySubAccount({ payload }) {
  try {
    const response = yield httpClient.post('/bank-accounts/sub-account', payload);

    yield put(retrySubAccountSuccess(response.data));
    yield put(fetchBankAccountsStart());
    Notify('Subaccount configured successfully', { position: 'top-right' });
  } catch (error) {
    yield put(retrySubAccountFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onRetrySubAccountStart() {
  yield takeLatest(bankActionTypes.RETRY_SUB_ACCOUNT_START, retrySubAccount);
}
