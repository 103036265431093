import { takeLatest, put } from 'redux-saga/effects';
import {
  deleteSingleDefinitionSuccess,
  deleteSingleDefinitionFail,
  fetchAllTraitGroupStart,
} from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* deleteSingleDefinition({ payload }) {
  try {
    const response = yield httpClient.delete(
      `/trait-configuration/trait-definitions/${payload.id}`,
    );
    yield put(deleteSingleDefinitionSuccess(response.data));
    Notify('Trait definition deleted succesfully', { position: 'top-right' });
    payload.callback();
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(deleteSingleDefinitionFail(error.fail));
    const err = handleError(error);
  }
}

export function* onDeleteSingleDefinitionStart() {
  yield takeLatest(earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_START, deleteSingleDefinition);
}
