import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { FIX_ANY } from 'utils/types';
import { findIndexInArray, InsertBeginning } from 'utils/utils';
import staffActionTypes from './types';

export interface IStaffState {
  isLoading: boolean;
  downloading: boolean;
  buttonLoading: boolean;
  saveButtonLoading: boolean;
  loading: boolean;
  bulkModal: boolean;
  declineModal: boolean;
  wizard: boolean;
  edit: boolean;
  deleteStaffModal: boolean;
  listOfStaffs: FIX_ANY[];
  profileEditRequests: FIX_ANY[];
  countries: FIX_ANY[];
  states: FIX_ANY[];
  lga: FIX_ANY[];
  staffBio: FIX_ANY;
  otherInfo: FIX_ANY;
  editUser: FIX_ANY;
  error: FIX_ANY;
  staffProfileData: FIX_ANY;
  total: number;
  stats: FIX_ANY;
  profilePictureEdit: boolean;
  documentModal: boolean;
  documentLoading: boolean;
  listOfDocuments: FIX_ANY[];
  deleteDocumentModal: boolean;
  resetModal: boolean;
  totalEditRequests: number;
  editRequest: FIX_ANY;
}

const INITIAL_STATE: IStaffState = {
  isLoading: false,
  downloading: false,
  buttonLoading: false,
  saveButtonLoading: false,
  loading: false,
  bulkModal: false,
  declineModal: false,
  wizard: false,
  edit: false,
  deleteStaffModal: false,
  listOfStaffs: [],
  profileEditRequests: [],
  countries: [],
  states: [],
  lga: [],
  staffBio: {},
  otherInfo: {},
  editUser: {},
  error: null,
  staffProfileData: {},
  total: 0,
  stats: {},
  profilePictureEdit: false,
  documentModal: false,
  documentLoading: false,
  listOfDocuments: [],
  deleteDocumentModal: false,
  resetModal: false,
  totalEditRequests: 0,
  editRequest: {},
};

interface actionParams {
  type: staffActionTypes;
  payload: any;
}

function staffReducer(state = INITIAL_STATE, { type, payload }: actionParams) {
  switch (type) {
    case staffActionTypes.FETCH_STAFFS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case staffActionTypes.FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfStaffs: payload.content,
        total: payload.total_elements,
      };
    case staffActionTypes.FETCH_STAFFS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case staffActionTypes.FETCH_STAFFS_STATS_START:
      return {
        ...state,
        error: null,
      };
    case staffActionTypes.FETCH_STAFFS_STATS_SUCCESS:
      return {
        ...state,
        stats: payload,
      };
    case staffActionTypes.FETCH_STAFFS_STATS_FAILURE:
      return {
        ...state,
        error: payload,
      };

    case staffActionTypes.FILTER_STAFFS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case staffActionTypes.FILTER_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfStaffs: payload.content,
        total: payload.total_elements,
      };
    case staffActionTypes.FILTER_STAFFS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case staffActionTypes.FETCH_COUNTRIES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case staffActionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: payload,
      };
    case staffActionTypes.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case staffActionTypes.CREATE_STAFF_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case staffActionTypes.CREATE_STAFF_SUCCESS:
      const modifiedEntities = InsertBeginning(state.listOfStaffs, payload);
      return {
        ...state,
        buttonLoading: false,
        wizard: false,
        listOfStaffs: modifiedEntities,
        states: [],
        lga: [],
        staffBio: {},
        otherInfo: {},
        editUser: {},
        edit: false,
      };
    case staffActionTypes.CREATE_STAFF_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_START:
      return {
        ...state,
        saveButtonLoading: true,
        error: null,
      };
    case staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_SUCCESS:
      const saveModifiedEntities = InsertBeginning(state.listOfStaffs, payload);
      return {
        ...state,
        saveButtonLoading: false,
        listOfStaffs: saveModifiedEntities,
        states: [],
        lga: [],
        staffBio: {},
        otherInfo: {},
        editUser: {},
        edit: false,
      };
    case staffActionTypes.SAVE_AND_CREATE_ANOTHER_STAFF_FAILURE:
      return {
        ...state,
        saveButtonLoading: false,
        error: payload,
      };

    case staffActionTypes.EDIT_STAFF_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case staffActionTypes.EDIT_STAFF_SUCCESS:
      const indexOfEditedUser = findIndexInArray(state.listOfStaffs, payload, 'id');
      state.listOfStaffs.splice(indexOfEditedUser, 1, payload);
      return {
        ...state,
        staffProfileData: payload,
        buttonLoading: false,
        wizard: false,
        states: [],
        lga: [],
        staffBio: {},
        otherInfo: {},
        editUser: {},
        edit: false,
      };
    case staffActionTypes.EDIT_STAFF_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case staffActionTypes.STAFF_STATUS_START:
      return {
        ...state,
        staffProfileData: payload,
        error: null,
      };
    case staffActionTypes.STAFF_STATUS_SUCCESS:
      return {
        ...state,
        staffProfileData: payload,
      };
    case staffActionTypes.STAFF_STATUS_FAILURE:
      state.staffProfileData.active_status =
        payload.data.active_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
      return {
        ...state,
        staffProfileData: state.staffProfileData,
        error: payload.error,
      };

    case staffActionTypes.FETCH_STATES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case staffActionTypes.FETCH_STATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: payload,
      };
    case staffActionTypes.FETCH_STATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case staffActionTypes.FETCH_LGA_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case staffActionTypes.FETCH_LGA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lga: payload,
      };
    case staffActionTypes.FETCH_LGA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_START:
      return {
        ...state,
        downloading: true,
        error: null,
      };
    case staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
      };
    case staffActionTypes.DOWNLOAD_XLSX_STAFF_TEMPLATE_FAILURE:
      return {
        ...state,
        downloading: false,
        error: payload,
      };

    case staffActionTypes.BULK_STAFF_UPLOAD_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case staffActionTypes.BULK_STAFF_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bulkModal: false,
      };
    case staffActionTypes.BULK_STAFF_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case staffActionTypes.RESET_LGA:
      return {
        ...state,
        lga: payload,
      };
    case staffActionTypes.SAVE_STAFF_BIO:
      return {
        ...state,
        staffBio: payload,
      };
    case staffActionTypes.SAVE_OTHER_INFO:
      return {
        ...state,
        otherInfo: payload,
      };
    case staffActionTypes.OPEN_WIZARD:
      return {
        ...state,
        wizard: payload,
      };
    case staffActionTypes.RESET_EVERYTHING:
      return {
        ...state,
        states: [],
        lga: [],
        staffBio: {},
        otherInfo: {},
        editUser: {},
        edit: false,
        wizard: false,
      };
    case staffActionTypes.HANDLE_EDIT:
      return {
        ...state,
        staffBio: payload.staffBio,
        otherInfo: payload.otherInfo,
        editUser: payload.editUser,
        edit: true,
        wizard: true,
      };
    case staffActionTypes.CREATE_STAFF_PROFILE_EDIT_REQUEST:
      return {
        ...state,
        staffBio: payload.staffBio,
        editUser: payload.editUser,
        edit: true,
        wizard: true,
      };
    case staffActionTypes.ACCEPT_STAFF_EDIT_REQUEST:
      return {
        ...state,
        edit: true,
      };
    case staffActionTypes.REJECT_STAFF_EDIT_REQUEST:
      return {
        ...state,
        id: payload.request_id,
        message: payload.message,
        edit: true,
      };
    case staffActionTypes.HANDLE_STAFF_PROFILE_DATA:
      return {
        ...state,
        staffProfileData: payload,
      };
    case staffActionTypes.HANDLE_EDIT_REQUEST_ACTION:
      return {
        ...state,
        editRequest: payload,
      };

    case staffActionTypes.HANDLE_BULK_MODAL:
      return {
        ...state,
        bulkModal: !payload,
      };
    case staffActionTypes.HANDLE_DECLINE_MODAL:
      return {
        ...state,
        declineModal: !payload,
      };

    case staffActionTypes.HANDLE_EDIT_PICTURE_ON_PROFILE:
      return {
        ...state,
        profilePictureEdit: payload,
      };

    case staffActionTypes.UPDATE_STAFF_PICTURE_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case staffActionTypes.UPDATE_STAFF_PICTURE_SUCCESS:
      const currentStaff = state.listOfStaffs.filter((staff) => {
        return staff.id === payload.id;
      })[0];

      currentStaff.profile_pic = payload.link;
      const indexOfUpdatePictureStaff = findIndexInArray(state.listOfStaffs, currentStaff, 'id');
      state.listOfStaffs.splice(indexOfUpdatePictureStaff, 1, currentStaff);
      return {
        ...state,
        staffProfileData: currentStaff,
        buttonLoading: false,
        profilePictureEdit: false,
      };
    case staffActionTypes.UPDATE_STAFF_PICTURE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case staffActionTypes.FETCH_EDIT_REQUESTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case staffActionTypes.FETCH_EDIT_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        profileEditRequests: payload.content,
        totalEditRequests: payload.total_elements,
      };
    case staffActionTypes.FETCH_EDIT_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case staffActionTypes.FILTER_EDIT_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case staffActionTypes.FILTER_EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        profileEditRequests: payload.content,
        totalEditRequests: payload.total_elements,
      };

    case staffActionTypes.FILTER_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case staffActionTypes.DELETE_STAFF_START:
      return {
        ...state,
        isLoading: true,
      };
    case staffActionTypes.DELETE_STAFF_SUCCESS:
      const indexOfDeleteStaff = findIndexInArray(state.listOfStaffs, { id: payload }, 'id');
      state.listOfStaffs.splice(indexOfDeleteStaff, 1);
      return {
        ...state,
        isLoading: false,
        deleteStaffModal: false,
      };
    case staffActionTypes.DELETE_STAFF_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_START:
      return {
        ...state,
        loading: true,
      };
    case staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfDocuments: payload,
      };
    case staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        listOfDocuments: [],
      };

    case staffActionTypes.HANDLE_DELETE_MODAL:
      return {
        ...state,
        deleteStaffModal: !payload,
      };

    case staffActionTypes.HANDLE_DOCUMENT_UPLOAD_MODAL:
      return {
        ...state,
        documentModal: !payload,
      };

    case staffActionTypes.UPLOAD_STAFF_DOCUMENT_START:
      return {
        ...state,
        documentLoading: true,
      };
    case staffActionTypes.UPLOAD_STAFF_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentLoading: false,
        documentModal: false,
      };
    case staffActionTypes.UPLOAD_STAFF_DOCUMENT_FAILURE:
      return {
        ...state,
        documentLoading: false,
        error: payload,
      };

    case staffActionTypes.STOP_UPLOAD_STAFF_BULK_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_START:
      return {
        ...state,
        error: null,
      };
    case staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_SUCCESS:
      return {
        ...state,
      };
    case staffActionTypes.DOWNLOAD_STAFF_DOCUMENTS_FAILURE:
      return {
        ...state,
        error: payload,
      };

    case staffActionTypes.HANDLE_DELETE_DOCUMENT_MODAL:
      return {
        ...state,
        deleteDocumentModal: !payload,
      };

    case staffActionTypes.DELETE_STAFF_DOCUMENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case staffActionTypes.DELETE_STAFF_DOCUMENTS_SUCCESS:
      const indexOfDeleteStaffDocument = findIndexInArray(
        state.listOfDocuments,
        { aws_s3_key: payload },
        'aws_s3_key',
      );
      state.listOfDocuments.splice(indexOfDeleteStaffDocument, 1);
      return {
        ...state,
        isLoading: false,
        deleteDocumentModal: false,
      };
    case staffActionTypes.DELETE_STAFF_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case staffActionTypes.HANDLE_RESET_MODAL:
      return {
        ...state,
        resetModal: !payload,
      };
    case staffActionTypes.ADMIN_RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };
    case staffActionTypes.ADMIN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetModal: false,
      };
    case staffActionTypes.ADMIN_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case staffActionTypes.SEND_PASSWORD_RESET_LINK_START:
      return {
        ...state,
        isLoading: true,
      };
    case staffActionTypes.SEND_PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetModal: false,
      };
    case staffActionTypes.SEND_PASSWORD_RESET_LINK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case staffActionTypes.UPDATE_STAFF_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'staff',
  storage,
  blacklist: [
    'error',
    'isLoading',
    'staffBio',
    'otherInfo',
    'buttonLoading',
    'wizard',
    'edit',
    'bulkModal',
    'declineModal',
    'profilePictureEdit',
    'deleteStaffModal',
    'documentModal',
    'documentLoading',
    'deleteDocumentModal',
    'resetModal',
    'downloading',
  ],
};

export default persistReducer(persistConfig, staffReducer);
