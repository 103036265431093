import { NameOrderDto } from 'generated';
import { saveToLocalStorage } from './utils';

const defaultOrderConfig = { first_name_order: 1, last_name_order: 2, other_names_order: 3 };

export function setNameOrderConfig({ config }: { config: NameOrderDto }) {
  delete config?.id;
  const configObject = config ? config : defaultOrderConfig;
  const configObjectArray = Object.entries(configObject).map(([key, value]) => ({
    order: parseInt(value as string).toString(),
    order_name: key.replace('_order', ''),
  }));

  const sortedConfigObjectArray = configObjectArray.sort(
    (a, b) => parseInt(a.order) - parseInt(b.order),
  );

  saveToLocalStorage('nameOrderConfig', sortedConfigObjectArray);
}

function getNameOrderConfig() {
  const configJsonString = localStorage.getItem('nameOrderConfig');
  return JSON.parse(configJsonString as string);
}

export const NAME_ORDER_CONFIGURATION = getNameOrderConfig();
