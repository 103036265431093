import { Box, Button, Spacer, Text } from '@flexisaf/flexibull2';
import ErrorBound from 'assets/error-bound.svg';
import singleBg from 'assets/singleBg.svg';
import { Theme } from 'config/theme';
import useBreakpointValue from 'utils/useBreakpointValue/useBreakpointValue';

export const ErrorBoundaryComponent = () => {
  const width = useBreakpointValue({ base: '90%', md: '30vw' });
  const size = useBreakpointValue({ base: '140px', md: '200px' });
  return (
    <Box relative pad="30px" style={{ overflow: 'hidden' }} maxHeight="100vh" height="100vh">
      <Box margin="10% auto" width={width} align="center" style={{ zIndex: '10' }}>
        <Box align="center">
          <img src={ErrorBound} height={size} />
        </Box>
        <Spacer space="40px" />
        <Text block bold size="16px">
          Well this is unexpected
        </Text>
        <Spacer space="30px" />
        <Text block>
          Sorry, an unexpected error occurred. We have logged this error and our engineers will
          investigate and fix it as soon as possible. If you need immediate assistance, please
          contact our support.
        </Text>
        <Spacer />
        <Text>Thank you for your patience.</Text>
        <Spacer space="40px" />
        <Box align="center">
          <Button
            onClick={() => (window.location.href = '/user-profile')}
            color={Theme.PrimaryFontColor}
            fontColor="#fff"
          >
            Return to profile
          </Button>
        </Box>
      </Box>
      <img src={singleBg} style={{ position: 'absolute', bottom: '-80px', right: '-100px' }} />
    </Box>
  );
};

export default ErrorBoundaryComponent;
