import { Box, Button, Text } from '@flexisaf/flexibull2';
import StyledTable from 'components/StyledTable';
import TraitGroup from 'components/traitCollapsible';
import { Theme } from 'config/theme';
import { useState } from 'react';
import styled from 'styled-components';
import { returnUpdatedList } from 'utils/utils';
import ScoreClassItem from './scoreClassItem';
import ScoreEntryAssessment from './scoreEntryAssessment';

const Collapsible = styled(TraitGroup)`
  .body {
    padding: 0;
    background-color: #fff;
  }
`;
const TimeframeTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  /* width: 100%; */

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  td {
    background-color: #fafafa;
  }
`;
const sort = (a, b) => a.assessment.assessment_order - b.assessment.assessment_order;

const ScoreTimeframeItem = ({ assessment, save, loading, updateSingleClass }) => {
  const initialState = assessment.breakdown.map((x) => ({
    ...x,
    start_date: '',
    end_date: '',
  }));
  const [breakdown, setBreakdown] = useState(initialState);

  const updateBreakdown = (data) => {
    const newList = returnUpdatedList(data, breakdown, 'assessment', 'id');
    setBreakdown([...newList]);
  };

  const onSave = () => {
    assessment.breakdown = breakdown;
    save(assessment);
  };

  const disabled = !breakdown.find((x) => x.start_date !== '' && x.end_date !== '');

  return (
    <Collapsible title={assessment.name}>
      <Box>
        <StyledTable>
          <thead>
            <tr>
              <th rowSpan={2}>
                <Text size="10px" color={Theme.PrimaryFontColor}>
                  Classes
                </Text>
              </th>
              {assessment.breakdown.sort(sort).map((x) => (
                <th>
                  <Text key={x.assessment.id} size="10px" color={Theme.PrimaryFontColor}>
                    {x.assessment.assessment_name}
                  </Text>
                </th>
              ))}
              <th />
            </tr>
            <tr>
              {breakdown.sort(sort).map((item) => (
                <ScoreEntryAssessment
                  updateBreakdown={updateBreakdown}
                  key={item.assessment.id}
                  item={item}
                />
              ))}
              <th style={{ textAlign: 'right' }}>
                <Button
                  disabled={disabled}
                  progress={loading}
                  height={37}
                  onClick={() => {
                    onSave();
                  }}
                >
                  <Text size="11px">Apply to all</Text>
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {assessment.class_levels.map((level) => (
              <ScoreClassItem
                key={level.class_level_id}
                level={level}
                format_id={assessment.id}
                save={updateSingleClass}
              />
            ))}
          </tbody>
        </StyledTable>
      </Box>
    </Collapsible>
  );
};

export default ScoreTimeframeItem;
