import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchStudentResultDataFail, fetchStudentResultDataSuccess } from '../actions';
import broadSheetTypes from '../types';

export function* fetchStudentResultData({ payload }) {
  try {
    const params = {
      'student-id': payload.studentId,
      'term-id': payload.termId,
      'result-config-id': payload.configId,
      'include-unapproved': true,
    };

    if (payload.traitGroupId && payload.traitGroupId !== 'undefined') {
      params['trait-group-id'] = payload.traitGroupId;
    }

    const url =
      JSON.parse(payload?.cumulative ? payload?.cumulative : 'false') == true
        ? '/student-results/cumulative/report'
        : '/student-results/report';

    const response = yield httpClient.get(url, { params: params });
    yield put(fetchStudentResultDataSuccess(response.data));
  } catch (error) {
    yield put(fetchStudentResultDataFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchStudentReportDataStart() {
  yield takeLatest(broadSheetTypes.FETCH_STUDENT_RESULT_DATA_START, fetchStudentResultData);
}
