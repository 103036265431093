import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchAllExistingTermsFail, fetchAllExistingTermsSuccess } from '../actions';
import configActionTypes from '../types';

export function* fetchAllExistingTerms({ payload }) {
  try {
    const response = yield httpClient.get(`/terms`, payload);
    yield put(fetchAllExistingTermsSuccess(response.data));
  } catch (error) {
    yield put(fetchAllExistingTermsFail(error.fail));
    const err = handleError(error);
  }
}

export function* onFetchAllExistingTermsStart() {
  yield takeLatest(configActionTypes.FETCH_ALL_EXISTING_TERMS_START, fetchAllExistingTerms);
}
