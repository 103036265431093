import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { addRatingFail, addRatingSuccess } from '../actions';
import earlyYearsActionTypes from '../types';

export function* addRating({ payload }) {
  try {
    const response = yield httpClient.post('/trait-configuration/trait-ratings', payload);
    yield put(addRatingSuccess(response.data));
    Notify('Rating added succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(addRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onAddRatingStart() {
  yield takeLatest(earlyYearsActionTypes.ADD_RATING_START, addRating);
}
