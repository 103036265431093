const scoreEntryActionTypes = {
  PUBLISH_CLASS_SCORES_START: 'PUBLISH_CLASS_SCORES_START',
  PUBLISH_CLASS_SCORES_SUCCESS: 'PUBLISH_CLASS_SCORES_SUCCESS',
  PUBLISH_CLASS_SCORES_FAILURE: 'PUBLISH_CLASS_SCORES_FAILURE',

  FETCH_CLASS_SCORES_START: 'FETCH_CLASS_SCORES_START',
  FETCH_CLASS_SCORES_SUCCESS: 'FETCH_CLASS_SCORES_SUCCESS',
  FETCH_CLASS_SCORES_FAILURE: 'FETCH_CLASS_SCORES_FAILURE',

  UPDATE_LOCAL_SCORES: 'UPDATE_LOCAL_SCORES',
  DELETE_LOCAL_STUDENT: 'DELETE_LOCAL_STUDENT',
  SET_ACTIVE_CLASS_INFO: 'SET_ACTIVE_CLASS_INFO',
  SET_MOST_RECENT_SERVER_SCORES: 'SET_MOST_RECENT_SERVER_SCORES',
  REFETCH_SCORES: 'REFETCH_SCORES',
};

export default scoreEntryActionTypes;
