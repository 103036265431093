import { put, takeLatest } from 'redux-saga/effects';
import globalActionTypes from '../types';
import { fetchLgaSuccess, fetchLgaFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchLga({ payload }) {
  try {
    const response = yield httpClient.get(`/states/${payload.value}/lgas`);

    yield put(fetchLgaSuccess(response.data));
  } catch (error) {
    yield put(fetchLgaFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchLgaStart() {
  yield takeLatest(globalActionTypes.FETCH_LGA_START, fetchLga);
}
