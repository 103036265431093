import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import internalRolesActionTypes from './types';

const INITIAL_STATE = {
  listOfInternalRoles: [],
  listOfUserRoles: [],
  buttonLoading: false,
  assignRolesModal: false,
};

function internalRolesReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_START:
      return {
        ...state,
      };
    case internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_SUCCESS:
      return {
        ...state,
        listOfInternalRoles: payload,
      };
    case internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_FAILURE:
      return {
        ...state,
        error: null,
      };

    case internalRolesActionTypes.FETCH_USER_ROLES_START:
      return {
        ...state,
        listOfUserRoles: [],
      };
    case internalRolesActionTypes.FETCH_USER_ROLES_SUCCESS:
      return {
        ...state,
        listOfUserRoles: payload,
      };
    case internalRolesActionTypes.FETCH_USER_ROLES_FAILURE:
      return {
        ...state,
        error: null,
      };

    case internalRolesActionTypes.ASSING_ROLES_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case internalRolesActionTypes.ASSING_ROLES_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        assignRolesModal: false,
        listOfUserRoles: payload,
      };
    case internalRolesActionTypes.ASSING_ROLES_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case internalRolesActionTypes.HANDLE_ASSIGN_ROLES_MODAL:
      return {
        ...state,
        assignRolesModal: payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'internal-roles',
  storage,
  blacklist: ['error', 'buttonLoading', 'assignRolesModal', 'listOfUserRoles'],
};

export default persistReducer(persistConfig, internalRolesReducer);
