import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import {
  termProfileSetAssessmentFormatSuccess,
  termProfileSetAssessmentFormatFail,
} from '../actions';
import { handleError } from 'utils/utils';

export function* termProfileSetAssessmentFormat({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.post('/assessment-config/termly', payload);
    yield put(termProfileSetAssessmentFormatSuccess(response.data));
    callback();
  } catch (error) {
    yield put(termProfileSetAssessmentFormatFail(error?.message));
    const err = handleError(error);
  }
}

export function* onTermProfileSetAssessmentFormat() {
  yield takeLatest(
    configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_START,
    termProfileSetAssessmentFormat,
  );
}
