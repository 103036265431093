import { Theme } from 'config/theme';
import { transparentize } from 'polished';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  border-radius: ${Theme.SecondaryRadius};
  border: 1px solid ${transparentize(0.4, Theme.PrimaryBorderColor)};
  margin: ${(props) => (props.margin ? props.margin : '15px 0')};
  background-color: #fff;
  position: relative;
  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width};
    `}

  & .header {
    padding: ${(props) => (props.headerPad ? props.headerPad : '15px')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${Theme.SecondaryRadius} ${Theme.SecondaryRadius} 0 0;

    & .header-title {
      width: 50%;
    }
    & .extras {
      width: 40%;
      text-align: right;
    }
    & .header-button__toggle {
      background-color: none;
      border: none;
      color: ${Theme.PrimaryFontColor};
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      cursor: pointer;
      border-radius: ${Theme.SecondaryRadius};

      & i {
        margin-top: 2px;
      }

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  & .body {
    background-color: ${(props) => (props.bodyBgColor ? props.bodyBgColor : '#f7f8fd')};
    border-radius: 0 0 ${Theme.SecondaryRadius} ${Theme.SecondaryRadius};
    padding: 20px 15px;

    ${(props) =>
      props.top &&
      css`
        padding: 10px 0;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 40px;
        border: 1px solid ${transparentize(0.4, Theme.PrimaryBorderColor)};
        border-radius: ${Theme.SecondaryRadius} ${Theme.SecondaryRadius} 0 0;
      `}
  }

  & .base-details {
    padding: 20px;
    border-radius: 0 0 ${Theme.SecondaryRadius} ${Theme.SecondaryRadius};
    background-color: ${(props) => (props.bodyBgColor ? props.bodyBgColor : '#f7f8fd')};
  }
`;

const TraitGroup = (props) => {
  const [isOpen, setIsOpen] = useState(props.open);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Wrapper {...props} ref={props.outerClickClose ? wrapperRef : null}>
      <div className="header">
        <div class="header-title">{props.title}</div>
        <div class="extras">{props.extras}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ margin: '0 20px 0 0' }}>{props.remove}</div>
          {!props.hideToggle && (
            <div className="header-button__toggle" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <i className="saf-arrow-up-2" title="arrow" />
              ) : (
                <i className="saf-arrow-down-1" title="arrow" />
              )}
            </div>
          )}
        </div>
      </div>
      {isOpen ? <div className="body">{props.children}</div> : null}
      {props.baseDetail && <div className="base-details"> {props.baseDetail} </div>}
    </Wrapper>
  );
};

export default TraitGroup;
