import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { deleteSkillRatingSuccess, deleteSkillRatingFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* deleteSkillRating({ payload }) {
  try {
    const response = yield httpClient.delete(
      `/cognitive-skill-config/skill-ratings/${payload.id}?force-delete=true`,
    );
    yield put(deleteSkillRatingSuccess(payload.id));

    Notify('Skill Rating deleted  successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteSkillRatingFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteSkillRatingStart() {
  yield takeLatest(cognitiveSkillsActionTypes.DELETE_SKILL_RATING_START, deleteSkillRating);
}
