import { takeLatest, put } from 'redux-saga/effects';
import { getClassLevelTraitsSuccess, getClassLevelTraitsFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';

export function* fetchClassLevelTraits({ payload }) {
  try {
    const response = yield httpClient.get(`/trait-configuration/class-level-traits`, {
      params: payload,
    });
    yield put(getClassLevelTraitsSuccess(response.data));
  } catch (error) {
    yield put(getClassLevelTraitsFail(error?.message));
  }
}

export function* onFetchClassLevelTraits() {
  yield takeLatest(configActionTypes.GET_CLASS_LEVEL_TRAITS_START, fetchClassLevelTraits);
}
