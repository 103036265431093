import { createSelector } from 'reselect';

const selectAssessment = (state) => state.assessment;

export const selectIsLoading = createSelector(
  [selectAssessment],
  (assessment) => assessment.isLoading,
);

export const selectTotal = createSelector([selectAssessment], (assessment) => assessment.total);

export const SelectCreateModal = createSelector(
  [selectAssessment],
  (assessment) => assessment.createModal,
);

export const SelectButtonLoading = createSelector(
  [selectAssessment],
  (assessment) => assessment.buttonLoading,
);

export const SelecListOfAssessments = createSelector(
  [selectAssessment],
  (assessment) => assessment.listOfAssessments,
);

export const SelectLevelsAssessments = createSelector(
  [selectAssessment],
  (assessment) => assessment.levelAssessments,
);

export const SelectActiveLevelAssessment = createSelector(
  [selectAssessment],
  (assessment) => assessment.activeLevelAssessment,
);
