import { takeLatest, put } from 'redux-saga/effects';
import { deleteRatingSuccess, deleteRatingFail } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* deleteRating({ payload }) {
  const { id, forceDelete } = payload;
  try {
    const response = yield httpClient.delete(
      `/trait-configuration/trait-ratings/${id}?force-delete=${forceDelete}`,
    );
    yield put(deleteRatingSuccess(response.data));
    Notify('Rating deleted succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onDeleteRatingStart() {
  yield takeLatest(earlyYearsActionTypes.DELETE_RATING_START, deleteRating);
}
