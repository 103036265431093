import { CustomDateRangePicker } from 'components/datepicker';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

const LevelPicker = ({ timeframe, updateTimeframe }) => {
  const data = useMemo(
    () =>
      timeframe.start_date || timeframe.end_date
        ? [moment(timeframe.start_date).toDate(), moment(timeframe.end_date).toDate()]
        : '',
    [timeframe],
  );
  const [range, setRange] = useState(data);

  useEffect(() => {
    setRange(data);
  }, [timeframe, data]);

  return (
    <td>
      <CustomDateRangePicker
        label="Start date "
        label2="End date"
        hideBtn
        onChange={(val) => {
          setRange(val);
          const item = {
            ...timeframe,
            start_date: val[0],
            end_date: val[1],
          };
          updateTimeframe(item);
        }}
        value={range}
        spaceTop="10px"
      />
    </td>
  );
};

export default LevelPicker;
