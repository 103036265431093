import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { updateSkillGroupFailure, updateSkillGroupSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* updateSkillGroup({ payload }) {
  try {
    const response = yield httpClient.put(
      `/cognitive-skill-config/skill-groups/${payload.id}`,
      payload.data,
    );
    yield put(updateSkillGroupSuccess(response.data));

    Notify('Skill Group Updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(updateSkillGroupFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onUpdateSkillGroupStart() {
  yield takeLatest(cognitiveSkillsActionTypes.UPDATE_SKILL_GROUP_START, updateSkillGroup);
}
