import { Notify } from '@flexisaf/flexibull2';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLevelsAssessmentsStart } from 'redux/assessment/actions';
import { SelectLevelsAssessments } from 'redux/assessment/selectors';
import { getTermProfileAssessmentFormatStart } from 'redux/configuration/actions';
import httpClient from 'utils/http-client';
import { handleError, returnUpdatedList } from 'utils/utils';

const useScoreTimeframe = ({ term_id }) => {
  const [assessmentList, setAssessmentList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const levelAssessmentList = useSelector(SelectLevelsAssessments);

  const levelAssessments = levelAssessmentList.map((item) => {
    const assessments = item.assessments;
    const timeframes = item.score_entry_timeframes;
    let newTimeframes: any = [];
    if (timeframes.length === assessments.length) {
      newTimeframes = timeframes;
    } else {
      if (timeframes.length > 0) {
        const class_level = timeframes[0].class_level;
        const term = timeframes[0].term;
        newTimeframes = assessments.map((x) => ({
          assessment: x,
          class_level,
          term,
          start_date: timeframes.find((t) => t.assessment.id === x.id)?.start_date || '',
          end_date: timeframes.find((t) => t.assessment.id === x.id)?.end_date || '',
        }));
      }
    }
    return {
      ...item,
      score_entry_timeframes: newTimeframes,
    };
  });

  useEffect(() => {
    dispatch(fetchLevelsAssessmentsStart({ term_id }));
    dispatch(
      getTermProfileAssessmentFormatStart({
        id: term_id,
        callback: (data) => {
          setAssessmentList(data);
          setLoading(false);
        },
      }),
    );
  }, [dispatch, term_id]);

  const SaveAll = async (assessment) => {
    try {
      const classLevels = assessment.class_levels;
      const breakdown = assessment.breakdown;
      setButtonLoading(true);
      const data: any = [];
      breakdown
        .filter((item) => item.start_date !== '' && item.end_date !== '')
        .map((a) => {
          classLevels.map((x) => {
            data.push({
              class_level_id: x.class_level_id,
              term_id,
              start_date: a.start_date,
              end_date: a.end_date,
              assessment_id: a.assessment.id,
            });
          });
        });
      await httpClient.post('/score-entry-timeframe', {
        timeframes: data,
      });
      const newLevels = classLevels.map((x) => ({
        ...x,
        score_entry_timeframes: x.score_entry_timeframes.map((y) => ({
          ...y,
          start_date: breakdown.find((i) => i.assessment.id === y.assessment.id)?.start_date,
          end_date: breakdown.find((i) => i.assessment.id === y.assessment.id)?.end_date,
        })),
      }));
      assessment.class_levels = newLevels;
      assessment.assessment_format = { id: assessment.id };
      const newList = returnUpdatedList(assessment, assessmentList, 'assessment_format', 'id');
      setAssessmentList(newList);

      Notify('Score timeframes have been updated successfully.');
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      handleError(err);
    }
  };

  const structuredAssessmentList = useMemo(() => {
    return assessmentList.map((x) => ({
      id: x.id || x.assessment_format.id,
      name: x.name || x.assessment_format.assessment_format_name,
      breakdown: x.breakdown || x.assessments_and_breakdowns,
      class_levels:
        x.class_levels ||
        x.assessment_format.class_levels.map((level) => ({
          ...level,
          score_entry_timeframes:
            levelAssessments.find((item) => item.id === level.class_level_id)
              ?.score_entry_timeframes?.length > 0
              ? levelAssessments.find((item) => item.id === level.class_level_id)
                  ?.score_entry_timeframes
              : x.assessments_and_breakdowns.map((y) => ({
                  assessment: y.assessment,
                  start_date: '',
                  end_date: '',
                })),
        })),
    }));
  }, [assessmentList, levelAssessments]);

  const updateSingleClass = async (level, format_id, loading) => {
    try {
      loading.start();
      const data = level.score_entry_timeframes
        .filter((item) => item.start_date !== '' && item.end_date !== '')
        .map((x) => ({
          class_level_id: level.class_level_id,
          term_id,
          start_date: x.start_date,
          end_date: x.end_date,
          assessment_id: x.assessment.id,
        }));
      await httpClient.post('/score-entry-timeframe', {
        timeframes: data,
      });

      const assessment = structuredAssessmentList.find((x) => x.id === format_id);
      assessment.class_levels = returnUpdatedList(level, assessment.class_levels, 'class_level_id');
      assessment.assessment_format = { id: assessment.id };
      const newList = returnUpdatedList(assessment, assessmentList, 'assessment_format', 'id');
      setAssessmentList(newList);
      loading.stop();
      Notify(`Score timeframe for ${level.short_name} was saved successfully.`);
    } catch (error) {
      loading.stop();
      handleError(error);
    }
  };

  return {
    structuredAssessmentList,
    setAssessmentList,
    buttonLoading,
    SaveAll,
    updateSingleClass,
    loading,
  };
};

export default useScoreTimeframe;
