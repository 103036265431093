import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { getBulkCheckoutInfoFail, getBulkCheckoutInfoSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* getBulkPaymentInfo({ payload }) {
  try {
    const response = yield httpClient.post(`/checkout/bulk`, payload);
    yield put(getBulkCheckoutInfoSuccess(response.data));
  } catch (error) {
    yield put(getBulkCheckoutInfoFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetBulkPaymentInfoStart() {
  yield takeLatest(invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_START, getBulkPaymentInfo);
}
