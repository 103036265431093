import { takeLatest, put } from 'redux-saga/effects';
import { fetchStudentsWithoutClassSuccess, fetchStudentsWithoutClassFail } from '../action';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* fetchStudentsWithoutClass({ payload }) {
  try {
    const response = yield httpClient.get(`/students/no-class`, { params: payload });
    yield put(fetchStudentsWithoutClassSuccess(response.data));
  } catch (error) {
    yield put(fetchStudentsWithoutClassFail(error?.message));
    handleError(error);
  }
}

export function* onFetchStudentsWithoutClassStart() {
  yield takeLatest(
    studentActionTypes.FETCH_STUDENTS_WITHOUT_CLASS_START,
    fetchStudentsWithoutClass,
  );
}
