import { all, call } from 'redux-saga/effects';
import { onSigninStart } from './authentication';
import { onCreateEditRequest } from './createEditProfileRequest';
import { onDownloadUserDocStart } from './downloadUserDocument';
import { onFetchStaffEditRequests } from './fetchStaffEditRequests';
import { onFetchUserDetails } from './fetchUserDetails';
import { onFetchUserDocument } from './fetchUserDocuments';
import { onResetPasswordStart } from './resetPasswordSagas';
import { onGoogleAuthLogin } from './googleAuth';
import { onGetUserDetailsStart } from './getUserDetails';
import { onUpdateOnboardingStepsStart } from './updateOnboardingSteps';
import { onChangeUsername } from './changeUsername';
import { onSendOTP } from './sendOTP';
import { onVerifyNewEmail } from './verifyNewEmail';

export function* userSagas() {
  yield all([
    call(onSigninStart),
    call(onCreateEditRequest),
    call(onFetchStaffEditRequests),
    call(onFetchUserDetails),
    call(onFetchUserDocument),
    call(onDownloadUserDocStart),
    call(onResetPasswordStart),
    call(onGoogleAuthLogin),
    call(onGetUserDetailsStart),
    call(onUpdateOnboardingStepsStart),
    call(onChangeUsername),
    call(onSendOTP),
    call(onVerifyNewEmail),
  ]);
}
