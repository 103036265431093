import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { updateParentPictureFailure, updateParentPictureSuccess } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';
import { school_id } from 'utils/constants';

export function* updateParentPicture({ payload }) {
  const { data, id } = payload;
  const formData = data;
  try {
    const response = yield httpClient.post(`/parents/${id}/uploads/profile-pic`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-TENANT-ID': `${school_id}`,
      },
    });
    yield put(updateParentPictureSuccess(response.data, id));
    Notify('Parent Picture Updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(updateParentPictureFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onUpdateParentPictureStart() {
  yield takeLatest(staffActionTypes.UPDATE_PARENT_PICTURE_START, updateParentPicture);
}
