import { SchoolFeesPaymentsRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { school_id } from 'utils/constants';
import { apiWrapper } from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { makePaymentFail, makePaymentSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* makePayment({ payload }) {
  try {
    const { callback } = payload;
    delete payload.callback;

    const response = yield apiWrapper(() =>
      SchoolFeesPaymentsRestControllerService.initOnlinePaymentUsingPost({
        request: payload,
        xTenantId: school_id,
      }),
    );

    switch (payload.payment_method?.toUpperCase()) {
      case 'PAYSTACK':
        yield put(makePaymentSuccess(response));
        const configObj = {
          reference: response?.data?.safsims_transaction_ref,
          amount: parseInt(`${response?.total}`) * 100,
          split_code: response?.split_code,
        };

        callback(configObj);
        break;
      case 'STRIPE':
        yield put(makePaymentSuccess(response));
        const config = {
          reference: response?.data?.safsims_transaction_ref,
          amount: parseInt(`${response?.total}`) * 100,
          split_code: response?.split_code,
          client_secret: response?.client_secret,
        };
        callback(config);
        break;
      case 'PESAPAL':
        yield put(makePaymentSuccess({ data: { link: response?.redirect_url } }));
        window.location.href = response?.redirect_url;
        break;
      default:
        window.location.href = response?.data?.link;
        break;
    }
  } catch (error) {
    yield put(makePaymentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onMakePaymentStart() {
  yield takeLatest(invoiceActionTypes.MAKE_PAYMENT_START, makePayment);
}
