import { takeLatest, put } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { getCumulativeArmBroadsheetFail, getCumulativeArmBroadsheetSuccess } from '../actions';
import broadSheetTypes from '../types';
import { handleError } from 'utils/utils';

export function* fetchCumulativeArmBroadsheet({ payload }) {
  const params = {
    'class-level-id': payload.classLevelId,
    'term-id': payload.termId,
    'arm-id': payload.armId,
  };
  try {
    const response = yield httpClient.get(`/student-results/broad-sheet/cumulative`, { params });
    yield put(getCumulativeArmBroadsheetSuccess(response.data));
  } catch (error) {
    yield put(getCumulativeArmBroadsheetFail(error?.message));
    const obj = {
      code: 404,
      message: 'No Grading Format defined for class. Please contact your school admin',
    };
    const err = handleError(error, obj);
  }
}

export function* onFetchCumulativeArmBroadsheetStart() {
  yield takeLatest(
    broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_START,
    fetchCumulativeArmBroadsheet,
  );
}
