export enum OnboardingStepsEnum {
  SCHOOL_INFORMATION = 'SCHOOL_INFORMATION',
  CLASS_LEVEL = 'CLASS_LEVEL',
  CLASS_ARM = 'CLASS_ARM',
  TERM_DEFINITION = 'TERM_DEFINITION',

  SESSION = 'SESSION',
}

export const OnboardingStepsInfo = [
  {
    name: 'SCHOOL_INFORMATION',
    index: 1,
    description: 'School Information Configuration',
    path: '/school-information',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Update School Name',
      'Upload School Logo',
      'Set School Colour',
      'Update School Contact Information',
      'Update Technical Contact',
      'Click on Save',
    ],
    helperText: `Kindly update the required school information. When you are done, click on the Next button to proceed to the next step.`,
  },
  {
    name: 'CLASS_LEVEL',
    index: 2,
    description: 'Class Level Configuration',
    path: '/class-level',
    module: 'general',
    required: true,
    status: false,
    text: [
      'Click on Create Level button',
      'Fill in level name and shortname',
      'Enable early years if applicable',
      'Select order',
      'Click on Save',
    ],
    helperText:
      "Kindly create all class level in your school. When you're done, Click on Next button to proceed.",
  },
  {
    name: 'CLASS_ARM',
    index: 3,
    description: 'Class Arm Configuration',
    path: '/arms',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Click on Create Arm button',
      'Enter the Arm Name in the field provided',
      'Click on the Save Arm button',
    ],
    helperText: "Kindly create a class arm. When you're done, Click on Next button to proceed.",
  },
  {
    name: 'SESSION',
    index: 4,
    description: 'Session Configuration',
    path: '/sessions-and-terms',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Click on Create Session',
      'Select the session from the dropdown',
      'Enter the "term start date, term end date, and next term start date"',
      'Click on Create Session',
    ],
    helperText: 'Kindly create a new session by clicking on the Create Session button.',
  },

  {
    name: 'TERM_DEFINITION',
    index: 5,
    description: 'Term Initialization Configuration',
    path: '/sessions-and-terms',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Under Sessions, scroll down to the session and term of your choice and click Configure',
      'Set the start and end dates',
      'Click on next and follow the wizard until you get to last step',
      'Click on Set as a current term',
    ],
    helperText:
      'Kindly initialize a term by clicking on the Configure button,  go through the steps and click on set as current term.',
  },
];
