import { takeLatest, put } from 'redux-saga/effects';
import { fetchParentChildrenSuccess, fetchParentChildrenFailure } from '../actions';
import {
  getStudentTraitAssessmentStart,
  getStudentCommentsStart,
  fetchChildResultStart,
  fetchChildSkillsResultStart,
  fetchChildAttendanceSummaryStart,
} from 'redux/configuration/actions';
import httpClient from 'utils/http-client';
import parentActionTypes from '../types';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* fetchParentChildren({ payload }) {
  try {
    const response = yield httpClient.get(`/parents/${payload.parent_id}/children`);
    yield put(fetchParentChildrenSuccess(response.data));

    if (response.data.length) {
      if (response.data[0].earlyYears) {
        yield put(
          getStudentTraitAssessmentStart({
            termId: payload.currentTerm,
            studentId: response.data[0].id,
          }),
        );
      } else {
        yield put(
          fetchChildResultStart({
            termId: payload.currentTerm,
            studentId: response.data[0].id,
          }),
        );
      }

      yield put(
        getStudentCommentsStart({
          termId: payload.currentTerm,
          studentId: response.data[0].id,
        }),
      );
      yield put(
        fetchChildAttendanceSummaryStart({
          studentId: response.data[0].id,
          termId: payload.currentTerm,
        }),
      );
      yield put(
        fetchChildSkillsResultStart({
          termId: payload.currentTerm,
          studentId: response.data[0].id,
        }),
      );
    }
  } catch (error) {
    yield put(fetchParentChildrenFailure(error?.message));
    handleError(error);
  }
}

export function* onFetchParentChildrenStart() {
  yield takeLatest(parentActionTypes.FETCH_PARENT_CHILDREN_START, fetchParentChildren);
}
