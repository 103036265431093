import { takeLatest, put } from 'redux-saga/effects';
import { addSingleTraitSuccess, addSingleTraitFail, fetchAllTraitGroupStart } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* addSingleTrait({ payload }) {
  const callback = payload.callback;
  delete payload.callback;
  try {
    const response = yield httpClient.post('/trait-configuration/traits', payload);
    yield put(addSingleTraitSuccess(response.data));
    Notify('Trait added succesfully', { position: 'top-right' });
    callback(response.data);
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(addSingleTraitFail(error.fail));
    const err = handleError(error);
  }
}

export function* onAddSingleTraitStart() {
  yield takeLatest(earlyYearsActionTypes.ADD_SINGLE_TRAIT_START, addSingleTrait);
}
