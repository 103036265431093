const subjectsActionTypes = {
  CREATE_SUBJECT_START: 'CREATE_SUBJECT_START',
  CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
  CREATE_SUBJECT_FAILURE: 'CREATE_SUBJECT_FAILURE',

  FETCH_SUBJECTS_START: 'FETCH_SUBJECTS_START',
  FETCH_SUBJECTS_SUCCESS: 'FETCH_SUBJECTS_SUCCESS',
  FETCH_SUBJECTS_FAILURE: 'FETCH_SUBJECTS_FAILURE',

  EDIT_SUBJECT_START: 'EDIT_SUBJECT_START',
  EDIT_SUBJECT_SUCCESS: 'EDIT_SUBJECT_SUCCESS',
  EDIT_SUBJECT_FAILURE: 'EDIT_SUBJECT_FAILURE',

  DELETE_SUBJECT_START: 'DELETE_SUBJECT_START',
  DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
  DELETE_SUBJECT_FAILURE: 'DELETE_SUBJECT_FAILURE',

  HANDLE_MODAL: 'HANDLE_MODAL',
  HANDLE_EDIT_MODAL: 'HANDLE_EDIT_MODAL',
  HANDLE_DELETE_MODAL: 'HANDLE_DELETE_MODAL',

  GET_LEVEL_SUBJECTS_START: 'GET_LEVEL_SUBJECTS_START',
  GET_LEVEL_SUBJECTS_SUCCESS: 'GET_LEVEL_SUBJECTS_SUCCESS',
  GET_LEVEL_SUBJECTS_FAILURE: 'GET_LEVEL_SUBJECTS_FAILURE',

  HANDLE_ASSIGN_SUBJECT_TEACHER_MODAL: 'HANDLE_ASSIGN_SUBJECT_TEACHER_MODAL',

  ASSIGN_SUBJECT_TEACHERS_START: 'ASSIGN_SUBJECT_TEACHERS_START',
  ASSIGN_SUBJECT_TEACHERS_SUCCCESS: 'ASSIGN_SUBJECT_TEACHERS_SUCCESS',
  ASSIGN_SUBJECT_TEACHERS_FAIL: 'ASSIGN_SUBJECT_TEACHERS_FAIL',

  EDIT_SUBJECT_TEACHERS_START: 'EDIT_SUBJECT_TEACHERS_START',
  EDIT_SUBJECT_TEACHERS_SUCCESS: 'EDIT_SUBJECT_TEACHERS_SUCCESS',
  EDIT_SUBJECT_TEACHERS_FAILURE: 'EDIT_SUBJECT_TEACHERS_FAILURE',

  SET_STUDENT_SUBJECT_PROFILE_START: 'SET_STUDENT_SUBJECT_PROFILE_START',
  SET_STUDENT_SUBJECT_PROFILE_SUCCESS: 'SET_STUDENT_SUBJECT_PROFILE_SUCCESS',
  SET_STUDENT_SUBJECT_PROFILE_FAILURE: 'SET_STUDENT_SUBJECT_PROFILE_FAILURE',

  GET_SUBJECT_RESULT_START: 'GET_SUBJECT_RESULT_START',
  GET_SUBJECT_RESULT_SUCCESS: 'GET_SUBJECT_RESULT_SUCCESS',
  GET_SUBJECT_RESULT_FAIL: 'GET_SUBJECT_RESULT_FAIL',

  HANDLE_SET_STUDENT_SUBJECT_MODAL: 'HANDLE_SET_STUDENT_SUBJECT_MODAL',
};

export default subjectsActionTypes;
