import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchGradeFormatsStart,
  updateGradeFormatsFail,
  updateGradeFormatsSuccess,
} from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import gradesActionTypes from '../types';

export function* updateFormat({ payload, callBack }) {
  try {
    const response = yield httpClient.put(`/grading/group`, payload);
    yield put(updateGradeFormatsSuccess(response.data));
    Notify('Grade format was updated successfully', { position: 'top-right', status: 'success' });
    callBack();
    yield put(fetchGradeFormatsStart());
  } catch (error) {
    yield put(updateGradeFormatsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onUpdateGradeFormatsStart() {
  yield takeLatest(gradesActionTypes.UPDATE_GRADE_FORMATS_START, updateFormat);
}
