import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { createReportTemplateFail, createReportTemplateSuccess } from '../actions';
import ReportTemplateActionTypes from '../types';

export function* createReportTemplate({ data, callback }) {
  const newData = { ...data };

  delete newData.id;
  delete newData.date_created;
  delete newData.product_id;

  newData.name = `${newData.name} (copy)`;
  newData.template_data.schema.name = newData.name;

  try {
    const response = yield httpClient.post(`/report-templates`, newData);
    yield put(createReportTemplateSuccess(response?.data));

    Notify('Template duplicated successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createReportTemplateFail(error?.message));
  }
}

export function* onCreateReportTemplateStart() {
  yield takeLatest(ReportTemplateActionTypes.CREATE_REPORT_TEMPLATE_START, createReportTemplate);
}
