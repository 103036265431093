import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchRevenueStatisticsSuccess, fetchRevenueStatisticsFail } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchRevenueStatistics({ payload }) {
  try {
    const { session_id, term_id, class_level_id, arm_id } = payload;
    let params = { session_id, term_id, class_level_id, arm_id };
    if (!params.class_level_id) {
      delete params.class_level_id;
    }
    if (!params.arm_id) {
      delete params.arm_id;
    }
    if (session_id || term_id) {
      const response = yield httpClient.get(`/revenue-statistics/`, { params });
      yield put(fetchRevenueStatisticsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchRevenueStatisticsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchRevenueStatisticsStart() {
  yield takeLatest(invoiceActionTypes.FETCH_REVENUE_STATISTICS_START, fetchRevenueStatistics);
}
