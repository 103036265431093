import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import httpClient from '../../../utils/http-client';
import { adminResetPasswordFailure, adminResetPasswordSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* adminResetPassword({ data }) {
  try {
    const response = yield httpClient.post(`/auth/admin/password/reset`, data);
    yield put(adminResetPasswordSuccess(response.data));
    Notify('Staff Password Reset successfully', { position: 'top-right' });
  } catch (error) {
    yield put(adminResetPasswordFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onAdminResetPasswordStart() {
  yield takeLatest(staffActionTypes.ADMIN_RESET_PASSWORD_START, adminResetPassword);
}
