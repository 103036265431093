import { takeLatest, put } from 'redux-saga/effects';
import { deleteClassLevelSuccess, deleteClassLevelFail, fetchClassLevelStart } from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { Theme } from 'config/theme';
import { handleError } from 'utils/utils';

export function* deleteClassLevel({ payload }) {
  try {
    const response = yield httpClient.delete(`/class-levels/${payload.id}`);
    yield put(deleteClassLevelSuccess(payload.id));
    yield put(fetchClassLevelStart());
    Notify('Class level deleted succesfully', { position: 'top-right' });

    if (payload.isProfile) payload.callback();
  } catch (error) {
    yield put(deleteClassLevelFail(error.fail));
    const obj = {
      code: 500,
      message: 'Class levels with arms cannot be deleted',
    };
    const err = handleError(error, obj);
  }
}

export function* onDeleteClassStart() {
  yield takeLatest(configActionTypes.DELETE_CLASS_LEVEL_START, deleteClassLevel);
}
