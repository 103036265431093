import { useGetRequest } from 'api/useGetRequest/useGetRequest';
import { TermRestControllerService } from 'generated';

interface Props {
  enabled?: boolean;
}

const useAllTermsGet = ({ enabled = true }: Props) => {
  const { data: terms = [], loading } = useGetRequest({
    service: TermRestControllerService.getAllTermsUsingGet,
    tag: 'TermRestControllerService',
    payload: undefined,
    enabled,
  });

  return {
    loading,
    terms,
  };
};

export default useAllTermsGet;
