import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { fetchListStaffDocumentsFailure, fetchListStaffDocumentsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchListOfStaffDocuments({ payload }) {
  try {
    const response = yield httpClient.get(`/documents/staff/${payload}`);
    yield put(fetchListStaffDocumentsSuccess(response.data));
  } catch (error) {
    yield put(fetchListStaffDocumentsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchListOfStaffDocuments() {
  yield takeLatest(staffActionTypes.FETCH_LIST_OF_STAFF_DOCUMENTS_START, fetchListOfStaffDocuments);
}
