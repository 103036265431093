import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { getSinglePageCheckoutFail, getSinglePageCheckoutSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* getSinglePageCheckout({ payload }) {
  try {
    const response = yield httpClient.get(`/direct-pay/checkout/${payload}`);
    yield put(getSinglePageCheckoutSuccess(response.data));
  } catch (error) {
    yield put(getSinglePageCheckoutFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetSinglePageCheckoutStart() {
  yield takeLatest(invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_START, getSinglePageCheckout);
}
