import { all, call } from 'redux-saga/effects';
import { onAssignFormTeachersStart } from './assignFormTeachers';
import { onfetchClassInformationStart } from './fetchClassInformation';
import { onFetchTeachersStart } from './fetchTeachers';

export function* ClassInformationSagas() {
  yield all([
    call(onfetchClassInformationStart),
    call(onFetchTeachersStart),
    call(onAssignFormTeachersStart),
  ]);
}
