import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { getTermProfileGradeFormatFail, getTermProfileGradeFormatSuccess } from '../actions';
import configActionTypes from '../types';

export function* getTermProfileGradeFormat({ payload }) {
  try {
    const callback = payload.callback;
    delete payload.callback;

    const response = yield httpClient.get(`/grading/grading-config/${payload.id}`);
    yield put(getTermProfileGradeFormatSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(getTermProfileGradeFormatFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTermProfileGradeFormat() {
  yield takeLatest(
    configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_START,
    getTermProfileGradeFormat,
  );
}
