const ReportTemplateActionTypes = {
  FETCH_REPORT_TEMPLATE_START: 'FETCH_REPORT_TEMPLATE_START',
  FETCH_REPORT_TEMPLATE_SUCCESS: 'FETCH_REPORT_TEMPLATE_SUCCESS',
  FETCH_REPORT_TEMPLATE_FAIL: 'FETCH_REPORT_TEMPLATE_FAIL',

  CREATE_REPORT_TEMPLATE_START: 'CREATE_REPORT_TEMPLATE_START',
  CREATE_REPORT_TEMPLATE_SUCCESS: 'CREATE_REPORT_TEMPLATE_SUCCESS',
  CREATE_REPORT_TEMPLATE_FAIL: 'CREATE_REPORT_TEMPLATE_FAIL',

  DELETE_REPORT_TEMPLATE_START: 'DELETE_REPORT_TEMPLATE_START',
  DELETE_REPORT_TEMPLATE_SUCCESS: 'DELETE_REPORT_TEMPLATE_SUCCESS',
  DELETE_REPORT_TEMPLATE_FAIL: 'DELETE_REPORT_TEMPLATE_FAIL',
};

export default ReportTemplateActionTypes;
