import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import { fetchStaffEditRequestsFailure, fetchStaffEditRequestsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';

export function* fetchStaffEditRequests({ payload }) {
  try {
    const response = yield httpClient.get(`/staff/profile-edit/${payload}`);
    yield put(fetchStaffEditRequestsSuccess(response.data));
  } catch (error) {
    yield put(fetchStaffEditRequestsFailure(error?.message));
  }
}

export function* onFetchStaffEditRequests() {
  yield takeLatest(userActionTypes.FETCH_STAFF_EDIT_REQUESTS_START, fetchStaffEditRequests);
}
