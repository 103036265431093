import { takeLatest, put } from 'redux-saga/effects';
import { editClassLevelSuccess, editClassLevelFail, fetchClassLevelStart } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* editClassLevel({ payload }) {
  try {
    const response = yield httpClient.put('/class-levels', payload);
    yield put(editClassLevelSuccess(response.data));
    yield put(fetchClassLevelStart());
    Notify('Class level edited succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(editClassLevelFail(error?.message));
    const err = handleError(error);
  }
}

export function* onEditClassLevelStart() {
  yield takeLatest(configActionTypes.EDIT_CLASS_LEVEL_START, editClassLevel);
}
