import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchGradeFormatsFail, fetchGradeFormatsSuccess } from '../actions';
import gradesActionTypes from '../types';

export function* fetchFormats({ payload }) {
  const callback = payload?.callback;
  delete payload?.callback;
  try {
    const response = yield httpClient.get(`/grading/group`, payload);
    yield put(fetchGradeFormatsSuccess(response.data));
    callback?.(response.data);
  } catch (error) {
    yield put(fetchGradeFormatsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchGradeFormatsStart() {
  yield takeLatest(gradesActionTypes.FETCH_GRADE_FORMATS_START, fetchFormats);
}
