/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSubjectAssessmentConfigurationRequest } from '../models/CreateSubjectAssessmentConfigurationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubjectAssessmentConfigurationRestControllerService {
  /**
   * getSubjectAssessmentConfig
   * @returns any OK
   * @throws ApiError
   */
  public static getSubjectAssessmentConfigUsingGet({
    classLevelId,
    subjectId,
    termId,
  }: {
    /**
     * class-level-id
     */
    classLevelId: string;
    /**
     * subject-id
     */
    subjectId: string;
    /**
     * term-id
     */
    termId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subject-assessment-configuration',
      query: {
        'class-level-id': classLevelId,
        'subject-id': subjectId,
        'term-id': termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createSubjectAssessmentConfig
   * @returns any OK
   * @throws ApiError
   */
  public static createSubjectAssessmentConfigUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: CreateSubjectAssessmentConfigurationRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subject-assessment-configuration',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateSubjectAssessmentConfig
   * @returns any OK
   * @throws ApiError
   */
  public static updateSubjectAssessmentConfigUsingPut({
    id,
    request,
  }: {
    /**
     * id
     */
    id: string;
    /**
     * request
     */
    request: CreateSubjectAssessmentConfigurationRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/subject-assessment-configuration/{id}',
      path: {
        id: id,
      },
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
