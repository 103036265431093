import { takeLatest, put } from 'redux-saga/effects';
import { getTermSuccess, getTermFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';

export function* fetchTerm() {
  try {
    const response = yield httpClient.get(`/terms`);
    yield put(getTermSuccess(response.data));
  } catch (error) {
    yield put(getTermFail(error?.message));
  }
}

export function* onfetchTermStart() {
  yield takeLatest(configActionTypes.GET_TERM_START, fetchTerm);
}
