import { put, takeLatest } from 'redux-saga/effects';
import plansActionTypes from '../types';
import { selectAppModulesSuccess, fetchAppModulesStart, selectAppModulesFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* selectAppModules({ payload }) {
  try {
    const response = yield httpClient.put('/app-module', payload);

    yield put(selectAppModulesSuccess(response.data));
    yield put(fetchAppModulesStart());
    Notify('App modules updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(selectAppModulesFailure(error.message));
    const err = handleError(error);
  }
}

export function* onSelectAppModulesStart() {
  yield takeLatest(plansActionTypes.SELECT_APP_MODULES_START, selectAppModules);
}
