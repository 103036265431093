import { AppState } from 'redux/root-reducer';
import { createSelector } from 'reselect';

const selectGlobal = (state: AppState) => state.global;

export const selectCountries = createSelector([selectGlobal], (global) => global.countries);

export const selectStates = createSelector([selectGlobal], (global) => global.states);

export const selectLga = createSelector([selectGlobal], (global) => global.lga);

export const selectSiteConfig = createSelector([selectGlobal], (global) => global.siteConfig);
