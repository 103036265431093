import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { getTransactionLogFail, getTransactionLogSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';

export function* getTransactionLog({ payload }) {
  try {
    const response = yield httpClient.post(`/payments/transactions`, payload);
    yield put(getTransactionLogSuccess(response.data));
  } catch (error) {
    yield put(getTransactionLogFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetTransactionLogStart() {
  yield takeLatest(invoiceActionTypes.GET_TRANSACTION_LOG_START, getTransactionLog);
}
