import { Notify } from '@flexisaf/flexibull2';
import {
  AssessmentConfigurationDto,
  AssessmentConfigurationRestControllerService,
  SubAssessmentBreakdownRequest,
} from 'generated';
import { useCallback, useState } from 'react';
import { apiWrapper } from 'utils/http-client';
import { handleError } from 'utils/utils';

const dummyAssessmentBreakdown = {
  assessment_format: {
    id: '2e051b40-dacc-4339-aa8f-32da876e02c9',
    assessment_format_name: 'Format Name',
    class_levels: [],
  },
  assessments_and_breakdowns: [
    {
      assessment: {
        id: '95c7562e-8eeb-4aeb-a749-99ff5c49f159',
        assessment_name: 'CA 1',
        percentage: 20,
        assessment_order: 1,
        assessment_type: 'CA',
        assessment_format: {
          id: '2e051b40-dacc-4339-aa8f-32da876e02c9',
          assessment_format_name: 'Format Name',
          class_levels: [],
        },
      },
      breakdowns: [
        {
          id: '0711a3c7-f006-4b93-bd45-d81104023fd3',
          assessment_breakdown_name: 'Class work',
          assessment_id: '95c7562e-8eeb-4aeb-a749-99ff5c49f159',
          percentage: 60.0,
          assessment_order: 2,
          sub_assessment_breakdown_dtos: [
            {
              id: '27f76666-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 1',
              max_score: 50.0,
              order: 1,
            },
            {
              id: 'kbhsk-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 2',
              max_score: 50.0,
              order: 2,
            },
            {
              id: 'dbhsboue-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 3',
              max_score: 50.0,
              order: 3,
            },
          ],
        },
        {
          id: '236a7a2a-935c-41f9-81bd-c5f67c991cc9',
          assessment_breakdown_name: 'Home work',
          assessment_id: '95c7562e-8eeb-4aeb-a749-99ff5c49f159',
          percentage: 40.0,
          assessment_order: 1,
          sub_assessment_breakdown_dtos: [
            {
              id: '0eaf2ca9-33ca-4d19-8714-0ad5f98630dd',
              name: ' Test 2',
              max_score: 20.0,
              order: 1,
            },
            {
              id: 'gdddrd-33ca-4d19-8714-0ad5f98630dd',
              name: ' Test 2',
              max_score: 20.0,
              order: 2,
            },
            {
              id: 'gerhtegt-33ca-4d19-8714-0ad5f98630dd',
              name: ' Test 2',
              max_score: 20.0,
              order: 3,
            },
          ],
        },
      ],
    },
    {
      assessment: {
        id: 'sfbsbsfb-8eeb-4aeb-a749-99ff5c49f159',
        assessment_name: 'CA 2',
        percentage: 30,
        assessment_order: 2,
        assessment_type: 'CA',
        assessment_format: {
          id: '2e051b40-dacc-4339-aa8f-32da876e02c9',
          assessment_format_name: 'Format Name',
          class_levels: [],
        },
      },
      breakdowns: [
        {
          id: 'vsfbsb-f006-4b93-bd45-d81104023fd3',
          assessment_breakdown_name: 'Class work',
          assessment_id: 'sfbsbsfb-8eeb-4aeb-a749-99ff5c49f159',
          percentage: 60.0,
          assessment_order: 2,
          sub_assessment_breakdown_dtos: [
            {
              id: 'ndndnd-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 1',
              max_score: 20.0,
              order: 1,
            },
            {
              id: 'xvsvdgfsb-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 2',
              max_score: 20.0,
              order: 2,
            },
            {
              id: 'bfdgndbndg-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 3',
              max_score: 20.0,
              order: 3,
            },
            {
              id: 'ndgnddgnd-2767-4fb1-b4ea-c5e2f4efaa0a',
              name: ' Test 4',
              max_score: 20.0,
              order: 4,
            },
          ],
        },
        {
          id: 'bdbdbdf-935c-41f9-81bd-c5f67c991cc9',
          assessment_breakdown_name: 'Home work',
          assessment_id: 'sfbsbsfb-8eeb-4aeb-a749-99ff5c49f159',
          percentage: 40.0,
          assessment_order: 1,
          sub_assessment_breakdown_dtos: [
            {
              id: 'hgbtrt-33ca-4d19-8714-0ad5f98630dd',
              name: 'Home Work 1',
              max_score: 20.0,
              order: 1,
            },
            {
              id: 'jkv-33ca-4d19-8714-0ad5f98630dd',
              name: 'Home Work 2',
              max_score: 20.0,
              order: 2,
            },
            {
              id: 'ssmnj-33ca-4d19-8714-0ad5f98630dd',
              name: 'Home Work 3',
              max_score: 20.0,
              order: 3,
            },
          ],
        },
      ],
    },
  ],
};

export const useAssessmentBreakdownGet = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<AssessmentConfigurationDto>({});

  const fetchAssessmentBreakdown = useCallback(
    async (payload: {
      armId?: string | undefined;
      classLevelId?: string | undefined;
      subjectId?: string | undefined;
      termId?: string | undefined;
    }) => {
      setLoading(true);
      try {
        const response = await apiWrapper(() =>
          AssessmentConfigurationRestControllerService.getAssessmentBreakdownsUsingGet(payload),
        );
        setLoading(false);
        setContent(response);
        return { success: true, response: response };
      } catch (error) {
        setContent({});
        setLoading(false);
        handleError(error);
        return { success: false, response: {} };
      }
    },
    [],
  );

  return {
    loadingAssessmentBreakdown: loading,
    fetchAssessmentBreakdown,
    assessmentBreakDownContent: content,
  };
};

export const useSubBreakdowPut = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any>({});

  const saveSubBreakdown = useCallback(async (payload: SubAssessmentBreakdownRequest) => {
    setLoading(true);
    try {
      const response = await apiWrapper(() =>
        AssessmentConfigurationRestControllerService.updateAssessmentSubBreakdownUsingPut({
          request: payload,
        }),
      );

      setLoading(false);
      setContent(response);
      return { success: true, response: response };
    } catch (error) {
      setContent({});
      setLoading(false);
      handleError(error);
      return { success: false, response: {} };
    }
  }, []);

  return {
    loadingSave: loading,
    saveSubBreakdown,
  };
};

export const useSubBreakdownDelete = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any>({});

  const deleteSubBreakdown = useCallback(
    async (payload: { assessmentBreakdownId: string; subAssessmentBreakdownId: string }) => {
      setLoading(true);

      try {
        const response = await apiWrapper(() =>
          AssessmentConfigurationRestControllerService.deleteAssessmentSubBreakdownUsingDelete(
            payload,
          ),
        );

        setLoading(false);
        setContent(response);
        Notify('Sub-assessment deleted successfully', { status: 'success' });
        return { success: true, response: response };
      } catch (error) {
        setContent({});
        setLoading(false);
        handleError(error);
        return { success: false, response: {} };
      }
    },
    [],
  );

  return {
    loadingDelete: loading,
    deleteSubBreakdown,
    deleteContent: content,
  };
};
