const invoiceSummariesActionTypes = {
  FETCH_STUDENT_INVOICES_SUMMARIES_START: 'FETCH_STUDENT_INVOICES_SUMMARIES_START',
  FETCH_STUDENT_INVOICES_SUMMARIES_SUCCESS: 'FETCH_STUDENT_INVOICES_SUMMARIES_SUCCESS',
  FETCH_STUDENT_INVOICES_SUMMARIES_FAILURE: 'FETCH_STUDENT_INVOICES_SUMMARIES_FAILURE',

  MESSAGE_DEFAULTERS_SEND_SMS_START: 'MESSAGE_DEFAULTERS_SEND_SMS_START',
  MESSAGE_DEFAULTERS_SEND_SMS_SUCCESS: 'MESSAGE_DEFAULTERS_SEND_SMS_SUCCESS',
  MESSAGE_DEFAULTERS_SEND_SMS_FAILURE: 'MESSAGE_DEFAULTERS_SEND_SMS_FAILURE',

  MESSAGE_DEFAULTERS_SEND_EMAIL_START: 'MESSAGE_DEFAULTERS_SEND_EMAIL_START',
  MESSAGE_DEFAULTERS_SEND_EMAIL_SUCCESS: 'MESSAGE_DEFAULTERS_SEND_EMAIL_SUCCESS',
  MESSAGE_DEFAULTERS_SEND_EMAIL_FAILURE: 'MESSAGE_DEFAULTERS_SEND_EMAIL_FAILURE',

  SET_ACTIVE_INVOICE_SUMMARY: 'SET_ACTIVE_INVOICE_SUMMARY',

  HANDLE_MESSAGE_DEFAULTS_MODAL: 'HANDLE_MESSAGE_DEFAULTS_MODAL',
};

export default invoiceSummariesActionTypes;
