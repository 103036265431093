import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { addTraitGroupFail, addTraitGroupSuccess, fetchAllTraitGroupStart } from '../actions';
import earlyYearsActionTypes from '../types';

export function* addTraitGroup({ payload }) {
  try {
    const response = yield httpClient.post('/trait-configuration', payload);
    yield put(addTraitGroupSuccess(response.data));
    Notify('Trait group added succesfully', { position: 'top-right' });
    yield put(fetchAllTraitGroupStart());
  } catch (error) {
    yield put(addTraitGroupFail(error.fail));
    const err = handleError(error);
  }
}

export function* onAddTraitGroupStart() {
  yield takeLatest(earlyYearsActionTypes.ADD_TRAIT_GROUP_START, addTraitGroup);
}
