enum earlyYearsActionTypes {
  ADD_TRAIT_GROUP_START = 'ADD_TRAIT_GROUP_START',
  ADD_TRAIT_GROUP_SUCCESS = 'ADD_TRAIT_GROUP_SUCCESS',
  ADD_TRAIT_GROUP_FAIL = 'ADD_TRAIT_GROUP_FAIL',

  FETCH_TRAIT_GROUP_START = 'FETCH_TRAIT_GROUP_START',
  FETCH_TRAIT_GROUP_SUCCESS = 'FETCH_TRAIT_GROUP_SUCCESS',
  FETCH_TRAIT_GROUP_FAIL = 'FETCH_TRAIT_GROUP_FAIL',

  DELETE_SINGLE_TRAIT_START = 'DELETE_SINGLE_TRAIT_START',
  DELETE_SINGLE_TRAIT_SUCCESS = 'DELETE_SINGLE_TRAIT_SUCCESS',
  DELETE_SINGLE_TRAIT_FAIL = 'DELETE_SINGLE_TRAIT_FAIL',

  EDIT_SINGLE_TRAIT_START = 'EDIT_SINGLE_TRAIT_START',
  EDIT_SINGLE_TRAIT_SUCCESS = 'EDIT_SINGLE_TRAIT_SUCCESS',
  EDIT_SINGLE_TRAIT_FAIL = 'EDIT_SINGLE_TRAIT_FAIL',

  ADD_SINGLE_TRAIT_START = 'ADD_SINGLE_TRAIT_START',
  ADD_SINGLE_TRAIT_SUCCESS = 'ADD_SINGLE_TRAIT_SUCCESS',
  ADD_SINGLE_TRAIT_FAIL = 'ADD_SINGLE_TRAIT_FAIL',

  ADD_SINGLE_DEFINITION_START = 'ADD_SINGLE_DEFINITION_START',
  ADD_SINGLE_DEFINITION_SUCCESS = 'ADD_SINGLE_DEFINITION_SUCCESS',
  ADD_SINGLE_DEFINITION_FAIL = 'ADD_SINGLE_DEFINITION_FAIL',

  EDIT_SINGLE_DEFINITION_START = 'EDIT_SINGLE_DEFINITION_START',
  EDIT_SINGLE_DEFINITION_SUCCESS = 'EDIT_SINGLE_DEFINITION_SUCCESS',
  EDIT_SINGLE_DEFINITION_FAIL = 'EDIT_SINGLE_DEFINITION_FAIL',

  DELETE_SINGLE_DEFINITION_START = 'DELETE_SINGLE_DEFINITION_START',
  DELETE_SINGLE_DEFINITION_SUCCESS = 'DELETE_SINGLE_DEFINITION_SUCCESS',
  DELETE_SINGLE_DEFINITION_FAIL = 'DELETE_SINGLE_DEFINITION_FAIL',

  HANDLE_ADD_TRAIT_MODAL = 'HANDLE_ADD_TRAIT_MODAL',

  ADD_RATING_START = 'ADD_RATING_START',
  ADD_RATING_SUCCESS = 'ADD_RATING_SUCCESS',
  ADD_RATING_FAIL = 'ADD_RATING_FAIL',

  HANDLE_ADD_RATING_MODAL = 'HANDLE_ADD_RATING_MODAL',

  FETCH_RATING_START = 'FETCH_RATING_START',
  FETCH_RATING_SUCCESS = 'FETCH_RATING_SUCCESS',
  FETCH_RATING_FAIL = 'FETCH_RATING_FAIL',

  EDIT_RATING_START = 'EDIT_RATING_START',
  EDIT_RATING_SUCCESS = 'EDIT_RATING_SUCCESS',
  EDIT_RATING_FAIL = 'EDIT_RATING_FAIL',

  DELETE_RATING_START = 'DELETE_RATING_START',
  DELETE_RATING_SUCCESS = 'DELETE_RATING_SUCCESS',
  DELETE_RATING_FAIL = 'DELETE_RATING_FAIL',

  HANDLE_DELETE_RATING_MODAL = 'HANDLE_DELETE_RATING_MODAL',

  HANDLE_EDIT_RATING_MODAL = 'HANDLE_EDIT_RATING_MODAL',

  DELETE_SINGLE_RATING_START = 'DELETE_SINGLE_RATING_START',
  DELETE_SINGLE_RATING_SUCCESS = 'DELETE_SINGLE_RATING_SUCCESS',
  DELETE_SINGLE_RATING_FAIL = 'DELETE_SINGLE_RATING_FAIL',

  EDIT_SINGLE_RATE_START = 'EDIT_SINGLE_RATE_START',
  EDIT_SINGLE_RATE_SUCCESS = 'EDIT_SINGLE_RATE_SUCCESS',
  EDIT_SINGLE_RATE_FAIL = 'EDIT_SINGLE_RATE_FAIL',
}

export default earlyYearsActionTypes;
