/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookResponse } from '../models/BookResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BookRestControllerService {
  /**
   * getAllBooks
   * @returns BookResponse OK
   * @throws ApiError
   */
  public static getAllBooksUsingGet(): CancelablePromise<Array<BookResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/books/all',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
