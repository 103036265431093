import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import scoreEntryActionTypes from './types';

const INITIAL_STATE = {
  isLoading: false,
  scoreList: [],
  activeClassInfo: {},
  buttonLoading: false,
  refetchScores: 0,
  mostRecentServerScores: undefined,
};

function scoreEntriesReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case scoreEntryActionTypes.PUBLISH_CLASS_SCORES_START:
      return {
        ...state,
        isLoading: true,
      };
    case scoreEntryActionTypes.UPDATE_LOCAL_SCORES:
      return {
        ...state,
        scoreList: payload,
      };
    case scoreEntryActionTypes.PUBLISH_CLASS_SCORES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scoreList: payload,
      };
    case scoreEntryActionTypes.PUBLISH_CLASS_SCORES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case scoreEntryActionTypes.FETCH_CLASS_SCORES_START:
      return {
        ...state,
        isLoading: true,
      };
    case scoreEntryActionTypes.FETCH_CLASS_SCORES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scoreList: payload,
      };
    case scoreEntryActionTypes.FETCH_CLASS_SCORES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case scoreEntryActionTypes.SET_ACTIVE_CLASS_INFO:
      return {
        ...state,
        activeClassInfo: payload,
      };
    case scoreEntryActionTypes.SET_MOST_RECENT_SERVER_SCORES:
      return {
        ...state,
        mostRecentServerScores: payload,
      };
    case scoreEntryActionTypes.REFETCH_SCORES:
      return {
        ...state,
        refetchScores: state.refetchScores + 1,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'scoreEntries',
  storage,
  blacklist: ['error', 'isLoading', 'buttonLoading', 'mostRecentServerScores', 'refetchScores'],
};

export default persistReducer(persistConfig, scoreEntriesReducer);
