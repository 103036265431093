/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SchoolFeesConfigurationDto } from '../models/SchoolFeesConfigurationDto';
import type { UpdateFeesConfigRequest } from '../models/UpdateFeesConfigRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SchoolFeesConfigurationRestControllerService {
  /**
   * getFeesConfig
   * @returns SchoolFeesConfigurationDto OK
   * @throws ApiError
   */
  public static getFeesConfigUsingGet({
    xTenantId,
  }: {
    /**
     * X-TENANT-ID
     */
    xTenantId: string;
  }): CancelablePromise<SchoolFeesConfigurationDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/fees-configurations',
      headers: {
        'X-TENANT-ID': xTenantId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateFeesConfig
   * @returns SchoolFeesConfigurationDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateFeesConfigUsingPut({
    request,
  }: {
    /**
     * request
     */
    request: UpdateFeesConfigRequest;
  }): CancelablePromise<SchoolFeesConfigurationDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/fees-configurations',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * createDefaultFeesConfig
   * @returns SchoolFeesConfigurationDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createDefaultFeesConfigUsingPost(): CancelablePromise<
    SchoolFeesConfigurationDto | any
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/fees-configurations/create-default',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
