/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScoreEntryDtoReq } from '../models/ScoreEntryDtoReq';
import type { ScoreEntryDtoRes } from '../models/ScoreEntryDtoRes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScoreEntryRestControllerService {
  /**
   * saveScores
   * @returns any OK
   * @throws ApiError
   */
  public static saveScoresUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: ScoreEntryDtoReq;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/scores',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * getScoreEntryData
   * @returns ScoreEntryDtoRes OK
   * @throws ApiError
   */
  public static getScoreEntryDataUsingGet({
    armId,
    classLevelId,
    staffId,
    termId,
  }: {
    armId: string;
    classLevelId: string;
    staffId: string;
    termId: string;
  }): CancelablePromise<ScoreEntryDtoRes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/scores/score-entry-data',
      query: {
        armId: armId,
        classLevelId: classLevelId,
        staffId: staffId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * uploadStudentScores
   * @returns any OK
   * @throws ApiError
   */
  public static uploadStudentScoresUsingPost({
    xTenantId,
    file,
  }: {
    /**
     * X-TENANT-ID
     */
    xTenantId: string;
    file?: Blob;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/scores/uploads/template',
      headers: {
        'X-TENANT-ID': xTenantId,
      },
      formData: {
        file: file,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
