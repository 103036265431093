/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentSpecialBillDto } from '../models/StudentSpecialBillDto';
import type { StudentSpecialBillRequest } from '../models/StudentSpecialBillRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudentSpecialBillRestControllerService {
  /**
   * getAll
   * @returns StudentSpecialBillDto OK
   * @throws ApiError
   */
  public static getAllUsingGet4(): CancelablePromise<Array<StudentSpecialBillDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-special-bill',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * saveStudentBill
   * @returns StudentSpecialBillDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static saveStudentBillUsingPost({
    studentSpecialBillRequests,
  }: {
    /**
     * studentSpecialBillRequests
     */
    studentSpecialBillRequests: Array<StudentSpecialBillRequest>;
  }): CancelablePromise<Array<StudentSpecialBillDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-special-bill',
      body: studentSpecialBillRequests,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteById
   * @returns any OK
   * @throws ApiError
   */
  public static deleteByIdUsingDelete1({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/student-special-bill/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
