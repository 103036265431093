import { Notify } from '@flexisaf/flexibull2';
import { SchoolFeesDiscountRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  deleteInvoiceDiscountFail,
  deleteInvoiceDiscountSuccess,
  fetchInvoiceDiscountStart,
  fetchStudentIncoiceSummaryStart,
  fetchStudentInvoicesStart,
} from '../actions';
import invoiceActionTypes from '../types';

export function* deleteDiscount({ payload }) {
  const payload1 = {
    payableItemId: payload['payable-item-id'],
    studentId: payload['student-id'],
  };
  if (payload?.month) {
    payload1['month'] = payload.month;
    payload1['year'] = payload.year;
  } else {
    payload1['termId'] = payload['term-id'];
  }

  try {
    const response = yield apiWrapper(() =>
      SchoolFeesDiscountRestControllerService.deleteSchoolFeesDiscountUsingDelete(payload1),
    );
    Notify('Invoice discount deleted successfully.', { position: 'top-right' });

    let payload2 = {
      student_id: payload['student-id'],
    };

    if (payload?.month) {
      payload2['year'] = payload.year;
      payload2['month'] = payload.month;
    } else {
      payload2['session-id'] = payload['session_id'];
      payload2['term-id'] = payload['term-id'];
    }

    yield put(deleteInvoiceDiscountSuccess(response));
    yield put(fetchInvoiceDiscountStart(payload2));
    yield put(fetchStudentIncoiceSummaryStart(payload['student-id']));
    yield put(fetchStudentInvoicesStart(payload));
  } catch (error) {
    yield put(deleteInvoiceDiscountFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteInvoiceDiscount() {
  yield takeLatest(invoiceActionTypes.DELETE_INVOICE_DISCOUNT_START, deleteDiscount);
}
