import { all, call } from 'redux-saga/effects';
import { onfetchCognitiveSkillsStart } from './fetchCognitiveSkills';
import { onCreateSkillGroupStart } from './createSkillGroup';
import { onUpdateSkillGroupStart } from './updateSkillGroup';
import { onDeleteSkillGroupStart } from './deleteSkillGroup';
import { onUpdateSkillStart } from './updateSkill';
import { onCreateSkillStart } from './createSkill';
import { onDeleteSkillStart } from './deleteSkill';
import { onCreateSkillRatingStart } from './createSkillRating';
import { onEditSkillRatingStart } from './editSkillRating';
import { onDeleteSkillRatingStart } from './deleteSkillRating';
import { onfetchSkillGroupStart } from './fetchSkillsGroup';
import { onfetchSkillRatingsStart } from './fetchSkillsRating';
import { onfetchStudentCognitiveAssessmentStart } from './fetchStudentCognitiveAssessment';
import { onAssignSkillRatingStart } from './assignSkillRating';

export function* CognitiveSkilsSagas() {
  yield all([
    call(onfetchCognitiveSkillsStart),
    call(onCreateSkillGroupStart),
    call(onUpdateSkillGroupStart),
    call(onCreateSkillStart),
    call(onUpdateSkillStart),
    call(onDeleteSkillStart),
    call(onDeleteSkillGroupStart),
    call(onCreateSkillRatingStart),
    call(onDeleteSkillRatingStart),
    call(onEditSkillRatingStart),
    call(onfetchSkillGroupStart),
    call(onfetchSkillRatingsStart),
    call(onfetchStudentCognitiveAssessmentStart),
    call(onAssignSkillRatingStart),
  ]);
}
