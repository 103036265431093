const internalRolesActionTypes = {
  FETCH_LIST_OF_INTERNAL_ROLES_START: 'FETCH_LIST_OF_INTERNAL_ROLES_START',
  FETCH_LIST_OF_INTERNAL_ROLES_SUCCESS: 'FETCH_LIST_OF_INTERNAL_ROLES_SUCCESS',
  FETCH_LIST_OF_INTERNAL_ROLES_FAILURE: 'FETCH_LIST_OF_INTERNAL_ROLES_FAILURE',

  FETCH_USER_ROLES_START: 'FETCH_USER_ROLES_START',
  FETCH_USER_ROLES_SUCCESS: 'FETCH_USER_ROLES_SUCCESS',
  FETCH_USER_ROLES_FAILURE: 'FETCH_USER_ROLES_FAILURE',

  ASSING_ROLES_START: 'ASSING_ROLES_START',
  ASSING_ROLES_SUCCESS: 'ASSING_ROLES_SUCCESS',
  ASSING_ROLES_FAILURE: 'ASSING_ROLES_FAILURE',

  HANDLE_ASSIGN_ROLES_MODAL: 'HANDLE_ASSIGN_ROLES_MODAL',
};

export default internalRolesActionTypes;
