import { takeLatest, put } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import studentActionTypes from '../types';
import { fetchStudentsStatsFailure, fetchStudentsStatsSuccess } from '../action';
import { handleError } from '../../../utils/utils';

export function* fetchStudentsStats() {
  try {
    const response = yield httpClient.get('students/statistics');
    yield put(fetchStudentsStatsSuccess(response.data));
  } catch (error) {
    yield put(fetchStudentsStatsFailure(error?.message));
    handleError(error);
  }
}

export function* onFetchStudentsStatsStart() {
  yield takeLatest(studentActionTypes.FETCH_STUDENT_STATISTICS_START, fetchStudentsStats);
}
