import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchAllTermsSuccess, fetchAllTermsFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchAllTerms() {
  try {
    const response = yield httpClient.get('/terms');
    yield put(fetchAllTermsSuccess(response.data));
  } catch (error) {
    yield put(fetchAllTermsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchAllTermsStart() {
  yield takeLatest(invoiceActionTypes.FETCH_ALL_TERMS_START, fetchAllTerms);
}
