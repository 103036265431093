import { put, takeLatest } from 'redux-saga/effects';
import parentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { fetchChildAttendanceSummarySuccess, fetcChildAttendanceSummarytFail } from '../actions';
import httpClient from 'utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchChildAttendanceSummary({ payload }) {
  try {
    const response = yield httpClient.get(
      `/attendance/student/${payload.studentId}?termId=${payload.termId}`,
    );
    yield put(fetchChildAttendanceSummarySuccess(response.data));
  } catch (error) {
    yield put(fetcChildAttendanceSummarytFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchingChildAttendanceSummaryStart() {
  yield takeLatest(
    parentActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_START,
    fetchChildAttendanceSummary,
  );
}
