import parentActionTypes from './types';

export const fetchParentsStart = (data) => ({
  type: parentActionTypes.FETCH_PARENTS_START,
  payload: data,
});

export const fetchParentsSuccess = (data) => ({
  type: parentActionTypes.FETCH_PARENTS_SUCCESS,
  payload: data,
});

export const fetchParentsFail = (error) => ({
  type: parentActionTypes.FETCH_PARENTS_FAIL,
  payload: error,
});

export const fetchSingleParentStart = (data) => ({
  type: parentActionTypes.FETCH_SINGLE_PARENT_START,
  payload: data,
});

export const fetchSingleParentSuccess = (data) => ({
  type: parentActionTypes.FETCH_SINGLE_PARENT_SUCCESS,
  payload: data,
});

export const fetchSingleParentFail = (error) => ({
  type: parentActionTypes.FETCH_SINGLE_PARENT_FAIL,
  payload: error,
});

export const handleParentProfileAction = (parentInfo) => ({
  type: parentActionTypes.HANDLE_PARENT_PROFILE,
  payload: parentInfo,
});

export const createParentStart = (data) => ({
  type: parentActionTypes.CREATE_PARENT_START,
  payload: data,
});

export const createParentSuccess = (data) => ({
  type: parentActionTypes.CREATE_PARENT_SUCCESS,
  payload: data,
});

export const createParentFailure = (error) => ({
  type: parentActionTypes.CREATE_PARENT_FAILURE,
  payload: error,
});

export const saveAndCreateAnotherParentStart = (data, callback) => ({
  type: parentActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_START,
  payload: { data, callback },
});

export const saveAndCreateAnotherParentSuccess = (data) => ({
  type: parentActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_SUCCESS,
  payload: data,
});

export const saveAndCreateAnotherParentFailure = (error) => ({
  type: parentActionTypes.SAVE_AND_CREATE_ANOTHER_PARENT_FAILURE,
  payload: error,
});

export const editParentStart = (data) => ({
  type: parentActionTypes.EDIT_PARENT_START,
  payload: data,
});

export const editParentSuccess = (data) => ({
  type: parentActionTypes.EDIT_PARENT_SUCCESS,
  payload: data,
});

export const editParentFailure = (error) => ({
  type: parentActionTypes.EDIT_PARENT_FAILURE,
  payload: error,
});

export const fetchListOfStudentStart = (data) => ({
  type: parentActionTypes.FETCH_LIST_OF_STUDENT_START,
  payload: data,
});

export const fetchListOfStudentSuccess = (data) => ({
  type: parentActionTypes.FETCH_LIST_OF_STUDENT_SUCCESS,
  payload: data,
});

export const fetchListOfStudentFailure = (error) => ({
  type: parentActionTypes.FETCH_LIST_OF_STUDENT_FAILURE,
  payload: error,
});

export const filterStaffsStart = (data) => ({
  type: parentActionTypes.FILTER_STAFFS_START,
  payload: data,
});

export const filterStaffsSuccess = (data) => ({
  type: parentActionTypes.FILTER_STAFFS_SUCCESS,
  payload: data,
});

export const filterStaffsFailure = (error) => ({
  type: parentActionTypes.FILTER_STAFFS_FAILURE,
  payload: error,
});

export const downloadXLSXTempleteStart = () => ({
  type: parentActionTypes.DOWNLOAD_PARENT_XLSX_TEMPLATE_START,
  payload: {},
});

export const downloadXLSXTempleteSuccess = (data) => ({
  type: parentActionTypes.DOWNLOAD_PARENT_XLSX_TEMPLATE_SUCCESS,
  payload: data,
});

export const downloadXLSXTempleteFailure = (error) => ({
  type: parentActionTypes.DOWNLOAD_PARENT_XLSX_TEMPLATE_FAILURE,
  payload: error,
});

export const parentStatusStart = (data) => ({
  type: parentActionTypes.PARENT_STATUS_START,
  payload: data,
});

export const parentStatusSuccess = (data) => ({
  type: parentActionTypes.PARENT_STATUS_SUCCESS,
  payload: data,
});

export const parentStatusFailure = (error, data) => ({
  type: parentActionTypes.PARENT_STATUS_FAILURE,
  payload: { error, data },
});

export const resetLgaAction = () => ({
  type: parentActionTypes.RESET_LGA,
  payload: [],
});

export const bulkParentUploadStart = (filename, data) => ({
  type: parentActionTypes.BULK_PARENT_UPLOAD_START,
  payload: { filename, data },
});

export const bulkParentUploadSuccess = (data) => ({
  type: parentActionTypes.BULK_PARENT_UPLOAD_SUCCESS,
  payload: data,
});

export const bulkParentUploadFailure = (error) => ({
  type: parentActionTypes.BULK_PARENT_UPLOAD_FAILURE,
  payload: error,
});

export const saveParentBioAction = (data) => ({
  type: parentActionTypes.SAVE_PARENT_BIO,
  payload: data,
});

export const saveLinkedChildAction = (data) => ({
  type: parentActionTypes.SAVE_LINKED_CHILD_INFO,
  payload: data,
});

export const openWizardAction = (wizard) => ({
  type: parentActionTypes.OPEN_WIZARD,
  payload: wizard,
});

export const resetEverythingAction = () => ({
  type: parentActionTypes.RESET_EVERYTHING,
  payload: {},
});

export const handleStaffProfileAction = (staffProfileData) => ({
  type: parentActionTypes.HANDLE_STAFF_PROFILE_DATA,
  payload: staffProfileData,
});

export const handleEditAction = (editUser, parentBio, linkedChilds) => ({
  type: parentActionTypes.HANDLE_EDIT,
  payload: { editUser, parentBio, linkedChilds },
});

export const handleBulkModalAction = (bulkModal) => ({
  type: parentActionTypes.HANDLE_BULK_MODAL,
  payload: bulkModal,
});

export const closeFeedBackAction = (data) => ({
  type: parentActionTypes.CLOSE_FEEDBACK_MODAL,
  payload: data,
});

export const handleFeedBackAction = (data) => ({
  type: parentActionTypes.HANDLE_FEEDBACK_MODAL,
  payload: data,
});

export const fetchParentStatsStart = (data) => ({
  type: parentActionTypes.FETCH_PARENT_STATISTICS_START,
  payload: data,
});

export const fetchParentStatsSuccess = (data) => ({
  type: parentActionTypes.FETCH_PARENT_STATISTICS_SUCCESS,
  payload: data,
});

export const fetchParentStatsFailure = (error) => ({
  type: parentActionTypes.FETCH_PARENT_STATISTICS_FAILURE,
  payload: error,
});

export const deleteParentStart = (id, isProfile, callback) => ({
  type: parentActionTypes.DELETE_PARENT_START,
  payload: { id, isProfile, callback },
});

export const deleteParentSuccess = (id) => ({
  type: parentActionTypes.DELETE_PARENT_SUCCESS,
  payload: id,
});

export const deleteParentFailure = (error) => ({
  type: parentActionTypes.DELETE_PARENT_FAILURE,
  payload: error,
});

export const handleDeleteModalAction = (deleteModal) => ({
  type: parentActionTypes.HANDLE_DELETE_MODAL,
  payload: deleteModal,
});

export const updateParentPictureStart = (data, id) => ({
  type: parentActionTypes.UPDATE_PARENT_PICTURE_START,
  payload: { data, id },
});

export const updateParentPictureSuccess = (link, id) => ({
  type: parentActionTypes.UPDATE_PARENT_PICTURE_SUCCESS,
  payload: { link, id },
});

export const updateParentPictureFailure = (error) => ({
  type: parentActionTypes.UPDATE_PARENT_PICTURE_FAILURE,
  payload: error,
});

export const linkChildStart = (data, listOfSelectedStudents, callback) => ({
  type: parentActionTypes.LINK_CHILD_START,
  payload: { data, listOfSelectedStudents, callback },
});

export const linkChildSuccess = (data, listOfSelectedStudents) => ({
  type: parentActionTypes.LINK_CHILD_SUCCESS,
  payload: { data, listOfSelectedStudents },
});

export const linkChildFailure = (error) => ({
  type: parentActionTypes.LINK_CHILD_FAILURE,
  payload: error,
});

export const unlinkChildStart = (data, callback) => ({
  type: parentActionTypes.UNLINK_CHILD_START,
  payload: { data, callback },
});

export const unlinkChildSuccess = (data) => ({
  type: parentActionTypes.UNLINK_CHILD_SUCCESS,
  payload: data,
});

export const unlinkChildFailure = (error) => ({
  type: parentActionTypes.UNLINK_CHILD_FAILURE,
  payload: error,
});

export const handleEditPictureOnProfileAction = (status) => ({
  type: parentActionTypes.HANDLE_EDIT_PICTURE_ON_PROFILE,
  payload: status,
});

export const handleLinkModalAction = (status) => ({
  type: parentActionTypes.HANDLE_LINK_MODAL,
  payload: status,
});

export const handleUnlinkModalAction = (status) => ({
  type: parentActionTypes.HANDLE_UNLINK_MODAL,
  payload: status,
});

export const handleCurrentStudentAction = (student) => ({
  type: parentActionTypes.HANDLE_CURRENT_STUDENT,
  payload: student,
});

export const stopParentBulkLoadingAction = () => ({
  type: parentActionTypes.STOP_UPLOAD_PARENT_BULK_LOADING,
  payload: {},
});

export const fetchParentChildrenStart = (data) => ({
  type: parentActionTypes.FETCH_PARENT_CHILDREN_START,
  payload: data,
});

export const fetchParentChildrenSuccess = (data) => ({
  type: parentActionTypes.FETCH_PARENT_CHILDREN_SUCCESS,
  payload: data,
});

export const fetchParentChildrenFailure = (error) => ({
  type: parentActionTypes.FETCH_PARENT_CHILDREN_FAILURE,
  payload: error,
});
