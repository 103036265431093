// fullstory initialization
function fsMain() {
  // @ts-ignore
  window._fs_debug = false;
  // @ts-ignore
  window._fs_host = 'fullstory.com';
  // @ts-ignore
  window._fs_script = 'edge.fullstory.com/s/fs.js';
  // @ts-ignore
  window._fs_org = '18J0ED';
  // @ts-ignore
  window._fs_namespace = 'FS';
  // @ts-ignore
  const _fs_script = window._fs_script;
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    // @ts-ignore
    g = m[e] = function (a, b, s) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    // @ts-ignore
    g.q = [];
    // @ts-ignore
    o = n.createElement(t);
    // @ts-ignore
    o.async = 1;
    // @ts-ignore
    o.crossOrigin = 'anonymous';
    // @ts-ignore
    o.src = 'https://' + _fs_script;
    // @ts-ignore
    y = n.getElementsByTagName(t)[0];
    // @ts-ignore
    y.parentNode.insertBefore(o, y);
    // @ts-ignore
    g.identify = function (i, v, s) {
      // @ts-ignore
      g(l, { uid: i }, s);
      // @ts-ignore
      if (v) g(l, v, s);
    };
    // @ts-ignore
    g.setUserVars = function (v, s) {
      // @ts-ignore
      g(l, v, s);
    };
    // @ts-ignore
    g.event = function (i, v, s) {
      // @ts-ignore
      g('event', { n: i, p: v }, s);
    };
    // @ts-ignore
    g.anonymize = function () {
      // @ts-ignore
      g.identify(!!0);
    };
    // @ts-ignore
    g.shutdown = function () {
      // @ts-ignore
      g('rec', !1);
    };
    // @ts-ignore
    g.restart = function () {
      // @ts-ignore
      g('rec', !0);
    };
    // @ts-ignore
    g.log = function (a, b) {
      // @ts-ignore
      g('log', [a, b]);
    };
    // @ts-ignore
    g.consent = function (a) {
      // @ts-ignore
      g('consent', !arguments.length || a);
    };
    // @ts-ignore
    g.identifyAccount = function (i, v) {
      // @ts-ignore
      o = 'account';
      // @ts-ignore
      v = v || {};
      v.acctId = i;
      // @ts-ignore
      g(o, v);
    };
    // @ts-ignore
    g.clearUserCookie = function () {};
    // @ts-ignore
    g.setVars = function (n, p) {
      // @ts-ignore
      g('setVars', [n, p]);
    };
    // @ts-ignore
    g._w = {};
    // @ts-ignore
    y = 'XMLHttpRequest';
    // @ts-ignore
    g._w[y] = m[y];
    // @ts-ignore
    y = 'fetch';
    // @ts-ignore
    g._w[y] = m[y];
    // @ts-ignore
    if (m[y])
      // @ts-ignore
      m[y] = function () {
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        return g._w[y].apply(this, arguments);
      };
    // @ts-ignore
    g._v = '1.3.0';
    // @ts-ignore
  })(window, document, window._fs_namespace, 'script', 'user');
}

export const initializeFullstory = () => {
  window.process = { ...window.process };
  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('load', (event) => {
      setTimeout(fsMain, 5000);
    });
  }
};
