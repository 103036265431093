/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentProjectionAssessmentStartWeek } from '../models/StudentProjectionAssessmentStartWeek';
import type { StudentProjectionAssessmentStartWeekRequest } from '../models/StudentProjectionAssessmentStartWeekRequest';
import type { WeeklyProjectionDto } from '../models/WeeklyProjectionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudentProjectionAssessmentStartWeekRestControllerService {
  /**
   * getStudentStartWeek
   * @returns WeeklyProjectionDto OK
   * @throws ApiError
   */
  public static getStudentStartWeekUsingGet({
    classLevelId,
    studentId,
    subjectId,
    termId,
  }: {
    classLevelId: string;
    studentId: string;
    subjectId: string;
    termId: string;
  }): CancelablePromise<WeeklyProjectionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/student-projection-assessment-start-weeks',
      query: {
        classLevelId: classLevelId,
        studentId: studentId,
        subjectId: subjectId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * assignStudentStartWeek
   * @returns StudentProjectionAssessmentStartWeek OK
   * @returns any Created
   * @throws ApiError
   */
  public static assignStudentStartWeekUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: StudentProjectionAssessmentStartWeekRequest;
  }): CancelablePromise<StudentProjectionAssessmentStartWeek | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/student-projection-assessment-start-weeks',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
