import { all, call } from 'redux-saga/effects';
import { onAddGradeFormatsStart } from './addGradingFormat';
import { onDeleteGradeFormatsStart } from './deleteGradingFormat';
import { onUpdateGradeFormatsStart } from './editGradingFormat';
import { onFetchGradeFormatsStart } from './fetchGradeFormats';

export function* GradesSagas() {
  yield all([
    call(onFetchGradeFormatsStart),
    call(onAddGradeFormatsStart),
    call(onUpdateGradeFormatsStart),
    call(onDeleteGradeFormatsStart),
  ]);
}
