import { takeLatest, put } from 'redux-saga/effects';
import { fetchActiveArmsSuccess, fetchActiveArmsFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';

export function* fetchActiveArms() {
  try {
    const response = yield httpClient.get(`/arms/active-arms`);
    yield put(fetchActiveArmsSuccess(response.data));
  } catch (error) {
    yield put(fetchActiveArmsFail(error?.message));
  }
}

export function* onFetchActiveArmsStart() {
  yield takeLatest(configActionTypes.FETCH_ACTIVE_ARMS_START, fetchActiveArms);
}
