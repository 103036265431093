/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NameOrderDto } from '../models/NameOrderDto';
import type { NameOrderRequest } from '../models/NameOrderRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NameOrderRestControllerService {
  /**
   * getNameOrder
   * @returns NameOrderDto OK
   * @throws ApiError
   */
  public static getNameOrderUsingGet(): CancelablePromise<NameOrderDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/name-order',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateNameOrder
   * @returns NameOrderDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateNameOrderUsingPut({
    request,
  }: {
    /**
     * request
     */
    request: NameOrderRequest;
  }): CancelablePromise<NameOrderDto | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/name-order',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
