import { all, call } from 'redux-saga/effects';
import { onCreateReportTemplateStart } from './createReportTemplate';
import { onDeleteReportTemplateStart } from './deleteReportTemplate';
import { onFetchReportTemplatesStart } from './fetchReportTemplates';

export function* ReportTemplateSagas() {
  yield all([
    call(onFetchReportTemplatesStart),
    call(onDeleteReportTemplateStart),
    call(onCreateReportTemplateStart),
  ]);
}
