import { put, takeLatest } from 'redux-saga/effects';
import subjectsActionTypes from '../types';
import { createSubjectSuccess, createSubjectFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';
import { setDisableNext } from 'redux/users/actions';

export function* createSubject({ payload }) {
  const callback = payload.callback;
  delete payload.callback;

  try {
    const response = yield httpClient.post('subjects', payload);
    yield put(createSubjectSuccess(response.data));
    Notify('Subject created successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createSubjectFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateSubjectStart() {
  yield takeLatest(subjectsActionTypes.CREATE_SUBJECT_START, createSubject);
}
