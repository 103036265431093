import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { fetchInvoiceDiscountFail, fetchInvoiceDiscountSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchDiscount({ payload }) {
  try {
    const response = yield httpClient.get(`/school-fees-discounts/${payload.student_id}`, {
      params: payload,
    });
    yield put(fetchInvoiceDiscountSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoiceDiscountFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchInvoiceDiscount() {
  yield takeLatest(invoiceActionTypes.FETCH_INVOICE_DISCOUNT_START, fetchDiscount);
}
