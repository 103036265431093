import localforage from 'localforage';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { getCurrentTermFail, getCurrentTermSuccess, handleCurrentSubscription } from '../actions';
import configActionTypes from '../types';

const dummy_subscription = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  subscription_end_date: '2023-07-14T14:49:54.546Z',
  subscription_plan: {
    amount: { NGN: 200000 },
    features: ['ATTENDANCE_REPORT_AND_ANALYSIS, LESSON_PLAN'],
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    max_student_count: 200,
    name: 'Starter Plan',
  },
  subscription_start_date: '2023-06-22T14:49:54.546Z',
  term: {
    current_term: true,
    end_date: '2023-06-22T14:49:54.546Z',
    initialized: true,
    next_term_start_date: '2023-06-22T14:49:54.546Z',
    school_term_definition: {
      definition_order: 0,
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'string',
      term_definition: {
        definition_order: 0,
        description: 'string',
        id: 'string',
      },
    },
    session: {
      id: 'string',
      name: 'string',
    },
    start_date: '2023-06-22T14:49:54.546Z',
    term_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  },
  trial: false,
  trial_expiry_date: '2023-07-23T14:49:54.546Z',
};

export function* getcurrentTerm() {
  try {
    const response = yield httpClient.get('terms/current-term');
    const termId = response?.data?.term_id;
    yield put(getCurrentTermSuccess(response.data));

    if (response?.data?.term_id) {
      try {
        const access_token = yield localforage.getItem('access_token');
        if (access_token) {
          // To ensure the call is made when token is present
          const response_2 = yield httpClient.get(`/subscriptions/term/${termId}`);
          const responseData = response_2?.data;
          yield put(handleCurrentSubscription(responseData));
        }
      } catch (error) {
        yield put(handleCurrentSubscription({}));
      }
    }
  } catch (error) {
    yield put(getCurrentTermFail(error?.message));
  }
}

export function* onGetcurrentTerm() {
  yield takeLatest(configActionTypes.GET_CURRENT_TERM_START, getcurrentTerm);
}
