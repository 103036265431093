import { all, call } from 'redux-saga/effects';
import { userSagas } from './users/sagas';
import { StaffSagas } from './staff/sagas';
import StudentSaga from './student/saga';
import ParentSaga from './parent/saga';
import GlobalSaga from './global-store/saga';
import ConfigSaga from './configuration/saga';
import { InvoiceSagas } from './invoice/sagas';
import { InvoiceSummariesSagas } from './student-invoice-summary/sagas';
import { CognitiveSkilsSagas } from './cognitve-skills/sagas';
import { AssessmentSagas } from './assessment/sagas';
import { ClassInformationSagas } from './class-information/sagas';
import { SubjectsSagas } from './subjects/sagas';
import BankSaga from './bank-setting/saga';
import { GradesSagas } from './grades/sagas';
import EarlyYearsGradingSaga from './early-years/saga';
import { InternalRolesSagas } from './internal-roles/sagas';
import broadSheetSagas from './broadsheet/saga';
import { plansSagas } from './plans/sagas';
import { ReportTemplateSagas } from './report-template/sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(StaffSagas),
    call(StudentSaga),
    call(ParentSaga),
    call(GlobalSaga),
    call(ConfigSaga),
    call(InvoiceSagas),
    call(InvoiceSummariesSagas),
    call(BankSaga),
    call(SubjectsSagas),
    call(GradesSagas),
    call(ClassInformationSagas),
    call(EarlyYearsGradingSaga),
    call(AssessmentSagas),
    call(CognitiveSkilsSagas),
    call(InternalRolesSagas),
    call(broadSheetSagas),
    call(plansSagas),
    call(ReportTemplateSagas),
  ]);
}
