import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  editTermDefinitionFail,
  editTermDefinitionSuccess,
  fetchAllExistingTermsStart,
  fetchAllTermDefStart,
} from '../actions';
import configActionTypes from '../types';

export function* editTermDefinition({ payload }) {
  try {
    const response = yield httpClient.put(`/term-definitions`, payload);
    yield put(editTermDefinitionSuccess(response.data));
    Notify('Term name edited succesfully', { position: 'top-right' });
    yield put(fetchAllTermDefStart());
    yield put(fetchAllExistingTermsStart());
  } catch (error) {
    yield put(editTermDefinitionFail(error.fail));
    const err = handleError(error);
  }
}

export function* onEditTermDefinitionStart() {
  yield takeLatest(configActionTypes.EDIT_TERM_DEF_START, editTermDefinition);
}
