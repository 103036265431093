import { Notify } from '@flexisaf/flexibull2';
import { AuthenticationRestControllerService } from 'generated';
import localforage from 'localforage';
import { put, takeLatest } from 'redux-saga/effects';
import { setSiteConfig } from 'redux/global-store/actions';
import { hotjarAttribute } from 'utils/Hotjar';
import { school_id } from 'utils/constants';
import { handleError } from 'utils/utils';
import httpClient, { apiWrapper } from '../../../utils/http-client';
import {
  setAccessToken,
  setLoginAttempts,
  setLoginResponses,
  setRefreshToken,
  updateAppUserState,
  userLoginFailure,
} from '../actions';
import userActionTypes from '../types';

export function* userLogIn({ payload }) {
  const config = payload.config;
  const callBack = payload.callBack;
  // const handleResponse = payload.handleResponse;
  const loginAttempts = payload.loginAttempts;
  const { username } = payload;
  delete payload.config;
  delete payload.callBack;
  // delete payload.handleResponse;
  delete payload.loginAttempts;

  try {
    const response = yield httpClient.post('/auth/login', payload);
    const ref = response.data;
    const ums = ref.ums_login_response;
    const roles = ums.user.roles;

    yield put(setAccessToken(ums.access_token));
    yield put(setRefreshToken(ums.refresh_token));

    yield localforage.setItem('access_token', ums.access_token);
    yield localforage.setItem('refresh_token', ums.refresh_token);
    httpClient.defaults.headers.common.Authorization = `Bearer ${ums.access_token}`;

    yield put(setSiteConfig({ ...config, accessToken: ums.access_token }));

    callBack(roles);
    yield put(setLoginResponses(response));
    // handleResponse(response);

    // HotJar User Attribute taging
    hotjarAttribute({
      userId: ums.user.username,
      attributeParams: {
        login_user: `${ums.user.first_name} ${ums.user.last_name}`,
        login_organisation: ums.user.organisation,
        login_date: new Date().toISOString(),
      },
    });

    // GTM data taging.
    window.dataLayer.push({
      event: 'login',
      username: ums.user.username,
      organisation: ums.user.organisation,
      login_date: new Date().toISOString(),
      user_role: ums.user.roles.map((item) => item.role.name),
    });
  } catch (error) {
    yield put(userLoginFailure(error?.message));
    const err = handleError(error, { code: 403, message: 'Account not verified' });

    if (err?.statusCode === 403) {
      try {
        yield apiWrapper(() =>
          AuthenticationRestControllerService.resendVerificationUsingPost({
            request: { username, verification_type: 'PINCODE' },
            xTenantId: school_id,
          }),
        );
      } catch (err) {}
      yield put(
        updateAppUserState({
          accountVerificationModalOpen: true,
        }),
      );
    } else if (err?.statusCode === 401) {
      Notify(err?.message, { status: 'error' });
    }
    yield put(setLoginAttempts(loginAttempts + 1));
  }
}

export function* onSigninStart() {
  yield takeLatest(userActionTypes.USER_LOGIN_START, userLogIn);
}
