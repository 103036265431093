import { takeLatest, put } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { editSubjectTeachersFailure, editSubjectTeachersSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import subjectsActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* editSubjectTeachers({ payload }) {
  try {
    const response = yield httpClient.put(`/subjects/teacher`, [payload]);
    yield put(editSubjectTeachersSuccess(response.data, payload.subject_id));
    Notify('Subject teacher(s) updated successfully', {
      position: 'top-right',
    });
  } catch (error) {
    yield put(editSubjectTeachersFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditSubjectTeachersStart() {
  yield takeLatest(subjectsActionTypes.EDIT_SUBJECT_TEACHERS_START, editSubjectTeachers);
}
