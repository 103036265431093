import { CustomDateRangePicker } from 'components/datepicker';
import { useEffect, useState } from 'react';

const ScoreEntryAssessment = ({ item, updateBreakdown }) => {
  const [dateDataRange, setDateDataRange] = useState('');

  useEffect(() => {
    if (dateDataRange) {
      const data = {
        ...item,
        start_date: dateDataRange[0],
        end_date: dateDataRange[1],
      };
      updateBreakdown(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateDataRange]);

  return (
    <th style={{ textAlign: 'left' }}>
      <CustomDateRangePicker
        label="Start date "
        label2="End date"
        hideBtn
        onChange={(val) => setDateDataRange(val)}
        value={dateDataRange}
        spaceTop="10px"
      />
    </th>
  );
};

export default ScoreEntryAssessment;
