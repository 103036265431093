import { put, takeLatest } from 'redux-saga/effects';
import studentActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { unlinkParentSuccess, unlinkParentFail, fetchAllStudentStart } from '../action';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

const data = {
  offset: 0,
  limit: 10,
};

export function* unlinkParent({ payload }) {
  try {
    const response = yield httpClient.post('/parents/unlink-student', payload);
    yield put(unlinkParentSuccess(payload));
    Notify('Parent unlinked successfully', { position: 'top-right' });
    yield put(fetchAllStudentStart(data));
  } catch (error) {
    const err = handleError(error);
    yield put(unlinkParentFail(err?.message));
  }
}

export function* onUnlinkParentStart() {
  yield takeLatest(studentActionTypes.UNLINK_PARENT_START, unlinkParent);
}
