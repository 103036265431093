import serviceWorkerActionTypes from './types';

export const initializeServiceWorker = (payload) => ({
  type: serviceWorkerActionTypes.INITIALIZE_SERVICE_WORKER,
  payload,
});

export const updateServiceWorker = (payload) => ({
  type: serviceWorkerActionTypes.UPDATE_SERVICE_WORKER,
  payload,
});
