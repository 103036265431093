/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupedSubCollaboratorStudentSkillAssessmentResponse } from '../models/GroupedSubCollaboratorStudentSkillAssessmentResponse';
import type { SubCollaboratorStudentSkillAssessmentRequest } from '../models/SubCollaboratorStudentSkillAssessmentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubCollaboratorStudentSkillAssessmentRestControllerService {
  /**
   * getStudentSkillAssessments
   * @returns GroupedSubCollaboratorStudentSkillAssessmentResponse OK
   * @throws ApiError
   */
  public static getStudentSkillAssessmentsUsingGet1({
    skillGroupId,
    studentId,
    subCollaboratorId,
    termId,
  }: {
    skillGroupId: string;
    studentId: string;
    subCollaboratorId: string;
    termId: string;
  }): CancelablePromise<GroupedSubCollaboratorStudentSkillAssessmentResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sub-collaborator-assessments',
      query: {
        skillGroupId: skillGroupId,
        studentId: studentId,
        subCollaboratorId: subCollaboratorId,
        termId: termId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * updateStudentSkillAssessments
   * @returns any OK
   * @throws ApiError
   */
  public static updateStudentSkillAssessmentsUsingPost({
    request,
  }: {
    /**
     * request
     */
    request: SubCollaboratorStudentSkillAssessmentRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sub-collaborator-assessments',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
