import { hotjar } from 'react-hotjar';

export const initializeHotjar = () => {
  window.process = { ...window.process };
  if (process) {
    const NODE_ENV = process.env.NODE_ENV;
    const REACT_APP_ENV = process.env.REACT_APP_ENV;
    if (NODE_ENV === 'production') {
      const PROD_ENV = { hjid: 3136463, hjsv: 6 };
      hotjar.initialize(PROD_ENV.hjid, PROD_ENV.hjsv);
    }
    if (REACT_APP_ENV === 'staging') {
      const STAGE_ENV = { hjid: 3316590, hjsv: 6 };
      hotjar.initialize(STAGE_ENV.hjid, STAGE_ENV.hjsv);
    }
  }
};

export const hotjarAttribute = ({
  userId,
  attributeParams,
}: {
  userId: string;
  attributeParams: any;
}) => {
  try {
    if (hotjar.initialized()) {
      hotjar.identify(userId, attributeParams);
    }
  } catch (err) {
    console.log(err);
  }
};
