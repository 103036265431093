import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { deleteInvoiceFailure, deleteInvoiceSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* deleteInvoice({ payload }) {
  const { class_level_id, term_id, month, year } = payload;
  try {
    const response = yield httpClient.delete(`/invoice-templates`, {
      params: { class_level_id, term_id, month, year },
    });
    yield put(deleteInvoiceSuccess(payload));
    Notify('Invoice Deleted successfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteInvoiceFailure(error?.message));
    const obj = {
      code: 500,
      message: 'Sorry you cannot delete an applied invoice template',
    };
    const err = handleError(error, obj);
  }
}

export function* onDeleteInvoiceStart() {
  yield takeLatest(invoiceActionTypes.DELETE_INVOICE_START, deleteInvoice);
}
