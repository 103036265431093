import { all, call } from 'redux-saga/effects';
import { onFetchAllStudentStart } from './fetchStudentSaga';
import { onCreateStudentStart } from './createStudentSaga';
import { onEditStudentStart } from './editStudentSaga';
import { onDownloadXLSXTempleteStart } from './downloadTemplate';
import { onStudentStatusStart } from './studentStatus';
import { onBulkStudentUploadStart } from './bulkStudentUpload';
import { onUpdateStudentPhotoStart } from './uploadProfilePicture';
import { onFetchStudentsStatsStart } from './studentStatistics';
import { onSaveAndCreateAnotherStudent } from './saveAndCreate';
import { onfilterStudentsStart } from './filterStudent';
import { onDeleteStudentStart } from './deleteStudent';
import { onlinkParentStart } from './linkParent';
import { onUploadStudentDocument } from './uploadStudentDoc';
import { onFetchStudentDocument } from './fetchStudentDocuments';
import { onDeleteStudentDocumentStart } from './deleteStudentDoc';
import { onDownloadStudentDocStart } from './downloadStudentDoc';
import { onFetchSingleStudent } from './fetchSingleStudent';
import { onFetchStudentsWithoutClassStart } from './fetchStudentsWithoutClassSaga';
import { onUnlinkParentStart } from './unlinkParent';
import { onGetTotalStudentsStart } from './getTotalStudents';

export default function* StudentSaga() {
  yield all([
    call(onFetchAllStudentStart),
    call(onCreateStudentStart),
    call(onEditStudentStart),
    call(onDownloadXLSXTempleteStart),
    call(onStudentStatusStart),
    call(onBulkStudentUploadStart),
    call(onUpdateStudentPhotoStart),
    call(onFetchStudentsStatsStart),
    call(onSaveAndCreateAnotherStudent),
    call(onfilterStudentsStart),
    call(onDeleteStudentStart),
    call(onlinkParentStart),
    call(onUploadStudentDocument),
    call(onFetchStudentDocument),
    call(onDownloadStudentDocStart),
    call(onDeleteStudentDocumentStart),
    call(onFetchSingleStudent),
    call(onFetchStudentsWithoutClassStart),
    call(onUnlinkParentStart),
    call(onGetTotalStudentsStart),
  ]);
}
