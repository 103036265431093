import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import globalActionTypes from './types';

import { ExamDto, QuestionDto, StudentLiteDto, StudentPaperDto } from 'generated';
import { school_id } from 'utils/constants';
import { FIX_ANY } from 'utils/types';
import loginImg from '../../assets/Image.svg';
import wlogo from '../../assets/white-logo.svg';
import { Theme } from '../../config/theme';

const config = {
  organizations: school_id,
  product: '00f45cb6-b611-456f-9863-ab7152a4ba77',
  logo: wlogo,
  loginImg,
  Theme,
  privileges: {
    usersView: false,
    usersCreate: false,
    usersEdit: false,
    usersDelete: false,
    rolesView: false,
    rolesCreate: false,
    rolesEdit: false,
    rolesDelete: false,
  },
};

interface IConfig {
  organizations: string;
  product: string;
  logo: FIX_ANY;
  loginImg: FIX_ANY;
  Theme: FIX_ANY;
  privileges: {
    usersView: boolean;
    usersCreate: boolean;
    usersEdit: boolean;
    usersDelete: boolean;
    rolesView: boolean;
    rolesCreate: boolean;
    rolesEdit: boolean;
    rolesDelete: boolean;
  };
}

const INITIAL_STATE = {
  loading: false,
  countries: [],
  states: [],
  lga: [],
  siteConfig: config,
  activePaperAnswers: [],
};

export interface IGlobalState {
  loading?: boolean;
  countries?: FIX_ANY[];
  states?: FIX_ANY[];
  lga?: FIX_ANY[];
  siteConfig?: IConfig;
  activePaper?: StudentPaperDto;
  activePaperAnswers?: QuestionDto[];
  activePaperStartTime?: Date;
  activePaperDuration?: number;
  activePaperEndTime?: Date;
  activeStudent?: StudentLiteDto;
  activeStudentSubmittedPapers?: StudentPaperDto[];
  activeExam?: ExamDto;
  isExamOngoing?: boolean;
  hasSubmittedFinal?: boolean;
  lastViewedQuestionId?: string;
}

interface IParam {
  type: globalActionTypes;
  payload: any;
}

function globalReducer(state: IGlobalState = INITIAL_STATE, { type, payload }: IParam) {
  switch (type) {
    case globalActionTypes.FETCH_COUNTRIES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case globalActionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: payload,
      };
    case globalActionTypes.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case globalActionTypes.FETCH_STATES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case globalActionTypes.SET_SITE_CONFIG:
      const stateConfig = state.siteConfig;
      return {
        ...state,
        siteConfig: {
          ...stateConfig,
          ...payload,
        },
      };

    case globalActionTypes.FETCH_STATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: payload,
      };
    case globalActionTypes.FETCH_STATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case globalActionTypes.FETCH_LGA_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case globalActionTypes.FETCH_LGA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lga: payload,
      };
    case globalActionTypes.FETCH_LGA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case globalActionTypes.RESET_LGA:
      return {
        ...state,
        lga: payload,
      };
    case globalActionTypes.UPDATE_GLOBAL_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'global',
  storage,
  blacklist: ['loading', 'siteConfig'],
};

export default persistReducer(persistConfig, globalReducer);
