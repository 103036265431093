import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import {
  parentAddInvoiceItemSuccess,
  parentAddInvoiceItemFail,
  getCheckoutStart,
} from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* parentAddInvoiceItem({ payload }) {
  const { id, data } = payload;
  const student_id = id;
  const session_id = data.session_id;
  const term_id = data.term_id;
  try {
    const response = yield httpClient.put(`/students-invoice/`, {
      ...data,
      enforce_minimum_quantity: true,
    });
    yield put(parentAddInvoiceItemSuccess(response.data));
    Notify('Item added successfully.', { position: 'top-right' });
    yield put(getCheckoutStart({ student_id, session_id, term_id }));
  } catch (error) {
    yield put(parentAddInvoiceItemFail(error?.message));
    const err = handleError(error);
  }
}

export function* onParentAddInvoiceItemStart() {
  yield takeLatest(invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_START, parentAddInvoiceItem);
}
