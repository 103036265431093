import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { updateSkillSuccess, updateSkillFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';

export function* updateSkill({ payload }) {
  try {
    const response = yield httpClient.put(
      `/cognitive-skill-config/skill-groups/skills/${payload.id}`,
      payload.data,
    );
    yield put(updateSkillSuccess(response.data, payload.data.skill_group_id));

    Notify('Skill Updated successfully', { position: 'top-right' });
  } catch (error) {
    yield put(updateSkillFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onUpdateSkillStart() {
  yield takeLatest(cognitiveSkillsActionTypes.UPDATE_SKILL_START, updateSkill);
}
