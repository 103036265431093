import BugsnagPluginReact, { formatComponentStack } from '@bugsnag/plugin-react';
import Bugsnag from '@bugsnag/js';

export const Bugsnag_Key = '995a83139e172eb7b52b08ed0d61081e';

export const BugsnagClient = Bugsnag.start({
  apiKey: Bugsnag_Key,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'],
});

export function BugsnagNotifyReactError(error, info) {
  const handledState = {
    severity: 'error',
    unhandled: true,
    severityReason: { type: 'unhandledException' },
  };
  const event = BugsnagClient.Event.create(error, true, handledState, 'Error boundary', 1);
  if (info && info.componentStack) {
    info.componentStack = formatComponentStack(info.componentStack);
  }
  event.addMetadata('react', info);
  BugsnagClient._notify(event);
}
