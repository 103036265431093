import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { fetchAssessmentsFailure, fetchAssessmentsSuccess } from '../actions';
import assessmentActionTypes from '../types';

export function* fetchAssessment({ payload }) {
  const callback = payload?.callback;
  delete payload?.callback;
  try {
    const response = yield httpClient.get('/assessment-config', { params: payload });
    yield put(fetchAssessmentsSuccess(response.data));
    callback?.(response.data);
  } catch (error) {
    yield put(fetchAssessmentsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchAssessmentStart() {
  yield takeLatest(assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_START, fetchAssessment);
}
