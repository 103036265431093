import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import {
  deleteTermDefinitionFail,
  deleteTermDefinitionSuccess,
  fetchAllExistingTermsStart,
  fetchAllTermDefStart,
} from '../actions';
import configActionTypes from '../types';

export function* deleteTerm({ payload }) {
  try {
    const response = yield httpClient.delete(`/term-definitions/${payload}`);
    yield put(deleteTermDefinitionSuccess(payload));
    Notify('Term deleted successfully', { position: 'top-right' });
    yield put(fetchAllTermDefStart());
    yield put(fetchAllExistingTermsStart());
  } catch (error) {
    yield put(deleteTermDefinitionFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteTermStart() {
  yield takeLatest(configActionTypes.DELETE_TERM_DEF_START, deleteTerm);
}
