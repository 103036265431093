import { takeLatest, put } from 'redux-saga/effects';
import { deleteSingleRatingSuccess, deleteSingleRatingFail } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* deleteSingleRating({ payload }) {
  try {
    const response = yield httpClient.delete(
      `/trait-configuration/trait-ratings/definitions/${payload}`,
    );
    yield put(deleteSingleRatingSuccess(response.data));
    Notify('Sub-Rating deleted succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(deleteSingleRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onDeleteSingleRatingStart() {
  yield takeLatest(earlyYearsActionTypes.DELETE_SINGLE_RATING_START, deleteSingleRating);
}
