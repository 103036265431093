import { all, call } from 'redux-saga/effects';
import { onfetchInvoicesStart } from './fetchInvoices';
import { onfetchInvoicesClassLevelStart } from './fetchInvoicesClassLevel';
import { onfetchInvoicesSessionsStart } from './fetchInvoicesSessions';
import { onfetchInvoicesItemsStart } from './fetchInvoicesItems';
import { onfetchInvoicesTermsStart } from './fetchInvoicesTerms';
import { onCreateInvoiceStart } from './createInvoice';
import { onEditInvoiceStart } from './editInvoice';
import { onDeleteInvoiceStart } from './deleteInvoice';
import { onfetchAllTermsStart } from './fetchAllTerms';
import { onFetchStudentInvoices } from './fetchStudentInvoices';
import { onAddItemToInvoiceStart } from './addItemToInvoice';
import { onFetchInvoiceDiscount } from './fetchInvoiceDiscount';
import { onAddInvoiceDiscount } from './addInvoiceDiscount';
import { onDeleteInvoiceDiscount } from './deleteInvoiceDiscount';
import { onDeleteItemFromInvoice } from './deleteItemFromInvoice';
import { onMakePaymentStart } from './makePayment';
import { onEditInvoiceItemsStart } from './editInvoiceItems';
import { onReceivePaymentStart } from './receivePayment';
import { onSendBillMailStart } from './sendOutstandingBillMail';
import { onGetCheckoutStart } from './getCheckoutDetails';
import { onGetStudentInvoiceSummary } from './getStudentInvoiceSummary';
import { onFetchPaymentReceipt } from './fetchPaymentReceipts';
import { onVerifyPaymentStart } from './verifyPayment';
import { onGetTransactionLogStart } from './transactionLogSaga';
import { onAdminEditInvoiceItemsStart } from './adminEditInvoiceItem';
import { onFetchRevenueSummaryStart } from './fetchRevenueSummary';
import { onFetchRevenueStatisticsStart } from './fetchRevenueStatistics';
import { onParentAddInvoiceItemStart } from './parentAddItemToInvoice';
import { onGetSinglePageCheckoutStart } from './getSinglePageCheckout';
import { onMakeDirectPayStart } from './makeDirectPay';
import { onGetBulkPaymentInfoStart } from './getBulkPaymentInfo';
import { onMakeBulkPaymentStart } from './makeBulkPayment';

export function* InvoiceSagas() {
  yield all([
    call(onfetchInvoicesStart),
    call(onfetchInvoicesClassLevelStart),
    call(onfetchInvoicesSessionsStart),
    call(onfetchInvoicesItemsStart),
    call(onfetchInvoicesTermsStart),
    call(onCreateInvoiceStart),
    call(onEditInvoiceStart),
    call(onDeleteInvoiceStart),
    call(onfetchAllTermsStart),
    call(onFetchStudentInvoices),
    call(onAddItemToInvoiceStart),
    call(onFetchInvoiceDiscount),
    call(onAddInvoiceDiscount),
    call(onDeleteInvoiceDiscount),
    call(onDeleteItemFromInvoice),
    call(onMakePaymentStart),
    call(onEditInvoiceItemsStart),
    call(onReceivePaymentStart),
    call(onSendBillMailStart),
    call(onGetCheckoutStart),
    call(onGetStudentInvoiceSummary),
    call(onFetchPaymentReceipt),
    call(onVerifyPaymentStart),
    call(onGetTransactionLogStart),
    call(onAdminEditInvoiceItemsStart),
    call(onFetchRevenueSummaryStart),
    call(onFetchRevenueStatisticsStart),
    call(onParentAddInvoiceItemStart),
    call(onGetSinglePageCheckoutStart),
    call(onMakeDirectPayStart),
    call(onGetBulkPaymentInfoStart),
    call(onMakeBulkPaymentStart),
  ]);
}
