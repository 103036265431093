import { StudentBillRestControllerService } from 'generated';
import { put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from 'utils/http-client';
import { fetchStudentInvoicesFail, fetchStudentInvoicesSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* fetchStudentInvoices({ payload }) {
  if (!payload['term-id'] && !payload.month && !payload.year) {
    yield put(fetchStudentInvoicesFail());
  } else {
    try {
      const response = yield apiWrapper(() =>
        StudentBillRestControllerService.getStudentInvoiceItemsUsingGet({
          studentId: payload['student-id'],
          termId: payload['term-id'],
          month: payload.month,
          year: payload.year,
        }),
      );
      yield put(fetchStudentInvoicesSuccess(response));
    } catch (error) {
      yield put(fetchStudentInvoicesFail(error?.message));
    }
  }
}

export function* onFetchStudentInvoices() {
  yield takeLatest(invoiceActionTypes.FETCH_STUDENT_INVOICES_START, fetchStudentInvoices);
}
