import { takeLatest, put } from 'redux-saga/effects';
import { deleteStaffDocumentsFailure, deleteStaffDocumentsSuccess } from '../actions';
import httpClient from 'utils/http-client';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from '../../../utils/utils';
import { fetchUserDocsStart } from 'redux/users/actions';

export function* deleteStaffDocumet({ payload }) {
  const { data, staff_id, fromUser } = payload;
  try {
    const response = yield httpClient.delete(
      `/documents/staff/${encodeURIComponent(staff_id)}?documentKey=${data.aws_s3_key}`,
    );
    yield put(deleteStaffDocumentsSuccess(data.aws_s3_key));
    Notify('Document deleted successfully', { position: 'top-right' });

    if (fromUser) {
      const user_id = JSON.parse(localStorage.getItem('user')).id;
      yield put(fetchUserDocsStart(user_id, 'staff'));
    }
  } catch (error) {
    yield put(deleteStaffDocumentsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteStaffDocumentStart() {
  yield takeLatest(staffActionTypes.DELETE_STAFF_DOCUMENTS_START, deleteStaffDocumet);
}
