/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWeeklyAssessmentQuestionRequest } from '../models/CreateWeeklyAssessmentQuestionRequest';
import type { UpdateWeeklyAssessmentQuestionRequest } from '../models/UpdateWeeklyAssessmentQuestionRequest';
import type { WeeklyAssessmentQuestionDto } from '../models/WeeklyAssessmentQuestionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WeeklyAssessmentQuestionRestControllerService {
  /**
   * getAllByAssessmentId
   * @returns WeeklyAssessmentQuestionDto OK
   * @throws ApiError
   */
  public static getAllByAssessmentIdUsingGet({
    weeklyAssessmentId,
  }: {
    /**
     * weeklyAssessmentId
     */
    weeklyAssessmentId: string;
  }): CancelablePromise<Array<WeeklyAssessmentQuestionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weekly-assessment-question',
      query: {
        weeklyAssessmentId: weeklyAssessmentId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * create
   * @returns WeeklyAssessmentQuestionDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static createUsingPost4({
    request,
  }: {
    /**
     * request
     */
    request: CreateWeeklyAssessmentQuestionRequest;
  }): CancelablePromise<Array<WeeklyAssessmentQuestionDto> | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/weekly-assessment-question',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * update
   * @returns WeeklyAssessmentQuestionDto OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateUsingPut5({
    request,
  }: {
    /**
     * request
     */
    request: UpdateWeeklyAssessmentQuestionRequest;
  }): CancelablePromise<Array<WeeklyAssessmentQuestionDto> | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/weekly-assessment-question',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * deleteAllByWeeklyAssessment
   * @returns any OK
   * @throws ApiError
   */
  public static deleteAllByWeeklyAssessmentUsingDelete({
    weeklyAssessmentId,
  }: {
    /**
     * weekly-assessment-id
     */
    weeklyAssessmentId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/weekly-assessment-question/{/weekly-assessment-id}',
      path: {
        'weekly-assessment-id': weeklyAssessmentId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * getById
   * @returns WeeklyAssessmentQuestionDto OK
   * @throws ApiError
   */
  public static getByIdUsingGet1({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<WeeklyAssessmentQuestionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weekly-assessment-question/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * delete
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUsingDelete4({
    id,
  }: {
    /**
     * id
     */
    id: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/weekly-assessment-question/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
