import { Box, Grid, Spacer } from '@flexisaf/flexibull2';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function Skeletonloader() {
  return (
    <Box width="100%" vAlign>
      <Box width="100%" margin="30px" align="left">
        <SkeletonTheme color="#FAFAFA" highlightColor="#F8F8F8">
          <Grid default="repeat(4, 1fr)" sm="1fr">
            <Skeleton height={120} />
            <Skeleton height={120} />
            <Skeleton height={120} />
            <Skeleton height={120} />
          </Grid>
          <Spacer />
          <Skeleton count={25} height={30} />
        </SkeletonTheme>
      </Box>
    </Box>
  );
}
