import { put, takeLatest } from 'redux-saga/effects';
import internalRolesActionTypes from '../types';
import { fetchInternalRolesSuccess, fetchInternalRolesFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchInternalRoles() {
  try {
    const response = yield httpClient.get('/user-roles');
    yield put(fetchInternalRolesSuccess(response.data));
  } catch (error) {
    yield put(fetchInternalRolesFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchInternalRolesStart() {
  yield takeLatest(internalRolesActionTypes.FETCH_LIST_OF_INTERNAL_ROLES_START, fetchInternalRoles);
}
